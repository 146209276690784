import { styled } from '@mui/material';

export const QuestionsCompTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: 'transparent',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'light' ? '#101010' : '#FFF',
  borderRadius: '8px',
}));

export const KamCardTitle = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  margin: '12px',
});

export const QuestionsCompContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '8px',
});
