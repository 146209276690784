import React, { FC } from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
}

const SVGCalendar: FC<Props> = ({ width, height, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill={color}
    height={height}
    width={width}
    viewBox='3 1 18 20'
  >
    <path d='M9 10v2H7v-2h2m4 0v2h-2v-2h2m4 0v2h-2v-2h2m2-7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1m0 16V8H5v11h14M9 14v2H7v-2h2m4 0v2h-2v-2h2m4 0v2h-2v-2h2Z' />
  </svg>
);

export default SVGCalendar;
