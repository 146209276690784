import React, { useState, useCallback } from 'react';
import { Stack, Button, TextField, Tooltip } from '@mui/material';
import { styled, useTheme, keyframes } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const slide = keyframes`
    from {
        background-position: 28px 0;
    }
    to {
        background-position: 0 0;
    }
`;

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type UploadFileFieldProps = {
  name: string;
  defaultValue: string;
  value: string[];
  onClick: (value: string[]) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const UploadFileField = ({
  name,
  value,
  onChange,
  onChangeInput,
  onClick,
  defaultValue = '',
}: UploadFileFieldProps) => {
  const theme = useTheme();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files.length) {
        const dataTransfer = new DataTransfer();
        Array.from(files).forEach((file) => {
          dataTransfer.items.add(file);
        });
        onChange({
          target: { files: dataTransfer.files, name },
        } as React.ChangeEvent<HTMLInputElement>);
      }
      setIsDragOver(false);
    },
    [onChange, name]
  );

  const dragOverStyle = isDragOver
    ? {
        animation: `${slide} 1s linear infinite`,
        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.dark} 25%, transparent 25%, transparent 50%, ${theme.palette.primary.dark} 50%, ${theme.palette.primary.dark} 75%, transparent 75%, transparent)`,
        backgroundSize: '28px 28px',
      }
    : {};

  return (
    <Stack
      direction='row'
      gap={1}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <TextField
        key={name + 'input'}
        id={name + 'input'}
        label={name}
        placeholder={name}
        onChange={onChangeInput}
        onClick={() => onClick(value)}
        sx={{
          flexGrow: 1,
          ...dragOverStyle,
        }}
        {...{ value, name }}
      />
      <Tooltip arrow title='También puedes arrastrar y soltar el archivo aquí'>
        <Button
          component='label'
          variant='outlined'
          startIcon={<CloudUploadIcon />}
          sx={{
            minWidth: 180,
            borderStyle: 'dashed',
            '&:hover': {
              borderStyle: 'dashed',
            },
            ...dragOverStyle,
          }}
        >
          Subir archivo
          <VisuallyHiddenInput
            type='file'
            accept='.pdf,.doc,.docx,.xls,.xlsx'
            {...{ name, defaultValue, onChange }}
          />
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default UploadFileField;
