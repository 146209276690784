import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { WitiPointsModalContainer } from './WitiPointsModal.styles';
import { DateTime } from 'luxon';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { WitiPointsInterface } from 'components/models/WitiPointsInterface';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import CloseIcon from '@mui/icons-material/Close';
import { formatNumber } from '../../../helpers/helpers';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  setOpenWitiPoints?: (r: boolean) => void;
  openWitiPoints?: boolean;
  idMentee: string;
  reloadWitiPoints?: number;
  setReloadWitiPoints?: (r: number) => void;
}

const WitiPointsModal: FC<Props> = ({
  openWitiPoints,
  setOpenWitiPoints,
  idMentee,
  reloadWitiPoints,
  setReloadWitiPoints,
}) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { REACT_APP_BACKEND_URL } = process.env;
  const { access_token, _id } = storeAuth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const handleCloseWitiPoints = () => setOpenWitiPoints(false);

  const [witiPoints, setWitiPoints] = useState<WitiPointsInterface>({
    mentee_id: '',
    responsable_id: '',
    responsable: '',
    fecha: DateTime.now().toISO().toLocaleString(),
    puntos: 0,
    motivo: '',
    descripcion: '',
  });

  useEffect(() => {
    setWitiPoints((prevState) => ({ ...prevState, mentee_id: idMentee, responsable_id: _id }));
  }, [_id, idMentee]);

  const [witiPointsList, setWitiPointsList] = useState([
    {
      mentee_id: '',
      responsable_id: '',
      responsable: '',
      fecha: DateTime.now().toISO().toLocaleString(),
      puntos: 0,
      motivo: '',
      descripcion: '',
    },
  ]);

  const getAllWitiPoints = useCallback(() => {
    getAxios
      .instance('')
      .get(`${REACT_APP_BACKEND_URL}/witipoints/${idMentee}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result) => {
        setWitiPointsList(result.data);
        setIsFetching(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [access_token, idMentee, REACT_APP_BACKEND_URL]);

  useEffect(() => {
    getAllWitiPoints();
  }, [getAllWitiPoints]);

  const handleClick = () => {
    if (witiPoints.motivo === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un motivo',
        icon: 'error',
        confirmButtonText: 'Entendido',
        showConfirmButton: true,
      });
      return null;
    }
    if (witiPoints.puntos === 0) {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar los puntos a sumar o restar',
        icon: 'error',
        confirmButtonText: 'Entendido',
        showConfirmButton: true,
      });
      return null;
    } else {
      setWitiPoints((prevWitiPoints) => ({
        ...prevWitiPoints,
        responsable: '',
        fecha: DateTime.now().toISO().toLocaleString(),
        puntos: 0,
        motivo: '',
        descripcion: '',
      }));

      setIsLoading(true);

      getAxios
        .instance('')
        .post(`${REACT_APP_BACKEND_URL}/witipoints`, witiPoints, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result) => {
          getAllWitiPoints();
          setReloadWitiPoints(reloadWitiPoints + 1);
        });
    }
  };

  const totalWitiPoints = witiPointsList.reduce((total, witiPoint) => total + witiPoint.puntos, 0);

  return (
    <Modal open={openWitiPoints} onClose={handleCloseWitiPoints} sx={{ zIndex: 1000 }}>
      <WitiPointsModalContainer>
        <IconButton
          title='Cerrar'
          aria-label='Cerrar'
          onClick={handleCloseWitiPoints}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack justifyContent='center' mb={6}>
          <Typography variant='h4' component='h2' align='center'>
            WiTI Points
          </Typography>
          <Typography variant='h2' component='div' align='center' color='primary'>
            {isLoading || isFetching ? '...' : formatNumber(totalWitiPoints)}
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} mb={3}>
          <FormControl sx={{ width: '20ch' }}>
            <InputLabel id='motivo-label'>Motivo</InputLabel>
            <Select
              id='motivo'
              labelId='motivo-label'
              label='Motivo'
              value={witiPoints.motivo}
              onChange={(e) => {
                setWitiPoints((prevWitiPoints) => ({
                  ...prevWitiPoints,
                  motivo: e.target.value as string,
                }));
              }}
            >
              <MenuItem value={''}></MenuItem>
              <MenuItem value={'Migración'}>Migración</MenuItem>
              <MenuItem value={'Certificación'}>Certificación</MenuItem>
              <MenuItem value={'Feedback laboral'}>Feedback laboral</MenuItem>
              <MenuItem value={'Canjeo por artículo'}>Canjeo por artículo</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id='outlined-number'
            label='Puntos'
            type='number'
            value={witiPoints.puntos}
            onChange={(e) => {
              setWitiPoints((prevWitiPoints) => ({
                ...prevWitiPoints,
                puntos: Number(e.target.value),
              }));
            }}
            sx={{ width: '10ch' }}
          />

          <TextField
            id='outlined-basic'
            label='Descripción'
            value={witiPoints.descripcion}
            onChange={(e) => {
              setWitiPoints((prevWitiPoints) => ({
                ...prevWitiPoints,
                descripcion: e.target.value as string,
              }));
            }}
            sx={{ flexGrow: 1 }}
          />

          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            variant='contained'
            onClick={handleClick}
            startIcon={<SaveIcon />}
          >
            Guardar
          </LoadingButton>
        </Stack>
        {isFetching ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Fecha</TableCell>
                  <TableCell align='left'>Responsable</TableCell>
                  <TableCell align='right'>Puntos</TableCell>
                  <TableCell align='left'>Motivo</TableCell>
                  <TableCell align='left'>Descripción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(witiPointsList) &&
                  witiPointsList
                    .sort(({ fecha: fechaA }, { fecha: fechaB }) => {
                      return fechaA > fechaB ? -1 : 1;
                    })
                    .map((witiPoint: WitiPointsInterface, index: number) => (
                      <TableRow key={index}>
                        <TableCell component='th' scope='row' align='left'>
                          {DateTime.fromISO(witiPoint.fecha).toLocaleString({
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </TableCell>
                        <TableCell component='th' scope='row' align='left'>
                          {witiPoint.responsable}
                        </TableCell>
                        <TableCell component='th' scope='row' align='right'>
                          {formatNumber(witiPoint.puntos)}
                        </TableCell>
                        <TableCell component='th' scope='row' align='left'>
                          {witiPoint.motivo}
                        </TableCell>
                        <TableCell align='left'>{witiPoint.descripcion}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </WitiPointsModalContainer>
    </Modal>
  );
};

export default WitiPointsModal;
