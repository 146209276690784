import React, { createContext, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useColorMode, { type UseColorModeReturn } from '../hooks/useColorMode';
import { getTheme } from '../styles/theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

interface Props {
  children?: React.ReactNode;
}

export const ColorModeContext = createContext<UseColorModeReturn>(null);

function ThemeContextProvider({ children }: Props) {
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useMemo(() => getTheme(colorMode), [colorMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

export default ThemeContextProvider;
