import React, { FC } from 'react';
import { StyledContainer } from './style';
import FilterButtonTableCard from '../filterButtonTableCard/FilterButtonTableCard';

interface Props {
  getSortedRequestByParamenter: (key: string) => void;
  orderedRequest: boolean;
}

const TableHeadRowRequest: FC<Props> = ({ getSortedRequestByParamenter, orderedRequest }) => {
  return (
    <StyledContainer>
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Empresa'
        label='Empresa'
      />
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Rol'
        label='Rol'
      />
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Nivel'
        label='Nivel'
      />
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Fecha Inicio'
        label='Fecha Inicio'
      />
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Fecha Fin'
        label='Fecha Fin'
      />
      <FilterButtonTableCard
        onClick={getSortedRequestByParamenter}
        state={orderedRequest}
        onClickParameter='Plazo'
        label='Plazo'
      />
    </StyledContainer>
  );
};

export default TableHeadRowRequest;
