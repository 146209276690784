import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import TeamFormImage from '../teamFormImage/TeamFormImage';
import Mentees from 'assets/alumnos.png';
import CreateTeamMemberModal from './createTeamMemberModal/CreateTeamMemberModal';
import { useQuery } from '@tanstack/react-query';
import TeamFormAddMembers from './teamFormAddMembers/TeamFormAddMembers';
import type { TeamFormStepProps } from '../../CreateTeam.types';
import type { Mentee } from 'components/ui/MenteeView/MenteeView.types';

const TeamFormStepMembers = ({ teamInfo, setTeamInfo }: TeamFormStepProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isTL, setIsTL] = React.useState(false);

  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;

  const { data, isLoading, isRefetching, refetch } = useQuery<Mentee[]>({
    queryKey: ['/employee?rol&nivel', access_token],
  });

  const handleOpenModal = (isTL: boolean) => {
    setOpenModal(true);
    if (isTL) {
      setIsTL(true);
    } else {
      setIsTL(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <CreateTeamMemberModal
        open={openModal}
        setOpen={setOpenModal}
        isTL={isTL}
        setTeamInfo={setTeamInfo}
        refetchMembers={refetch}
      />
      <Grid item xs={4}>
        <TeamFormImage imageUrl={Mentees} />
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ mt: '20px' }}>
          <Typography variant='h4' gutterBottom>
            Integrantes
          </Typography>
          <Typography>
            Selecciona el líder técnico y los desarrolladores que formarán el equipo.
          </Typography>
        </Box>
        <TeamFormAddMembers
          teamInfo={teamInfo}
          setTeamInfo={setTeamInfo}
          data={isLoading || isRefetching ? [] : data}
          loading={isLoading || isRefetching}
          handleOpenModal={handleOpenModal}
        />
      </Grid>
    </Grid>
  );
};

export default TeamFormStepMembers;
