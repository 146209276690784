import React, { useState, useEffect } from 'react';

interface UseLocalStorageStateProps<T> {
  storageKey: string;
  initialState: T;
}

/**
 * Hook personalizado para persistir cualquier estado en el almacenamiento local del navegador.
 * Este hook puede ser utilizado para persistir el estado entre diferentes sesiones de usuario.
 *
 * @param {Object} params - Un objeto que contiene las propiedades necesarias para el hook.
 * @param {string} params.storageKey - La clave bajo la cual se almacenará el estado en el almacenamiento local.
 * @param {T} params.initialState - El estado inicial para el hook.
 *
 * @returns {Array} Un array con dos elementos: el estado actual y una función para actualizar el estado.
 * El estado actual es del tipo que se pasó como parámetro `T` al hook.
 * La función para actualizar el estado es una función de despacho de React que acepta una acción de estado de React.
 *
 * @template T - El tipo de estado que se va a persistir. Este debe ser un tipo que pueda ser serializado a JSON.
 *
 * @example
 * const [userPreferences, setUserPreferences] = useLocalStorageState({
 *   storageKey: 'user_preferences',
 *   initialState: {},
 * });
 */
function useLocalStorageState<T>({
  storageKey,
  initialState,
}: UseLocalStorageStateProps<T>): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    const localStorageState = localStorage.getItem(storageKey);
    return localStorageState ? JSON.parse(localStorageState) : initialState;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [storageKey, state]);

  return [state, setState];
}

export default useLocalStorageState;
