import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const StyledCardHeader = styled(Card.Header)`
  background-color: #f15b29;
`;

export const StyledIcon = styled.span`
  & > * {
    color: #2857a7;
    cursor: pointer;
    font-size: 20px;
    margin: 20px 10px;
    transition: color 200ms ease;
    text-decoration: none;
    &:hover {
      color: #f15b29;
    }
  }
`;

export const StyledCard = styled(Card)`
  margin: 0 10px 10px 10px;
  min-width: 200px;
  text-align: center;
  @media only screen and (max-width: 498px) {
    width: 100%;
    min-width: 200px;
  }
`;
export const StyledCardText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px 0;
`;

export const StyledIconSpan = styled.span`
  color: #2857a7;
  cursor: pointer;
  font-size: 20px;
  margin: 20px 10px;
  transition: color 200ms ease;
  &:hover {
    color: #f15b29;
  }
`;

export const StyledLinkHeader = styled(Link)`
  color: white;
  text-decoration: none !important;
  transition: 200ms ease;
  font-weight: 600;
  &:hover {
    color: #2857a7;
  }
`;
