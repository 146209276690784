import { styled, Paper } from '@mui/material';

export const WitiPointsModalContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  maxWidth: '800px',
  maxHeight: '90%',
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  overflow: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxHeight: '720px',
  },
}));
