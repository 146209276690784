import styled from '@emotion/styled/macro';
import TableBootstrap from 'react-bootstrap/Table';

export const StyledTableDesktop = styled(TableBootstrap)`
  display: inline-table;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
export const StyledTableMobile = styled.div`
  display: none;
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;
