import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import TeamsTable from './teamsTable/TeamsTable';
import GroupsIcon from '@mui/icons-material/Groups';
import PageHeader from '../../ui/PageHeader';

function Teams() {
  return (
    <>
      <PageHeader>Equipos</PageHeader>
      <Box mb={2}>
        <Button
          component={RouterLink}
          to='/teams/create'
          variant='contained'
          startIcon={<GroupsIcon />}
        >
          Nuevo equipo
        </Button>
      </Box>
      <TeamsTable />
    </>
  );
}

export default Teams;
