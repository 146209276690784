export const meetingActions = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  gap: '1rem',
  backgroundColor: '#7b9bd914',
  padding: '1rem',
  borderRadius: '1rem',
};

export const actionBtn = {
  color: 'white',
  width: '50px',
  height: '60px',
};

export const actionBtnLeft = {
  backgroundColor: '#31cff214',
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
};

export const actionBtnRight = {
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
};

export const popperStyle = {
  zIndex: 1000,
  backgroundColor: 'rgba(43, 48, 61, 0.9)',
  borderRadius: '1rem',
  width: 'fit-content',
  padding: '0.5em 1rem',
};
