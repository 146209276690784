import React, { FC } from 'react';
import { IPreguntas } from 'components/models/SkillsInterfaces';
import GlossaryAnswer from './GlossaryAnswer';
import {
  StyledGlossaryQuestion,
  StyledGlossarySkillContainer,
  StyledGlossarySubskillCardContainer,
  StyledGlossarySubskillsContainer,
  StyledGlossarySubskillsTitle,
  StyledGlossarySubtitle,
} from './style';

interface Props {
  questionName: string;
  questions: IPreguntas[];
  counterID: number;
}

const GlossarySubSkills: FC<Props> = ({ questions, questionName, counterID }) => {
  return (
    <StyledGlossarySubskillsContainer key={`box-gss${counterID}`}>
      <StyledGlossarySubskillsTitle key={`text-gss${counterID}`}>
        {questionName}
      </StyledGlossarySubskillsTitle>{' '}
      {/*react-angular-css*/}
      <StyledGlossarySkillContainer key={`sub-box-gss${counterID}`}>
        {questions &&
          questions.map((question: IPreguntas, i: number) => {
            return (
              <StyledGlossarySubskillCardContainer key={`box-map-gss${i}`}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <StyledGlossarySubtitle key={`text-subtitle-gss${i}`}>
                    {question.nombre}
                  </StyledGlossarySubtitle>
                  <StyledGlossaryQuestion key={`text-desc-gss${i}`}>
                    {question.description}
                  </StyledGlossaryQuestion>
                </div>
                <GlossaryAnswer counterID={i} answers={question.respuestas} type={question.tipo} />
              </StyledGlossarySubskillCardContainer>
            );
          })}
      </StyledGlossarySkillContainer>
    </StyledGlossarySubskillsContainer>
  );
};

export default GlossarySubSkills;
