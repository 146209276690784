import React from 'react';
import { Typography, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

type Props = {
  isPrivate?: boolean;
  children?: React.ReactNode;
};

const PersonalInfoHeadItem = ({ children, isPrivate }: Props) => {
  return (
    <Box>
      <Typography style={{ display: 'inline-flex', marginTop: '5px' }}>
        {isPrivate ? <LockIcon fontSize='small' titleAccess='Este campo es privado' /> : null}
      </Typography>
      {children}
    </Box>
  );
};

export default PersonalInfoHeadItem;
