import { useState, useEffect, useMemo } from 'react';
import { type PaletteMode } from '@mui/material';

export type UseColorModeReturn = { colorMode: PaletteMode; toggleColorMode: () => void };

const STORAGE_KEY = 'levelup.colorMode';
const DEFAULT_COLOR_MODE: PaletteMode = 'dark';

/**
 * Custom hook para gestionar el modo de color (light/dark) en Material UI.
 *
 * Este hook se encarga de persistir el estado del modo de color entre las sesiones del usuario
 * utilizando el almacenamiento local del navegador.
 *
 * Aunque este hook puede ser utilizado de manera independiente, su diseño original contempla su uso
 * dentro de un Proveedor de Contexto Global. Esta decisión de diseño permite una separación de responsabilidades,
 * manteniendo la lógica de cambio de modo de color encapsulada dentro del hook, mientras que la propagación del estado
 * se maneja a nivel del Proveedor de Contexto Global.
 *
 * @returns {UseColorModeReturn} Un objeto con dos propiedades:
 * - `colorMode`: El modo de color actual, que puede ser 'light' o 'dark'.
 * - `toggleColorMode`: Una función que alterna entre el modo de color actual y el otro modo.
 */
function useColorMode(): UseColorModeReturn {
  const [colorMode, setColorMode] = useState<PaletteMode>(() => {
    const localColorMode = window.localStorage.getItem(STORAGE_KEY) as PaletteMode;
    return localColorMode ? localColorMode : DEFAULT_COLOR_MODE;
  });

  useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY, colorMode);
  }, [colorMode]);

  const colorModeControl = useMemo(
    () => ({
      colorMode,
      toggleColorMode: () => {
        setColorMode((prevColorMode) => (prevColorMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [colorMode]
  );

  return colorModeControl;
}

export default useColorMode;
