import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography, Button } from '@mui/material';
import 'firebase/app';
import 'firebase/auth';
import { logoutFirebase } from 'utils/firebase';
import { AuthContext } from 'services/authContext/AuthContextProvider';

const LoginStatus = () => {
  const history = useHistory();
  const { actionsAuth } = useContext(AuthContext)!;

  const logout = () => {
    logoutFirebase(history, actionsAuth.logout);
    window.location.reload();
  };

  return (
    <Stack spacing={6} width='100%'>
      <Box>
        <Typography component='h1' variant='h4' fontWeight='bolder' gutterBottom>
          Esperando aprobación
        </Typography>
        <Typography>
          Esperando la aprobación del administrador para ingresar a <strong>LevelUp</strong>.
        </Typography>
      </Box>

      <Button size='large' variant='contained' fullWidth onClick={logout}>
        Volver
      </Button>
    </Stack>
  );
};

export default LoginStatus;
