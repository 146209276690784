interface CountryType {
  code: string;
  label: string;
  typeCoin: string;
  suggested?: boolean;
}

export const countries: readonly CountryType[] = [
  { code: 'CL', label: 'Chile', typeCoin: 'CLP' },
  { code: 'UY', label: 'Uruguay', typeCoin: 'UYU' },
  { code: 'US', label: 'United States', typeCoin: 'USD' },
  { code: 'CO', label: 'Colombia', typeCoin: 'COP' },
];
