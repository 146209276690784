import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { ChartStyle } from 'components/pages/report/style';
import { useTheme } from '@mui/material';

const Chart = ({ filter, chartId, height, width, baseUrl }) => {
  const theme = useTheme();
  const chartDiv = useRef(null);
  const chartRef = useRef(null);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({ baseUrl });

    if (chartDiv.current && !chartRef.current) {
      chartRef.current = sdk.createChart({
        chartId: chartId,
        height: height,
        width: width,
        theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
        showAttribution: false,
      });

      chartRef.current
        .render(chartDiv.current)
        .then(() => setRendered(true))
        .catch((err) => console.error('Error during Charts rendering.', err));
    }
  }, [chartId, height, width, theme, baseUrl]);

  useEffect(() => {
    if (rendered) {
      chartRef.current
        .setFilter(filter)
        .catch((err) => console.error('Error while filtering.', err));
    }
  }, [filter, rendered]);

  return <ChartStyle ref={chartDiv}></ChartStyle>;
};

export default Chart;
