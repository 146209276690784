import React, { useEffect, useState } from 'react';
import FakeProgress from 'fake-progress';
import { Backdrop, LinearProgress, Stack, Typography } from '@mui/material';
import PsychologyIcon from '@mui/icons-material/Psychology';

type FakeProgressIndicatorProps = {
  expectedDuration?: number;
  shouldEndProgress?: boolean;
};

function FakeProgressIndicator({
  expectedDuration = 10,
  shouldEndProgress = false,
}: FakeProgressIndicatorProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const expectedProgressAtTime = 0.9;
    const calculatedTimeConstant = -expectedDuration / Math.log(1 - expectedProgressAtTime);

    const fakeProgress = new FakeProgress({
      timeConstant: calculatedTimeConstant * 1000,
      autoStart: true,
    });

    if (shouldEndProgress) fakeProgress.end();

    const interval = setInterval(() => {
      setProgress(fakeProgress.progress * 100);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [expectedDuration, shouldEndProgress]);

  return (
    <LinearProgress
      variant='determinate'
      value={progress}
      sx={{ width: '100%', maxWidth: 400, mt: 4 }}
    />
  );
}

type RecommendationsProgressProps = FakeProgressIndicatorProps & {
  show: boolean;
};

function RecommendationsProgress({
  show,
  expectedDuration,
  shouldEndProgress,
}: RecommendationsProgressProps) {
  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    let backdropTimer: NodeJS.Timeout;

    if (show) {
      setShowBackdrop(true);
    } else {
      backdropTimer = setTimeout(() => {
        setShowBackdrop(false);
      }, 1000);
    }

    return () => {
      clearTimeout(backdropTimer);
    };
  }, [show]);

  return (
    <Backdrop
      open={showBackdrop}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }}
      unmountOnExit
    >
      <Stack alignItems='center' mb={2} width='100%'>
        <PsychologyIcon sx={{ fontSize: '100px' }} />
        <Typography variant='h5' fontWeight='bold'>
          Generando recomendaciones
        </Typography>
        <FakeProgressIndicator
          expectedDuration={expectedDuration}
          shouldEndProgress={shouldEndProgress}
        />
      </Stack>
    </Backdrop>
  );
}

export default RecommendationsProgress;
