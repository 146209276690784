import React, { FC } from 'react';
import MarkdownEditor from '../markdownEditor';
import { Box, Card, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ISkillsDimensions } from 'components/models/SkillsInterfaces';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';
import { DivGlowEffect } from './style';

type FormProps = {
  tabActive: string;
  tabController: string;
  interviewDetails: CandidateInterface2;
  primaryControllerName: string;
  idMentee: string;
  type: string;
  glowingComponent: string;
  makeGlowingAnimation: boolean;
};

type GlowingMarkdownProps = {
  setValue: (
    name: string,
    value: any,
    options?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
  ) => void;
  getValues: (name?: string | string[]) => string | undefined;
  sendSkillsSocket: (skillIndex: number, dimensionIndex: number, newValue: string) => void;
  skillIndex: number;
  dimensionIndex: number;
  skillDimensions: ISkillsDimensions;
  control: Control<FormProps>;
  textAreaRules: object;
  socketConnected: boolean;
  setFormIsDirty: (isDirty: boolean) => void;
  watch: (value: string) => any;
};

export const GlowingMarkdownEditor: FC<GlowingMarkdownProps> = ({
  setFormIsDirty,
  setValue,
  getValues,
  watch,
  control,
  sendSkillsSocket,
  textAreaRules,
  skillDimensions,
  skillIndex,
  dimensionIndex,
  socketConnected,
}) => {
  const glowingComponent = watch(`glow-${skillIndex}-${dimensionIndex}`);
  const glowingClassName = glowingComponent === `on` ? 'glow' : '';

  const handleBlur = () => {
    if (socketConnected) {
      setValue(`glow-${skillIndex}-${dimensionIndex}`, `on`);
      sendSkillsSocket(
        skillIndex,
        dimensionIndex,
        getValues(`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`) ?? ''
      );
      setTimeout(() => setValue(`glow-${skillIndex}-${dimensionIndex}`, ''), 1000);
    }
  };
  if (
    getValues(`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`) ===
    undefined
  ) {
    setValue(`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`, '');
  }

  return (
    <DivGlowEffect className={glowingClassName} onBlur={handleBlur}>
      <Card variant='outlined' sx={{ padding: '12px' }}>
        <Box key={'box2' + skillIndex + '-' + dimensionIndex}>
          <Card sx={{ paddingTop: '10px', paddingLeft: '10px' }}>
            <Typography variant='h6' gutterBottom>
              {skillDimensions.nombre}
            </Typography>
          </Card>
          <br />
          <Typography variant='body1' gutterBottom>
            {skillDimensions.description}
          </Typography>
        </Box>
        <Controller
          control={control}
          name={`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`}
          rules={textAreaRules}
          render={({ field: { onChange, value } }) => {
            return (
              <MarkdownEditor
                key={'editor' + skillIndex + '-' + dimensionIndex}
                value={
                  getValues(
                    `interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`
                  ) ?? ''
                }
                onChange={(newValue: string) => {
                  setFormIsDirty(true);
                  onChange(newValue);
                }}
              />
            );
          }}
        />
      </Card>
    </DivGlowEffect>
  );
};
