import { DateTime } from 'luxon';
import findLast from 'lodash/findLast';
import { ROW_CLASSES } from './CandidatesTable.styles';
import { CandidateTableRow } from './CandidatesTable.types';
import {
  innerMotivoInterface,
  subEtapasInterface,
  WorkflowInterface,
} from '../../../models/WorkFlowInterfaces';
import { CandidateTableData } from '../../../pages/listCandidate/ListCandidate';

export function calculateDaysFromNow(date: string): number {
  const now: DateTime = DateTime.now();
  const inputDate: DateTime = DateTime.fromISO(date);

  const differenceInDays: number = now.diff(inputDate, 'days').days;
  const roundedDifference: number = Math.round(Math.abs(differenceInDays));

  return roundedDifference;
}

export function getFormattedDates(date: string) {
  const localeDate = DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const relativeDate = DateTime.fromISO(date).toRelative();
  const title = `${localeDate} (${relativeDate})`;
  return { localeDate, relativeDate, title };
}

function isWithinWeek(lastActiveStage: WorkflowInterface): boolean {
  const restaFecha: number = calculateDaysFromNow(lastActiveStage?.fecha);
  return restaFecha >= 4 && restaFecha <= 7;
}

function isOverWeek(lastActiveStage: WorkflowInterface): boolean {
  const restaFecha: number = calculateDaysFromNow(lastActiveStage?.fecha);
  return restaFecha > 7;
}

export function isStageRejected(stage: WorkflowInterface): boolean {
  const hasSelectedReason = stage?.motivo?.some((reason: innerMotivoInterface) => reason.selected);
  return hasSelectedReason || false;
}

export function getWorkflowStatusClassName(stages: WorkflowInterface[]): string {
  const lastActiveStage: WorkflowInterface = getLastActiveStage(stages);
  const isCandidateHired: boolean = lastActiveStage?.nombre === 'Contratado';
  const isCandidateRejected: boolean = isStageRejected(lastActiveStage);

  if (isCandidateHired) return ROW_CLASSES.BASE;
  if (isCandidateRejected) return ROW_CLASSES.BASE;
  if (isWithinWeek(lastActiveStage)) return `${ROW_CLASSES.BASE} ${ROW_CLASSES.WARNING}`;
  if (isOverWeek(lastActiveStage)) return `${ROW_CLASSES.BASE} ${ROW_CLASSES.DANGER}`;
  return `${ROW_CLASSES.BASE} ${ROW_CLASSES.SAFE}`;
}

export function getWorkflowStatusText(stages: WorkflowInterface[]): string | null {
  const lastActiveStage: WorkflowInterface = getLastActiveStage(stages);
  const lastActiveStageName: string = lastActiveStage?.nombre;
  const lastActiveSubStageName: string = getLastActiveSubStage(lastActiveStage)?.nombre;

  if (!lastActiveStageName) return null;

  return `${lastActiveStageName.trim()}${
    lastActiveSubStageName ? ` (${lastActiveSubStageName})` : ''
  }`;
}

export function getLastActiveStage(stages: WorkflowInterface[]): WorkflowInterface | null {
  const stage = findLast(stages, (stage: WorkflowInterface) => stage.estado);
  return stage || null;
}

export function getLastActiveSubStage(stage: WorkflowInterface | null): subEtapasInterface | null {
  if (stage?.subEtapas?.length) {
    const subStage = findLast(stage.subEtapas, (subStage: subEtapasInterface) => subStage.estado);
    return subStage || null;
  }
  return null;
}

export function mapDataToRows(candidatesData: CandidateTableData[]): CandidateTableRow[] {
  return candidatesData.map((candidateData: CandidateTableData) => ({
    responsable: candidateData.state.workflow[0].responsable, // SIEMPRE PRIMERA ETAPA
    nombre: candidateData.name.data,
    rol: candidateData.personalInfo.data.find((dato: any) => dato.nombre === 'Rol')?.valor,
    nivel: candidateData.personalInfo.data.find((dato: any) => dato.nombre === 'Nivel')?.valor,
    score: candidateData.personalInfo.data.find((dato: any) => dato.nombre === 'Score')?.valor,
    etapa: candidateData.state.workflow,
    fechaEtapaActual: candidateData.date.data,
    fechaInicio: candidateData.state.workflow[0]?.fecha || candidateData.date.data, // SIEMPRE PRIMERA ETAPA
    actions: candidateData.actions,
    tagged: candidateData.tagged.toString(),
  }));
}
