import styled from '@emotion/styled/macro';

export const StyledTr = styled.tr`
  background-color: #2857a7;
  color: white;
`;
export const StyledTh = styled.th`
  background-color: #0b5ed7 !important;
  color: white !important;
  border-right: 2px solid #f8f8ff;
`;
