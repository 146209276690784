import React, { FC } from 'react';
import { StyledTr, StyledTh } from './style';
import FilterThTableHead from '../filterThTableHead/FilterThTableHead';
import { Button } from 'react-bootstrap';

interface Props {
  getSortedCanditatesByParamenter: (key: string) => void;
  orderedCandidate: boolean;

  setTodos: (r: boolean) => void;
  loading: boolean;
}

const TableHeadInvitations: FC<Props> = ({
  getSortedCanditatesByParamenter,
  orderedCandidate,
  setTodos,
  loading,
}) => {
  return (
    <>
      <StyledTr>
        <StyledTh>
          <Button size='sm' variant='info' onClick={() => setTodos(true)} disabled={loading}>
            Enviar a todos
          </Button>
        </StyledTh>
        <StyledTh>Nombre</StyledTh>

        <FilterThTableHead
          onClick={getSortedCanditatesByParamenter}
          state={orderedCandidate}
          onClickParameter='Nivel'
          label='Nivel'
        />
        <FilterThTableHead
          onClick={getSortedCanditatesByParamenter}
          state={orderedCandidate}
          onClickParameter='estado'
          label='Etapa'
        />
        <FilterThTableHead
          onClick={getSortedCanditatesByParamenter}
          state={orderedCandidate}
          onClickParameter='fechaUltimaInvitacion'
          label='Fecha última invitación'
        />
        <FilterThTableHead
          onClick={getSortedCanditatesByParamenter}
          state={orderedCandidate}
          onClickParameter='timestamp'
          label='Fecha etapa actual'
        />
        <FilterThTableHead
          onClick={getSortedCanditatesByParamenter}
          state={orderedCandidate}
          onClickParameter='workflowInicial'
          label='Fecha inicio'
        />
        <StyledTh>Opciones</StyledTh>
      </StyledTr>
    </>
  );
};

export default TableHeadInvitations;
