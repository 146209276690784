import { Autocomplete, Box, Chip, Drawer, List, TextField } from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const ShowRoomFilter = ({
  selectedRoles,
  selectedSeniorities,
  onRoleChange,
  onSeniorityChange,
  uniqueRoles,
  uniqueSeniorities,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box role='filters'>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Filtros</Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        <Autocomplete
          multiple
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ width: '100%' }}
          options={uniqueSeniorities}
          value={selectedSeniorities} // Asegúrate de que selectedRoles sea controlado y esté actualizado con las selecciones actuales
          onChange={(event, newValue) => {
            onSeniorityChange(newValue); // Suponiendo que onRoleChange actualiza el estado de selectedRoles
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                key={`${option}-${index}`}
                variant='outlined'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} label='Seniority' />}
        />

        <Autocomplete
          multiple
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ width: '100%' }}
          options={uniqueRoles}
          value={selectedRoles} // Asegúrate de que selectedRoles sea controlado y esté actualizado con las selecciones actuales
          onChange={(event, newValue) => {
            onRoleChange(newValue); // Suponiendo que onRoleChange actualiza el estado de selectedRoles
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                key={`${option}-${index}`}
                variant='outlined'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} label='Rol' />}
        />
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '.5rem',
        display: 'flex',
        zIndex: 999,
        backgroundColor: '#113264',
        right: '.5rem',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ padding: '1.2rem' }} onClick={toggleDrawer(true)}>
        <FilterAltIcon sx={{ color: 'white', width: '24px', height: '24px' }} />{' '}
      </Box>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true, // Mejora el rendimiento en dispositivos móviles al evitar el montaje/desmontaje.
          // BackdropProps: {
          //   invisible: true,     // Box shadow de fondo transparente del Drawer
          // },
          disableScrollLock: true, // Esto previene que el Drawer bloquee el desplazamiento del cuerpo
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80vw',
            padding: '1rem',
            borderRadius: '1rem 1rem 0 0',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '9999999',
            opacity: 1,
            boxShadow: 'none',
            backgroundColor: '#113264',
            [theme.breakpoints.up('md')]: {
              width: '60vw',
            },
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
};

export default ShowRoomFilter;
