import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { StyledReactQuillContainer } from './styles';
import { TextEditorProps } from '../../types';

function QuillTextEditor({ value, onChange, onBlur, placeholder, id, className }: TextEditorProps) {
  return (
    <StyledReactQuillContainer>
      <ReactQuill
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        id={id}
        className={className}
        theme='snow'
      />
    </StyledReactQuillContainer>
  );
}

export default QuillTextEditor;
