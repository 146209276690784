import React, { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { allCountryOptions } from '../../../../../data/countryDataSpanish';

interface Props {
  label: string;
  value?: string;
  onChange: (event: SyntheticEvent, newValue: string) => void;
}
export default function CountrySelect({ onChange, label, value }: Props) {
  return (
    <Autocomplete
      sx={{ width: '100%' }}
      options={allCountryOptions}
      getOptionLabel={(option) => `${option.name} (${option.code.toUpperCase()})`}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.name} ({option.code.toUpperCase()})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...{ ...params, label }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      defaultValue={allCountryOptions.find((country) => country.code === value)}
      onChange={(event, value) => {
        onChange(event, value?.code ?? '');
      }}
    />
  );
}
