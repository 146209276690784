import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { MenteeView } from '../../ui/MenteeView/MenteeView';
import ROL from 'utils/Roles';
import FullscreenAlert from '../../ui/alerts/FullscreenAlert';

interface IMentee {
  idMentee: string;
}

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    No tienes un perfil Mentee.
  </FullscreenAlert>
);

const Mentee = () => {
  const { idMentee } = useParams<IMentee>();
  const [selectedMentee, setSelectedMentee] = useState<string>();
  const [isPending, setIsPending] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { storeAuth } = useContext(AuthContext)!;
  const { user } = storeAuth;

  useEffect(() => {
    const userRole = user.role[0];
    const userMenteeId = user.menteeId;

    if (idMentee && user) {
      if (userRole === ROL.MENTOR || userRole === ROL.ADMINISTRADOR) {
        setSelectedMentee(idMentee);
      } else {
        setSelectedMentee(userMenteeId);
      }
    } else if (user) {
      setSelectedMentee(userMenteeId);
    } else {
      setIsError(true);
    }
    setIsPending(false);
  }, [idMentee, user]);

  if (isPending) return;

  if (isError) return <ErrorAlert />;

  return <MenteeView idMentee={selectedMentee} />;
};

export default Mentee;
