export function getDifference<T>(array1: T[], array2: T[], key: keyof T): T[] {
  return array1.filter((object1) => !array2.some((object2) => object1[key] === object2[key]));
}

export function formatNumber(number: number): string {
  return new Intl.NumberFormat('es-CL').format(number);
}

export function joinArrayWithCommas(value: string | string[]): string {
  if (Array.isArray(value)) {
    return value.join(', ');
  } else {
    return value;
  }
}
