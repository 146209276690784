import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { loginGoogle, loginMicrosoft } from 'utils/firebase';
import { firebaseAnalytics } from 'utils/firebase';
import LoginButton from 'components/ui/login/LoginButton';

const Login = () => {
  const history = useHistory();
  const { actionsAuth } = useContext(AuthContext)!;
  const [loadedToken, setLoadedToken] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [tries, setTries] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    firebaseAnalytics.logEvent('Login Visitado');
  }, []);

  const login = useCallback(
    (token) => {
      if (token) {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user: any) => {
          !!user &&
            actionsAuth.login(user._delegate.accessToken, token, history, actionsAuth.logout);
        });
        return () => unregisterAuthObserver();
      }
    },
    [actionsAuth, history]
  );

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return;
    } else {
      try {
        const token = await executeRecaptcha('login');
        setLoadedToken(true);
        login(token);
      } catch (err) {
        setError(true);
        console.error(err);
      }
    }
    // eslint-disable-next-line
  }, [executeRecaptcha, tries]);

  const resetToken = useCallback(() => {
    setLoadedToken(false);
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleLoginGoogle = useCallback(() => {
    loginGoogle();
  }, []);

  const handleLoginMicrosoft = useCallback(() => {
    loginMicrosoft();
  }, []);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    if (error) {
      setError(false);
      setTries((prevValue) => prevValue + 1);
      if (tries < 3) {
        resetToken();
      } else {
        setTries(0);
        window.location.href = '/';
      }
    }
  }, [error, resetToken, tries]);

  return (
    <Stack spacing={6} width='100%'>
      <Box>
        <Typography variant='h1' fontWeight='bolder' gutterBottom>
          LevelUp
        </Typography>
        <Typography>
          Bienvenido a <strong>LevelUp</strong>, la plataforma de reclutamiento y desarrollo
          profesional de <strong>WiTI</strong>. Descubre talentos y acelera su desarrollo.
        </Typography>
      </Box>
      <Stack spacing={2}>
        {loadedToken ? (
          <>
            <LoginButton provider='Google' onClick={handleLoginGoogle} />
            <LoginButton provider='Microsoft' onClick={handleLoginMicrosoft} />
          </>
        ) : (
          <>
            <Skeleton variant='rounded' width={'100%'} height={60} />
            <Skeleton variant='rounded' width={'100%'} height={60} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Login;
