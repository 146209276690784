import React from 'react';
import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import { StepSpan, CircularSpan, LabelSpan, StepTip } from './style';
import { Popover, Rating, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';

interface Props {
  item: WorkflowInterface;
  stepNumber: number;
  isActual: boolean;
  clickStage?: (s: boolean, n: string) => void;
}

const Step = ({ item, stepNumber, isActual, clickStage }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const stepWithTooltip = () => {
    return (
      <div>
        <Tooltip title={isActual ? 'Haz clic para activar/desactivar' : null}>
          <StepSpan
            onClick={() => clickStage(item.estado, item.nombre)}
            isactual={String(isActual)}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <CircularSpan estado={String(item.estado)}>{stepNumber}</CircularSpan>
            <LabelSpan estado={String(item.estado)}>{item.nombre}</LabelSpan>
            <Rating name='Evaluacion' value={item.estrellas ? item.estrellas : 0} readOnly />
          </StepSpan>
        </Tooltip>

        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: -10,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <StepTip>
            <p>{DateTime.fromISO(item.fecha).toLocaleString()}</p>
            <p>{item.responsable}</p>
          </StepTip>
        </Popover>
      </div>
    );
  };

  const stepWithoutTooltip = () => {
    return (
      <Tooltip title={isActual ? 'Haz clic para activar/desactivar' : null}>
        <StepSpan onClick={() => clickStage(item.estado, item.nombre)} isactual={String(isActual)}>
          <CircularSpan estado={String(item.estado)}>{stepNumber}</CircularSpan>
          <LabelSpan estado={String(item.estado)}>{item.nombre}</LabelSpan>
          {<Rating name='Evaluacion' value={item.estrellas ? item.estrellas : 0} readOnly />}
        </StepSpan>
      </Tooltip>
    );
  };

  return item.responsable && item.fecha ? stepWithTooltip() : stepWithoutTooltip();
};

export default Step;
