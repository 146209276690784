import React, { FC } from 'react';
import { Box, Card, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { GlowingMarkdownEditor, GlowingRadioGroup } from '../../../ui/skillForm/index';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';
import { socket } from 'services/socketIO/socketCliente';
import { ISkills, ISkillsDimensions } from 'components/models/SkillsInterfaces';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';

type FormProps = {
  tabActive: string;
  tabController: string;
  interviewDetails: CandidateInterface2;
  primaryControllerName: string;
  idMentee: string;
  type: string;
  glowingComponent: string;
  makeGlowingAnimation: boolean;
};
type ReactHookFormRules = {
  textAreaRules: object;
  RadioGroupRules: object;
  setFormIsDirty: (isDirty: boolean) => void;
};

type Interview = {
  skills: ISkills[];
  interviewed_id: String;
};

const SkillsForm: FC<ReactHookFormRules> = ({ textAreaRules, RadioGroupRules, setFormIsDirty }) => {
  const { setValue, watch, control, getValues } = useFormContext<FormProps>();
  const tabActive: string = watch('tabActive');

  const { fields: skills } = useFieldArray({
    control,
    name: 'interviewDetails.skills',
  });
  function sendSkillsSocket(skillIndex: number, dimensionIndex: number, newValue: string) {
    const skill = getValues(`interviewDetails.skills.${skillIndex}`);
    const interview: Interview = {
      skills: [
        { ...skill, dimensiones: [{ ...skill.dimensiones[dimensionIndex], valor: newValue }] },
      ],
      interviewed_id: getValues('idMentee'),
    };
    if (socket.connected) socket.emit('changeDimension', { interview, type: getValues('type') });
  }
  return (
    <Controller
      control={control}
      name='primaryControllerName'
      render={() => (
        <TabContext value={tabActive}>
          <Card>
            <TabList
              onChange={(e, newValue: string) => {
                setValue('tabActive', newValue);
                if (socket.connected)
                  socket.emit('tabChange', {
                    tab: newValue,
                    interviewed_id: getValues('idMentee'),
                    type: getValues('type'),
                  });
              }}
              variant='scrollable'
              scrollButtons='auto'
            >
              {skills?.map((InterviewSkills: ISkills, skillIndex: number) => {
                return (
                  <Tab
                    key={'tab-' + skillIndex}
                    label={InterviewSkills.nombre}
                    value={'tab-' + skillIndex}
                    sx={{ width: '16.5%' }}
                  />
                );
              })}
            </TabList>
          </Card>

          {skills?.map((skill: ISkills, skillIndex: number) => {
            return (
              <TabPanel key={'TabPanel-' + skillIndex} value={'tab-' + skillIndex}>
                {skill.dimensiones.map(
                  (skillDimensions: ISkillsDimensions, dimensionIndex: number) => {
                    return (
                      <Box
                        key={'Box-' + skillIndex + '-' + dimensionIndex}
                        sx={{ marginBottom: '10px' }}
                      >
                        {skillDimensions.tipo === 'textArea' ? (
                          <GlowingMarkdownEditor
                            key={`glowingMarkdownEditor-${skillIndex}-${dimensionIndex}`}
                            skillIndex={skillIndex}
                            dimensionIndex={dimensionIndex}
                            skillDimensions={skillDimensions}
                            sendSkillsSocket={sendSkillsSocket}
                            setValue={setValue}
                            getValues={getValues}
                            control={control}
                            textAreaRules={textAreaRules}
                            socketConnected={socket.connected}
                            setFormIsDirty={setFormIsDirty}
                            watch={watch}
                          />
                        ) : (
                          ''
                        )}
                        {skillDimensions.tipo === 'radio' ? (
                          <GlowingRadioGroup
                            key={`glowingRadioButton-${skillIndex}-${dimensionIndex}`}
                            skillIndex={skillIndex}
                            dimensionIndex={dimensionIndex}
                            skillDimensions={skillDimensions}
                            sendSkillsSocket={sendSkillsSocket}
                            setValue={setValue}
                            getValues={getValues}
                            control={control}
                            RadioGroupRules={RadioGroupRules}
                            socketConnected={socket.connected}
                            setFormIsDirty={setFormIsDirty}
                            watch={watch}
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    );
                  }
                )}
              </TabPanel>
            );
          })}
        </TabContext>
      )}
    />
  );
};

export default SkillsForm;
