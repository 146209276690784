import React from 'react';
import { EventItemProps } from '../types';
import { EventItemFlag } from './EventItemFlag';
import { EventItemWhatsapp } from './EventItemWhatsapp';

export const EventItem = ({ event }: EventItemProps) => {
  if (event.event.toLowerCase() === 'whatsapp') {
    return <EventItemWhatsapp event={event} />;
  }

  return <EventItemFlag event={event} />;
};
