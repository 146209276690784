import styled from '@emotion/styled/macro';

export const StyledDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  min-width: 500px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;
