import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Typography, Box, List, ListItem, Chip } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AuthContext } from '../../../../services/authContext/AuthContextProvider';
import getAxios from '../../../../apiRestConnector';
import type { ITeam, IStack } from '../../createTeam/CreateTeam.types';

type TeamsTableResponse = {
  data: ITeam[];
  totalCount: number;
  currentPage: number;
  totalPages: number;
};

const TeamsTable = () => {
  const history = useHistory();
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;

  const {
    data = { data: [] },
    isError,
    isRefetching,
    isLoading,
  } = useQuery<TeamsTableResponse>({
    queryKey: ['teams', access_token],
    queryFn: async () => {
      const fetchURL = new URL(
        '/team/table?sortby=updatedAt&orderby=ASC&limit=20',
        REACT_APP_BACKEND_URL
      );
      // Se deja este espacio para agregar parámetros de búsqueda a la URL en el futuro.
      const response = await getAxios.instance(access_token).get(fetchURL.href);
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<ITeam>[]>(
    () => [
      {
        accessorKey: 'nombre',
        header: 'Equipo',
        size: 150,
      },
      {
        accessorKey: 'nombre_manager',
        header: 'Manager',
        size: 150,
      },
      {
        accessorKey: 'email_manager',
        header: 'Email',
        size: 200,
      },
      {
        accessorKey: 'empresa',
        header: 'Empresa',
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data.data,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      pagination: { pageSize: 20, pageIndex: 0 },
    },
    state: {
      isLoading: isLoading,
      showProgressBars: isRefetching,
      showLoadingOverlay: false,
      showAlertBanner: isError,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error cargando los datos',
        }
      : undefined,
    muiTableBodyRowProps: ({ row }) => {
      return {
        sx: { cursor: 'pointer' },
        onClick: () => history.push(`/teams/${row.original._id}/edit`),
      };
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          margin: 'auto',
          width: '100%',
        }}
      >
        <Typography gutterBottom>
          <strong>Descripción:</strong> {row.original.descripcion}
        </Typography>
        <Typography>
          <strong>Stack tecnológico:</strong>
        </Typography>
        <List>
          {row.original.stack.map((stackItem: IStack, index: number) => {
            return (
              <ListItem key={index} sx={{ display: 'inline', p: 0.5 }}>
                <Chip label={stackItem.nombre} variant='outlined' />
              </ListItem>
            );
          })}
        </List>
      </Box>
    ),
    muiTopToolbarProps: {
      justifyContent: 'flex-start',
    },
    positionGlobalFilter: 'left',
    layoutMode: 'grid',
    enableGlobalFilterRankedResults: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    enableBottomToolbar: false,
    localization: MRT_Localization_ES,
  });

  return <MaterialReactTable table={table} />;
};

export default TeamsTable;
