import styled from '@emotion/styled/macro';
import Card from 'react-bootstrap/Card';

export const StyledDivTooltip = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
`;

export const StyledCardHeader = styled(Card.Header)`
  border-radius: 10px 10px 0 0 !important;
  background-color: #f15b29;
  min-height: 75px;
  max-height: 75px;
  position: relative;

  .dot {
    height: 25px;
    width: 25px;
    background-color: blue;
    color: white;
    border-radius: 50%;
    display: inline-block;
  }
`;
export const StyledNumberCardHeader = styled.span`
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: scale(0.7);
  transition: 200ms ease;
  &:hover {
    transition: 200ms ease;
    transform: scale(0.9);
  }
`;

export const StyledArrowCardHeader = styled.div`
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  left: 5px;
  top: 5px;
  color: white;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: scale(0.7);
  transition: 200ms ease;
  &:hover {
    transition: 200ms ease;
    transform: scale(0.9);
  }
`;

export const StyledH1TextToTaskCard = styled.h6`
  margin: 10px 0;
  font-size: 20px;
  font-weight: 900;
  color: white;
`;

export const StyledCard = styled(Card)`
  margin: 10px 10px 30px 10px;
  width: 220px;
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width: 498px) {
    width: 100%;
    min-width: 200px;
  }
`;
export const StyledCardText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px 0;
`;

export const StyledCardTextToTaskCard = styled.h6`
  font-size: 16px;
  font-weight: 900;
  min-height: 30px;
  margin-bottom: 10px;
`;

export const StyledTaskCardBar = styled.div`
  margin-bottom: 20px;
  width: 70%;
  height: 4%;
  display: flex;
  background: #dfdcff;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  position: relative;
  left: 16%;
`;
export const StyledTaskCardCircleBar = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 100px;
  background: #ededed;
  border: 2px solid #dfdcff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

export const QuestionMarksWrapper = styled.div`
  font-size: 54px;
`;

export const StyledImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  img {
    width: 100%;
    height: 30%;
  }
`;

export const ProfileCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  color: #f3f3f7;
  background-color: #3495eb;
  padding-top: 1px;
`;

export const RecruitersWrapper = styled.div`
  display: flex;
  padding: 1rem 1rem;
  gap: 5px;
  flex-wrap: wrap;
`;
