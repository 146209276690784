export const isSpeakingStyle = {
  boxShadow: '0 0 5px #7b9bd994, 0 0 10px #7b9bd994, 0 0 15px #7b9bd994',
  border: '1px solid #7b9bd994',
};

export const isNotSpeakingStyle = { border: '1px solid transparent', boxShadow: 'none' };

export const videoBox = {
  width: '100%',
  height: '100%',
  aspectRatio: '3/2',
  position: 'relative',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

export const cameraOff = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'black',
  borderRadius: '1rem',
  flexDirection: 'column',
};

export const identityBox = {
  backgroundColor: 'rgba(120, 120, 120, 0.8)',
  width: 'max-content',
  height: '2rem',
  position: 'absolute',
  bottom: 0,
  left: '10%',
  margin: 0,
  marginRight: '2rem',
  marginBottom: '1rem',
  padding: '0 0.5rem ',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 0,
  borderRadius: '5px',
};

export const cameraAdvisoryDescription = {
  color: 'white',
  textAlign: 'center',
  justifySelf: 'center',
  fontSize: '.65rem',
  textWrap: 'balance',
};

export const cameraAdvisoryTitle = {
  color: 'white',
  textAlign: 'center',
  justifySelf: 'center',
  fontSize: '1rem',
  textWrap: 'balance',
};

export const cameraIcon = {
  color: 'white',
  fontSize: '2rem',
};
