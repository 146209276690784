import React, { FC } from 'react';

import { IRespuestas } from 'components/models/SkillsInterfaces';

import {
  StyledGlossaryAnswerContainer,
  StyledGlossaryAnswerDescription,
  StyledGlossaryButton,
  StyledGlossaryButtonTextContainer,
  StyledGlossaryQuestion,
} from './style';

interface Props {
  type: string;
  answers: IRespuestas[];
  counterID: number;
}

const GlossaryAnswer: FC<Props> = ({ answers, type, counterID }) => {
  return (
    <StyledGlossaryAnswerContainer key={`box-ga${counterID}`}>
      {answers &&
        answers.map((ans: IRespuestas) => {
          return (
            <div key={ans.nombre}>
              {type === 'check' ? (
                <div>
                  <StyledGlossaryQuestion>{ans.nombre}</StyledGlossaryQuestion>
                </div>
              ) : (
                <StyledGlossaryButtonTextContainer>
                  <StyledGlossaryButton>{ans.nombre}</StyledGlossaryButton>
                  <StyledGlossaryAnswerDescription>
                    {ans.descripcion}
                  </StyledGlossaryAnswerDescription>
                </StyledGlossaryButtonTextContainer>
              )}
            </div>
          );
        })}
    </StyledGlossaryAnswerContainer>
  );
};

export default GlossaryAnswer;
