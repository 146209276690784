import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

interface Props {
  provider: string;
  onClick: () => void;
}

const LoginButton = ({ provider, onClick }: Props) => {
  const ProviderIcon = {
    Google: <GoogleIcon />,
    Microsoft: <MicrosoftIcon />,
  }[provider];

  return (
    <Button
      variant='contained'
      size='large'
      startIcon={ProviderIcon}
      onClick={onClick}
      fullWidth
      sx={{
        minHeight: 60,
        fontSize: { xs: '1rem', sm: '1.1rem' },
      }}
    >
      Iniciar sesión con {provider}
    </Button>
  );
};

export default LoginButton;
