import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import TeamFormImage from '../teamFormImage/TeamFormImage';
import Confirm from 'assets/confirma.png';
import type { TeamFormStepProps } from '../../CreateTeam.types';

const TeamFormStepName = ({ teamInfo, setTeamInfo }: TeamFormStepProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TeamFormImage imageUrl={Confirm} />
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ mt: '20px', mb: '10px' }}>
          <Typography variant='h4' gutterBottom>
            Identidad del equipo
          </Typography>
          <Typography>
            Ingresa el nombre y una breve descripción del equipo, estableciendo su identidad y
            propósito.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label='Nombre'
            value={teamInfo.nombre}
            onChange={(e) => setTeamInfo({ ...teamInfo, nombre: e.target.value })}
            sx={{ width: '80%', mb: '10px', mt: '30px' }}
          />
          <TextField
            label='Descripción'
            multiline
            minRows={4}
            value={teamInfo.descripcion}
            onChange={(e) => setTeamInfo({ ...teamInfo, descripcion: e.target.value })}
            sx={{ width: '80%', mb: '20px', mt: '30px' }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TeamFormStepName;
