import { Box, Button, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import { actionBtn, meetingActions, actionBtnLeft, actionBtnRight, popperStyle } from './styles';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ButtonGroup from '@mui/material/ButtonGroup';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const StreamActions = ({
  isCameraOn,
  toggleVideo,
  isMicOn,
  toggleMic,
  handleEndCall,
  audioDevices,
  videoDevices,
  audioDeviceSelected,
  handleChangeAudioDevice,
  videoDeviceSelected,
  handleChangeVideoDevice,
}) => {
  const [isOpenAudioModal, setIsOpenAudioModal] = useState(false);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const audioModalRef = useRef<HTMLDivElement>(null);
  const videoModalRef = useRef<HTMLDivElement>(null);

  const handleToggleAudioModal = () => {
    setIsOpenAudioModal((prevOpen) => !prevOpen);
  };

  const handleCloseAudioModal = () => {
    setIsOpenAudioModal(false);
  };

  const handleToggleVideoModal = () => {
    setIsOpenVideoModal((prevOpen) => !prevOpen);
  };

  const handleCloseVideoModal = () => {
    setIsOpenVideoModal(false);
  };

  return (
    <>
      <Box sx={{ ...meetingActions }}>
        <ButtonGroup variant='contained' ref={audioModalRef} sx={{ borderColor: 'transparent' }}>
          <Tooltip title='Ajuste de audio' placement='bottom-start'>
            <Button
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggleAudioModal}
              sx={{
                ...actionBtn,
                ...actionBtnLeft,
              }}
            >
              {isOpenAudioModal ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
          </Tooltip>
          <Tooltip
            title={isMicOn ? 'Apagar micrófono' : 'Encender micrófono'}
            placement='bottom-end'
          >
            <Button
              sx={{
                backgroundColor: isMicOn ? 'gray' : 'red',
                ...actionBtn,
                ...actionBtnRight,
              }}
              onClick={toggleMic}
              aria-label={isMicOn ? 'Apagar micrófono' : 'Encender micrófono'}
            >
              {isMicOn ? <MicIcon /> : <MicOffIcon />}
            </Button>
          </Tooltip>
        </ButtonGroup>
        <ButtonGroup variant='contained' ref={videoModalRef}>
          <Tooltip title='Ajuste de video' placement='bottom-start'>
            <Button
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggleVideoModal}
              sx={{
                ...actionBtn,
                ...actionBtnLeft,
              }}
            >
              {isOpenVideoModal ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
          </Tooltip>
          <Tooltip title={isCameraOn ? 'Apagar cámara' : 'Encender cámara'} placement='bottom'>
            <Button
              sx={{
                backgroundColor: isCameraOn ? 'gray' : 'red',
                ...actionBtn,
                ...actionBtnRight,
              }}
              onClick={toggleVideo}
              aria-label={isCameraOn ? 'Apagar cámara' : 'Encender cámara'}
            >
              {isCameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
            </Button>
          </Tooltip>
        </ButtonGroup>
        <Tooltip title='Finalizar llamada' placement='bottom'>
          <Button
            sx={{
              ...actionBtn,
              backgroundColor: 'red',
              borderRadius: '50%',
            }}
            onClick={handleEndCall}
          >
            <CallEndIcon />
          </Button>
        </Tooltip>
      </Box>
      <Popper
        sx={popperStyle}
        open={isOpenAudioModal}
        anchorEl={audioModalRef.current}
        placement={'top'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10], // X, Y offsets in pixels
            },
          },
        ]}
      >
        <ClickAwayListener
          mouseEvent='onMouseDown'
          touchEvent='onTouchStart'
          onClickAway={handleCloseAudioModal}
        >
          <Box
            sx={{
              p: '0.2em',
              marginBottom: '1em',
              borderRadius: '3em',
              backgroundColor: '#7b9bd914',
              width: 500,
            }}
          >
            <Grid columns={1} container spacing={3}>
              <Grid item xs={6}>
                {/* TODO: Fix this <InputLabel id='audioDeviceInput'>Entrada</InputLabel> */}
                <Typography>Entrada Micrófono</Typography>
                <FormControl
                  size='small'
                  variant='standard'
                  fullWidth
                  onClick={(event) => event.stopPropagation()}
                >
                  <Select
                    labelId='audioDeviceInput'
                    id='audioDeviceInput'
                    label={'Entrada'}
                    value={audioDeviceSelected.input.deviceId ?? ''}
                    onChange={(event) => {
                      handleChangeAudioDevice(event);
                      event.stopPropagation(); // Detener la propagación también en el onChange
                    }}
                    autoWidth={false}
                    fullWidth
                    onClick={(event) => event.stopPropagation()}
                  >
                    {audioDevices.input.map(({ deviceId, label }) => (
                      <MenuItem
                        value={deviceId}
                        key={deviceId}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {/* TODO: Fix this <InputLabel id='audioDeviceOutput'>Salida</InputLabel> */}
                <Typography>Salida Audio</Typography>
                <FormControl
                  size='small'
                  variant='standard'
                  fullWidth
                  onClick={(event) => event.stopPropagation()}
                >
                  <Select
                    labelId='audioDeviceOutput'
                    id='audioDeviceOutput'
                    value={''}
                    label='Salida'
                    disabled
                    onChange={(event) => {
                      // handleChangeAudioDevice(event);
                      event.stopPropagation(); // Detener la propagación también en el onChange
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {audioDevices.output.map(({ deviceId, label }) => (
                      <MenuItem
                        value={deviceId}
                        key={deviceId}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </ClickAwayListener>
      </Popper>
      <Popper
        sx={popperStyle}
        open={isOpenVideoModal}
        anchorEl={videoModalRef.current}
        placement={'top'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10], // X, Y offsets in pixels
            },
          },
        ]}
      >
        <ClickAwayListener
          mouseEvent='onMouseDown'
          touchEvent='onTouchStart'
          onClickAway={handleCloseVideoModal}
        >
          <Box
            sx={{
              p: '0.2em',
              marginBottom: '1em',
              borderRadius: '3em',
              width: 300,
            }}
          >
            <FormControl size='small' variant='standard' fullWidth>
              <Grid container>
                <Grid item xs={12}>
                  {/* TODO: Fix this <InputLabel id='audioDeviceInput'>Entrada</InputLabel> */}
                  <Select
                    labelId='audioDeviceInput'
                    id='audioDeviceInput'
                    label={'Entrada'}
                    value={videoDeviceSelected.deviceId ?? ''}
                    onChange={(event) => {
                      handleChangeVideoDevice(event);
                      event.stopPropagation();
                      event.preventDefault(); // Detener la propagación también en el onChange
                    }}
                    autoWidth={false}
                    fullWidth
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    {videoDevices.map(({ deviceId, label }) => (
                      <MenuItem
                        value={deviceId}
                        key={deviceId}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                        }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default StreamActions;
