import React from 'react';
import { Box, Chip, Divider, List, ListItem, Typography, Paper } from '@mui/material';
import type { TeamFormStepProps } from '../../CreateTeam.types';

const listItemStyle = { display: 'inline', p: 0.5 };

const TeamFormStepSummary = ({ teamInfo }: TeamFormStepProps) => {
  return (
    <Box>
      <Box sx={{ mt: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Resumen
        </Typography>
        <Typography>
          Revisa y confirma toda la información introducida antes de guardar el equipo.
        </Typography>
      </Box>

      <Divider sx={{ mb: '20px', mt: '10px' }} />

      <Paper elevation={0} sx={{ p: 2, mb: 2, borderRadius: 4 }}>
        <Typography variant='body2' color='text.secondary' mb={1} fontWeight='bold'>
          Equipo:
        </Typography>
        {teamInfo.nombre}
        <Typography variant='body2' color='text.secondary' mb={1} mt={2} fontWeight='bold'>
          Descripción:
        </Typography>
        {teamInfo.descripcion}
        <Divider sx={{ mb: '10px', mt: '10px' }} />
        <Typography variant='body2' color='text.secondary' mb={1} mt={2} fontWeight='bold'>
          Empresa:
        </Typography>
        {teamInfo.empresa}
        <Typography variant='body2' color='text.secondary' mb={1} mt={2} fontWeight='bold'>
          Area:
        </Typography>
        {teamInfo.area_empresa}
        <Typography variant='body2' color='text.secondary' mb={1} mt={2} fontWeight='bold'>
          Nombre del Manager:
        </Typography>
        {teamInfo.nombre_manager}
        <Typography variant='body2' color='text.secondary' mb={1} mt={2} fontWeight='bold'>
          Email del Manager:
        </Typography>
        {teamInfo.email_manager}
        <Divider sx={{ mb: '10px', mt: '10px' }} />
        <Typography variant='body2' color='text.secondary' fontWeight='bold'>
          Líder Técnico:
        </Typography>
        <List>
          {teamInfo.lider_tecnico_ids.map((item, index) => {
            return (
              <ListItem key={index} sx={listItemStyle}>
                <Chip label={item.nombre} variant='outlined' />
              </ListItem>
            );
          })}
        </List>
        <Divider sx={{ mb: '10px', mt: '10px' }} />
        <Typography variant='body2' color='text.secondary' fontWeight='bold'>
          Integrantes:
        </Typography>
        <List>
          {teamInfo.integrantes_ids.map((item, index) => {
            return (
              <ListItem key={index} sx={listItemStyle}>
                <Chip label={item.nombre} variant='outlined' />
              </ListItem>
            );
          })}
        </List>
        <Divider sx={{ mb: '10px', mt: '10px' }} />
        <Typography variant='body2' color='text.secondary' fontWeight='bold'>
          Stack tecnológico:
        </Typography>
        <List>
          {teamInfo.stack.map((item, index) => {
            return (
              <ListItem key={index} sx={listItemStyle}>
                <Chip label={item.nombre} variant='outlined' />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
};

export default TeamFormStepSummary;
