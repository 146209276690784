import React from 'react';
import { StyledContainer, StyledButton } from './style';
import { logoutFirebase } from 'utils/firebase';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { useHistory } from 'react-router-dom';

import { useContext } from 'react';

const NotFound = () => {
  const history = useHistory();
  const { actionsAuth } = useContext(AuthContext)!;

  return (
    <StyledContainer>
      <h1>Ruta Incorrecta</h1>
      <StyledButton onClick={() => logoutFirebase(history, actionsAuth.logout)}>
        Logout
      </StyledButton>
    </StyledContainer>
  );
};

export default NotFound;
