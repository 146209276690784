import { useContext } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { DateTime } from 'luxon';
import ROL from '../../../../utils/Roles';
import { MentoringData } from './MentoringTable.types';
import { GetFirstElement } from 'helpers/currentPathSearch';

export function GenerateMenteeUrl(id: string): string {
  const { storeAuth } = useContext(AuthContext)!;
  const moduleName = () => {
    const isRoleAllowed = (): Boolean => {
      const role = storeAuth.user.role?.[0];
      return role === ROL.MENTOR || role === ROL.KAM || role === ROL.ADMINISTRADOR;
    };
    if (isRoleAllowed()) {
      if (GetFirstElement() === 'mentor') {
        return `/mentored/${id}`;
      } else if (GetFirstElement() === 'kam') {
        return `/kam/${id}`;
      }
    }
  };
  return moduleName();
}

export function getFormattedDates(date: string) {
  if (!DateTime.fromISO(date).isValid) {
    return { localeDate: '', relativeDate: '', title: '' };
  }

  const localeDate = DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const relativeDate = DateTime.fromISO(date).toRelative();
  const title = `${localeDate} (${relativeDate})`;
  return { localeDate, relativeDate, title };
}

export function mapDataToRows(employeesData: MentoringData[]): MentoringData[] {
  //Colaborador, nombre equipo, quien hizo la ultima sesión, cuando, rol,
  //cinturón, quien lo guardo, cuando, fecha, activos segun especificación de kam
  return employeesData.map((employee: MentoringData) => ({
    id: employee.id,
    mentee: employee.mentee,
    teams: employee.teams,
    lastMentor: employee.lastMentor,
    lastMentorUpdate: employee.lastMentorUpdate,
    rol: employee.rol,
    belt: employee.belt,
    creatorName: employee.creatorName,
    creationDate: employee.creationDate,
    active: employee.active,
  }));
}
