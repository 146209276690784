import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from '../../../services/authContext/AuthContextProvider';
import MenteeProfile from './MenteeProfile';
import MenteeRoadmap from './MenteeRoadmap';
import FullscreenLoading from '../loading/FullscreenLoading';
import { type Mentee } from './MenteeView.types';
import FullscreenAlert from '../alerts/FullscreenAlert';
import ROL from 'utils/Roles';

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    No tienes un perfil Mentee.
  </FullscreenAlert>
);

export function MenteeView({ idMentee }: { idMentee: string }) {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token, role } = storeAuth.user;
  const currentMenteeUri: string =
    role?.[0] === ROL.ADMINISTRADOR || role?.[0] === ROL.KAM || role?.[0] === ROL.MENTOR
      ? `/mentee/${idMentee}`
      : '';

  const {
    data: mentee,
    isPending,
    isError,
  } = useQuery<Mentee>({
    queryKey: [`/employee/${idMentee}`, access_token],
  });

  if (isPending) return <FullscreenLoading />;

  if (isError) return <ErrorAlert />;

  return (
    <>
      <MenteeProfile
        idMentee={idMentee}
        mentee={mentee}
        options={{ component: 'MenteeView' }}
        typeOfInterview='MENTEE'
        currentMenteeUri={currentMenteeUri}
      />
      <MenteeRoadmap objectives={mentee.objectives} />
    </>
  );
}
