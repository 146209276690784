import React from 'react';
import {
  Paper,
  Box,
  Chip,
  Divider,
  Stack,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkdownEditor from '../../../../ui/markdownEditor';
import { stackTemplate } from './stack-template';
import { filterAvailableStacks, searchCorrectLevel } from './TeamFormStepStack.helpers';
import type { IStackLevel, IStack, ITeam, TeamFormStepProps } from '../../CreateTeam.types';

interface IStackTemplate {
  stack: IStack[];
}

const stacks: IStack[] = (JSON.parse(stackTemplate) as IStackTemplate).stack;

const TeamFormStepStack = ({ teamInfo, setTeamInfo }: TeamFormStepProps) => {
  const [stackInput, setStackInput] = React.useState('');

  const filteredStacks = filterAvailableStacks({
    searchTerm: stackInput,
    allAvailableStacks: stacks,
    assignedStacks: teamInfo.stack,
  });

  function changeStackLevel(newLevel: string, index: number) {
    const newLevels: IStackLevel[] = teamInfo.stack[index].nivel.map((level) => {
      return {
        ...level,
        valor: level.nombre === newLevel,
      };
    });

    const levelDescription = newLevels.find((level) => level.nombre === newLevel)?.descripcion;

    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack[index].nivel = newLevels;
      newTeamInfo.stack[index].descripcion = levelDescription ?? '';
      return newTeamInfo;
    });
  }

  function changeStackDescription(newDescription: string, index: number) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack[index].descripcion = newDescription;
      return newTeamInfo;
    });
  }

  function addStack(newStack: IStack) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      const updatedStack: IStack = structuredClone(newStack);
      updatedStack.nivel[0].valor = true;
      updatedStack.descripcion = updatedStack.nivel[0].descripcion;
      newTeamInfo.stack.push(updatedStack);
      return newTeamInfo;
    });
  }

  function removeStack(index: number) {
    setTeamInfo((prevTeamInfo) => {
      const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
      newTeamInfo.stack.splice(index, 1);
      return newTeamInfo;
    });
  }

  return (
    <Box sx={{ minHeight: '500px' }}>
      <Box sx={{ mt: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Stack tecnológico
        </Typography>
        <Typography>
          Define las herramientas y tecnologías con las que trabajará el equipo.
        </Typography>
      </Box>
      <Divider sx={{ mb: '20px', mt: '10px' }} />
      <Stack alignItems='center' minHeight={200}>
        <TextField
          label='Tecnologías'
          variant='outlined'
          value={stackInput}
          onChange={(e) => {
            setStackInput(e.target.value);
          }}
          fullWidth
          sx={{ maxWidth: 560, mb: '10px', mt: '30px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  title='Limpiar'
                  onClick={() => {
                    setStackInput('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box>
          {filteredStacks.map((filteredStack, index) => {
            if (index < 10 && stackInput.length > 1) {
              return (
                <Chip
                  key={index}
                  label={filteredStack.nombre}
                  variant='outlined'
                  onClick={() => {
                    addStack(filteredStack);
                  }}
                  sx={{ mr: '10px' }}
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
          gap: 2,
          mb: 4,
        }}
      >
        {teamInfo.stack.map((stack, stackIndex) => {
          return (
            <Paper key={stack.nombre} elevation={2} sx={{ p: 2 }}>
              <Stack gap={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Typography fontWeight='bold'>{stack.nombre}</Typography>
                  <IconButton
                    title='Eliminar'
                    onClick={() => {
                      removeStack(stackIndex);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <FormControl fullWidth>
                  <InputLabel>Nivel</InputLabel>
                  <Select
                    label='Nivel'
                    value={searchCorrectLevel(stack)?.nombre ?? ''}
                    onChange={(e) => {
                      changeStackLevel(e.target.value, stackIndex);
                    }}
                    size='small'
                  >
                    {stack.nivel.map((level, levelIndex) => {
                      return (
                        <MenuItem key={levelIndex} value={level.nombre}>
                          {level.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <MarkdownEditor
                  placeholder='Descripción'
                  value={stack.descripcion}
                  onChange={(newValue) => {
                    changeStackDescription(newValue, stackIndex);
                  }}
                />
              </Stack>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default TeamFormStepStack;
