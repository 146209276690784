import { styled, Box } from '@mui/material';

export const StyledMDXEditorContainer = styled(Box)`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'};
  border-radius: 4px;

  .mdxeditor {
    --baseTextContrast: ${({ theme }) => theme.palette.text.primary};
    --baseSolid: ${({ theme }) =>
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'};

    [role='textbox'] {
      min-height: 100px;
    }
  }
`;
