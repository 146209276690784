import styled from '@emotion/styled/macro';

export const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem;
  & > * {
    min-width: 250px;
    margin: 10px 20px;
    flex: 1;
  }
`;
