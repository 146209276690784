import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '10px',
  marginTop: '15px',
  '@media screen and (max-width: 500px)': {
    flexDirection: 'column-reverse',
  },
}));

export const StyledInput = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  zIndex: '1 !important',
  height: '60px',
  width: '133px',
  cursor: 'pointer',
  '& > input': {
    position: 'absolute',
    top: '0px',
    height: '60px',
    width: '133px',
    opacity: 0,
    outline: 'none',
    zIndex: '10 !important',
  },
  '& > svg': {
    position: 'absolute',
    top: '15px',
    left: '50px',
    fontSize: '30px',
    userSelect: 'none',
    zIndex: '0 !important',
  },
}));

export const StyledInputArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '280px',
  height: '200px',
  padding: '5px',
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: '8px',
  zIndex: '99',
  transition: '200ms ease',
  '& > span': {
    fontSize: '12px',
  },
}));

export const StyledProfile = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ProfileImageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  padding: '0 25px',
});

export const ProfileImage = styled('img')({
  width: '150px',
  height: '150px',
  borderRadius: '8px',
  objectFit: 'cover',
});
