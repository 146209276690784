import React from 'react';

import TrainerSVG from './img/Trainer.svg';
import JuniorSVG from './img/Junior.svg';
import SemiSeniorOrangeSVG from './img/SemiSeniorOrange.svg';
import SemiSeniorGreenSVG from './img/SemiSeniorGreen.svg';
import SemiSeniorBlueSVG from './img/SemiSeniorBlue.svg';
import SemiSeniorPurpleSVG from './img/SemiSeniorPurple.svg';
import SemiSeniorBrownSVG from './img/SemiSeniorBrown.svg';
import SeniorSVG from './img/Senior.svg';

const avatarMap = {
  Blanco: TrainerSVG,
  Amarillo: JuniorSVG,
  Naranja: SemiSeniorOrangeSVG,
  Verde: SemiSeniorGreenSVG,
  Azul: SemiSeniorBlueSVG,
  Morado: SemiSeniorPurpleSVG,
  Marrón: SemiSeniorBrownSVG,
  Negro: SeniorSVG,
};

const SeniorityAvatar = ({ variant }) => {
  const avatar = avatarMap[variant];
  return <img src={avatar} alt='Avatar Mentee' style={{ width: '100%', maxWidth: '250px' }} />;
};

export default SeniorityAvatar;
