import React, { FC } from 'react';
import { StyledTr, StyledTh } from './style';
import FilterThTableHead from '../filterThTableHead/FilterThTableHead';

interface Props {
  getSortedRequestByParamenter: (key: string) => void;
  orderedRequest: boolean;
}

const TableHeadRequest: FC<Props> = ({ getSortedRequestByParamenter, orderedRequest }) => {
  return (
    <>
      <StyledTr>
        <FilterThTableHead
          onClick={getSortedRequestByParamenter}
          state={orderedRequest}
          onClickParameter='Empresa'
          label='Empresa'
        />
        <StyledTh>Rol</StyledTh>
        <StyledTh>Nivel</StyledTh>
        <FilterThTableHead
          onClick={getSortedRequestByParamenter}
          state={orderedRequest}
          onClickParameter='Fecha Inicio'
          label='Fecha Inicio'
        />
        <FilterThTableHead
          onClick={getSortedRequestByParamenter}
          state={orderedRequest}
          onClickParameter='Fecha Estimada Fin'
          label='Fecha Estimada Fin'
        />
        <StyledTh>Plazo</StyledTh>
        <FilterThTableHead
          onClick={getSortedRequestByParamenter}
          state={orderedRequest}
          onClickParameter='Estado'
          label='Estado'
        />
        <StyledTh>Stack</StyledTh>
        <StyledTh>Clonar solicitud</StyledTh>
      </StyledTr>
    </>
  );
};

export default TableHeadRequest;
