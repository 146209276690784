import React from 'react';
import {
  type ModalProps,
  Modal,
  Button,
  Typography,
  Box,
  Stack,
  Paper,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { flagFunction } from 'helpers/flags';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: 600,
  gap: 4,
  p: 4,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export type ContactProps = {
  name: string;
  phone: string;
};

function ContactCard(props: ContactProps) {
  const name = props.name || 'Nombre desconocido';
  const phone = props.phone;

  const parsedPhone = parsePhoneNumberFromString(`+${phone}`);
  const isValidPhone = parsedPhone?.isValid();

  if (!isValidPhone) {
    return (
      <Stack gap={2} width='100%'>
        <Typography variant='h5' id='modal-contact-title'>
          {name}
        </Typography>
        <Alert id='modal-contact-description' variant='outlined' severity='error'>
          El número de teléfono de este contacto no es válido
        </Alert>
      </Stack>
    );
  }

  const formattedPhoneNumber = parsedPhone?.formatInternational();

  const countryCodeIso2 = flagFunction(phone.toString());
  const flagUrl = new URL(
    `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCodeIso2}.svg`
  );

  const whatsappUrl = new URL('https://api.whatsapp.com/send');
  whatsappUrl.search = new URLSearchParams({
    phone: `+${phone}`,
    text: `Hola ${name}, cómo estás?`,
  }).toString();

  const phoneUrl = new URL(`tel:+${phone}`);

  return (
    <>
      <Stack gap={2} width='100%'>
        <Typography variant='h5' id='modal-contact-title'>
          {name}
        </Typography>
        <Stack direction='row' gap={2}>
          <Box component='img' src={flagUrl.href} alt='País' sx={{ width: '30px' }} />
          <Typography variant='h5' sx={{ fontFamily: '"Roboto Mono", monospace' }}>
            {formattedPhoneNumber}
          </Typography>
        </Stack>
        <Typography variant='subtitle1' color='text.secondary' id='modal-contact-description'>
          Puedes comunicarte a través de WhatsApp o llamada telefónica.
          <br />
          Selecciona la opción que prefieras.
        </Typography>
      </Stack>
      <Stack direction='row' gap={2} width='100%'>
        <Button
          href={whatsappUrl.href}
          target='_blank'
          rel='noopener noreferrer'
          startIcon={<WhatsAppIcon />}
          variant='contained'
          size='large'
          color='success'
          fullWidth
        >
          WhatsApp
        </Button>
        <Button
          href={phoneUrl.href}
          startIcon={<PhoneIcon />}
          variant='contained'
          size='large'
          color='info'
          fullWidth
        >
          Llamar
        </Button>
      </Stack>
    </>
  );
}

type ContactModalProps = Omit<ModalProps, 'children'> & ContactProps;

function ContactModal(props: ContactModalProps) {
  return (
    <Modal
      aria-labelledby='modal-contact-title'
      aria-describedby='modal-contact-description'
      {...props}
    >
      <Paper sx={style}>
        <ContactCard name={props.name} phone={props.phone} />
        <IconButton
          title='Cerrar'
          aria-label='Cerrar'
          onClick={(event) => props.onClose(event, 'backdropClick')}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    </Modal>
  );
}

export default ContactModal;
