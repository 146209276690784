import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CircularSpan = styled(Box)<{ estado: string }>`
  display: block;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  line-height: 1em;
  color: ${(props) => props.theme.palette.background.paper};
  background-color: ${(props) =>
    props.estado === 'true' ? props.theme.palette.primary.main : props.theme.palette.text.disabled};
  border-radius: 1em;
`;

export const LabelSpan = styled(Box)<{ estado: string }>`
  text-align: center;
  display: block;
  margin: 0.25rem;
  color: ${(props) =>
    props.estado === 'true' ? props.theme.palette.primary.main : props.theme.palette.text.disabled};
`;

export const StepSpan = styled(Box)<{ isactual: string }>`
  display: inline-block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.isactual === 'true' ? props.theme.palette.primary.main : 'transparent'};
  border-radius: 0.25rem;
  transition:
    background-color 0.15s ease-out,
    color 0.15s ease-out;
  cursor: pointer;
`;

export const StepTip = styled(Box)`
  padding: 20px 20px 5px;
  text-align: center;
  background-color: #1e2020;
  border-radius: 5px;
  color: #fff;
  line-height: 0.5;
`;
