import React, { FC } from 'react';

import { SkillsInterface } from 'components/models/SkillsInterfaces';
import GlossarySkills from './GlossarySkills';
import { StyledGlossaryContainer, StyledGlossaryTitle } from './style';

interface Props {
  skills: SkillsInterface[];
}

export const NewGlossary: FC<Props> = ({ skills }) => (
  <StyledGlossaryContainer>
    <StyledGlossaryTitle>Glosario</StyledGlossaryTitle>
    {skills &&
      skills.map((skill: SkillsInterface, index: number) => {
        return <GlossarySkills key={index} counterID={index} skills={skill} />;
      })}
  </StyledGlossaryContainer>
);
