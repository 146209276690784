import React from 'react';
import { StyledMDXEditorContainer } from './styles';
import { MarkdownEditorProps } from '../../types';
import MDEditor from '@uiw/react-md-editor';

function MDXMarkdownEditor({ value, onChange, onBlur }: MarkdownEditorProps) {
  const handleChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  const hanldeBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <StyledMDXEditorContainer data-color-mode='light' onBlur={hanldeBlur}>
      <MDEditor value={value} onChange={handleChange} preview='preview' hideToolbar={false} />
    </StyledMDXEditorContainer>
  );
}

export default MDXMarkdownEditor;
