import React from 'react';
import { Backdrop, Alert, AlertTitle, type AlertProps } from '@mui/material';

type FullscreenAlertProps = AlertProps & {
  alertTitle?: React.ReactNode;
  children: React.ReactNode;
};

function FullscreenAlert({ children, alertTitle, ...restProps }: FullscreenAlertProps) {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <Alert variant='filled' {...restProps} sx={{ maxWidth: 600, ...restProps.sx }}>
        {Boolean(alertTitle) && <AlertTitle>{alertTitle}</AlertTitle>}
        {children}
      </Alert>
    </Backdrop>
  );
}

export default FullscreenAlert;
