import React, { FC } from 'react';
import Loading from 'components/ui/loading/Loading';
import SVGLike from 'components/ui/icons/SVGLike';
import { StyledButton, StyledContainer, StyledMessage } from './style';
import { CircularProgress } from '@mui/material';

interface IProps {
  likeLoading: boolean;
  manageLike: () => void;
  like: boolean;
  infoLikes: boolean;
  likeList: string[];
  showInfoLikes: () => void;
  setInfoLikes: (infoLikes: boolean) => void;
}

const MeTincaButton: FC<IProps> = ({
  likeLoading,
  manageLike,
  like,
  infoLikes,
  likeList,
  showInfoLikes,
  setInfoLikes,
}) => {
  return (
    <StyledContainer>
      {infoLikes && likeList.length > 0 && (
        <StyledMessage>
          <h5>Interesados: </h5>
          {likeList.map((item: string) => (
            <div key={item}>{item}</div>
          ))}
        </StyledMessage>
      )}

      <StyledButton
        like={like}
        onClick={manageLike}
        onMouseOver={showInfoLikes}
        onMouseLeave={() => setInfoLikes(false)}
      >
        {likeLoading && (
          <>
            <Loading>
              <CircularProgress />
            </Loading>
          </>
        )}
        {!likeLoading && (
          <>
            <SVGLike width={30} height={30} color={'white'} />
            <span>Me Interesa</span>
          </>
        )}
      </StyledButton>
    </StyledContainer>
  );
};

export default MeTincaButton;
