import { styled, Box } from '@mui/material';

export const StyledMenteeProfileData = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
  },
  '> *': {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '4px 8px',
  },
  '> *:first-of-type': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '> *:last-of-type': {
    flexGrow: 1,
    borderLeftWidth: '0px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));
