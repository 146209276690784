import styled from '@emotion/styled/macro';

export const Like = styled.div<{ like: boolean; withEdit: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-weight: 900;
  font-size: 14px;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: ${(props) => (props.like ? '#FE8516' : '#FCD13B')};
  width: 164px;
  height: 35px;
  cursor: pointer;

  :hover {
    background-color: #20879e;
    border-color: #31cff2;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const EditCandidate = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  text-decoration: none;
  border: none;
  background: none;
`;

export const TitleGroupCard = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
`;
