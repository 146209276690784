import React, { useContext, useEffect, useState, useRef } from 'react';
import './style.css';

import { Container, Row, Col, Form } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import Step from 'components/ui/wizard/step/Step';
import Separador from 'components/ui/wizard/separador/Separador';

import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { WorkflowInterface, innerTareasInterface } from 'components/models/WorkFlowInterfaces';
import { DateTime } from 'luxon';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import {
  Box,
  Rating,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import getAxios from 'apiRestConnector';
import SubStages from './subStages/SubStages';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import Tooltip from '@mui/material/Tooltip';

import MDXMarkdownEditor from 'components/ui/markdownEditor/editors/mdxeditor/MDXMarkdownEditor';

interface Props {
  dataCandidate: CandidateInterface;
  setTemplateFather: (t: CandidateInterface) => void;
  setEstadoActualTemplate: (e: number) => void;
}
const ScrollableBox = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '20px',
  marginTop: '20px',
  overflow: 'auto',
  paddingBottom: '20px',

  '&::-webkit-scrollbar': {
    width: '0.5rem',
    height: '0.5rem',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0.25rem',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const WorkflowComponent: React.FC<Props> = ({ dataCandidate, setTemplateFather }) => {
  const [template, setTemplate] = useState<CandidateInterface>(Object);

  const [estadoActual, setEstadoActual] = useState(0);

  const [estadoNombre, setEstadoNombre] = useState<string>('');
  const { storeAuth } = useContext(AuthContext)!;
  const userName = storeAuth.user.name;
  const { access_token } = storeAuth.user;
  const [templateData, setTemplateData] = useState<CandidateInterface>(Object);
  const [skeleton, setSkeleton] = useState<CandidateInterface>(Object);
  const { REACT_APP_BACKEND_URL } = process.env;
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const handleComponentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedComponent = event.currentTarget;
    clickedComponent.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  useEffect(() => {
    const urlBackend = `${REACT_APP_BACKEND_URL}/templates/skills/`;
    getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        setSkeleton(templatesData);
      });
  }, [access_token, REACT_APP_BACKEND_URL]);

  useEffect(() => {
    setTemplateData(dataCandidate);
    setTemplate(dataCandidate);

    const getEstadoActualFromTemplate = () => {
      if (dataCandidate.workflow) {
        for (let i = 0; i < dataCandidate.workflow.length; i++) {
          if (dataCandidate.workflow[i].nombre === dataCandidate.estado) {
            return i;
          }
        }
      }
      return 0;
    };
    if (dataCandidate.workflow) {
      setEstadoActual(getEstadoActualFromTemplate());
    }
  }, [dataCandidate]);

  useEffect(() => {
    const fechaCreacion = DateTime.now().toUTC().toISO();

    if (estadoNombre) {
      setTemplateFather({
        ...dataCandidate,
        estado: estadoNombre,
        timestamp: fechaCreacion,
      });
    }
    /* eslint-disable-next-line */
  }, [estadoNombre]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(template).length !== 0) {
      if (template.estado === undefined && template.timestamp === undefined) {
        const result = template;
        const fechaCreacion = DateTime.now().toUTC().toISO();

        result.workflow![0].estado = true;
        result.workflow![0].responsable = userName;
        result.workflow![0].fecha = fechaCreacion;
        result.timestamp = fechaCreacion;
        result.estado = template.workflow[0].nombre;

        setTemplate({ ...result });
      }
    }
  }, [template, userName]);

  const onChangeFeedback = (value: string) => {
    const result = templateData;
    result.workflow![estadoActual].feedback = value;
    setTemplate({ ...result });
  };

  const onBlurEvent = () => {
    const result = templateData;
    const feedback = result.workflow![estadoActual].feedback;
    const lastEntry = feedback.trim().split('\n').pop();

    if (!lastEntry?.includes(`modificado por ${userName} el`)) {
      let concat = feedback.concat(
        `\n\n<div style="text-align: right;">modificado por ${userName} el ${DateTime.now().toFormat('yyyy-MM-dd HH:mm')}</div>\n\n`
      );
      concat = concat.concat(`\n\n`);
      result.workflow![estadoActual].feedback = concat;
      setTemplate({ ...result });
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const result = templateData;

    const selected = result.workflow![estadoActual].motivo[index].selected;
    result.workflow![estadoActual].motivo[index].selected = !selected;
    setTemplate(result);
  };

  const handleCheckboxTareas = (event: {
    target: { checked: boolean; getAttribute: (arg0: string) => any };
  }) => {
    const alt = parseInt(event.target.getAttribute('alt'));

    let arrTareas = templateData;
    if (arrTareas.workflow[estadoActual].tareas) {
      arrTareas.workflow = templateData.workflow.map((w: WorkflowInterface) => {
        if (arrTareas.workflow[estadoActual].nombre === w.nombre) {
          w.tareas?.map((t: innerTareasInterface) => {
            if (parseInt(t.id) === alt) {
              t.selected = !t.selected;
            }
            return t;
          });
        }
        return w;
      });

      setTemplate({ ...arrTareas });
    }
  };

  const handleCheckboxTareasOld = (event: {
    target: { checked: boolean; getAttribute: (arg0: string) => any };
  }) => {
    const alt = parseInt(event.target.getAttribute('alt')); // index dentro del array de checks
    const result = templateData;
    let arrTareas = [{}];

    skeleton.workflow.forEach((res) => {
      if (result.workflow[estadoActual].nombre === res.nombre) {
        arrTareas = res.tareas?.map((task: innerTareasInterface) => {
          if (alt === parseInt(task.id)) {
            task.selected = !task.selected;
            return task;
          }
          return task;
        }) || [''];
      }
    });
    result.workflow[estadoActual].tareas = arrTareas as innerTareasInterface[];
    setTemplate({ ...result });
  };

  const clickStage = (state: boolean, name: string) => {
    const selectedStageWorkflowIndex = templateData.workflow.findIndex(
      ({ nombre }: WorkflowInterface) => nombre === name
    );
    const selectedStageIndex = selectedStageWorkflowIndex !== -1 ? selectedStageWorkflowIndex : 0;
    setEstadoActual(selectedStageIndex);
    if (estadoActual === selectedStageIndex) {
      const estado = templateData.workflow[selectedStageIndex].nombre;
      const timestamp = DateTime.now().toUTC().toISO();
      const workflow = templateData.workflow.map((w: WorkflowInterface) => {
        if (w.nombre === name) {
          w.estado = selectedStageIndex !== 0 ? !state : true;
          w.fecha = timestamp;
          w.responsable = userName;
        }
        return w;
      });

      setTemplate({ ...template, estado, timestamp, workflow });
      setEstadoNombre(name);
    }
  };

  const LikeDislikeButtons: React.FC<{ actualStage: number }> = ({ actualStage }) => {
    const initLikedFeedback: number = templateData.workflow![actualStage]?.likedFeedback ?? -1;
    const [likedFeedback, setLikedFeedback] = useState<number>(initLikedFeedback);

    const [successHover, setSuccessHover] = useState(false);
    const [errorHover, setErrorHover] = useState(false);
    const saveLikeDislike = (like: number) => {
      const result = templateData;
      result.workflow![estadoActual].likedFeedback = like;
      setTemplate({ ...result });
    };

    return (
      <>
        {likedFeedback === 1 && (
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Tooltip title='De acuerdo'>
              <ThumbUpIcon className='icon-hover' color={'success'} />
            </Tooltip>
            |
            <Tooltip title='En desacuerdo'>
              <ThumbDownAltOutlinedIcon
                className='icon-hover'
                color={errorHover ? 'error' : 'disabled'}
                onMouseEnter={() => setErrorHover(true)}
                onMouseLeave={() => setErrorHover(false)}
                onClick={() => {
                  setLikedFeedback(0);
                  setErrorHover(false);
                  saveLikeDislike(0);
                }}
              />
            </Tooltip>
          </div>
        )}
        {likedFeedback === 0 && (
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Tooltip title='De acuerdo'>
              <ThumbUpAltOutlinedIcon
                className='icon-hover'
                color={successHover ? 'success' : 'disabled'}
                onMouseEnter={() => setSuccessHover(true)}
                onMouseLeave={() => setSuccessHover(false)}
                onClick={() => {
                  setLikedFeedback(1);
                  setSuccessHover(false);
                  saveLikeDislike(1);
                }}
              />
            </Tooltip>
            |
            <Tooltip title='En desacuerdo'>
              <ThumbDownIcon className='icon-hover' color={'error'} />
            </Tooltip>
          </div>
        )}
        {likedFeedback === -1 && (
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Tooltip title='De acuerdo'>
              <ThumbUpAltOutlinedIcon
                className='icon-hover'
                color={successHover ? 'success' : 'disabled'}
                onMouseEnter={() => setSuccessHover(true)}
                onMouseLeave={() => setSuccessHover(false)}
                onClick={() => {
                  setLikedFeedback(1);
                  setSuccessHover(false);
                  saveLikeDislike(1);
                }}
              />
            </Tooltip>
            |
            <Tooltip title='En desacuerdo'>
              <ThumbDownAltOutlinedIcon
                className='icon-hover'
                color={errorHover ? 'error' : 'disabled'}
                onMouseEnter={() => setErrorHover(true)}
                onMouseLeave={() => setErrorHover(false)}
                onClick={() => {
                  setLikedFeedback(0);
                  setErrorHover(false);
                  saveLikeDislike(0);
                }}
              />
            </Tooltip>
          </div>
        )}
      </>
    );
  };

  const returnTsxInputs = (item: WorkflowInterface, index: number) => {
    return (
      <React.Fragment key={index}>
        {skeleton.workflow?.some((etapa) => etapa.nombre === item.nombre) && (
          <SubStages
            template={template}
            setTemplate={setTemplate}
            skeleton={skeleton}
            item={item}
            estadoActual={estadoActual}
          />
        )}
        <Divider sx={{ marginTop: '20px', marginBottom: '35px' }} />
        <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
          <FormGroup>
            <Typography component='legend'>Comentarios</Typography>
            <Typography component='legend' variant='caption' color='text.secondary' gutterBottom>
              Deje un comentario sobre el desempeño del candidato en esta etapa
            </Typography>
            <MDXMarkdownEditor
              value={item.feedback}
              onChange={onChangeFeedback}
              onBlur={onBlurEvent}
              placeholder='Comentarios'
            />

            <LikeDislikeButtons actualStage={estadoActual} />
          </FormGroup>
          <Box>
            <Typography component='legend'>Escala de desempeño</Typography>
            <Typography component='legend' variant='caption' color='text.secondary' gutterBottom>
              De 1 a 5 estrellas, califique el desempeño del candidato en esta etapa
            </Typography>
            <Rating
              name='Evaluacion'
              value={item.estrellas ? item.estrellas : 0}
              precision={0.5}
              onChange={(event, newValue) => {
                let result = templateData;
                result.workflow = template.workflow.map((work: WorkflowInterface) => {
                  if (work.nombre === item.nombre) {
                    work.estrellas = newValue ? newValue : 0;
                  }
                  return work;
                });
                setTemplate({ ...result });
              }}
            />
          </Box>
          <div className='row'>
            <div className='col-6'>
              {item.motivo[0] && (
                <FormGroup>
                  <Typography component='legend'>Motivo de rechazo</Typography>
                  <Typography
                    component='legend'
                    variant='caption'
                    color='text.secondary'
                    gutterBottom
                  >
                    Seleccione el motivo por el cual el candidato no continúa en el proceso
                  </Typography>
                  {item.motivo.map((value, indexMotivo) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={value.selected}
                          onChange={(event) => handleCheckbox(event, indexMotivo)}
                          name={value.nombre}
                          size='small'
                        />
                      }
                      label={value.nombre}
                      key={indexMotivo}
                    />
                  ))}
                </FormGroup>
              )}
            </div>
            <div className='col-6'>
              {item.tareas?.length > 0 && (
                <Form.Group>
                  <Form.Label>Tareas (requerido *)</Form.Label>
                  {item.tareas &&
                    item.tareas.map((value) => (
                      <Form.Check
                        defaultChecked={value.selected}
                        type='checkbox'
                        key={value.id}
                        label={`${value.required ? value.nombre + '*' : value.nombre}`}
                        alt={`${value.id}`}
                        name={value.nombre}
                        onChange={handleCheckboxTareas}
                      />
                    ))}
                </Form.Group>
              )}

              {skeleton &&
                item.tareas === undefined &&
                skeleton.workflow?.some((etapa) => etapa.nombre === item.nombre) && (
                  <Form.Group>
                    <Form.Label>Tareas (requerido *)</Form.Label>
                    {skeleton?.workflow?.map((value) =>
                      value.tareas?.map((tarea) => {
                        if (item.nombre === value.nombre) {
                          return (
                            <Form.Check
                              defaultChecked={tarea.selected}
                              type='checkbox'
                              key={tarea.id}
                              label={`${tarea.required ? tarea.nombre + '*' : tarea.nombre}`}
                              alt={`${tarea.id}`}
                              name={tarea.nombre}
                              onChange={handleCheckboxTareasOld}
                            />
                          );
                        }
                        return null;
                      })
                    )}
                  </Form.Group>
                )}
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
  };

  const returnTsxWorkflow = (item: WorkflowInterface, index: number) => {
    return (
      <React.Fragment key={index}>
        {index !== 0 && <Separador />}
        <Col>
          <div ref={scrollBoxRef} onClick={handleComponentClick}>
            <Step
              clickStage={clickStage}
              item={item}
              stepNumber={index + 1}
              isActual={index === estadoActual}
            />
          </div>
        </Col>
      </React.Fragment>
    );
  };

  return (
    <Container id='workflow'>
      <Row>
        <ScrollableBox sx={{ display: 'flex', marginBottom: '20px', overflow: 'auto' }}>
          {template.workflow &&
            template.workflow.map((item: WorkflowInterface, index: number) =>
              returnTsxWorkflow(item, index)
            )}
        </ScrollableBox>
      </Row>

      {template.workflow &&
        template.workflow.map(
          (item: WorkflowInterface, index: number) =>
            index === estadoActual && returnTsxInputs(item, index)
        )}
    </Container>
  );
};

export default WorkflowComponent;
