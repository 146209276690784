import React from 'react';
import './box.css';

const Box = (props: any, key?: string) => {
  return (
    <div
      className={`${props?.variant?.display}`}
      onMouseLeave={props?.mouseleave?.handle}
      onMouseOver={props?.mouseover?.handle}
      onClick={props?.click?.handle}
      key={`${props?.keys?.keys}`}
    >
      {props.children}
    </div>
  );
};

export default Box;
