import styled from '@emotion/styled/macro';

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    transition: 200ms ease;
    cursor: pointer;
    color: white;
    & > * {
      font-size: 20px;
    }
    &:hover {
      color: #f15b29;
    }
  }
`;

export const StyledTh = styled.th`
  border-right: 2px solid #f8f8ff;
  background-color: #0b5ed7 !important;
  color: white !important;
`;
