class PeerService {
  constructor() {
    if (!this.peer) {
      this.peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              'stun:179.60.67.81:3478',
              'stun:stun.l.google.com:19302',
              'stun:global.stun.twilio.com:3478',
            ],
          },
          {
            urls: 'turn:179.60.67.81:3478',
            username: 'seba',
            credential: '12345',
          },
        ],
      });
    }
  }
  async reconnect() {
    if (this.peer) {
      console.log('Intentando reconectar...');
      this.peer.close(); // Cierra la conexión actual
      this.peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              'stun:179.60.67.81:3478',
              'stun:stun.l.google.com:19302',
              'stun:global.stun.twilio.com:3478',
            ],
          },
          {
            urls: 'turn:179.60.67.81:3478',
            username: 'seba',
            credential: '12345',
          },
        ],
      });
      console.log('Reconexión establecida.');
    } else {
      console.log('No hay conexión existente para reconectar.');
    }
  }

  async getAnswer(offer) {
    if (this.peer) {
      try {
        await this.peer.setRemoteDescription(offer);
        const ans = await this.peer.createAnswer();
        await this.peer.setLocalDescription(new RTCSessionDescription(ans));
        return ans;
      } catch (error) {
        console.log('Error al crear la respuesta:', error);
      }
    }
  }

  async setLocalDescription(ans) {
    if (this.peer) {
      try {
        await this.peer.setRemoteDescription(new RTCSessionDescription(ans));
      } catch (error) {
        console.log('Error al establecer la descripción remota:', error);
      }
    }
  }

  async getOffer() {
    if (this.peer) {
      try {
        const offer = await this.peer.createOffer();
        await this.peer.setLocalDescription(new RTCSessionDescription(offer));
        return offer;
      } catch (error) {
        console.log('Error al crear la oferta:', error);
      }
    }
  }
}
const peer = new PeerService();
export default peer;
