import React, { useState, useEffect } from 'react';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { CompetenciasInterface } from 'components/models/SoftSkillsInterfaces';
import TextEditor from '../../../ui/textEditor';
import { Stack, Button, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  dataCandidate: CandidateInterface;
}

interface Competencia {
  nombre: string;
  valor: string | string[];
}

const SoftSkillsComponent: React.FC<Props> = ({ dataCandidate }) => {
  const [template, setTemplate] = useState<any>({});
  const [opciones, setOpciones] = useState<string[][]>([[]]);

  useEffect(() => {
    setTemplate(dataCandidate);
  }, [dataCandidate]);

  const handleOnChangeInput =
    (name: string = '') =>
    (value: string) => {
      const result = dataCandidate;
      result.competencias =
        dataCandidate.competencias &&
        dataCandidate.competencias.map((data: any) => {
          if (name === data.nombre) {
            if (Array.isArray(data.valor)) {
              return data;
            } else {
              return { nombre: data.nombre, valor: value };
            }
          } else {
            return data;
          }
        });

      setTemplate({ ...result });
    };

  const handleArrayInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nombre = event.target.getAttribute('name');
    const position = event.target.getAttribute('position');
    const valor = event.target.value;

    if (!opciones[nombre]) opciones[nombre] = [];
    opciones[nombre][parseInt(position)] = valor;

    setOpciones(opciones);
    const result = template;

    result.competencias = template.competencias?.map((comp: any) => {
      if (comp.nombre === nombre) {
        comp.valor[position] = valor;
        return { ...comp };
      } else {
        return comp;
      }
    });
    setTemplate({ ...result, competencias: result.competencias });
  };

  const handleAddCompetencias = (Newcompetencias: Competencia) => {
    const updatedCompetencias = template.competencias;
    updatedCompetencias.push(Newcompetencias);
    const result = { ...template, competencias: updatedCompetencias };
    setTemplate({ ...result });
  };

  const callFormAddCompetencia = () => {
    SwalAlert({
      title: 'Agregar Competencia',
      text: 'Ingrese el nombre de la competencia',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      input: 'text',
      showConfirmButton: true,
      confirmButtonText: 'Definir tipo de ingreso',
      inputValidator: (value: string) => {
        if (value === '') {
          return 'Ingrese el nombre de la competencia';
        }
      },
    }).then(({ isConfirmed, value }: any) => {
      if (isConfirmed) {
        const nombre = value;
        SwalAlert({
          title: 'Agregar Competencia',
          text: 'Seleccione el tipo de ingreso',
          input: 'radio',
          inputOptions: { simple: 'Valor simple', multiple: 'Valor multiple' },
          showConfirmButton: true,
          confirmButtonText: 'Siguiente',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then(({ isConfirmed, value }: any) => {
          const type = value;
          if (isConfirmed) {
            SwalAlert({
              title: 'Agregar Competencia',
              text:
                type === 'simple'
                  ? 'Ingrese el valor de la competencia'
                  : 'Ingrese un valor para su lista de competencia',
              input: 'text',
              confirmButtonText: 'Crear competencia',
              showConfirmButton: true,
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              background: '#213456',
            }).then(({ isConfirmed, value }: any) => {
              if (isConfirmed) {
                if (type === 'simple') {
                  handleAddCompetencias({ nombre: nombre, valor: value });
                } else {
                  handleAddCompetencias({ nombre: nombre, valor: [value] });
                }
              }
            });
          }
        });
      }
    });
  };

  const handleAddInput = (index: number) => {
    let result = dataCandidate;

    result.competencias = template.competencias.map((comp: Competencia, i: number) => {
      if (comp.nombre === 'Fortalezas' && index === 0) {
        let value = comp.valor.concat('');
        return { ...comp, valor: value };
      } else if (comp.nombre === 'Debilidades' && index === 1) {
        let value = comp.valor.concat('');
        return { ...comp, valor: value };
      } else if (comp.nombre === 'Hobbies' && index === 2) {
        let value = comp.valor.concat('');
        return { ...comp, valor: value };
      } else if (index > 2 && index === i) {
        let value = comp.valor.concat('');
        return { ...comp, valor: value };
      } else {
        return comp;
      }
    });

    setTemplate({ ...result });
  };

  const returnListSkillTsx = (items: CompetenciasInterface, index: number) => (
    <Stack key={index}>
      <Typography component='p' variant='h6' gutterBottom>
        {items.nombre}
      </Typography>
      {Array.isArray(items.valor) ? (
        <Stack gap={1} alignItems='flex-start'>
          {items.valor.map((item, key) => (
            <TextField
              key={key}
              name={items.nombre}
              variant='outlined'
              size='small'
              spellCheck={true}
              defaultValue={template ? template.competencias[index].valor[key] : undefined}
              onChange={handleArrayInputs}
              fullWidth
              inputProps={{ position: key.toString() }}
            />
          ))}
          <Button onClick={() => handleAddInput(index)} startIcon={<AddCircleIcon />}>
            Agregar
          </Button>
        </Stack>
      ) : (
        <TextEditor
          value={template ? items.valor : ''}
          onChange={handleOnChangeInput(items.nombre)}
          placeholder={items.nombre}
        />
      )}
    </Stack>
  );

  return (
    <Stack gap={3}>
      {template.competencias &&
        template.competencias.map((competencia: CompetenciasInterface, index: number) =>
          returnListSkillTsx(competencia, index)
        )}
      <Button
        onClick={callFormAddCompetencia}
        startIcon={<AddCircleIcon />}
        sx={{ alignSelf: 'flex-start' }}
      >
        Agregar competencia
      </Button>
    </Stack>
  );
};

export default SoftSkillsComponent;
