import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Line = styled(Box)`
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto 5px;
  background-color: ${(props) => (props.theme.palette.mode === 'dark' ? '#757575' : '#bdbdbd')};
`;
