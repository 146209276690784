import { Box, Typography, Modal, Button, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import {
  bodyModal,
  buttonModal,
  headerModal,
  roomInfo,
  titleModal,
  warningIcon,
  warningMessage,
} from './style';
import WarningIcon from '@mui/icons-material/Warning';

const RoomInfo = ({ isVisible, setIsVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Modal
      open={isVisible}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={roomInfo}>
        <Box sx={warningMessage}>
          <Box sx={{ ...headerModal, [theme.breakpoints.up('md')]: { flexDirection: 'row' } }}>
            <WarningIcon sx={warningIcon} />
            <Typography variant='h3' sx={titleModal}>
              Información Importante
            </Typography>
            {isMobile && <WarningIcon sx={warningIcon} />}
          </Box>
          <Typography variant='body1' sx={bodyModal}>
            Por favor, note que el led de la cámara estará encendido durante la sesión por políticas
            de privacidad.
            <br /> Puede desactivar el video con el botón en la esquina inferior derecha. <br />
            Aunque el led siga encendido, no se enviarán datos de video. <br />
            La cámara se apagará automáticamente al finalizar la sesión.
          </Typography>
          <Button variant='contained' sx={buttonModal} onClick={() => setIsVisible(false)}>
            Aceptar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RoomInfo;
