import React, { createContext, useMemo, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
interface SocketProviderProps {
  children?: React.ReactNode;
  shouldConnect: boolean;
}

const SocketContext = createContext<{
  socket: Socket | null;
  shouldConnect: boolean;
  setShouldConnect: (value: boolean) => void;
} | null>(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [shouldConnect, setShouldConnect] = useState(false);
  const socket = useMemo(() => {
    //return shouldConnect ? io('https://shocking-jewel-result.glitch.me') : null;
    //return shouldConnect ? io('https://fir-puddle-catsup.glitch.me') : null;
    //return shouldConnect ? io('https://8000-01hxwwvfmr2z194ys35m9s7qb1.cloudspaces.litng.ai') : null;
    return shouldConnect ? io(process.env.REACT_APP_SOCKETIO_INTERVIEW_URL) : null;
  }, [shouldConnect]);

  useEffect(() => {
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, shouldConnect, setShouldConnect }}>
      {children}
    </SocketContext.Provider>
  );
};
