import React, { FC, useState, useEffect } from 'react';
import { IconSelector } from './iconSelector';
import type { ITeam, IStack, IStackLevel } from 'components/pages/createTeam/CreateTeam.types';
import getAxios from 'apiRestConnector';
import CircularProgress from '@mui/material/CircularProgress';

type TTeamValidator = {
  employeeTeams: {
    _id: string;
    nombre: string;
  }[];
  REACT_APP_BACKEND_URL: string;
  access_token: string;
};
type TIconSelectorProps = {
  color: 'error' | 'success';
  toolTipMessage: string;
};

export const TeamValidator: FC<TTeamValidator> = ({
  employeeTeams,
  REACT_APP_BACKEND_URL,
  access_token,
}) => {
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [loading, setLoading] = useState(true);
  const [iconSelelectorProps, setIconSelelectorProps] = useState<TIconSelectorProps>({
    color: 'error',
    toolTipMessage: 'No se encontraron equipos',
  });

  const teamIconSelector = () => {
    if (!teams)
      setIconSelelectorProps({ color: 'error', toolTipMessage: 'No se encontraron equipos' });
    let toolTipMessage: string =
      teams.length === 1
        ? 'El equipo contiene toda la información necesaria'
        : 'Los equipos contienen toda la información necesaria';
    let iconColor: 'success' | 'error' = 'success';
    const validateTeamStack = (stack: IStack[]): boolean => {
      const validateNivel = (nivel: IStackLevel[]): boolean => {
        if (!nivel) return false;
        if (nivel.length === 0) return false;
        if (nivel.filter((nivelItem) => nivelItem.valor === true).length === 0) return false;
        return true;
      };
      if (!stack) return false;
      if (stack.length === 0) return false;
      stack.forEach((stackItem) => {
        if (!stackItem) return false;
        if (
          stackItem.descripcion === '' ||
          stackItem.nombre === '' ||
          stackItem.tags?.length === 0 ||
          validateNivel(stackItem.nivel) === false
        ) {
          return false;
        }
      });
      return true;
    };
    teams.forEach((team) => {
      if (
        team.area_empresa === '' ||
        team.empresa === '' ||
        team.email_manager === '' ||
        team.nombre_manager === '' ||
        team.nombre === '' ||
        team.descripcion === '' ||
        team.lider_tecnico_ids?.length === 0 ||
        team.integrantes_ids?.length === 0 ||
        validateTeamStack(team.stack) === false
      ) {
        iconColor = 'error';
        toolTipMessage = 'El equipo no contiene toda la información necesaria';
      }
    });
    setIconSelelectorProps({ color: iconColor, toolTipMessage: toolTipMessage });
    setLoading(false);
  };

  useEffect(() => {
    const fetchTeams = async (): Promise<ITeam[]> => {
      const allTeams: ITeam[] = [];
      for (const team of employeeTeams) {
        if (!team._id) continue;
        const response = await getAxios
          .instance(access_token)
          .get(`${REACT_APP_BACKEND_URL}/team/${team._id}`);

        allTeams.push(response.data);
      }
      return allTeams;
    };
    const loadTeams = async () => {
      const teams = await fetchTeams();
      setTeams(teams);
    };
    loadTeams();
  }, [employeeTeams, REACT_APP_BACKEND_URL, access_token]);

  if (teams) {
    setTimeout(teamIconSelector, 2000);
  }
  if (loading) {
    return <CircularProgress size={20} />;
  }
  return (
    <>
      {employeeTeams.length === 0 && teams.length === 0 ? (
        <IconSelector color='error' toolTipMessage='El Mentee no pertenece a un equipo' />
      ) : (
        <IconSelector {...iconSelelectorProps} />
      )}
    </>
  );
};
