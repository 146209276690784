import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const teamFormImageStyle = {
  width: '100%',
  height: '400px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

type TeamFormImageProps = BoxProps & {
  imageUrl: string;
};

const TeamFormImage = ({ imageUrl, ...restProps }: TeamFormImageProps) => {
  return (
    <Box
      component='img'
      {...restProps}
      sx={{ ...teamFormImageStyle, backgroundImage: `url(${imageUrl})`, ...restProps.sx }}
    />
  );
};

export default TeamFormImage;
