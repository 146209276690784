import React, { FC } from 'react';
import { StyledTd, StyledLink } from './style';
import { CountInput } from 'components/ui/buttons/countInput/CountInput';
import { DateTime } from 'luxon';

interface Props {
  contents: Array<Contents>;
}

interface Contents {
  rol: any;
  empresa: any;
  nivel: any;
  estado: any;
  fechaFin: any;
  fechaInicio: any;
  stack: any;
  plazo: any;
  clonar: any;
}

const TableRowRequest: FC<Props> = ({ contents }) => {
  const rowsColor = (content: Contents) => {
    if (content.estado.data === 'Cerrado OK') {
      return '#d8ffb7';
    } else if (content.estado.data === 'Cerrado no OK') {
      return '#FFE1DC';
    }
  };

  return (
    <>
      {contents.length > 0 &&
        contents.map((content, i) => (
          <tr
            style={{ background: rowsColor(content), verticalAlign: `center` }}
            key={`tableRowRequest${i}`}
          >
            <StyledTd>
              <StyledLink to={`${content.rol.href}`}>{content.empresa.data}</StyledLink>
            </StyledTd>
            <StyledTd>
              <div>
                {typeof content.rol.data === 'string'
                  ? content.rol.data
                  : content.rol.data.join(', ')}
              </div>
            </StyledTd>
            <StyledTd>
              <div>{content.nivel.data}</div>
            </StyledTd>
            <StyledTd>
              <div>{DateTime.fromISO(content.fechaInicio.data).toLocaleString()}</div>
            </StyledTd>
            <StyledTd>
              <div>{DateTime.fromISO(content.fechaFin.data).toLocaleString()}</div>
            </StyledTd>
            <StyledTd>
              <div>{content.plazo.data}</div>
            </StyledTd>
            <StyledTd>
              <div>{content.estado.data}</div>
            </StyledTd>
            <StyledTd>
              <div>{content.stack.data.join(', ')}</div>
            </StyledTd>
            <StyledTd style={{ width: 100, position: 'relative' }}>
              <CountInput id={content.clonar.id} />
            </StyledTd>
          </tr>
        ))}
    </>
  );
};

export default TableRowRequest;
