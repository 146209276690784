import { allCountries } from 'data/countryData';

function flagFunction(num: string) {
  let six = '';
  allCountries
    .filter((c: any) => c.dialCode.length === 6)
    .forEach((cf: any) => {
      if (cf.dialCode.trim() === num.slice(0, 6).trim()) {
        six = cf.iso2.trim().toUpperCase();
      }
    });

  if (six !== '') {
    return six;
  }

  let five = '';
  allCountries
    .filter((c: any) => c.dialCode.length === 5)
    .forEach((cf: any) => {
      if (cf.dialCode.trim() === num.slice(0, 5).trim()) {
        five = cf.iso2.trim().toUpperCase();
      }
    });

  if (five !== '') {
    return five;
  }

  let four = '';
  allCountries
    .filter((c: any) => c.dialCode.length === 4)
    .forEach((cf: any) => {
      if (cf.dialCode.trim() === num.slice(0, 4).trim()) {
        four = cf.iso2.trim().toUpperCase();
      }
    });
  if (four !== '') {
    return four;
  }

  let three = '';
  allCountries
    .filter((c: any) => c.dialCode.length === 3)
    .forEach((cf: any) => {
      if (cf.dialCode.trim() === num.slice(0, 3).trim()) {
        three = cf.iso2.trim().toUpperCase();
      }
    });
  if (three !== '') {
    return three;
  }

  let two = '';
  allCountries
    .filter((c: any) => c.dialCode.length === 2)
    .forEach((cf: any) => {
      if (cf.dialCode.trim() === num.slice(0, 2).trim()) {
        two = cf.iso2.trim().toUpperCase();
      }
    });

  if (two !== '') {
    return two;
  }
}

export { flagFunction };
