import { IPreguntas, innerSkillsInterface } from 'components/models/SkillsInterfaces';
import React from 'react';
import { Wrapper } from 'components/ui/graphics/tooltip/style';
import BarChart from '../bar/BarChart';

interface TooltipProps {
  skills: innerSkillsInterface[];
  index: number;
  filtered: IPreguntas[];
}

const Tooltip: React.FC<TooltipProps> = ({ skills, index, filtered }) => {
  return (
    <>
      <h5 style={{ borderBottom: '1px solid rgba(49, 207, 242, 0.32)' }}>{skills[index].nombre}</h5>
      <Wrapper>
        <BarChart preguntas={filtered} />
      </Wrapper>
    </>
  );
};

export default Tooltip;
