import { useEffect } from 'react';
export function useSocketConnection(socket) {
  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [socket]);
}
