import React, { FC, useState } from 'react';
import { StyledDiv, StyledTh } from './style';
import { ArrowUpCircleFill, ArrowDownCircleFill, FunnelFill } from 'react-bootstrap-icons';

interface Props {
  onClick: (key: string) => void;
  state: boolean;
  onClickParameter: string;
  label: string;
}

const FilterThTableHead: FC<Props> = ({ onClick, onClickParameter, state, label }) => {
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [condition, setCondition] = useState<boolean>(state);

  const filter = () => {
    setShowFilter(!showFilter);
    onClick(onClickParameter);
  };

  return (
    <StyledTh>
      <StyledDiv>
        {label}
        {showFilter ? (
          <span>
            <FunnelFill onClick={filter} />
          </span>
        ) : (
          <span
            onClick={() => {
              onClick(onClickParameter);
              setCondition(!condition);
            }}
          >
            {condition ? <ArrowUpCircleFill /> : <ArrowDownCircleFill />}
          </span>
        )}
      </StyledDiv>
    </StyledTh>
  );
};

export default FilterThTableHead;
