import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import OneSignal from 'react-onesignal';
import { Settings } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ThemeContextProvider from './context/ThemeContextProvider';
import AuthContextProvider, { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from './apiRestConnector';
import Routes from './routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import ROL from 'utils/Roles';
import { SocketProvider } from 'context/SocketProvider';

// Configura el idioma por defecto para las fechas.
const DATE_LOCALE = 'es';
Settings.defaultLocale = DATE_LOCALE;

// Inicializa Google Analytics con el ID de seguimiento.
ReactGA.initialize('G-R5JLQPWDH7');

// Define la función de consulta predeterminada para las consultas de TanStack Query.
const defaultQueryFn = async ({ queryKey }) => {
  const [url, access_token] = queryKey;
  const response = await getAxios.instance(access_token).get(url);
  return response.data;
};

// Crea una instancia de cliente de consulta para TanStack Query.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

// Lista de roles validos para suscripcion de notificaciones Push
const validRolesForOneSignal = [ROL.ADMINISTRADOR, ROL.RECLUTADOR];

// Variable para controlar si OneSignal ya fue inicializado una sola vez en la aplicacion.
let oneSignalInitialized = false;

const App = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const REACT_APP_RECAPTCHA = storeAuth.recaptcha;

  // Se ejecuta cada vez que el usuario hace login, logout o cambia de rol.
  useEffect(() => {
    if (storeAuth.isAuth && validRolesForOneSignal.includes(storeAuth.user.role[0])) {
      // Si el usuario esta autenticado y su rol es valido para OneSignal, inicializa OneSignal si no esta inicializado.
      if (!oneSignalInitialized) {
        OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        })
          .then(() => {
            // Se marca como inicializado para que no se vuelva a inicializar.
            oneSignalInitialized = true;

            // Se actualiza el External ID y se añaden tags al usuario en OneSignal.
            OneSignal.login(storeAuth.user._id)
              .then(() => {
                OneSignal.User.addTags({
                  role: storeAuth.user.role[0].toString(),
                  name: storeAuth.user.name.toString(),
                });
                OneSignal.User.addEmail(storeAuth.user.email);
              })
              .catch((error) => {
                console.error('Error en login de OneSignal:', error);
              });
          })
          .catch((error) => {
            console.error('Error en la inicialización de OneSignal:', error);
          });
      }
    }
  }, [storeAuth]);

  return (
    <ThemeContextProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DATE_LOCALE}>
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />
            <SocketProvider shouldConnect={false}>
              <Routes />
            </SocketProvider>
          </QueryClientProvider>
        </GoogleReCaptchaProvider>
      </LocalizationProvider>
    </ThemeContextProvider>
  );
};

export default AuthContextProvider(App);
