import React, { useContext, useState } from 'react';
import { InputNumber } from 'components/ui/tables/tableRow/style';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import { useCounter } from 'hooks/useCounter';
import { ButtonRest, ButtonPlus, GroupButton } from '../../tables/tableRow/style';

import Loading from 'components/ui/loading/Loading';
import Spinner from 'components/ui/spinner/Spinner';
import SwalAlert from 'components/ui/alerts/SwalAlert';

export const CountInput = ({ id }: any) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [activeCloneCard, setactiveCloneCard] = useState(false);
  const [loadingClone, setLoadingClone] = useState<boolean>(false);
  const { counter, increment, decrement, reset } = useCounter(1);
  const [validateHoverColor, setValidateHoverColor] = useState(false);

  const duplicateCard = async (element: number) => {
    try {
      const urlBackend = `${REACT_APP_BACKEND_URL}/solicitudes/duplicar/${element}`;
      let response: any;
      for (let i = 0; i < counter; i++) {
        response = await getAxios.instance(access_token).post(urlBackend);
        reset();
        setactiveCloneCard(false);
      }
      response.data &&
        SwalAlert({
          title: 'Solicitud Clonada',
          text: `Se han clonado ${counter} solicitudes correctamente`,
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
        }).then((result) => {
          window.location.reload();
        });
      setLoadingClone(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDuplicateCard = () => {
    if (counter > 0) {
      duplicateCard(id);
      setLoadingClone(true);
    } else {
      setactiveCloneCard(false);
    }
  };

  return (
    <>
      {loadingClone ? (
        <Loading>
          <Spinner color='success' />
        </Loading>
      ) : (
        <div>
          <NoteAddOutlinedIcon
            sx={
              activeCloneCard === false
                ? {
                    opacity: 1,
                    transform: 'scale(1.2)',
                    position: 'absolute',
                    zIndex: 2,
                    top: '40%',
                    left: '40%',
                    cursor: 'pointer',
                  }
                : { opacity: 0, display: 'none' }
            }
            onClick={() => setactiveCloneCard(true)}
          />
          <GroupButton style={activeCloneCard === true ? { opacity: 1 } : { opacity: 0 }}>
            <ButtonRest
              style={{ backgroundColor: counter === 0 ? 'gray' : '#5789df' }}
              disabled={counter === 0}
              onClick={decrement}
            >
              -
            </ButtonRest>
            <InputNumber
              onClick={handleDuplicateCard}
              onMouseOver={() => setValidateHoverColor(true)}
              onMouseLeave={() => setValidateHoverColor(false)}
              style={{
                backgroundColor:
                  counter !== 0 && validateHoverColor
                    ? '#66BB6A'
                    : counter === 0 && validateHoverColor
                      ? '#FF0000'
                      : 'white',
              }}
              title={counter !== 0 ? 'Clonar' : ''}
            >
              {counter === 0 ? 'Cancelar' : counter}
            </InputNumber>
            <ButtonPlus onClick={increment}>+</ButtonPlus>
          </GroupButton>
        </div>
      )}
    </>
  );
};
