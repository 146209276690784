export const stackTemplate = `{
    "stack": [
        {
            "nombre": "ReactJS",
            "descripcion": "Conocimiento en desarrollo de aplicaciones web utilizando la librería ReactJS.",
            "esFramework": true,
            "default": true,
            "tags": [
                "FrontEnd",
                "Biblioteca",
                "JavaScript",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de ReactJS debería incluir:\\n\\n1. Componentes funcionales con Hooks (useState, useEffect).\\n2. Manejo de rutas con React Router.\\n3. Gestión de estado con Context API.\\n4. Formularios con validación usando React Hook Form o Formik.\\n5. Estilos con CSS Modules o Styled Components.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto ReactJS de complejidad media debería incluir:\\n\\n1. **Hooks personalizados** para lógica reusable.\\n2. **Context API** para manejo de estado global.\\n3. **React Router** para navegación.\\n4. **Lazy loading** y **Suspense** para optimización de carga.\\n5. **Formik** o **React Hook Form** para gestión de formularios.\\n6. **PropTypes** o **TypeScript** para tipado estático.\\n7. **Pruebas unitarias** con **Jest** y **React Testing Library**.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de ReactJS debería incluir:\\n\\n1. **Hooks personalizados** para lógica reusable.\\n2. **Context API** o **Redux** con **Redux Toolkit** para manejo de estado global.\\n3. **React Router** para navegación.\\n4. **Lazy loading** y **Code Splitting** para optimización de carga.\\n5. **TypeScript** para tipado estático.\\n6. **Pruebas unitarias y de integración** con **Jest** y **React Testing Library**.\\n7. **Patrones avanzados** como Compound Components, Render Props, y Higher-Order Components.\\n8. **Internacionalización** para soporte multilenguaje.\\n9. **Accesibilidad (a11y)** implementada con **ARIA** roles y prácticas recomendadas.\\n10. **Estilos robustos** con **Styled Components** o **Emotion**.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Angular",
            "descripcion": "Framework JavaScript/TypeScript para desarrollo web SPA.",
            "esFramework": true,
            "default": false,
            "tags": [
                "FrontEnd",
                "SPA",
                "TypeScript"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto Angular básico debería incluir: \\n\\n1. Componentes reutilizables.\\n2. Enrutamiento con \`RouterModule\` para navegación SPA.\\n3. Servicios para manejar lógica de negocio y llamadas HTTP con \`HttpClientModule\`.\\n4. Formularios reactivos o basados en plantillas.\\n5. Modularización con \`NgModule\`.\\n6. Directivas estructurales y de atributos.\\n7. Binding de datos bidireccional con \`[(ngModel)]\`.\\n8. Uso de \`RxJS\` para manejo reactivo de datos.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Angular de complejidad media debería incluir: Lazy loading para módulos, gestión de estado con NgRx o servicios con BehaviorSubject, formularios reactivos, interceptores HTTP, routing avanzado con guardas, y pruebas unitarias con Jasmine y Karma.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de Angular debería incluir lazy loading para módulos, gestión de estado con NgRx o Akita, manejo avanzado de formularios reactivos, interceptores HTTP, estrategias de detección de cambios OnPush, SSR con Angular Universal, y pruebas unitarias y de integración con Jasmine y Protractor.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "VueJS",
            "descripcion": "Framework JavaScript progresivo para construcción de interfaces de usuario.",
            "esFramework": true,
            "default": false,
            "tags": [
                "FrontEnd",
                "Reactividad"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de Vue.js debería incluir:\\n\\n1. Uso de Vue CLI para configuración inicial.\\n2. Componentes Vue reutilizables y con props para la comunicación.\\n3. Uso de la Composition API para lógica reactiva.\\n4. Manejo de estado con \`reactive\` o \`ref\`.\\n5. Directivas básicas (\`v-bind\`, \`v-model\`, \`v-on\`, \`v-if\`, \`v-for\`).\\n6. Estilos locales en componentes.\\n7. Navegación con Vue Router.\\n8. Llamadas HTTP con Axios o Fetch API.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Vue.js de complejidad media debería incluir:\\n\\n1. Componentes reutilizables y modulares.\\n2. Uso de Vue Router para navegación SPA.\\n3. Vuex para manejo de estado centralizado.\\n4. Composición API para lógica reactiva avanzada.\\n5. Directivas personalizadas para funcionalidades específicas.\\n6. Mixins o Composition API para compartir lógica.\\n7. Manejo de formularios y validaciones.\\n8. Integración con APIs REST o GraphQL.\\n9. Lazy loading y code splitting para optimización de carga.\\n10. Pruebas unitarias y de integración con Vue Test Utils y Jest.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de VueJS debería incluir:\\n\\n1. Uso de Vue 3 con Composition API para una mejor organización del código.\\n2. Manejo de estado con Vuex 4 y persistencia de estado.\\n3. Vue Router para navegación SPA.\\n4. SSR con Nuxt.js para SEO.\\n5. Integración de TypeScript para tipado estático.\\n6. Pruebas unitarias y E2E con Jest y Cypress.\\n7. Lazy loading y code splitting para optimizar la carga.\\n8. Uso de Vite para un desarrollo y construcción rápidos.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "CSS",
            "descripcion": "Conocimiento en diseño y estilización de páginas web utilizando CSS.",
            "esFramework": false,
            "default": true,
            "tags": [
                "FrontEnd",
                "Diseño",
                "Estilización",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Para un proyecto de CSS de complejidad básica, las funcionalidades clave podrían incluir:\\n\\n1. Uso de variables CSS para colores y fuentes.\\n2. Flexbox para diseño responsivo y alineación de elementos.\\n3. Grid para estructuras de página más complejas.\\n4. Media queries para adaptabilidad a diferentes tamaños de pantalla.\\n5. Transiciones y transformaciones básicas para interactividad sutil.\\n6. Metodología BEM o similar para nomenclatura consistente y mantenible.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto CSS de complejidad media debería incluir:\\n\\n1. **Flexbox/Grid**: Para crear layouts responsivos y complejos.\\n2. **Variables CSS**: Para manejar colores y fuentes de forma consistente.\\n3. **Media Queries**: Para adaptabilidad a diferentes dispositivos.\\n4. **Transiciones y Animaciones**: Para interactividad y feedback visual.\\n5. **Metodología BEM o similar**: Para mantener la escalabilidad y legibilidad del código.\\n6. **Preprocesadores (opcional)**: Como SASS o LESS para funciones avanzadas y mejor organización del código.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto CSS avanzado debería incluir:\\n\\n1. **CSS Grid y Flexbox**: Para layouts complejos y responsivos.\\n2. **Variables CSS**: Para mantener la consistencia en colores y fuentes.\\n3. **Media Queries**: Para adaptabilidad a distintos dispositivos.\\n4. **CSS Modules o CSS-in-JS**: Para modularidad y evitar colisiones de nombres.\\n5. **PostCSS o SASS/SCSS**: Para funcionalidades avanzadas como mixins y anidamiento.\\n6. **Animaciones y Transiciones**: Para interactividad y feedback visual.\\n7. **Metodologías como BEM o SMACSS**: Para mantenimiento y escalabilidad del código.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "NestJS",
            "descripcion": "Conocimiento en desarrollo de aplicaciones backend utilizando el framework NestJS.",
            "esFramework": true,
            "default": true,
            "tags": [
                "BackEnd",
                "Framework",
                "TypeScript",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de NestJS debería incluir:\\n\\n1. Controladores REST con CRUD básico.\\n2. Servicios para lógica de negocio.\\n3. Módulos para organizar el código por funcionalidad.\\n4. DTOs para validación de datos entrantes.\\n5. Middleware para tareas comunes como logging.\\n6. Excepciones filtradas para manejar errores.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto NestJS de complejidad media debería incluir: módulos organizados, controladores con lógica de enrutamiento, servicios para la lógica de negocio, DTOs para validación de datos, interceptores para manejo de respuestas, middleware para tareas comunes, y mapeo ORM con TypeORM o Prisma.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de NestJS debería incluir: autenticación JWT, autorización basada en roles, microservicios con comunicación inter-servicio (gRPC o Kafka), manejo de excepciones global, validación de datos, documentación con Swagger, testing unitario e integración, y caching con Redis.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Java",
            "descripcion": "Conocimiento en desarrollo de aplicaciones utilizando el lenguaje de programación Java.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "Lenguaje de programación",
                "Técnico",
                "OOP"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto Java básico podría incluir:\\n\\n1. **Interfaz de usuario sencilla**: CLI o GUI básica con JavaFX o Swing.\\n2. **Gestión de dependencias**: Uso de Maven o Gradle.\\n3. **Pruebas unitarias**: Uso de JUnit.\\n4. **Control de versiones**: Integración con Git.\\n5. **Logging**: Uso de SLF4J o Log4j.\\n6. **Java moderno**: Uso de Java 8+ con características como streams y lambdas.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Java de complejidad media debería incluir: uso de Java SE 17+, API Stream para procesamiento de datos, Lambdas, módulos para encapsulación, JUnit 5 para pruebas unitarias, integración con bases de datos mediante JPA/Hibernate, y uso de Spring Framework para inyección de dependencias, seguridad y RESTful APIs.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto Java avanzado podría incluir funcionalidades como microservicios con Spring Boot, seguridad con OAuth2/JWT, reactividad con Spring WebFlux, integración de Kafka para mensajería asíncrona, y contenedores Docker para despliegue.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Spring Boot",
            "descripcion": "Conocimiento en desarrollo de aplicaciones backend utilizando el framework Spring Boot.",
            "esFramework": true,
            "default": false,
            "tags": [
                "BackEnd",
                "Framework",
                "Java",
                "Técnico",
                "Microservicios"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de Spring Boot debería incluir:\\n\\n1. **Spring Web**: Para construir RESTful APIs con anotaciones como \`@RestController\` y \`@RequestMapping\`.\\n2. **Spring Data JPA**: Para la persistencia de datos y operaciones CRUD con bases de datos, usando \`@Entity\` y repositorios.\\n3. **H2 Database**: Base de datos en memoria para desarrollo y pruebas.\\n4. **Spring Security**: Para autenticación básica.\\n5. **Actuator**: Para monitoreo y gestión de la aplicación.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto de complejidad media en Spring Boot debería incluir:\\n\\n1. **Spring MVC** para la construcción de APIs REST.\\n2. **Spring Data JPA** o **Spring Data MongoDB** para la persistencia de datos.\\n3. **Spring Security** para la autenticación y autorización.\\n4. **Spring Cache** para mejorar el rendimiento.\\n5. **Spring Actuator** para la monitorización y gestión de la aplicación.\\n6. **Validación de datos** con Hibernate Validator.\\n7. **Pruebas unitarias y de integración** con JUnit y Mockito.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de Spring Boot podría incluir:\\n\\n1. Spring Security para autenticación y autorización.\\n2. Spring Data JPA con Hibernate para persistencia de datos y manejo de transacciones.\\n3. Spring Cloud para microservicios, con Eureka, Hystrix y Feign.\\n4. Spring WebFlux para programación reactiva.\\n5. Actuator para monitoreo y gestión.\\n6. Mensajería con Kafka o RabbitMQ.\\n7. Caching con Redis.\\n8. Validación de API con OpenAPI/Swagger.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Base de Datos NoSQL",
            "descripcion": "Conocimiento en diseño, implementación y consulta de bases de datos NoSQL.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "Bases de Datos",
                "Técnico",
                "Diseño",
                "Almacenamiento"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Para un proyecto básico de Base de Datos NoSQL, las funcionalidades clave incluirían:\\n\\n1. CRUD (Create, Read, Update, Delete) operations.\\n2. Esquema flexible o sin esquema.\\n3. Escalabilidad horizontal.\\n4. Consistencia eventual o configuración de consistencia.\\n5. Soporte para consultas simples.\\n6. Mecanismos de indexación eficientes.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Para un proyecto de complejidad media con una base de datos NoSQL, las funcionalidades clave podrían incluir:\\n\\n1. Esquemas flexibles para manejar datos semi-estructurados.\\n2. Escalabilidad horizontal para gestionar el crecimiento de datos.\\n3. Indexación eficiente para búsquedas rápidas.\\n4. Replicación y particionamiento para alta disponibilidad y rendimiento.\\n5. Consistencia eventual para garantizar la integridad de los datos a lo largo del tiempo.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Para un proyecto avanzado de Base de Datos NoSQL, las funcionalidades clave incluirían: escalabilidad horizontal, replicación y alta disponibilidad, consistencia eventual, soporte para grandes volúmenes de datos, consultas eficientes con índices secundarios, y flexibilidad en el esquema de datos. Además, debería ofrecer seguridad robusta y opciones de backup y recuperación.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Base de Datos SQL",
            "descripcion": "Conocimiento en diseño, implementación y consulta de bases de datos relacionales SQL.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "Bases de Datos",
                "Técnico",
                "Diseño",
                "Almacenamiento"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Para un proyecto de base de datos SQL de complejidad básica, las funcionalidades clave incluirían:\\n\\n1. Creación de tablas con claves primarias.\\n2. Relaciones entre tablas con claves foráneas.\\n3. Consultas básicas (SELECT, INSERT, UPDATE, DELETE).\\n4. Índices para optimizar consultas.\\n5. Backup y restauración de datos.\\n6. Seguridad a nivel de acceso a la base de datos.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Para un proyecto de complejidad media, una base de datos SQL moderna debería incluir: índices optimizados, integridad referencial, procedimientos almacenados, vistas, triggers, seguridad a nivel de fila, particionamiento de tablas, y soporte para transacciones ACID. Además, debería permitir consultas complejas y ofrecer herramientas para el monitoreo y ajuste del rendimiento.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Para un proyecto avanzado de base de datos SQL, las funcionalidades clave incluirían: particionamiento de datos, replicación y alta disponibilidad, procedimientos almacenados optimizados, vistas materializadas, índices avanzados (como índices de texto completo o espaciales), y seguridad a nivel de fila/columna.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Scrum",
            "descripcion": "Conocimiento en la metodología ágil Scrum para la gestión y desarrollo de proyectos.",
            "esFramework": false,
            "default": true,
            "tags": [
                "Metodología",
                "Ágil",
                "Gestión",
                "Proyecto",
                "Equipo"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Para un proyecto Scrum básico, las funcionalidades clave incluirían: Backlog de Producto para gestionar historias de usuario, Sprint Backlog para tareas del sprint actual, un tablero Kanban para seguimiento visual, y funcionalidades para la planificación de Sprints y revisiones.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Scrum de complejidad media debería incluir: backlog priorizado, sprints de 2-4 semanas, reuniones diarias de sincronización, revisiones de sprint para evaluar entregables, retrospectivas para mejora continua y roles claros (Product Owner, Scrum Master, Equipo de Desarrollo).",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto Scrum avanzado debería incluir: backlog priorizado, sprints definidos, integración continua, despliegue automatizado, pruebas automatizadas, monitorización en tiempo real, y retrospectivas ágiles. Además, debe soportar escalado Scrum (como SAFe o LeSS) para equipos múltiples.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Queue",
            "descripcion": "Conocimiento en sistemas de colas y mensajería para gestionar el procesamiento y comunicación asíncrona en aplicaciones.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "Mensajería",
                "Técnico",
                "Comunicación",
                "Integración"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto de Queue básico debería incluir:\\n\\n1. Creación y gestión de colas.\\n2. Enviar y recibir mensajes.\\n3. Asegurar la entrega al menos una vez.\\n4. Escalabilidad básica.\\n5. Monitoreo simple de la salud de la cola.\\n6. Soporte para mensajes de tamaño estándar.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un sistema de colas (Queue) de complejidad media debería incluir:\\n\\n1. Persistencia de mensajes para garantizar la durabilidad.\\n2. Escalabilidad horizontal para manejar cargas variables.\\n3. Confirmación de mensajes y reintentos automáticos.\\n4. Priorización de mensajes.\\n5. Monitoreo y alertas en tiempo real.\\n6. Soporte para múltiples consumidores y productores.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de Queue debería incluir: alta disponibilidad, escalabilidad horizontal, persistencia de mensajes, confirmación de entrega, manejo de prioridades, dead-letter queue para mensajes fallidos, monitoreo en tiempo real y soporte para múltiples protocolos de mensajería.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Socket",
            "descripcion": "Conocimiento en comunicación en tiempo real mediante sockets.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "Real-Time",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto de Socket básico debería incluir:\\n\\n1. Establecimiento de conexión TCP/UDP.\\n2. Envío y recepción de mensajes en tiempo real.\\n3. Manejo de eventos de conexión y desconexión.\\n4. Tratamiento de errores de red.\\n5. Soporte para concurrencia con manejo de múltiples clientes.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto de Socket de complejidad media debería incluir funcionalidades como manejo de conexiones concurrentes, comunicación full-duplex, serialización eficiente de datos, autenticación y cifrado de la comunicación, manejo de desconexiones y reconexiones, y una API clara para la integración con el cliente y el servidor.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de sockets debería incluir: manejo de conexiones full-duplex (WebSocket), soporte para múltiples subprotocolos, autenticación y cifrado TLS/SSL, manejo de desconexiones y reconexiones automáticas, heartbeats para mantener la conexión activa, y capacidad de manejar miles de conexiones concurrentes de manera eficiente.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Jenkins",
            "descripcion": "Conocimiento en integración y despliegue continuo con Jenkins.",
            "esFramework": false,
            "default": false,
            "tags": [
                "DevOps",
                "CI/CD",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto Jenkins básico debería incluir:\\n\\n1. Integración con sistemas de control de versiones (Git).\\n2. Webhooks para disparar builds automáticamente tras cada push.\\n3. Pipelines como código (Jenkinsfile) para definir el proceso de build y test.\\n4. Notificaciones de éxito/fallo de builds.\\n5. Entorno aislado para builds (por ejemplo, Docker).",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Jenkins de complejidad media debería incluir: integración continua (CI), entrega continua (CD), pipelines como código (Jenkinsfile), integración con sistemas de control de versiones (Git), automatización de pruebas, notificaciones (Slack/email), y gestión de artefactos (Artifactory o Nexus).",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de Jenkins debería incluir: Pipelines as Code (Jenkinsfile), integración con contenedores (Docker/Kubernetes), soporte para múltiples entornos (dev, test, prod), automatización de pruebas, análisis de código estático, gestión de artefactos (Artifactory/Nexus), y notificaciones (Slack/Email).",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Docker",
            "descripcion": "Conocimiento en contenerización y administración de contenedores con Docker.",
            "esFramework": false,
            "default": false,
            "tags": [
                "DevOps",
                "Contenedores",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Para un proyecto Docker de complejidad básica, las funcionalidades clave incluirían:\\n\\n1. \`Dockerfile\` para definir la imagen del contenedor con una base ligera como \`alpine\`.\\n2. Uso de \`docker-compose\` para orquestar servicios simples.\\n3. Volumen persistente para datos.\\n4. Redes para aislamiento de contenedores.\\n5. Integración con un registro de contenedores para versionado y despliegue.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Docker de complejidad media debería incluir:\\n\\n1. Dockerfiles optimizados para construir imágenes eficientes.\\n2. Docker Compose para orquestar servicios multi-contenedor.\\n3. Volumenes para persistencia de datos.\\n4. Redes personalizadas para comunicación entre contenedores.\\n5. Integración continua (CI) para automatizar construcción y despliegue de imágenes.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto Docker avanzado debería incluir orquestación con Docker Swarm o Kubernetes, CI/CD con Docker integrado, multi-stage builds para optimizar imágenes, uso de Docker Secrets para manejar datos sensibles, y health checks para auto-reparación y balanceo de carga.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "Kubernetes",
            "descripcion": "Conocimiento en orquestación y administración de contenedores con Kubernetes.",
            "esFramework": false,
            "default": false,
            "tags": [
                "DevOps",
                "Orquestación",
                "Contenedores",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de Kubernetes debería incluir:\\n\\n1. **Despliegue de Pods**: Configuración de contenedores en Pods.\\n2. **Servicios**: Exposición de Pods para comunicación.\\n3. **Ingress**: Gestión de acceso externo.\\n4. **ConfigMaps/Secrets**: Manejo de configuración y datos sensibles.\\n5. **Autoescalado**: HPA para ajustar recursos automáticamente.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto Kubernetes de complejidad media debería incluir: despliegue automatizado con Helm o Kustomize, autoescalado con HPA, monitoreo con Prometheus y Grafana, logging con EFK (Elasticsearch, Fluentd, Kibana), red segura con Network Policies y gestión de secretos.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de Kubernetes debería incluir autoescalado (HPA/VPA), gestión de configuraciones y secretos, CI/CD integrado, monitoreo y logging con Prometheus y Grafana, políticas de red y seguridad con NetworkPolicies y OPA/Gatekeeper, y operadores personalizados para automatización.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        },
        {
            "nombre": "GraphQL",
            "descripcion": "Conocimiento en desarrollo e implementación de APIs usando GraphQL.",
            "esFramework": false,
            "default": false,
            "tags": [
                "BackEnd",
                "API",
                "Técnico"
            ],
            "nivel": [
                {
                    "nombre": "Básico",
                    "descripcion": "Un proyecto básico de GraphQL debería incluir:\\n\\n1. Un esquema GraphQL bien definido con tipos, consultas (queries) y mutaciones básicas.\\n2. Resolvers para manejar la lógica de negocio de las consultas y mutaciones.\\n3. Un servidor GraphQL, como Apollo Server, para procesar las peticiones.\\n4. Integración con una base de datos simple (p.ej., SQLite).\\n5. Documentación interactiva con GraphiQL o Playground.",
                    "valor": false,
                    "puntaje": 0
                },
                {
                    "nombre": "Medio",
                    "descripcion": "Un proyecto GraphQL de complejidad media debería incluir:\\n\\n1. Esquemas bien definidos con tipos, consultas (queries), y mutaciones.\\n2. Resolvers eficientes para la lógica de negocio.\\n3. Filtrado avanzado, paginación y ordenamiento.\\n4. Directivas personalizadas para lógica reutilizable.\\n5. Integración con bases de datos y caché.\\n6. Autenticación y autorización.\\n7. Validación de inputs y manejo de errores.\\n8. Pruebas unitarias y de integración.\\n9. Documentación interactiva con GraphiQL o similar.",
                    "valor": false,
                    "puntaje": 1
                },
                {
                    "nombre": "Avanzado",
                    "descripcion": "Un proyecto avanzado de GraphQL debería incluir:\\n\\n1. Esquemas y tipos bien definidos con directivas personalizadas.\\n2. Resolvers eficientes con batching y caching (DataLoader).\\n3. Autenticación y autorización a nivel de campo.\\n4. Subscriptions para datos en tiempo real.\\n5. Manejo de errores y validación de esquemas.\\n6. Paginación cursor-based.\\n7. Persistencia y optimización de queries (persisted queries).\\n8. Monitoreo y logging con herramientas como Apollo Studio.",
                    "valor": false,
                    "puntaje": 2
                }
            ]
        }
    ]
}`;
