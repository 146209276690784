import { ISkills } from 'components/models/SkillsInterfaces';
export function patchSkillDimension(
  skills: ISkills[],
  skillName: string,
  dimensionName: string,
  dimensionType: string,
  dimensionValue: string
) {
  let skillI = skills.findIndex((skill) => skill.nombre === skillName);
  if (skillI !== -1) {
    let dimensionI = skills[skillI].dimensiones.findIndex(
      (dimension) => dimension.nombre === dimensionName && dimension.tipo === dimensionType
    );
    if (dimensionI !== -1) {
      skills[skillI].dimensiones[dimensionI].valor = dimensionValue;
      return { skillI, dimensionI };
    }
  }
}
