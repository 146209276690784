import { List } from '@mui/material';
import * as React from 'react';
import { WorkflowEvent } from '../types';
import { EventItem } from './EventItem';

export type EventListProps = {
  events: WorkflowEvent[];
};

export const EventList = ({ events }: EventListProps) => {
  return (
    <List>
      {events.map((event) => (
        <EventItem key={event._id} event={event} />
      ))}
    </List>
  );
};
