import styled from '@emotion/styled/macro';

export const ChartStyle = styled('div')`
  display: flex;
  flex: 1 0 20%;
  min-width: 600px;
  max-width: 100%;
  min-height: 600px;
  padding: 10px;
`;
