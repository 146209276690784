import React, { FC } from 'react';
import { SummaryInterface } from 'components/models/SummaryInterfaces';
import { StyledResumeContainer } from './style';
import { sanitize } from 'dompurify';

interface Props {
  softSkills: SummaryInterface[];
}

export const NewSummary: FC<Props> = ({ softSkills }) => (
  <StyledResumeContainer>
    <h3 className='resume-title'>Síntesis diagnóstica</h3>
    {softSkills?.map(({ valor }: SummaryInterface, index: number) => (
      <div
        key={index}
        className='resume-text inner-html'
        dangerouslySetInnerHTML={{
          __html: sanitize(valor as string),
        }}
      />
    ))}
  </StyledResumeContainer>
);
