import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
  getLastActiveStage,
  getWorkflowStatusText,
  isStageRejected,
} from './CandidatesTable.helpers';
import { WorkflowInterface } from '../../../models/WorkFlowInterfaces';

interface WorkflowStatusProps {
  stages: WorkflowInterface[];
}

function WorkflowStatusIcon({ stages }: WorkflowStatusProps): ReactElement {
  const lastActiveStage: WorkflowInterface = getLastActiveStage(stages);
  const isCandidateHired: boolean = lastActiveStage?.nombre === 'Contratado';
  const isCandidateRejected: boolean = isStageRejected(lastActiveStage);

  const iconStyles = { width: '0.8em' };

  if (isCandidateHired) {
    return <CheckCircleIcon sx={{ ...iconStyles, color: '#00AF5A' }} />;
  } else if (isCandidateRejected) {
    return <CancelIcon sx={{ ...iconStyles, color: '#FF0000' }} />;
  } else {
    return <HourglassEmptyIcon sx={{ ...iconStyles, color: '#E07C32' }} />;
  }
}

export function WorkflowStatus({ stages }: WorkflowStatusProps): ReactElement {
  const workflowStatusText: string = getWorkflowStatusText(stages);

  return (
    <Box
      title={workflowStatusText}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.3em',
        textTransform: 'capitalize',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <WorkflowStatusIcon stages={stages} />
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{workflowStatusText}</Box>
    </Box>
  );
}
