import styled from '@emotion/styled/macro';

export const StyledButton = styled.button`
  outline: 0 !important;
  border: 0 !important;
  margin: 10px 0;
  height: 30px;
  background-color: #2857a7;
  width: 90%;
  color: white;
  transition: 200ms ease;
  &:hover {
    background-color: #f15b29;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
