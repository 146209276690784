import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { Button, Checkbox, Divider, Grid, Switch, TextField } from '@mui/material';
import {
  SkillsInterface,
  innerSkillsInterface,
  IPreguntas,
  IRespuestas,
} from 'components/models/SkillsInterfaces';
import QuestionsComp from '../questions/QuestionsComp';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';

import { OptionsInterface } from '../OptionsInterface';
import Box from 'components/ui/LevelUp/box/box';
import Text from 'components/ui/LevelUp/text/text';

interface Props {
  skill: SkillsInterface;
  setTemplate: (e: CandidateInterface) => void;
  template: CandidateInterface;
  options: OptionsInterface;
}

const reducer = (state: CandidateInterface) => {
  return state;
};

const InnerSkill: FC<Props> = ({ skill, setTemplate, template, options }) => {
  const [skills, setSkills] = useState<SkillsInterface>(Object);
  const [add, setAdd] = useState<IPreguntas>(Object);
  const [displayInput, setDisplayInput] = useState<boolean>(false);
  const [state] = useReducer(reducer, template);
  const [changeSwitchSlider] = useState<boolean>(false);
  const [dato, setDato] = useState({
    nombreSubSkill: '',
    nombreCambiado: '',
  });

  useEffect(() => {
    setSkills(skill);
  }, [skill]);

  const addNota = useCallback(() => {
    let totalSoloCheck = false; //tipo respuestas "check" se evaluan manualmente, true es = a mantener evaluacion manual
    let permiteCalculo = true;
    let validaNuevoJson = false; //donde la nota es 1.1 (version JSON)

    let suma = 0;
    let addBool = 0;
    let totalBool = 0;

    if (Object.keys(skill).length !== 0) {
      validaNuevoJson = skill.nota === 1.1 ? true : false;

      let asignaNota = skill;
      asignaNota.skills = skill.skills.map((sk: innerSkillsInterface) => {
        let changeSwitchPadre = false;
        permiteCalculo = sk.preguntas?.some((preg: IPreguntas) => {
          return preg.nombre === add.nombre ? true : false;
        })
          ? true
          : false;
        if (!permiteCalculo) {
          return sk;
        }
        sk.preguntas?.map((preg: IPreguntas) => {
          preg.respuestas.map((res: IRespuestas) => {
            if (preg.tipo === 'radio') {
              changeSwitchPadre = true;
              if (res.valor) {
                suma = suma + (res.puntaje === undefined ? 0 : res.puntaje);
              }
            } else if (preg.tipo === 'checkbox') {
              changeSwitchPadre = true;
              if (res.valor) {
                suma = suma + (res.puntaje === undefined ? 0 : res.puntaje);
              }
            } else if (preg.tipo === 'check') {
              changeSwitchPadre = true;
              totalSoloCheck = true;
            }
            return res;
          });
          if (preg.tipo === 'checkbox' && !validaNuevoJson) {
            if (totalBool === addBool) {
              suma = suma + preg.puntajeMaximo;
            } else if (addBool >= totalBool / 2) {
              suma = suma + preg.puntajeMaximo / 2;
            }
          }
          addBool = 0;
          totalBool = 0;
          return preg;
        });
        if (!totalSoloCheck) {
          sk.nota = suma;
        }
        totalSoloCheck = false;
        suma = 0;
        if (changeSwitchPadre) {
          asignaNota.estado = true;
        }
        return sk;
      });
      return asignaNota;
    } else {
      return skill;
    }
  }, [skill, add.nombre]);

  const cambiaSwitchPadre = useCallback(
    (sk) => {
      const skill = state.skills?.map((s: SkillsInterface) => {
        if (sk.nombre === s.nombre) {
          return sk;
        }
        return s;
      });
      return { ...state, skills: skill };
    },
    [state]
  );

  useEffect(() => {
    const sk = addNota();
    const s = cambiaSwitchPadre(sk);
    setSkills({ ...sk });
    setTemplate(s);
  }, [addNota, add, cambiaSwitchPadre, setTemplate]);

  useEffect(() => {
    if (changeSwitchSlider) {
      const s = state.skills?.map((sk: SkillsInterface) => {
        if (skill.nombre === sk.nombre) {
          sk.estado = true;
        }
        return sk;
      });
      setTemplate({ ...state, skills: s });
    }
  }, [changeSwitchSlider, skill, state, setTemplate]);

  const handleInputChange = (e: string, nombre: string) => {
    setDato({ nombreSubSkill: nombre, nombreCambiado: e });
  };

  const cambioNombreSkill = (event: any) => {
    event.preventDefault();
    try {
      let sk = skill.skills.map((inner: innerSkillsInterface) => {
        if (inner.nombre === dato.nombreSubSkill) {
          inner.nombre = dato.nombreCambiado;
        }
        return inner;
      });
      setSkills({ ...skills, skills: sk });
      setDisplayInput(!displayInput);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo guardar la edicion del nombre en esta habilidad',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <>
      {skill &&
        skill.skills?.map((innerSkill: innerSkillsInterface, i: number) => {
          return (
            <Box variant={{ display: 'kam-card' }} keys={{ keys: `card${innerSkill.nombre}${i}` }}>
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Grid item xs={1}>
                    <Switch
                      checked={innerSkill.estado === false ? false : true}
                      onChange={(e) => {
                        if (innerSkill.estado === false ? false : true) innerSkill.estado = false;
                        else innerSkill.estado = true;
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    container
                    spacing={2}
                    sx={{ marginTop: '10px', fontSize: 15, display: 'flex' }}
                  >
                    <Box variant={{ display: 'mentor-row-container' }}>
                      {displayInput ? (
                        <form onSubmit={cambioNombreSkill}>
                          <TextField
                            inputProps={{
                              style: {
                                color: 'gray',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '24px',
                                textAlign: 'center',
                              },
                            }}
                            sx={{ marginRight: '12px', size: 'small' }}
                            id={`nom-skill${innerSkill.nombre}`}
                            defaultValue={innerSkill.nombre}
                            size='small'
                            variant='outlined'
                            type='text'
                            name='nombreSkill'
                            onChange={(e) => handleInputChange(e.target.value, innerSkill.nombre)}
                          />
                          <Button variant='contained' endIcon={<SendIcon />} type='submit'>
                            Guardar
                          </Button>
                        </form>
                      ) : (
                        <Text variant={{ display: 'kam-card-title' }}>{innerSkill.nombre}</Text>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box variant={{ display: 'mentor-row-container' }}>
                      <Checkbox
                        id={`${i}`}
                        checked={displayInput}
                        sx={{ color: 'gray', marginTop: '-10px' }}
                        onChange={() => setDisplayInput(!displayInput)}
                      />
                      <Text variant={{ display: 'kam-card-edit' }}>Editar</Text>
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{ marginBottom: 2 }} />
              </Box>
              <Box variant={{ display: 'mentor-question-container' }}>
                <QuestionsComp options={options} innerSkills={innerSkill} setAdd={setAdd} />
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default InnerSkill;
