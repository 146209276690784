import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import CreateTeam from '../createTeam/CreateTeam';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import FullscreenAlert from '../../ui/alerts/FullscreenAlert';
import { type ITeam } from '../createTeam/CreateTeam.types';
import getAxios from 'apiRestConnector';

interface ParamType {
  idMentee: string;
}

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    No se pudieron obtener los datos del equipo.
  </FullscreenAlert>
);

function UpdateTeam() {
  const { idMentee } = useParams<ParamType>();
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { data, isError, isLoading } = useQuery<ITeam>({
    queryKey: [`team/${idMentee}`, access_token, idMentee],
    queryFn: async () => {
      const response = await getAxios.instance(access_token).get(`/team/find/${idMentee}`);
      return response.data;
    },
  });

  if (isLoading) return <FullscreenLoading />;

  if (isError) return <ErrorAlert />;

  return <CreateTeam teamToUpdate={data} />;
}

export default UpdateTeam;
