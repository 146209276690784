export interface IRoadmap {
  resumen: string;
  objetivos: IRoadmapObjective[];
}

export enum RoadmapStatus {
  PENDING = 'Pendiente',
  IN_PROGRESS = 'En progreso',
  FINISHED = 'Finalizado',
}

export interface IRoadmapObjective {
  nombre: string;
  descripcion: string;
  tiempo_estimado: string;
  dedicacion_semanal?: string;
  estado: RoadmapStatus;
  actividades: IRoadmapActivity[];
}

export interface IRoadmapActivity {
  nombre: string;
  descripcion: string;
  url: string;
  plataforma: string;
  tipo: string;
  tiempo_estimado: string;
  dedicacion_semanal?: string;
  estado: RoadmapStatus;
}

export interface IRoadmapResponse {
  message: string;
  data: IRoadmap;
  elapsedTime: number;
}
