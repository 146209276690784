import emotionStyled from '@emotion/styled/macro';

export const NameImageWrapper = emotionStyled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 9px 10px;
  border-radius: 16px;
  cursor: pointer;
`;

export const StyledImage = emotionStyled.img`
  border-radius: 50%;
  width: 40px;
  user-select: none;
`;

export const StyledGlassDiv = emotionStyled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(55, 80, 108);
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
`;

export const StyledSVG = emotionStyled.span<{ size: number; scale: number }>`
  transform: scale(1);
  cursor: pointer;
  transition: 200ms ease;
  display: flex;
  justify-content: center;
  &:hover {
    transform: ${({ scale }) => `scale(${scale})`};
  }
  & > svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  }
`;
export const StyledImage2 = emotionStyled.img`
  border-radius: 50%;
  width: 100px;
  margin-bottom: 10px;
`;

export const StyledCard = emotionStyled.div`
  position: absolute;
  top: 66px;
  right: 0;
  width: 320px;
  z-index: -1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  color: #fff;
  background: radial-gradient(circle, rgb(28, 67, 110) 0%, rgb(29, 46, 67) 100%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: rgba(49, 207, 242, 0.32);
  border-radius: 8px;

  & > span:last-child {
    margin: 20px 0;
  }
  
  @media screen and (max-width: 635px) {
    position: fixed;
    top: 58px;
    width: 100vw;
    border: none;
    border-radius: 0;
    box-shadow: 0 50px 70px rgba(0, 0, 0, 1);
  }
`;

export const RolesContainer = emotionStyled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #0f171e;
  opacity: 1;
`;

export const StyledButton = emotionStyled.button`
  outline: 0 !important;
  border: 0 !important;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  background-color: #31cff2;
  width: 100%;
  color: black;
  transition: 200ms ease;
  &:hover {
    background-color: #f15b29;
    color: white;
  }
  font-weight: 600;
`;

export const StyledSwitch = emotionStyled.div`
  background-color: #0f171e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 1rem;
`;

export const StyledSection = emotionStyled.section`
  display: flex;
  align-items: center;
`;
