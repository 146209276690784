import { isPossiblePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

export function verifyPhone(phone: string) {
  let parsedPhone = phone.replaceAll('"', '');
  parsedPhone = parsedPhone.replace(/\D/g, '');
  if (parsedPhone.charAt(0) !== '+') {
    parsedPhone = `+${parsedPhone}`;
  }
  const isPhoneNumber = isPossiblePhoneNumber(parsedPhone);
  const validateResponse = validatePhoneNumberLength(parsedPhone);
  if (isPhoneNumber) {
    return validateResponse;
  } else {
    if (validateResponse === 'INVALID_COUNTRY') {
      return false;
    } else {
      return validateResponse;
    }
  }
}
