import { styled } from '@mui/material';

export const StyledDetailsTitleSubskillsContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: '0px',
  gap: '20px',
});

export const StyledDetailsRadarContainer = styled('div')<{ count: number }>(({ count }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '24px 0 38px 0',
  flex: 1,
  minWidth: '49%',
  maxWidth: count === 1 ? '100%' : '49%',
  background: 'rgba(55, 80, 108, 0.12)',
  borderWidth: '1px 0 0 1px',
  borderStyle: 'solid',
  borderColor: 'rgba(49, 207, 242, 0.32)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',

  '@media(max-width: 1065px)': {
    display: 'flex',
    minwidth: '488px',
    maxWidth: '488px',
  },
  '@media(max-width: 550px)': {
    display: 'flex',
    minwidth: '95%',
    maxWidth: '95%',
  },
}));

export const StyledDetailsSkillsTitlesSub = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  color: '#FFFFFF',
});
