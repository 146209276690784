import { Box, Button, Modal, ModalProps, Paper, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { LoadingButton } from '@mui/lab';
import type { ITeam } from '../../../CreateTeam.types';
import getAxios from '../../../../../../apiRestConnector';

const initialForm = {
  name: '',
  rol: [''],
  telefono: '',
  companyEmail: '',
  empresa: '',
};

type CreateTeamMemberModalProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isTL: boolean;
  setTeamInfo: React.Dispatch<React.SetStateAction<ITeam>>;
  refetchMembers: () => void;
};

function CreateTeamMemberModal({
  open,
  setOpen,
  isTL,
  setTeamInfo,
  refetchMembers,
  ...restModalProps
}: CreateTeamMemberModalProps) {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;

  const [newMember, setNewMember] = useState(initialForm);

  const mutation = useMutation({
    mutationFn: async () => {
      const newMemberBody = {
        ...newMember,
        isMentor: isTL,
        isTeamLeader: isTL,
      };

      const response = await getAxios
        .instance(access_token)
        .post('/employee?rol&nivel', newMemberBody);
      return response.data;
    },
    onSuccess: (data) => {
      if (isTL) {
        setTeamInfo((prevTeamInfo) => ({
          ...prevTeamInfo,
          lider_tecnico_ids: [
            ...prevTeamInfo.lider_tecnico_ids,
            { id: data._id, nombre: data.name },
          ],
        }));
      } else {
        setTeamInfo((prevTeamInfo) => ({
          ...prevTeamInfo,
          integrantes_ids: [...prevTeamInfo.integrantes_ids, { id: data._id, nombre: data.name }],
        }));
      }

      mutation.reset();
      setNewMember(initialForm);
      setOpen(false);
      refetchMembers();
    },
  });

  const handleSendData = () => {
    mutation.mutate();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      {...restModalProps}
      sx={{ top: '120px', width: '500px', ml: 'auto', mr: 'auto', ...restModalProps.sx }}
    >
      <Paper sx={{ padding: '30px' }}>
        {mutation.isError && 'Error al guardar integrante'}
        <Typography variant='h5' fontWeight='bold'>
          {`Nuevo ${isTL ? 'Líder Técnico' : 'Integrante'}`}
        </Typography>
        <Typography></Typography>
        <TextField
          label='Nombre'
          value={newMember.name}
          onChange={(e) => setNewMember((old) => ({ ...old, name: e.target.value }))}
          sx={{ width: '100%', mb: '10px', mt: '20px' }}
        />
        <TextField
          label='Teléfono'
          value={newMember.telefono}
          onChange={(e) => setNewMember((old) => ({ ...old, telefono: e.target.value }))}
          sx={{ width: '100%', mb: '10px', mt: '20px' }}
        />
        <TextField
          label='Email'
          value={newMember.companyEmail}
          onChange={(e) => setNewMember((old) => ({ ...old, companyEmail: e.target.value }))}
          sx={{ width: '100%', mb: '10px', mt: '20px' }}
        />
        <TextField
          label='Rol'
          value={newMember.rol[0]}
          onChange={(e) => setNewMember((old) => ({ ...old, rol: [e.target.value] }))}
          sx={{ width: '100%', mb: '10px', mt: '20px' }}
        />
        <TextField
          label='Empresa'
          value={newMember.empresa}
          onChange={(e) => setNewMember((old) => ({ ...old, empresa: e.target.value }))}
          sx={{ width: '100%', mb: '10px', mt: '20px' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '20px' }}>
          <Button
            variant='outlined'
            onClick={() => {
              setOpen(false);
            }}
            disabled={mutation.isPending}
            sx={{ mr: '20px' }}
          >
            Cancelar
          </Button>
          <LoadingButton variant='contained' loading={mutation.isPending} onClick={handleSendData}>
            Guardar
          </LoadingButton>
        </Box>
      </Paper>
    </Modal>
  );
}

export default CreateTeamMemberModal;
