import React from 'react';
import { Box, Step, StepButton, Stepper } from '@mui/material';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { WorkflowInterface, subEtapasInterface } from 'components/models/WorkFlowInterfaces';

interface Props {
  template: CandidateInterface;
  setTemplate: (t: CandidateInterface) => void;
  skeleton: CandidateInterface;
  item: WorkflowInterface;
  estadoActual: number;
  telefono?: string;
}

const SubStages: React.FC<Props> = ({ template, setTemplate, skeleton, item, estadoActual }) => {
  const subEtapasHandlerFirstTime = (name: string) => {
    let subEtapasArr = [{}];
    skeleton.workflow?.forEach((workf: WorkflowInterface) => {
      if (workf.nombre === item.nombre) {
        subEtapasArr = workf.subEtapas?.map((se: subEtapasInterface) => {
          if (se.nombre === name) {
            se.estado = !se.estado;
            se.fecha = new Date().toISOString();
            return se;
          }
          return se;
        }) || [''];
      }
    });

    let result = template;
    result.workflow[estadoActual].subEtapas = subEtapasArr as subEtapasInterface[];
    setTemplate({ ...result });
  };

  const subEtapasHandler = (name: string) => {
    let result = template;

    result.workflow?.forEach((workf: WorkflowInterface) => {
      if (workf.nombre === item.nombre) {
        workf.subEtapas?.forEach((se: subEtapasInterface) => {
          if (se.nombre === name) {
            se.estado = !se.estado;
            se.fecha = new Date().toISOString();
            return se;
          }
          return se;
        });
      }
    });
    setTemplate({ ...result });
  };

  return (
    <Box sx={{ p: '20px' }}>
      {item.subEtapas !== undefined && (
        <Stepper activeStep={-1} nonLinear alternativeLabel>
          {item.subEtapas?.map((se: subEtapasInterface, i: number) => {
            return (
              <Step key={se.nombre} completed={se.estado}>
                <StepButton onClick={() => subEtapasHandler(se.nombre)}>{se.nombre}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      )}
      {skeleton && item.subEtapas === undefined && (
        <Stepper activeStep={-1} nonLinear alternativeLabel>
          {skeleton &&
            skeleton.workflow?.map((workf: WorkflowInterface) => {
              if (workf.nombre === item.nombre) {
                return workf.subEtapas?.map((se: subEtapasInterface, i: number) => {
                  return (
                    <Step key={se.nombre} completed={se.estado}>
                      <StepButton onClick={() => subEtapasHandlerFirstTime(se.nombre)}>
                        {se.nombre}
                      </StepButton>
                    </Step>
                  );
                });
              }
              return null;
            })}
        </Stepper>
      )}
    </Box>
  );
};

export default SubStages;
