import React, { useState, useEffect, FC, useContext, useCallback } from 'react';
import getAxios from 'apiRestConnector';
import { SkillsComponent } from 'components/pages/detailsCandidate/skillsComponent/SkillsComponent';
import { RouteComponentProps, withRouter } from 'react-router';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import MeTincaButton from 'components/ui/buttons/meTincaButton/MeTincaButton';
import { StyledDiv } from '../listCandidate/style';
import { SkillsInterface } from 'components/models/SkillsInterfaces';
import ShowroomCard from 'components/ui/cards/showroomCard/ShowroomCard';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import NewSoftSkillsComp from './softSkillsComponent/NewSoftSkillComp';
import { NewSummary } from './summaryComponent/NewSummary';
import { NewGlossary } from './newGlossary/NewGlossary';
import {
  StyledDetailsContainer,
  StyledDetailsSkillsTitle,
  StyledDetailsTitleCardContainer,
  StyledDetailsTitleSkillsContainer,
} from './style';
import ROL from 'utils/Roles';

interface MatchParams {
  phone: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

const DetailsCandidate: FC<Props> = ({ match }) => {
  const { phone } = match.params;
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [role] = useState(JSON.parse(localStorage.getItem('user')!).role[0]);

  const [skillTemplate, setSkillTemplate] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [candidate, setCandidate] = useState(Object);
  const [infoLikes, setInfoLikes] = useState<boolean>(false);
  const [like, setLike] = useState<boolean>(false);
  const [likeList, setLikeList] = useState<string[]>(Array);
  const [likeLoading, setLikeLoading] = useState<boolean>(true);

  const deleteLike = () => {
    getAxios
      .instance(access_token)
      .delete(`/likes/email/${storeAuth.user.email}/telefono/${phone}`)
      .then((response: any) => response.data)
      .then(() => {
        setLike(false);
        setLikeLoading(false);
      });
  };

  const postLike = () => {
    let body = {
      email: storeAuth.user.email,
      nombreUsuario: storeAuth.user.name,
      telefono: phone,
      nombrePersona: candidate.nombre,
      isLike: true,
    };
    getAxios
      .instance(access_token)
      .post('/likes', body)
      .then((response: any) => response.data)
      .then(() => {
        setLike(true);
        setLikeLoading(false);
      });
  };

  const getLikesByCandidate = () => {
    getAxios
      .instance(access_token)
      .get(`/likes/users-by-candidate/${phone}`)
      .then((response: any) => response.data)
      .then((data: string[]) => {
        setLikeList(data);
      });
  };

  const showInfoLikes = () => {
    setInfoLikes(true);
    getLikesByCandidate();
  };

  const manageLike = () => {
    setLikeLoading(true);
    if (!like) {
      postLike();
    } else {
      deleteLike();
    }
  };

  const getLike = useCallback(() => {
    getAxios
      .instance(access_token)
      .get(`/likes/email/${storeAuth.user.email}/telefono/${phone}`)
      .then((response: any) => response.data)
      .then((data: any) => {
        setLike(data);
        setLikeLoading(false);
      });
  }, [access_token, storeAuth.user.email, phone]);

  useEffect(() => {
    getLike();
  }, [getLike]);

  useEffect(() => {
    const isAdmin = role === ROL.ADMINISTRADOR || role === ROL.RECLUTADOR;
    const urlBackend = isAdmin ? `/personas/${phone}` : `/personas/${phone}?isUser=true`;

    getAxios
      .instance(access_token)
      .get(urlBackend)
      .then((result: any) => result.data)
      .then((entry) => {
        setCandidate(entry);
        setLoading(false);
        document.title = `${entry.nombre ? `WiTI - ${entry.nombre}` : 'WiTI - LevelUp'}`;
      });
  }, [phone, access_token, role]);

  useEffect(() => {
    if (Object.keys(candidate).length > 0) {
      getAxios
        .instance(access_token)
        .get('/templates/skills')
        .then((result: any) => {
          const skillsArray = candidate.skills.map((element: SkillsInterface) => element.nombre);
          const filteredSkills = result.data.skills.filter((skill: SkillsInterface) => {
            if (skillsArray.includes(skill.nombre)) {
              return true;
            }
            return false;
          });
          setSkillTemplate(filteredSkills);
        });
    }
  }, [candidate, access_token, role, storeAuth]);

  if (loading) return <FullscreenLoading />;

  return (
    <StyledDetailsContainer>
      <div style={{ maxWidth: '1158px' }} className='' id='content'>
        <div>
          <StyledDetailsTitleCardContainer>
            <ShowroomCard
              key={candidate.telefono}
              name={candidate.nombre}
              phone={candidate.telefono}
              datos={candidate.datos}
              residence={
                candidate.datos.find((dato) => dato.nombre === 'Residencia')?.valor as string
              }
              skills={candidate.skills}
              photo={candidate.profileImgUrl}
              isABot={false}
              validPrivateData
            />
          </StyledDetailsTitleCardContainer>
        </div>
        <div>
          <NewSoftSkillsComp softSkills={candidate.competencias} />
        </div>

        <StyledDetailsTitleSkillsContainer>
          <StyledDetailsSkillsTitle>Habilidades</StyledDetailsSkillsTitle>

          <SkillsComponent skills={candidate.skills} />
        </StyledDetailsTitleSkillsContainer>
        <div>
          <NewSummary softSkills={candidate.footer} />
        </div>

        <div style={{ display: role === ROL.ADMINISTRADOR ? '' : 'none' }}>
          <NewGlossary skills={skillTemplate} />
        </div>

        <StyledDiv>
          <MeTincaButton
            {...{
              likeLoading,
              manageLike,
              setInfoLikes,
              like,
              likeList,
              infoLikes,
              showInfoLikes,
            }}
          />
        </StyledDiv>
      </div>
    </StyledDetailsContainer>
  );
};

export default withRouter(DetailsCandidate);
