import React, { ReactElement, useMemo, memo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import useLocalStorageState from '../../../../hooks/useLocalStorageState';
import { columns, detailPanel, rowActions } from './CandidatesTableColumns';
import { getWorkflowStatusClassName, mapDataToRows } from './CandidatesTable.helpers';
import { StyledTableBodyRows } from './CandidatesTable.styles';
import { CandidatesTableProps, CandidateTableRow } from './CandidatesTable.types';
import { WorkflowInterface } from '../../../models/WorkFlowInterfaces';
import { ROW_CLASSES } from './CandidatesTable.styles';

const CandidatesTable = memo(function CandidatesTable({
  data,
  filterCallback,
  onContactClick,
  loading,
}: CandidatesTableProps): ReactElement {
  const history = useHistory();

  const [sorting, setSorting] = useLocalStorageState<MRT_SortingState>({
    storageKey: 'levelup.candidatesTable.sorting',
    initialState: [{ id: 'fechaEtapaActual', desc: true }],
  });

  const rows: CandidateTableRow[] = useMemo(() => mapDataToRows(data), [data]);

  const table = useMaterialReactTable({
    columns,
    data: rows,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
    },
    state: {
      isLoading: loading,
      showProgressBars: loading,
      showLoadingOverlay: false,
      sorting,
    },
    onSortingChange: setSorting,
    positionGlobalFilter: 'left',
    muiSearchTextFieldProps: {
      placeholder: 'Buscar candidato',
      onKeyUp: filterCallback,
    },
    muiTableBodyRowProps: ({ row }) => {
      const stages: WorkflowInterface[] = row.getValue('etapa');
      const tagged: boolean = row.original.tagged ? row.original.tagged === 'true' : false;
      let className: string;
      if (tagged) {
        className = ROW_CLASSES.DANGER;
      } else {
        className = getWorkflowStatusClassName(stages);
      }
      className = getWorkflowStatusClassName(stages);
      return {
        className,
        onClick: (event: React.MouseEvent) => {
          const url = row.original.actions.edit.href;
          // Verifica si Cmd (macOs) o Ctrl (ctrlKey) está presionado
          if (event.metaKey || event.ctrlKey) {
            window.open(url, '_blank');
          } else {
            history.push(url);
          }
        },
      };
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 180 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => rowActions(row.original.actions, onContactClick),
    layoutMode: 'grid',
    renderDetailPanel: detailPanel,
    enableGlobalFilterRankedResults: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    enableBottomToolbar: false,
    localization: MRT_Localization_ES,
  });

  return (
    <StyledTableBodyRows>
      <MaterialReactTable table={table} />
    </StyledTableBodyRows>
  );
});

export default CandidatesTable;
