import React, { useState, FC, useContext, useEffect } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import ListCandidateFilter from './ReportsFilters';
import { firebaseAnalytics } from 'utils/firebase';
import Chart from 'components/pages/report/chartComponent/Chart';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import PageHeader from '../../ui/PageHeader';

const { REACT_APP_CHARTS_URL, REACT_APP_CHARTS_IDS, REACT_APP_CHARTS_IDS_LOOKUP_ASOCIACIONS } =
  process.env;

interface Props {}

const Report: FC<Props> = () => {
  useEffect(() => {
    firebaseAnalytics.logEvent(`ADMIN_LOGIN: ${storeAuth.user.name}`);
  });

  const { storeAuth } = useContext(AuthContext)!;
  const [filter, setFilter] = useState({});
  const [filterJoinPersonasSolicituds, setFilterJoinPersonasSolicituds] = useState({}); //se debe aplicar filtro nuevo para este elemento por una incompatibilidad al momento de realizar LookUp en MongoDB Charts, los elementos quedan a nombre dado y no en vista principal... esto se soluciona generando vistas para cada oportunidad de graficos.

  const [nameFilter, setNameFilter] = useState('');

  const chartsID = REACT_APP_CHARTS_IDS.toString().split(',');
  const chartsIdLookAsociacions = REACT_APP_CHARTS_IDS_LOOKUP_ASOCIACIONS.toString().split(',');

  return (
    <>
      <PageHeader>Reportes</PageHeader>
      <Box mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Filtros</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ListCandidateFilter
              setFilter={setFilter}
              nameFilter={nameFilter}
              setNameFilter={setNameFilter}
              setFilterJoinPersonasSolicituds={setFilterJoinPersonasSolicituds}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {chartsID.map((id, index) => {
          return (
            <Chart
              key={`chart-${index}`}
              width={'100%'}
              height={'auto'}
              baseUrl={REACT_APP_CHARTS_URL.toString()}
              filter={filter}
              chartId={id.toString()}
            />
          );
        })}
        {chartsIdLookAsociacions.map((id, index) => {
          return (
            <Chart
              key={`chart-lookup-${index}`}
              width={'100%'}
              height={'auto'}
              baseUrl={REACT_APP_CHARTS_URL.toString()}
              filter={filterJoinPersonasSolicituds}
              chartId={id.toString()}
            />
          );
        })}
      </Box>
    </>
  );
};

export default Report;
