import getAxios from 'apiRestConnector';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState, memo } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import {
  QuestionMarksWrapper,
  StyledArrowCardHeader,
  StyledCard,
  StyledCardHeader,
  StyledCardText,
  StyledCardTextToTaskCard,
  StyledContainer,
  StyledH1TextToTaskCard,
  StyledImageRow,
  StyledNumberCardHeader,
} from './style';
import { SVGArrowLeft } from 'components/ui/icons/SVGArrowLeft';
import { filterCardsByAgrupation } from '../../../../helpers/filterCardsByAgrupation';
import { CardAgrupation } from 'components/models/RequestInterfaces';
import { RequestInterface } from '../../../models/RequestInterfaces';
import logoCenco from 'assets/companies/cencosud.png';
import logoAlsea from 'assets/companies/Alsea.png';
import logoMeds from 'assets/companies/LOGO-MEDS-FINAL.png';
import logoFalabella from 'assets/companies/Falabella.svg.png';
import logoXintec from 'assets/companies/xintec.png';
import logoColmena from 'assets/companies/colmena.jpg';
import logoTalentti from 'assets/companies/talentti.png';
import logoLudens from 'assets/companies/ludens.jpg';
import witiLogo from 'assets/companies/witilogo.png';
import logoVidaCamara from 'assets/companies/vidacamara.jpg';
import Loading from 'components/ui/loading/Loading';
import AssociatedRecruiters from './AssociatedRecruiters';
import AssociateCandidates from './AssociateCandidates';
import Spinner from 'components/ui/spinner/Spinner';
import { Box, Paper } from '@mui/material';
import {
  EstadosCandidateInterface,
  RequestCandidateInterface,
} from 'components/models/requestCandidate';

interface Props {
  setRequestCandidate: (r: number) => void;
}

const TaskCard = memo(function TaskCard({ setRequestCandidate }: Props) {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [template, setTemplate] = useState<RequestInterface[]>([]);
  const [filterCard, setfilterCard] = useState(false);
  const [auxTemplate, setAuxTemplate] = useState<CardAgrupation[]>([]);
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);
  const [candidatosPorSolicitud, setCandidatosPorSolicitud] = useState<RequestCandidateInterface[]>(
    []
  );

  useEffect(() => {
    const urlBackend = `${REACT_APP_BACKEND_URL}/solicitudes`;
    getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => {
        sortSolicitudes(result.data);
        setLoadingCSV(true);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });
  }, [access_token, REACT_APP_BACKEND_URL]);

  useEffect(() => {
    const urlBackend = `${REACT_APP_BACKEND_URL}/solicitudes/personas`;
    getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => {
        setCandidatosPorSolicitud(result.data);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });
  }, [access_token, REACT_APP_BACKEND_URL]);

  const request = (requestId: string) => {
    window.open(`/request/${requestId}/editar`);
  };

  const sortSolicitudes = (data: any) => {
    const templateFiltered = data
      .filter((item: any) => item.estado === 'Abierto')
      .sort((c1: any, c2: any) => {
        let factorC1 =
          c1.prioridad === 'Reemplazo'
            ? 0.2
            : c1.prioridad === 'Nuevo colaborador en nuevo cliente'
              ? 0.5
              : c1.prioridad === 'Nuevo colaborador'
                ? 0.8
                : c1.prioridad === 'Alta'
                  ? 0.9
                  : c1.prioridad === 'Media'
                    ? 0.9
                    : c1.prioridad === 'Baja'
                      ? 0.9
                      : 0.9;
        let factorC2 =
          c2.prioridad === 'Reemplazo'
            ? 0.2
            : c2.prioridad === 'Nuevo colaborador en nuevo cliente'
              ? 0.5
              : c2.prioridad === 'Nuevo colaborador'
                ? 0.8
                : c2.prioridad === 'Alta'
                  ? 0.9
                  : c2.prioridad === 'Media'
                    ? 0.9
                    : c2.prioridad === 'Baja'
                      ? 0.9
                      : 0.9;

        if (
          DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
          DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
        ) {
          return ((new Date(c1.fechaEstimadaFin).getTime() - DateTime.now().valueOf()) /
            (1000 * 60 * 60 * 24)) *
            factorC1 <
            ((new Date(c2.fechaEstimadaFin).getTime() - DateTime.now().valueOf()) /
              (1000 * 60 * 60 * 24)) *
              factorC2
            ? -1
            : 1;
        } else if (
          DateTime.fromISO(c1.fechaEstimadaFin).valueOf() < DateTime.now().valueOf() &&
          DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
        ) {
          return 1;
        } else if (
          DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
          DateTime.fromISO(c2.fechaEstimadaFin).valueOf() < DateTime.now().valueOf()
        ) {
          return -1;
        } else {
          return DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >
            DateTime.fromISO(c2.fechaEstimadaFin).valueOf()
            ? -1
            : 1;
        }
      });
    setTemplate(templateFiltered);
    setAuxTemplate(filterCardsByAgrupation(templateFiltered));
  };

  const handleNumberHeaderCard = (num: number, value: string) => {
    const field = auxTemplate.filter((temp: any) => temp.element.id === num)[0].element[value];
    setAuxTemplate(auxTemplate.filter((temp) => temp.element[value] === field));
    const filteredTemplate = auxTemplate.filter((temp) => temp.element[value] === field);
    const elementObj = filteredTemplate.map((filtered: any) => filtered.element.id);
    setRequestCandidate(elementObj[0]);
    setfilterCard(true);
  };

  const resetFilterTemplate = () => {
    setfilterCard(false);
    setAuxTemplate(filterCardsByAgrupation(template));
  };

  const agrupationFilterCard = (element: any) => {
    let groupArray: any[] = [];

    template.forEach((item) => {
      let compareRol;
      let compareStack;
      if (item.nivel === element.nivel && item.responsable === element.responsable) {
        compareStack =
          element.stack &&
          JSON.stringify(Array.isArray(element.stack) ? element.stack.sort() : element.stack) ===
            JSON.stringify(Array.isArray(item.stack) ? item.stack.sort() : item.stack);
        compareRol =
          element.rol &&
          JSON.stringify(Array.isArray(element.rol) ? element.rol.sort() : element.rol) ===
            JSON.stringify(Array.isArray(item.rol) ? item.rol.sort() : item.rol);
      }
      (compareStack === compareRol ? (compareStack === true ? true : false) : false) &&
        groupArray.push({ element: item, number: 1, prioridad: item.prioridad });
    });
    setAuxTemplate(groupArray);
    setfilterCard(true);
  };

  const colorHeader = (prioridad: string, fechaEstimadaFin: string) => {
    let factor1 =
      prioridad === 'Reemplazo'
        ? 0.2
        : prioridad === 'Nuevo colaborador en nuevo cliente'
          ? 0.5
          : prioridad === 'Nuevo colaborador'
            ? 0.8
            : prioridad === 'Alta'
              ? 0.9
              : prioridad === 'Media'
                ? 0.9
                : prioridad === 'Baja'
                  ? 0.9
                  : 0.9;

    let diffDias =
      (new Date(fechaEstimadaFin).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);

    return DateTime.fromISO(fechaEstimadaFin).valueOf() < DateTime.now().valueOf()
      ? '#B8C6C6'
      : diffDias * factor1 > 0 && diffDias * factor1 <= 7
        ? '#cc4646'
        : diffDias * factor1 > 7 && diffDias * factor1 <= 14
          ? '#EBAA39'
          : diffDias * factor1 > 14
            ? '#5EAB39'
            : '#B8C6C6';
  };

  const estados = (id: number, candidatosPorSolicitud: RequestCandidateInterface[]) => {
    const resultado = candidatosPorSolicitud.flatMap((candidatos: RequestCandidateInterface) => {
      if (candidatos.id === id) {
        return candidatos.estados;
      }
      return null;
    });
    return resultado.filter((res: EstadosCandidateInterface) => res);
  };

  return (
    <Box sx={{ height: '100%', paddingBottom: '1rem' }}>
      <StyledContainer
        style={{ width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'row' }}
      >
        {auxTemplate && loadingCSV ? (
          auxTemplate.map((temp: any, i) => (
            <Box key={i}>
              <>
                <StyledCard
                  key={temp.element.id}
                  style={{
                    borderColor: colorHeader(temp.element.prioridad, temp.element.fechaEstimadaFin),
                  }}
                >
                  <StyledCardHeader
                    style={{
                      backgroundColor: colorHeader(
                        temp.element.prioridad,
                        temp.element.fechaEstimadaFin
                      ),
                    }}
                  >
                    {temp.number > 1 && (
                      <StyledNumberCardHeader onClick={() => agrupationFilterCard(temp.element)}>
                        {temp.number}
                      </StyledNumberCardHeader>
                    )}
                    <StyledH1TextToTaskCard
                      onClick={() => handleNumberHeaderCard(temp.element.id, 'rol')}
                    >
                      {Array.isArray(temp.element.rol)
                        ? temp.element.rol.join(', ')
                        : temp.element.rol}
                    </StyledH1TextToTaskCard>
                  </StyledCardHeader>
                  {filterCard && (
                    <StyledArrowCardHeader onClick={() => resetFilterTemplate()}>
                      <SVGArrowLeft />
                    </StyledArrowCardHeader>
                  )}
                  <Paper sx={{ height: '100%' }}>
                    <div style={{ height: '100%' }}>
                      <div style={{ height: '60%' }} onClick={() => request(temp.element.id)}>
                        <StyledCardText>
                          <StyledImageRow>
                            {temp.element.empresa === 'Otra' ? (
                              <QuestionMarksWrapper>?</QuestionMarksWrapper>
                            ) : (
                              <Box
                                sx={{ width: '100%', backgroundColor: 'white', padding: '1rem 0' }}
                              >
                                <img
                                  src={
                                    temp.element.empresa === 'Cencosud'
                                      ? logoCenco
                                      : temp.element.empresa === 'Alsea'
                                        ? logoAlsea
                                        : temp.element.empresa === 'Meds'
                                          ? logoMeds
                                          : temp.element.empresa === 'Ludens'
                                            ? logoLudens
                                            : temp.element.empresa === 'Colmena'
                                              ? logoColmena
                                              : temp.element.empresa === 'Falabella'
                                                ? logoFalabella
                                                : temp.element.empresa === 'Talentti'
                                                  ? logoTalentti
                                                  : temp.element.empresa === 'Vida Cámara'
                                                    ? logoVidaCamara
                                                    : temp.element.empresa === 'Witi'
                                                      ? witiLogo
                                                      : temp.element.empresa === 'Xintec'
                                                        ? logoXintec
                                                        : ''
                                  }
                                  alt={temp.element.empresa}
                                  style={{ height: '80px', objectFit: 'scale-down' }}
                                />
                              </Box>
                            )}
                          </StyledImageRow>
                        </StyledCardText>
                        <StyledCardText>
                          <StyledCardTextToTaskCard
                            onClick={() => handleNumberHeaderCard(temp.element.id, 'nivel')}
                          >
                            {temp.element.nivel}
                          </StyledCardTextToTaskCard>
                        </StyledCardText>
                        <StyledCardText>
                          <StyledCardTextToTaskCard
                            onClick={() => handleNumberHeaderCard(temp.element.id, 'prioridad')}
                            style={{
                              color: colorHeader(
                                temp.element.prioridad,
                                temp.element.fechaEstimadaFin
                              ),
                            }}
                          >
                            {temp.element.fechaEstimadaFin !== undefined
                              ? DateTime.fromISO(temp.element.fechaEstimadaFin)
                                  .endOf('day')
                                  .toRelative()
                              : ''}
                          </StyledCardTextToTaskCard>
                        </StyledCardText>
                        <StyledCardText>
                          <StyledCardTextToTaskCard style={{ height: '100px', overflow: 'auto' }}>
                            {Array.isArray(temp.element.stack)
                              ? temp.element.stack.join(', ')
                              : temp.element.stack}
                          </StyledCardTextToTaskCard>
                        </StyledCardText>
                      </div>
                      <div style={{ height: '40%' }}>
                        <StyledCardText>
                          <StyledCardTextToTaskCard>
                            {temp.element.responsable}
                          </StyledCardTextToTaskCard>
                        </StyledCardText>
                        {candidatosPorSolicitud && temp.element.id && (
                          <AssociateCandidates
                            candidatesArray={estados(temp.element.id, candidatosPorSolicitud)}
                          />
                        )}
                        <AssociatedRecruiters
                          recruiters={temp.element.reclutadores}
                          body={temp}
                        ></AssociatedRecruiters>
                      </div>
                    </div>
                  </Paper>
                </StyledCard>
              </>
            </Box>
          ))
        ) : (
          <Loading>
            <Spinner color='primary' />
          </Loading>
        )}
      </StyledContainer>
    </Box>
  );
});

export default TaskCard;
