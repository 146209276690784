import { useEffect } from 'react';
import { ISkills } from 'components/models/SkillsInterfaces';
import { patchSkillDimension } from 'components/pages/newCandidate/skillsComponent/helpers/patchSkillDimension';

type escentials = {
  interviewer_id: string;
  type: string;
  interviewed_id: String;
};
type newInterview = escentials & {
  skills: ISkills[];
};
type tabChange = escentials & {
  tab: string;
};
type skillIndexes = { skillI: number; dimensionI: number };

export function useSocketSubcribeEvents(socket, reactHookFormMethods, idMentee, typeOfInterview) {
  useEffect(() => {
    function onTabChange(value: tabChange) {
      if (
        idMentee === value.interviewed_id &&
        reactHookFormMethods.getValues('tabActive') !== value.tab &&
        value.type === typeOfInterview
      )
        reactHookFormMethods.setValue('tabActive', value.tab);
    }
    function makeItGlow(skillI: number, dimensionI: number) {
      reactHookFormMethods.setValue(`glow-${skillI}-${dimensionI}`, 'on');
      setTimeout(() => reactHookFormMethods.setValue(`glow-${skillI}-${dimensionI}`, ''), 1000);
    }
    function formChange(value: newInterview) {
      const skills = reactHookFormMethods.getValues('interviewDetails.skills');
      const indexes: skillIndexes[] = [];
      for (let skill of value.skills) {
        for (let dimension of skill.dimensiones) {
          const { skillI, dimensionI } = patchSkillDimension(
            skills,
            skill.nombre,
            dimension.nombre,
            dimension.tipo,
            dimension.valor
          );
          indexes.push({ skillI, dimensionI });
        }
      }
      reactHookFormMethods.setValue('interviewDetails.skills', skills);
      return indexes;
    }
    function onJoinRoom(value: newInterview) {
      if (idMentee === value.interviewed_id && value.type === typeOfInterview) formChange(value);
    }
    function onFormChange(value: newInterview) {
      if (idMentee === value.interviewed_id && value.type === typeOfInterview) {
        const indexes = formChange(value);
        indexes.forEach(({ skillI, dimensionI }) => makeItGlow(skillI, dimensionI));
      }
    }

    socket.on('tabChange', onTabChange);
    socket.on('changeDimension', onFormChange);
    socket.on('joinRoom', onJoinRoom);

    return () => {
      socket.off('tabChange', onTabChange);
      socket.off('changeDimension', onFormChange);
      socket.off('joinRoom', onJoinRoom);
    };
  }, [socket, reactHookFormMethods, idMentee, typeOfInterview]);
}
