import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Paper,
  Stack,
  Button,
  TextField,
  Autocomplete,
  Container,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiTelInput } from 'mui-tel-input';
import TextEditor from '../../ui/textEditor';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { DateTime } from 'luxon';
import { FilterCandidates } from './FilterCandidates';
import { RequestInterface } from 'components/models/RequestInterfaces';
import { verifyPhone } from 'helpers/verifyPhone';
import PageHeader from '../../ui/PageHeader';
import { countries } from 'data/countriesAndCurrencies';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface ParamType {
  requestId: string;
}

const DEFAULT_DESCRIPTION =
  '<p><strong>Misión del cargo</strong></p><ul><li><p></p></li></ul><p><strong>Principales funciones</strong></p><ul><li><p></p></li></ul><p><strong>Logros esperados</strong></p><ul><li><p></p></li></ul><p><strong>Stack o herramientas</strong></p><ul><li><p></p></li></ul><p><strong>Habilidades blandas</strong></p><ul><li><p></p></li></ul>';

const Request = () => {
  const { requestId } = useParams<ParamType>();
  const history = useHistory();
  const [filterRequestHasta, setFilterRequestHasta] = useState<Date>(new Date());
  const [filterRequestInicio, setFilterRequestInicio] = useState<Date>(new Date());
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [roles, setRoles] = useState<string[]>(Array);
  const [modalidades, setModalidades] = useState<string[]>(Array);
  const [niveles, setNiveles] = useState<string[]>(Array);
  const [stack, setStack] = useState<string[]>(Array);
  const [loading, setLoading] = useState(false);
  const [validPhone, setValidPhone] = useState<boolean>(true);
  const isExistingRequest = useRouteMatch('*/editar');
  const [externalAssociations, setExternalAssociations] = useState<string[]>([]);
  const [solicitud, setSolicitud] = useState({
    solicitud: [
      {
        nombre: '',
        valor: '',
      },
    ],
    stack: [
      {
        nombre: '',
        valor: '',
      },
    ],
    salario: [
      {
        nombre: '',
        valor: '',
      },
    ],
    externalAssociations: [
      {
        nombre: '',
        valor: '',
      },
    ],
  });

  const [template, setTemplate] = useState<RequestInterface>({
    descripcion: DEFAULT_DESCRIPTION,
    empresa: '',
    estado: 'Abierto',
    fechaEstimadaFin: new Date().toString(),
    fechaFin: '',
    fechaInicio: new Date().toString(),
    id: Date.now(),
    nivel: '',
    modalidad: '',
    plazo: '',
    prioridad: '',
    rol: [],
    salario: [{ min: '0', max: '0', moneda: '' }],
    responsable: '',
    equipo: '',
    solicitante: '',
    stack: [],
    managerEmail: '',
    managerPhone: '',
    responsibleEmail: '',
    responsiblePhone: '',
    externalAssociations: [],
  });

  const onBlurFono = (e: any) => {
    const response = verifyPhone(e.target.value);
    if (response === false) {
      SwalAlert({
        title: 'Error',
        text: 'Ha ingresado un número telefónico inválido',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
      setValidPhone(false);
    } else {
      if (response === 'TOO_SHORT') {
        SwalAlert({
          text: 'El número telefónico está incompleto',
          icon: 'warning',
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
        setValidPhone(false);
      } else if (response === 'TOO_LONG') {
        SwalAlert({
          text: 'El número telefónico supera los caracteres necesarios',
          icon: 'warning',
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
        setValidPhone(false);
      } else {
        setValidPhone(true);
      }
    }
  };

  function isUnexpectedValue(value: string) {
    return isNaN(parseInt(value));
  }

  const getRequestData = (event: React.ChangeEvent<{ value: string; name: string }>) => {
    const salt = structuredClone(template);
    const { value, name } = event.target;
    if (name === 'plazo') {
      let valor = parseInt(value.replace(/\D/g, ''));
      if (isNaN(valor)) {
        setTemplate((prevTemplate) => {
          return { ...prevTemplate, plazo: '' };
        });
      } else {
        getRequestDataDateEstimadaFin(
          DateTime.fromJSDate(filterRequestInicio).plus({ days: valor }).toJSDate()
        );
      }
      return '';
    }

    if (name === 'min') {
      let valor = value.replace(/\D/g, '');
      salt.salario.forEach((min) => {
        isUnexpectedValue(valor)
          ? (min.min = '0')
          : (min.min = parseInt(valor).toLocaleString('es-CL'));
      });
      setTemplate(salt);
      return '';
    }
    if (name === 'max') {
      let valor = value.replace(/\D/g, '');
      salt.salario.forEach((max) => {
        isUnexpectedValue(valor)
          ? (max.max = '0')
          : (max.max = parseInt(valor).toLocaleString('es-CL'));
      });
      setTemplate(salt);
      return '';
    }
    if (name === 'moneda') {
      salt.salario.map((moneda) => (moneda.moneda = value));
      setTemplate(salt);
      return '';
    }

    if (name === 'estado') {
      if (value === 'Cerrado OK' || value === 'Cerrado no OK') {
        setTemplate((prevTemplate) => {
          return { ...prevTemplate, fechaFin: new Date().toISOString(), estado: value };
        });
      } else if (value === 'Abierto') {
        setTemplate((prevTemplate) => {
          return { ...prevTemplate, fechaFin: '', estado: value };
        });
      }
      return '';
    }
    setTemplate((prevTemplate) => {
      return {
        ...prevTemplate,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getAxios
      .instance(access_token)
      .get('/templates/skills/')
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        const roles = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'rol'
        );
        setRoles(roles?.valor ?? []);

        const modalidades = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'modalidad'
        );
        setModalidades(modalidades?.valor ?? []);

        const niveles = templatesData.datos.find(
          (template: any) => template.nombre.toLowerCase() === 'nivel'
        );
        setNiveles(niveles?.valor ?? []);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });

    getAxios
      .instance(access_token)
      .get('/templates/solicitud')
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        setSolicitud(templatesData);

        templatesData.solicitud.forEach((res: any) => {
          if (res.nombre === 'stack') {
            setStack(res.valor);
          } else if (res.nombre === 'externalAssociations') {
            setExternalAssociations(res.valor);
          }
        });
      })
      .catch((error) => {
        console.error('error:', error.message);
      });

    if (requestId !== undefined) {
      getAxios
        .instance(access_token)
        .get(`/solicitudes/${requestId}`)
        .then((result: any) => result.data)
        .then((templatesData: RequestInterface) => {
          setFilterRequestHasta(new Date(templatesData.fechaEstimadaFin));
          setFilterRequestInicio(new Date(templatesData.fechaInicio));
          setTemplate(templatesData);
        })
        .catch((error) => {
          console.error('error:', error.message);
        });
    }
  }, [access_token, requestId]);

  const updateRequest = async (data: RequestInterface) => {
    setLoading(true);
    await getAxios.instance(access_token).put(`/solicitudes/${requestId}`, data);
    setLoading(false);
  };

  const createRequest = async (data: RequestInterface) => {
    setLoading(true);
    await getAxios.instance(access_token).post('/solicitudes', data);
    setLoading(false);
  };

  const setRequestEndPoint = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (template.empresa === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una empresa.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.rol.length < 0) {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un rol.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.nivel === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un nivel.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.modalidad === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una modalidad.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.prioridad === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una prioridad.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.plazo === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un plazo.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.responsable === '') {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un responsable',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.solicitante.length === 0) {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un solicitante.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (
      template.managerEmail.length > 0 &&
      (!template.managerEmail.includes('@') || !template.managerEmail.includes('.'))
    ) {
      await SwalAlert({
        title: 'Error',
        text: 'Email debe tener un formato válido.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else if (template.stack.length === 0) {
      await SwalAlert({
        title: 'Error',
        text: 'Debe ingresar por lo menos un stack.',
        icon: 'error',
        confirmButtonText: 'OK',
        showConfirmButton: true,
      });
    } else {
      try {
        const data: RequestInterface = {
          ...structuredClone(template),
          fechaInicio: new Date(template.fechaInicio).toISOString(),
          fechaEstimadaFin: new Date(template.fechaEstimadaFin).toISOString(),
        };

        if (requestId !== undefined) {
          await updateRequest(data);
        } else {
          await createRequest(data);
        }

        await SwalAlert({
          title: 'Guardado',
          text: 'Solicitud guardada con éxito',
          icon: 'success',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });

        history.push('/list/requests');
      } catch (errorMessage) {
        console.error(errorMessage);

        await SwalAlert({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });
      }
    }
  };

  const deleteRequest = async () => {
    setLoading(true);
    await getAxios
      .instance(access_token)
      .delete(`/solicitudes/${requestId}`)
      .then((result: any) => result.data)
      .catch((error) => {
        console.error('error:', error.message);
      });
    setLoading(false);
  };

  const deleteRequestEndPoint = async () => {
    SwalAlert({
      icon: 'warning',
      text: '¿Realmente desea eliminar la solicitud?',
      showCancelButton: true,
      confirmButtonText: 'Eliminar solicitud',
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRequest();
        history.push('/list/requests');
      }
    });
  };

  const getRequestDataDateEstimadaFin = (date: Date | null) => {
    const dateInput = new Date(date);
    if (dateInput instanceof Date) {
      setFilterRequestHasta(dateInput);
      if (filterRequestInicio <= dateInput) {
        setTemplate((prevTemplate) => {
          return {
            ...prevTemplate,
            plazo: Math.round(
              Math.abs(dateInput.getTime() - filterRequestInicio.getTime()) / (1000 * 60 * 60 * 24)
            ).toString(),
            fechaEstimadaFin: dateInput.toISOString(), // Guardar la fecha en formato ISO 8601
          };
        });
      } else {
        setTemplate((prevTemplate) => {
          return { ...prevTemplate, plazo: '' };
        });
      }
    }
  };
  const getRequestDataDateInicio = (date: Date | null) => {
    if (date) {
      const dateInput = new Date(date);
      setFilterRequestInicio(dateInput);
      if (filterRequestHasta >= dateInput) {
        setTemplate((prevTemplate) => {
          return {
            ...prevTemplate,
            plazo: Math.round(
              Math.abs(filterRequestHasta.getTime() - dateInput.getTime()) / (1000 * 60 * 60 * 24)
            ).toString(),
            fechaInicio: dateInput.toISOString(), // Guardar la fecha en formato ISO 8601
          };
        });
      } else {
        setTemplate((prevTemplate) => {
          return { ...prevTemplate, plazo: '' };
        });
      }
    }
  };
  useEffect(() => {
    if (template.plazo && filterRequestInicio) {
      const plazo = parseInt(template.plazo);
      if (!isNaN(plazo)) {
        const nuevaFechaFin = new Date(filterRequestInicio);
        nuevaFechaFin.setDate(nuevaFechaFin.getDate() + plazo);
        setFilterRequestHasta(nuevaFechaFin);
      }
    }
  }, [template.plazo, filterRequestInicio]);

  function renderTags(value: readonly string[], getTagProps) {
    return value.map((option: string, index: number) => (
      <Chip variant='outlined' label={option} {...getTagProps({ index })} />
    ));
  }

  return (
    <Container maxWidth='md' disableGutters>
      <PageHeader>{isExistingRequest ? 'Solicitud' : 'Nueva Solicitud'}</PageHeader>
      <Paper sx={{ p: 3 }}>
        <Stack component='form' noValidate autoComplete='off' gap={3}>
          <FormControl>
            <InputLabel id='empresa'>Empresa</InputLabel>
            <Select
              labelId='empresa'
              label='Empresa'
              id='empresa'
              name='empresa'
              onChange={(e) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, [e.target.name]: e.target.value as string };
                });
              }}
              value={template.empresa}
            >
              {solicitud.solicitud.length !== 0 &&
                solicitud.solicitud.map((request: any) => {
                  if (request.nombre === 'empresa') {
                    return request.valor.map((emp: any) => {
                      return (
                        <MenuItem key={emp} value={emp}>
                          {emp}
                        </MenuItem>
                      );
                    });
                  }
                  return null;
                })}
              <MenuItem value='Otra'>Otra</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <Autocomplete
              multiple={Array.isArray(template.rol)}
              id='rol'
              size='medium'
              options={roles}
              value={Array.isArray(template.rol) ? template.rol : [template.rol]}
              onChange={(event, newValue) => {
                setTemplate((prevTemplate) => ({
                  ...prevTemplate,
                  rol: newValue,
                }));
              }}
              freeSolo
              renderTags={renderTags}
              renderInput={(params) => (
                <TextField {...params} label='Rol' placeholder='Ingrese un rol' />
              )}
            />
          </FormControl>

          <FormControl>
            <InputLabel id='nivel'>Nivel</InputLabel>
            <Select
              labelId='nivel'
              label='Nivel'
              name='nivel'
              onChange={(e) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, [e.target.name]: e.target.value as string };
                });
              }}
              value={template.nivel}
            >
              {niveles.length !== 0 &&
                niveles.map((nivel: string) => {
                  return (
                    <MenuItem key={nivel} value={nivel}>
                      {nivel}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='modalidad'>Modalidad</InputLabel>
            <Select
              labelId='modalidad'
              label='Modalidad'
              name='modalidad'
              onChange={(e) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, [e.target.name]: e.target.value as string };
                });
              }}
              value={template.modalidad}
            >
              {modalidades.length !== 0 &&
                modalidades.map((modalidad: string) => {
                  return (
                    <MenuItem key={modalidad} value={modalidad}>
                      {modalidad}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='prioridad'>Prioridad</InputLabel>
            <Select
              labelId='prioridad'
              label='Prioridad'
              name='prioridad'
              onChange={(e) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, [e.target.name]: e.target.value as string };
                });
              }}
              value={template.prioridad}
            >
              <MenuItem value='Reemplazo'>Reemplazo</MenuItem>
              <MenuItem value='Nuevo colaborador en nuevo cliente'>
                Nuevo colaborador en nuevo cliente
              </MenuItem>
              <MenuItem value='Nuevo colaborador'>Nuevo colaborador</MenuItem>
            </Select>
          </FormControl>

          <Stack direction='row' gap={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor='min'>Min</InputLabel>
              <OutlinedInput
                id='min'
                name='min'
                value={
                  template.salario &&
                  template.salario.map((min) => {
                    return parseInt(min.min) > 0 ? min.min : '';
                  })
                }
                placeholder={'0'}
                onChange={getRequestData}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                label='Min'
                inputProps={{ maxLength: 11 }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='max'>Max</InputLabel>
              <OutlinedInput
                id='max'
                name='max'
                value={
                  template.salario &&
                  template.salario.map((max) => {
                    return parseInt(max.max) > 0 ? max.max : '';
                  })
                }
                placeholder={'0'}
                onChange={getRequestData}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                label='Max'
                inputProps={{ maxLength: 11 }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                id='typeCoin'
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.typeCoin}
                disableClearable
                value={
                  (template.salario &&
                    countries &&
                    countries.find(
                      (country) =>
                        country.typeCoin === template.salario.map((moneda) => moneda.moneda)[0]
                    )) ||
                  null
                }
                onChange={(e, value) => {
                  setTemplate((prevTemplate) => ({
                    ...prevTemplate,
                    salario: prevTemplate.salario.map((moneda) => ({
                      ...moneda,
                      moneda: value.typeCoin,
                    })),
                  }));
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading='lazy'
                      width='20'
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt='Bandera'
                    />
                    {option.typeCoin} - {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label='Moneda' id='moneda' name='moneda' />
                )}
              />
            </FormControl>
          </Stack>

          <Stack direction='row' gap={2}>
            <FormControl fullWidth>
              <TextField
                label='Plazo (en días)'
                placeholder='Plazo (en días)'
                id='plazo'
                name='plazo'
                onChange={getRequestData}
                value={template.plazo}
                inputProps={{ maxLength: 3 }}
              />
            </FormControl>
            <DatePicker
              label='Fecha desde'
              value={DateTime.fromJSDate(filterRequestInicio)}
              onChange={(date: DateTime) => getRequestDataDateInicio(date.toJSDate())}
              sx={{ width: '100%' }}
            />
            <DatePicker
              label='Fecha estimada fin'
              value={DateTime.fromJSDate(filterRequestHasta)}
              onChange={(date: DateTime) => getRequestDataDateEstimadaFin(date.toJSDate())}
              sx={{ width: '100%' }}
            />
          </Stack>

          <FormControl>
            <InputLabel id='estado'>Estado</InputLabel>
            <Select
              labelId='estado'
              label='Estado'
              onChange={(e) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, [e.target.name]: e.target.value as string };
                });
              }}
              name='estado'
              value={template.estado}
            >
              <MenuItem value='Abierto'>Abierto</MenuItem>
              <MenuItem value='Cerrado OK'>Cerrado OK</MenuItem>
              <MenuItem value='Cerrado no OK'>Cerrado no OK</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <TextEditor
              value={template.descripcion}
              onChange={(newValue) => {
                setTemplate((prevTemplate) => {
                  return { ...prevTemplate, descripcion: newValue };
                });
              }}
              placeholder='Descripción'
            />
          </FormControl>

          <FormControl>
            <TextField
              label='Equipo'
              placeholder='Equipo'
              id='equipo'
              name='equipo'
              onChange={getRequestData}
              value={template.equipo}
            />
          </FormControl>

          <FormControl>
            <Autocomplete
              multiple={Array.isArray(template.stack)}
              id='stack'
              size='medium'
              options={stack}
              value={typeof template.stack === 'string' ? [template.stack] : template.stack}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setTemplate((prevTemplate) => {
                    return {
                      ...prevTemplate,
                      stack: Array.isArray(newValue) ? newValue : [newValue],
                    };
                  });
                } else {
                  setTemplate((prevTemplate) => {
                    return {
                      ...prevTemplate,
                      stack: newValue,
                    };
                  });
                }
              }}
              freeSolo
              renderTags={renderTags}
              renderInput={(params) => (
                <TextField {...params} label='Stack' placeholder='Ingrese stack' />
              )}
            />
          </FormControl>
          <Typography variant='h6'>Asociación externa</Typography>
          <Box>
            {template?.externalAssociations &&
              template.externalAssociations.map(({ nombre, valor }, index) => (
                <Stack key={index} direction='row' gap={2} mb={2}>
                  <FormControl sx={{ width: '25%' }}>
                    <InputLabel id={`estado-${index}`}>Asociación externa</InputLabel>
                    <Select
                      labelId={`ExternalAssociation-${index}`}
                      label='Asociación externa'
                      name={`externalAssociations-${index}`}
                      onChange={(e) => {
                        setTemplate((prevTemplate) => {
                          const externalAssociations = [...prevTemplate.externalAssociations];
                          externalAssociations[index] = {
                            ...externalAssociations[index],
                            nombre: e.target.value as string,
                          };
                          return {
                            ...prevTemplate,
                            externalAssociations,
                          };
                        });
                      }}
                      value={template.externalAssociations?.[index].nombre || ''}
                    >
                      {externalAssociations.length !== 0 &&
                        externalAssociations.map((nombre) => (
                          <MenuItem key={nombre} value={nombre}>
                            {nombre}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: '75%' }}>
                    <TextField
                      label='Valor'
                      placeholder='Nombre de la asociación externa'
                      id={`ExternalAssociationValue-${index}`}
                      name={`ExternalAssociationValue-${index}`}
                      onChange={(e) => {
                        setTemplate((prevTemplate) => {
                          const externalAssociations = [...prevTemplate.externalAssociations];
                          externalAssociations[index] = {
                            ...externalAssociations[index],
                            valor: e.target.value as string,
                          };
                          return {
                            ...prevTemplate,
                            externalAssociations,
                          };
                        });
                      }}
                      value={template.externalAssociations?.[index].valor || ''}
                    />
                  </FormControl>
                </Stack>
              ))}
            <Button
              variant='contained'
              onClick={() =>
                setTemplate((prevTemplate) => {
                  const externalAssociations = [...(prevTemplate.externalAssociations ?? [])];
                  externalAssociations.push({
                    nombre: '',
                    valor: '',
                  });
                  return {
                    ...prevTemplate,
                    externalAssociations,
                  };
                })
              }
            >
              Agregar Asociación Externa
            </Button>
          </Box>
          <Typography variant='h6'>Responsable (Líder Técnico)</Typography>

          <Stack direction='row' gap={2}>
            <FormControl fullWidth>
              <TextField
                label='Nombre responsable'
                placeholder='Nombre responsable'
                id='responsable'
                name='responsable'
                onChange={getRequestData}
                value={template.responsable}
              />
            </FormControl>

            <MuiTelInput
              fullWidth
              error={!validPhone}
              label='Teléfono responsable'
              variant='outlined'
              value={template.responsiblePhone || '+56999999999'}
              onChange={(e: any) => {
                setTemplate((prevTemplate) => {
                  if (e === '+') {
                    return { ...prevTemplate, responsiblePhone: '' };
                  }
                  return { ...prevTemplate, responsiblePhone: e };
                });
              }}
              onBlur={(e: any) => {
                onBlurFono(e);
              }}
            />
          </Stack>

          <FormControl>
            <TextField
              label='Email responsable'
              placeholder='Email responsable'
              id='responsibleEmail'
              name='responsibleEmail'
              onChange={getRequestData}
              value={template.responsibleEmail}
            />
          </FormControl>

          <Typography variant='h6'>Manager</Typography>

          <Stack direction='row' gap={2}>
            <FormControl fullWidth>
              <TextField
                label='Nombre manager'
                placeholder='Nombre manager'
                id='solicitante'
                name='solicitante'
                onChange={getRequestData}
                value={template.solicitante}
              />
            </FormControl>

            <MuiTelInput
              fullWidth
              error={!validPhone}
              label='Teléfono manager'
              variant='outlined'
              value={template.managerPhone || '+56999999999'}
              onChange={(e: any) => {
                setTemplate((prevTemplate) => {
                  if (e === '+') {
                    return { ...prevTemplate, managerPhone: '' };
                  }
                  return { ...prevTemplate, managerPhone: e };
                });
              }}
              onBlur={(e: any) => {
                onBlurFono(e);
              }}
            />
          </Stack>

          <FormControl>
            <TextField
              label='Email manager'
              placeholder='Email manager'
              id='managerEmail'
              name='managerEmail'
              onChange={getRequestData}
              value={template.managerEmail}
            />
          </FormControl>

          {template.empresa !== '' && <FilterCandidates id={template.id} />}

          <Stack direction='row' gap={2} pt={3}>
            <LoadingButton
              variant='contained'
              size='large'
              color='success'
              startIcon={<SaveIcon />}
              onClick={setRequestEndPoint}
              loading={loading}
              loadingPosition='start'
              fullWidth
              sx={{ maxWidth: 300 }}
            >
              Guardar
            </LoadingButton>
            {isExistingRequest && (
              <Button
                variant='outlined'
                size='large'
                color='error'
                startIcon={<DeleteIcon />}
                onClick={deleteRequestEndPoint}
                disabled={loading}
              >
                Eliminar
              </Button>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Request;
