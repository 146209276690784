import React, { FC, Fragment } from 'react';
import {
  StyledContainer,
  StyledLinkHeader,
  StyledCard,
  StyledCardText,
  StyledCardHeader,
} from './style';
import Card from 'react-bootstrap/Card';

interface Props {
  contents: Array<Contents>;
}
interface Contents {
  rol: any;
  empresa: any;
  nivel: any;
  estado: any;
  fechaFin: any;
  fechaInicio: any;
  stack: any;
  plazo: any;
}

const TableCardRowRequest: FC<Props> = ({ contents }) => {
  const rowsColor = (content: Contents) => {
    if (content.estado.data === 'Cerrado no OK') {
      return '#FBBCAF';
    } else if (content.estado.data === 'Cerrado OK') {
      return '#d8ffb7';
    }
  };

  return (
    <StyledContainer>
      {contents.length > 0 &&
        contents.map((content, i) => (
          <Fragment key={`tableRow${i}`}>
            <StyledCard>
              <StyledCardHeader>
                <StyledLinkHeader to={`${content.empresa.href}`}>
                  {content.empresa.data}
                </StyledLinkHeader>
              </StyledCardHeader>

              <Card.Body style={{ background: rowsColor(content) }}>
                <StyledCardText>{content.rol.data}</StyledCardText>
                <StyledCardText>
                  <span>Plazo</span>
                  {content.plazo.data}
                </StyledCardText>
              </Card.Body>
            </StyledCard>
          </Fragment>
        ))}
    </StyledContainer>
  );
};

export default TableCardRowRequest;
