import React, { useContext, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Divider, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { steps } from '../CreateTeam';
import { type ITeam } from '../CreateTeam.types';
import getAxios from 'apiRestConnector';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface CreateTeamFormProps {
  step: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  teamInfo: ITeam;
  setTeamInfo: Dispatch<SetStateAction<ITeam>>;
}

function CreateTeamForm({ step, setCurrentStep, teamInfo, setTeamInfo }: CreateTeamFormProps) {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const history = useHistory();
  const [showSaveStateError, setShowSaveStateError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);

  const showAlert = () => {
    SwalAlert({
      title: 'Equipo guardado',
      text: `El equipo se ha guardado exitosamente.`,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'OK',
    }).then((result) => {
      setIsPending(false);
      history.push('/teams');
    });
  };
  const sendData = async (newTeam: ITeam) => {
    setIsPending(true);
    if (teamInfo._id) {
      const url = `/team/update/${teamInfo._id}`;
      const response = await getAxios.instance(access_token).patch(url, newTeam);
      if (response.data) showAlert();
      else setShowSaveStateError(true);
      return response.data;
    } else {
      const url = '/team';
      const response = await getAxios.instance(access_token).post(url, newTeam);
      if (response.data) showAlert();
      else setShowSaveStateError(true);
      return response.data;
    }
  };

  const handleNextStep = () => {
    setCurrentStep((currentStep) => currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const handleSendData = () => {
    sendData(teamInfo);
  };

  return (
    <Paper sx={{ mt: '30px', padding: '40px' }}>
      {showSaveStateError && 'Error en la llamada'}
      {React.createElement(steps[step - 1].component, { teamInfo, setTeamInfo })}
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '20px' }}>
        {step > 1 && (
          <Button
            onClick={handlePrevStep}
            disabled={isPending}
            sx={{ mr: '20px' }}
            startIcon={<ArrowBackIcon />}
          >
            Volver
          </Button>
        )}
        {step < steps.length ? (
          <Button variant='contained' onClick={handleNextStep} endIcon={<ArrowForwardIcon />}>
            Siguiente
          </Button>
        ) : (
          <LoadingButton
            variant='contained'
            loading={isPending}
            onClick={handleSendData}
            endIcon={<SaveIcon />}
          >
            Guardar
          </LoadingButton>
        )}
      </Box>
    </Paper>
  );
}

export default CreateTeamForm;
