import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container } from '@mui/material';
import { createTeamTemplate } from './create-team-template';
import { type ITeam, TeamFormStepProps } from './CreateTeam.types';
import CreateTeamForm from './createTeamForm/CreateTeamForm';
import CreateTeamStepper from './createTeamStepper/CreateTeamStepper';
import TeamFormStepName from './createTeamForm/teamFormStepName/TeamFormStepName';
import TeamFormStepClient from './createTeamForm/teamFormStepClient/TeamFormStepClient';
import TeamFormStepMembers from './createTeamForm/teamFormStepMembers/TeamFormStepMembers';
import TeamFormStepStack from './createTeamForm/teamFormStepStack/TeamFormStepStack';
import TeamFormStepSummary from './createTeamForm/teamFormStepSummary/TeamFormStepSummary';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BusinessIcon from '@mui/icons-material/Business';
import GroupsIcon from '@mui/icons-material/Groups';
import ComputerIcon from '@mui/icons-material/Computer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageHeader from '../../ui/PageHeader';

type TeamFormStep = {
  name: string;
  icon: React.ReactNode;
  component: React.FC<TeamFormStepProps>;
};

export const steps: TeamFormStep[] = [
  {
    name: 'Identidad',
    icon: <DriveFileRenameOutlineIcon />,
    component: TeamFormStepName,
  },
  {
    name: 'Cliente',
    icon: <BusinessIcon />,
    component: TeamFormStepClient,
  },
  {
    name: 'Integrantes',
    icon: <GroupsIcon />,
    component: TeamFormStepMembers,
  },
  {
    name: 'Stack tecnológico',
    icon: <ComputerIcon />,
    component: TeamFormStepStack,
  },
  {
    name: 'Resumen',
    icon: <CheckCircleIcon />,
    component: TeamFormStepSummary,
  },
];

interface Props {
  teamToUpdate?: ITeam;
}

function CreateTeam({ teamToUpdate }: Props) {
  const isExistingTeam = useRouteMatch('*/edit');
  const [currentStep, setCurrentStep] = useState(1);
  const [teamInfo, setTeamInfo] = useState<ITeam>(JSON.parse(createTeamTemplate));

  useEffect(() => {
    if (teamToUpdate) {
      setTeamInfo(teamToUpdate);
    }
  }, [teamToUpdate]);

  return (
    <Container maxWidth='xl' disableGutters>
      <PageHeader mb={5}>{isExistingTeam ? 'Equipo' : 'Nuevo Equipo'}</PageHeader>
      <CreateTeamStepper step={currentStep} setStep={setCurrentStep} />
      <CreateTeamForm
        step={currentStep}
        teamInfo={teamInfo}
        setTeamInfo={setTeamInfo}
        setCurrentStep={setCurrentStep}
      />
    </Container>
  );
}

export default CreateTeam;
