import { styled } from '@mui/material';

export const StyledResumeContainer = styled('div')({
  padding: '24px',
  marginTop: '64px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  borderWidth: '1px 0 0 1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  borderColor: 'rgba(49, 207, 242, 0.32)',
  backgroundColor: 'rgba(55, 80, 108, 0.12)',
});
