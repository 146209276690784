import Background from 'assets/witi-background-01.jpg';

export const container = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  minHeight: '100vh',
  margin: 0,
  minWidth: '100vw',
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
};

export const transparentBox = {
  backgroundColor: 'rgba(0, 70, 150, 0.95)',
  padding: 5,
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
