import React, { FC, useContext, useEffect, useState } from 'react';
import { SkillsInterface } from 'components/models/SkillsInterfaces';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { Box, Switch } from '@mui/material';

import InnerSkill from './innerSkill/InnerSkill';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { OptionsInterface } from './OptionsInterface';
import InnerSkillKam from './innerSkillKam/InnerSkillKam';
import InnerMentor from './innerMentor/innerMentor';

interface Props {
  personalData?: CandidateInterface;
  templateData: CandidateInterface;
  options: OptionsInterface;
  lightBG: boolean;
}

const SkillCompMenu: FC<Props> = ({ personalData, templateData, options, lightBG }) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [skills, setSkills] = useState('');
  const [template, setTemplate] = useState<CandidateInterface>(Object);

  useEffect(() => {
    if (options.isEdit) {
      const data: any = personalData;
      const sk: any = data.skills;
      let arr = sk?.map((sk: SkillsInterface) => sk.nombre);

      let todasSkills: SkillsInterface[] = [];
      let arrBool = false;

      //solo mostrará las dimensiones/skills/data guardadas lo demas lo muestra pero lo deja en false lo toma del templateData

      templateData.skills?.forEach((tsk: SkillsInterface) => {
        arrBool = !arr?.includes(tsk.nombre);

        if (!arrBool === false) {
          todasSkills.push(tsk);
        }
      });

      data.skills = [...todasSkills, ...sk];
      data.skills.sort((c1: SkillsInterface, c2: SkillsInterface) => {
        return (c1.order === undefined ? 0 : c1.order) > (c2.order === undefined ? 0 : c2.order)
          ? 1
          : -1;
      });
      setTemplate({ ...data });
    } else {
      //componente limpio sin data guardada
      setTemplate({ ...personalData });
    }
  }, [REACT_APP_BACKEND_URL, access_token, personalData, options, templateData]);

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <ButtonGroup disableElevation sx={{ margin: '0 auto', flexFlow: 'row wrap' }}>
          {template &&
            template.skills?.map((sk: SkillsInterface) => {
              return (
                <Box key={sk.nombre} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    onClick={() => setSkills(sk.nombre)}
                    variant={skills === sk.nombre ? 'contained' : 'outlined'}
                  >
                    {sk.nombre}
                  </Button>
                  <Switch
                    checked={sk.estado}
                    sx={{ margin: '0 auto' }}
                    onChange={(e) => {
                      sk.estado = !sk.estado;
                      setTemplate({ ...template, skills: template.skills });
                    }}
                  />
                </Box>
              );
            })}
        </ButtonGroup>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ flexDirection: 'column' }}>
          {
            //recorre habilidades, si es que la habilidad corresponde a la del boton se manda el template siguiente componente que renderiza el contenido y preguntas
            options.component === 'candidate' &&
              template.skills
                ?.filter((sk: SkillsInterface) => sk.nombre === skills)
                .map((sk: SkillsInterface) => {
                  return (
                    <InnerSkill
                      template={template}
                      setTemplate={setTemplate}
                      key={sk.nombre}
                      skill={sk}
                    />
                  );
                })
          }
          {
            //recorre habilidades, si es que la habilidad corresponde a la del boton se manda el template siguiente componente que renderiza el contenido y preguntas
            options.component === 'MentorView' &&
              template.skills
                ?.filter((sk: SkillsInterface) => sk.nombre === skills)
                .map((sk: SkillsInterface) => {
                  return (
                    <>
                      <InnerMentor
                        template={template}
                        setTemplate={setTemplate}
                        key={sk.nombre}
                        skill={sk}
                        options={options}
                      />
                    </>
                  );
                })
          }
          {
            //recorre habilidades, si es que la habilidad corresponde a la del boton se manda el template siguiente componente que renderiza el contenido y preguntas
            options.component === 'kam' &&
              template.skills
                ?.filter((sk: SkillsInterface) => sk.nombre === skills)
                .map((sk: SkillsInterface) => {
                  return (
                    <InnerSkillKam
                      template={template}
                      setTemplate={setTemplate}
                      key={sk.nombre}
                      skill={sk}
                      options={options}
                    />
                  );
                })
          }
        </Box>
      </Box>
    </Box>
  );
};

export default SkillCompMenu;
