import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import 'firebase/app';
import 'firebase/auth';
import { MainContainer, FormContainer, InputName, SubmitButton } from './style';
import ninjawelcome from '../../../assets/ninjawelcome.png';
import { IncorporationFormInterface } from 'components/models/IncorporationFormInterface';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';

import { useHistory } from 'react-router-dom';
import { logoutFirebase } from 'utils/firebase';
import SwalAlert from 'components/ui/alerts/SwalAlert';

const IncorporationForm: FC = () => {
  const { storeAuth, actionsAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { email } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [idEmployee, setIdEmployee] = useState('');
  const history = useHistory();

  const [formValues, setFormValues] = useState<IncorporationFormInterface>({
    email: email,
    name: '',
    dni: '',
    maritalStatus: '',
    nationality: '',
    profilePicture: '',
    phone: '',
    birthDate: '',
    curriculum: '',
    inscriptionDate: '',
    position: '',
    salary: '',
    observation: '',
    needsComputer: false,
    address: '',
    city: '',
    contractDuration: '',
    nationalData: {
      afp: '',
      isapre: '',
      dependents: 0,
      afpCertificate: '',
      healthCertificate: '',
    },
    foreignData: {
      passportNumber: '',
      professionalTitle: '',
      dniPicture: '',
    },
    bankInfo: {
      bank: '',
      accountType: '',
      accountNumber: '',
    },
  });

  useEffect(() => {
    const urlBackend = `${REACT_APP_BACKEND_URL}/employee/${email}/verify-employee`;

    getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((templatesData: any) => {
        setIdEmployee(templatesData._id);
      })
      .catch((error) => {
        console.warn('error:', error.message);
      });
  }, [email, REACT_APP_BACKEND_URL, access_token]);

  const handleChangeComputadora = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormValues({ ...formValues, needsComputer: checked });
    /*setFormValues({
          ...formValues,
          [name]: checked,
        });*/
  };
  const validateDNI = (dni: string) => {
    let isValid = false;
    const pattern = /^[0-9]{2,2}\.[0-9]{3,3}\.[0-9]{3,3}-[0-9K]{1,1}$/;
    isValid = pattern.test(dni);
    if (isValid) {
      return isValid;
    }

    return isValid;
  };

  const formValidator = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateDNI(formValues.dni)) {
      alert('Validación incorrecta: DNI');
    } else {
      alert('Se ha validado correctamente');
    }
  };

  const handleChangeObject = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'afp') {
      const updatedForm = { ...formValues };
      updatedForm.nationalData.afp = value;

      setFormValues(updatedForm);
    }

    if (name === 'afpCertificate') {
      const updatedForm = { ...formValues };
      updatedForm.nationalData.afpCertificate = value;

      setFormValues(updatedForm);
    }

    if (name === 'isapre') {
      const updatedForm = { ...formValues };
      updatedForm.nationalData.isapre = value;

      setFormValues(updatedForm);
    }

    if (name === 'dependents') {
      const updatedForm = { ...formValues };
      updatedForm.nationalData.dependents = parseInt(value);

      setFormValues(updatedForm);
    }

    if (name === 'healthCertificate') {
      const updatedForm = { ...formValues };
      updatedForm.nationalData.healthCertificate = value;

      setFormValues(updatedForm);
    }

    if (name === 'passportNumber') {
      const updatedForm = { ...formValues };
      updatedForm.foreignData.passportNumber = value;

      setFormValues(updatedForm);
    }

    if (name === 'professionalTitle') {
      const updatedForm = { ...formValues };
      updatedForm.foreignData.professionalTitle = value;

      setFormValues(updatedForm);
    }

    if (name === 'bank') {
      const updatedForm = { ...formValues };
      updatedForm.bankInfo.bank = value;

      setFormValues(updatedForm);
    }

    if (name === 'accountType') {
      const updatedForm = { ...formValues };
      updatedForm.bankInfo.accountType = value;

      setFormValues(updatedForm);
    }

    if (name === 'accountNumber') {
      const updatedForm = { ...formValues };
      updatedForm.bankInfo.accountNumber = value;

      setFormValues(updatedForm);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const onSubmit = () => {
    const data = {
      employeeId: idEmployee,
      data: formValues,
    };

    if (formValues.email === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un correo electronico',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    } else if (formValues.name === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un nombre',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    } else if (formValues.dni === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un dni',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    } else if (formValues.maritalStatus === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar su estado civil',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.nationality === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar su nacionalidad',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    } else if (formValues.phone === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar un telefono celular',
        icon: 'error',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    } else if (formValues.birthDate === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar su fecha de nacimiento',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.inscriptionDate === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una fecha de ingreso',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.position === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar su cargo',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.salary === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar su salario',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.address === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una direccion',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.city === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar una ciudad',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else if (formValues.contractDuration === '') {
      SwalAlert({
        title: 'Error',
        text: 'Debe ingresar la duracion del contrato',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    } else {
      const urlBackend = `${REACT_APP_BACKEND_URL}/employee/inscription-form`;
      getAxios
        .instance('')
        .patch(urlBackend, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result: any) => result.data)
        .then((entries: any) => {
          SwalAlert({
            title: 'Enviado',
            text: 'Empleado guardado con éxito',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Listo',
          }).then(() => {
            logoutFirebase(history, actionsAuth.logout);
          });
        })
        .catch((error) => {
          console.warn('error:', error.message);
        });
    }
  };

  const onChangeFile = (e: any) => {
    e.preventDefault();
    let files = e.target.files;
    if (files.length > 0) {
      let file = new FormData();
      file.append('file', files[0]);
      getAxios
        .instance('')
        .post(`${REACT_APP_BACKEND_URL}/personas/upload`, file, {
          headers: {
            Authorization: `Bearer ${storeAuth.user.access_token}`,
          },
        })
        .then((response: any) => response.data)
        .then((data: any) => {
          if (e.target.name === 'profilePicture') {
            setFormValues({ ...formValues, profilePicture: data });
          } else if (e.target.name === 'curriculum') {
            setFormValues({ ...formValues, curriculum: data });
          } else if (e.target.name === 'dniPicture') {
            const updatedForm = { ...formValues };
            updatedForm.foreignData.dniPicture = data;
            setFormValues(updatedForm);
          }
        });
    }
  };

  return (
    <>
      <MainContainer>
        <FormContainer>
          <p id='titulo'>Formulario Incorporación</p>
          <p id='subtitulo'>¡Bienvenido a nuestra familia!</p>
          <img src={ninjawelcome} style={{ width: '15vw' }} alt='' />
          <form onSubmit={formValidator}>
            <label>
              <InputName>
                <p>Email</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='email'
                name='email'
                placeholder='Email'
                value={formValues.email}
                onChange={handleChange}
                disabled
              />
            </label>
            <label>
              <InputName>
                <p>Nombre</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='name'
                placeholder='Nombre completo'
                value={formValues.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>DNI</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='dni'
                placeholder='12.345.678-9'
                value={formValues.dni}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>Estado civil</p>
                <p id='important'>*</p>
              </InputName>
              <select
                id='estadocivil'
                value={formValues.maritalStatus}
                name='maritalStatus'
                onChange={handleChange}
              >
                <option value=''> Escoga una opcion </option>
                <option value='soltero'>Soltero</option>
                <option value='casado'>Casado</option>
                <option value='viudo'>Viudo</option>
                <option value='divorciado'>Divorciado</option>
              </select>
            </label>
            <label>
              <InputName>
                <p>Nacionalidad</p>
                <p id='important'>*</p>
              </InputName>
              <select
                id='nacionalidad'
                value={formValues.nationality}
                name='nationality'
                onChange={handleChange}
                required
              >
                <option value=''> Escoga una opcion </option>
                <option value='chileno'>Chileno</option>
                <option value='venezolano'>Venezolano</option>
                <option value='argentino'>Argentino</option>
                <option value='peruano'>Peruano</option>
                <option value='otro'>Otro</option>
              </select>
            </label>

            <label>
              <p>
                Foto del profesional <p className='details'>{'(Con fondo blanco)'}</p>
              </p>
              <input type='file' name='profilePicture' onChange={onChangeFile} />
            </label>
            <label>
              <InputName>
                <p>Teléfono</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='phone'
                placeholder='+12 3456 7890'
                value={formValues.phone}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>Fecha de nacimiento</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='date'
                name='birthDate'
                value={formValues.birthDate}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>Curriculum</p>
              </InputName>
              <input type='file' name='curriculum' onChange={onChangeFile} />
            </label>
            <label>
              <InputName>
                <p>Fecha de ingreso</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='date'
                name='inscriptionDate'
                value={formValues.inscriptionDate}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>Cargo</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='position'
                placeholder='Vicepresidente ejecutivo'
                value={formValues.position}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <InputName>
                <p>Sueldo Liquido</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='number'
                name='salary'
                value={formValues.salary}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <p>Observacion adicional</p>
              <input
                type='text'
                name='observation'
                value={formValues.observation}
                onChange={handleChange}
              />
            </label>
            <label>
              <p>Necesita computador? </p>
              <input
                type='checkbox'
                className='bigcheckbox'
                checked={formValues.needsComputer}
                name='needsComputer'
                onChange={handleChangeComputadora}
              />
            </label>

            <label>
              <InputName>
                <p>Direccion particular completa</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='address'
                placeholder='Narnia, namekuseijin 1234'
                value={formValues.address}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              <InputName>
                <p>Ciudad la cual reside </p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='city'
                value={formValues.city}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              <InputName>
                <p>Duracion del contrato</p>
                <p id='important'>*</p>
              </InputName>
              <input
                type='text'
                name='contractDuration'
                value={formValues.contractDuration}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              <InputName>
                <p>Informacion nacional</p>
              </InputName>
              <br />

              <InputName>
                <p>AFP</p>
              </InputName>

              <input
                type='text'
                name='afp'
                value={formValues.nationalData.afp}
                onChange={handleChangeObject}
                required
              />

              <br />
              <br />
              <br />
              <InputName>
                <p>Certificado de AFP</p>
              </InputName>

              <input
                type='text'
                name='afpCertificate'
                value={formValues.nationalData.afpCertificate}
                onChange={handleChangeObject}
              />
              <br />
              <br />
              <br />

              <InputName>
                <p>Isapre</p>
              </InputName>

              <input
                type='text'
                name='isapre'
                value={formValues.nationalData.isapre}
                onChange={handleChangeObject}
                required
              />

              <br />
              <br />
              <br />
              <InputName>
                <p>Cargas</p>
              </InputName>

              <input
                type='number'
                min={0}
                name='dependents'
                value={formValues.nationalData.dependents}
                onChange={handleChangeObject}
              />
              <br />
              <br />
              <br />
              <InputName>
                <p>Certificado de salud</p>
              </InputName>

              <input
                type='text'
                name='healthCertificate'
                value={formValues.nationalData.healthCertificate}
                onChange={handleChangeObject}
              />
            </label>

            <label>
              <InputName>
                <p>Informacion extranjera</p>
              </InputName>
              <br />

              <InputName>
                <p>Numero de pasaporte</p>
              </InputName>

              <input
                type='text'
                name='passportNumber'
                value={formValues.foreignData.passportNumber}
                onChange={handleChangeObject}
              />

              <br />
              <br />
              <br />
              <InputName>
                <p>Titulo profesional</p>
              </InputName>

              <input
                type='text'
                name='professionalTitle'
                value={formValues.foreignData.professionalTitle}
                onChange={handleChangeObject}
              />
              <br />
              <br />
              <br />
              <p>Foto del dni</p>
              <input type='file' name='dniPicture' onChange={onChangeFile} />
            </label>

            <label>
              <InputName>
                <p>Informacion bancaria</p>
              </InputName>
              <br />

              <InputName>
                <p>Banco</p>
              </InputName>

              <input
                type='text'
                name='bank'
                value={formValues.bankInfo.bank}
                onChange={handleChangeObject}
              />

              <br />
              <br />
              <br />
              <InputName>
                <p>Tipo de cuenta</p>
              </InputName>

              <input
                type='text'
                name='accountType'
                value={formValues.bankInfo.accountType}
                onChange={handleChangeObject}
              />
              <br />
              <br />
              <br />

              <InputName>
                <p>Numero de cuenta</p>
              </InputName>

              <input
                type='text'
                name='accountNumber'
                value={formValues.bankInfo.accountNumber}
                onChange={handleChangeObject}
              />
            </label>

            <SubmitButton onClick={onSubmit}>Enviar formulario</SubmitButton>
          </form>
        </FormContainer>
      </MainContainer>
    </>
  );
};
export default IncorporationForm;
