import React from 'react';
import { StyledRole } from './style';
import { capitalizeWord } from '../menu-links';

interface Props {
  role: string;
}

function CurrentRoleLabel({ role }: Props) {
  const getRole = () => {
    return capitalizeWord(role);
  };

  return <StyledRole>{getRole()}</StyledRole>;
}

export default CurrentRoleLabel;
