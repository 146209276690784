import { styled } from '@mui/system';

export const CardDark = styled('div')({
  gap: '28px',
  width: '100%',
  padding: '12px 20px',
  display: 'flex',
  borderRadius: '8px',
  borderStyle: 'solid',
  backdropFilter: 'blur(5px)',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderWidth: '1px 0 0 1px',
  background: 'rgba(55, 80, 108, 0.081)',
  borderColor: 'rgba(49, 207, 242, 0.32)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  position: 'relative',
  maxWidth: '1156px',

  '@media  (max-width: 720px)': {
    justifyContent: 'center',
    padding: '12px 16px',
  },
});

export const ContainerInfoCard = styled('div')({
  display: 'flex',
  gap: '28px',
  height: ' 100%',

  '@media (max-width: 720px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});
export const CardContainerShowRoomSC = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px',
});

export const Text = styled('div')({
  fontWeight: '800',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#151E29',
  margin: 0,
});

export const RolText = styled('p')({
  fontWeight: '800',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#31CFF2',
  margin: 0,
});

export const ListLikesCard = styled('div')({
  position: 'absolute',
  zIndex: '100 !important',
  bottom: '0px',
  right: '-100px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  padding: '10px',
  color: 'white',
  borderRadius: '5px',
  fontSize: '12px',

  '@media screen and (max-width: 768px)': {
    display: 'none',
  },
});

export const DarkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  padding: '8px',
  rowGap: '8px',
  backgroundColor: '#0F171E',
  flexWrap: 'wrap',
  width: '100%',
  borderRadius: '8px',
  '.dark-container-info:not(:last-child)::after': {
    content: "''",
    height: '100%',
    width: '2px',
    backgroundColor: '#1B3E5C',

    '@media (max-width: 420px)': {
      display: 'none',
    },
  },
});

export const DarkSkillsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  gap: '8px',
  padding: '8px',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: '#0F171E',
});

export const DarkContainerAfter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export const DarkContainerInfo = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  flex: '1 0 10%',
});
export const DarkContainerInfoTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#637DAF',
  textAlign: 'center',
});
export const DarkContainerInfoText = styled('div')({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '122px',
  textAlign: 'center',
});
export const SkillCard = styled('div')({
  flex: '1 0.5 20%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0 4px',
  minWidth: '105px',
  minHeight: '56px',
  background: '#151E29',
  borderRadius: '4px',
  width: '100%',
  justifyContent: 'space-between',
});
export const SkillCardTitle = styled('div')({
  display: 'flex',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  padding: '4px',
  color: '#637DAF',
  background: '#102537',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px 4px 0 0',
  textAlign: 'center',
});
export const CandidateInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  '@media (max-width: 720px)': {
    width: '100%',
  },
});

export const ContainerButtonCustom = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '16px',
});
export const ImagenCandidate = styled('img')({
  width: '164px',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',

  '@media (max-width: 720px)': {
    width: '100%',
    height: '152px',
  },
});
export const ImagenFlag = styled('img')({
  display: 'flex',
  marginTop: '3px',
  width: '20px',
});
export const FakeImagenCandidate = styled('div')({
  height: '100%',
  border: '2px solid #637DAF',
  width: '164px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 720px)': {
    width: '100%',
  },
});
export const FirstTitleName = styled('div')({
  '@media (max-width: 720px)': {
    display: 'none',
  },
});
export const SecondTitleName = styled('div')({
  display: 'none',

  '@media (max-width: 720px)': {
    display: 'flex',
    width: '100%',
  },
});

export const ButtonCustom = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: ' 4px 8px',
  gap: '8px',
  height: '24px',
  background: '#637DAF',
  borderRadius: '4px',
  fontWeight: '800',
  fontSize: '14px',
  lineHeight: '16px',
  border: 'none',
  flex: '1.5 0 160px',

  '&:hover': {
    backgroundColor: '#31CFF2',
  },

  '@media (max-width: 720px)': {
    height: '32px',
  },
});

export const DetailInfoAnchor = styled('a')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: ' 4px 8px',
  gap: '8px',
  height: '24px',
  background: '#637DAF',
  borderRadius: '4px',
  fontWeight: '800',
  fontSize: '14px',
  lineHeight: '16px',
  border: 'none',
  flex: '1.5 0 160px',
  textDecoration: 'none',
  color: '#000',

  '&:hover': {
    backgroundColor: '#31CFF2',
  },

  '@media (max-width: 720px)': {
    height: '32px',
  },
});

export const InfoText = styled('p')({
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '20px',
  color: '#637DAF',
  margin: 'auto 3px',
});
