import React, { FC, useState } from 'react';
import { innerSkillsInterface, SkillsInterface } from 'components/models/SkillsInterfaces';
import GlossarySubSkills from './GlossarySubSkills';
import {
  StyledGlossarySkillsContainer,
  StyledGlossarySkillsName,
  StyledSkillsContainer,
} from './style';

interface Props {
  skills: SkillsInterface;
  counterID: number;
}

const GlossarySkills: FC<Props> = ({ skills, counterID }) => {
  const [display, setDisplay] = useState('');

  return (
    <StyledGlossarySkillsContainer
      key={`box-glossary${counterID}`}
      onClick={() => setDisplay(display === skills.nombre ? '' : skills.nombre)}
    >
      <StyledGlossarySkillsName key={`text-glossary${counterID}`}>
        {skills.nombre}
      </StyledGlossarySkillsName>
      {/*habilidad blanda-frontend-backend*/}
      <StyledSkillsContainer
        key={`sub-box-glossary${counterID}`}
        style={{ display: display === skills.nombre ? 'flex' : 'none' }}
      >
        {skills &&
          skills.skills.map((skill: innerSkillsInterface, i: number) => {
            return (
              <GlossarySubSkills
                key={skill.nombre}
                counterID={i}
                questions={skill.preguntas}
                questionName={skill.nombre}
              />
            );
          })}
      </StyledSkillsContainer>
    </StyledGlossarySkillsContainer>
  );
};

export default GlossarySkills;
