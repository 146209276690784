import styled, { keyframes } from 'styled-components';

const glow = keyframes`
  0% 100%{
    box-shadow: 0 0 3px #fff;
  }
  50% {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff;
  }
`;
const glowSubmitButton = keyframes`
  0% 100%{
    box-shadow: 0 0 1px #fff;
  }
  50% {
    box-shadow: 0 0 3px #fff, 0 0 6px #fff;
  }
`;

export const DivGlowEffect = styled.div`
  &.glow {
    animation: ${glow} 1s ease-in-out infinite;
  }
`;
export const DivGlowEffectSubmitButton = styled.div`
  &.glow-button {
    animation: ${glowSubmitButton} 1s ease-in-out infinite;
    display: inline-block;
  }
`;
