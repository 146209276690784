import React, { FC } from 'react';
import ROL from 'utils/Roles';
import { Box } from '@mui/material';
import { SkillValidator } from './utils/skillValidator';
import { Interviewvalidator } from './utils/interviewValidator';
import { TeamValidator } from './utils/teamValidator';

type TmenteeProgressIndicatorsProps = {
  userRol: string;
  mentee: any;
  access_token: string;
  idMentee: string;
  teams: { _id: string; nombre: string }[];
};

export const MenteeProgressIndicators: FC<TmenteeProgressIndicatorsProps> = ({
  userRol,
  mentee,
  access_token,
  idMentee,
  teams,
}) => {
  const { REACT_APP_BACKEND_URL } = process.env;

  return (
    <>
      {(userRol === ROL.ADMINISTRADOR || userRol === ROL.KAM || userRol === ROL.MENTOR) && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            bottom: 10,
            left: 16,
            alignItems: 'center',
          }}
        >
          <SkillValidator
            userRol={userRol}
            candidateID={mentee.candidateID}
            access_token={access_token}
            REACT_APP_BACKEND_URL={REACT_APP_BACKEND_URL}
          />
          <Interviewvalidator
            REACT_APP_BACKEND_URL={REACT_APP_BACKEND_URL}
            access_token={access_token}
            idMentee={idMentee}
            userRol={userRol}
          />
          <TeamValidator
            employeeTeams={teams}
            REACT_APP_BACKEND_URL={REACT_APP_BACKEND_URL}
            access_token={access_token}
          />
        </Box>
      )}
    </>
  );
};
