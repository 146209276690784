import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import MenteeProfile from 'components/ui/MenteeView/MenteeProfile';
import { useParams } from 'react-router-dom';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { socket } from 'services/socketIO/socketCliente';
import { useSocketConnection } from '../../../services/socketIO/useSocketConnection';
import { useSocketSubcribeEvents } from '../../../hooks/useMenteeInterviewSocketSubcribeEvents';
import useBeforeUnload from 'hooks/useBeforeUnload';

import WitiPointsModal from './WitiPointsModal';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import SkillsForm from '../newCandidate/skillsComponent/SkillsForm';

import { Box } from '@mui/material';
import { GlowingSaveButton } from 'components/ui/skillForm/GlowingSaveButton';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import FullscreenAlert from '../../ui/alerts/FullscreenAlert';

import jsonTemplate from './mentor-template-questions.json';
import { ISkills } from 'components/models/SkillsInterfaces';

import SwalAlert from 'components/ui/alerts/SwalAlert';

type SkillsFormProps = {
  tabActive: string;
  tabController: string;
  interviewDetails: CandidateInterface2;
  primaryControllerName: string;
  message: string;
  showAlert: boolean;
  idMentee: string;
  type: string;
  glowingComponent: string;
  makeGlowingAnimation: boolean;
};
type ReactHookFormObjects = {
  textAreaRules: object;
  RadioGroupRules: object;
  setFormIsDirty: (isDirty: boolean) => void;
};
type newInterview = {
  skills: ISkills[];
  type: String;
  interviewer_id: String;
  interviewed_id: String;
};

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    No existen datos asociados a este perfil.
  </FullscreenAlert>
);

const Mentor = () => {
  const setShouldConfirmExit = useBeforeUnload();
  const { idMentee } = useParams<{ idMentee: string }>();
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [openWitiPoints, setOpenWitiPoints] = useState(false);
  const [reloadWitiPoints, setReloadWitiPoints] = useState<number>(0);
  const [interviewId, setInterviewId] = useState<string>('');
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  useEffect(() => {
    setShouldConfirmExit(formIsDirty);
  }, [formIsDirty, setShouldConfirmExit]);

  const idMentor = storeAuth?.user?._id;

  const reactHookFormMethods = useForm<SkillsFormProps>({
    defaultValues: {
      tabActive: 'tab-0',
      interviewDetails: structuredClone(jsonTemplate),
      primaryControllerName: 'primaryControllerName',
      message: '',
      showAlert: false,
      idMentee: idMentee,
      type: 'MENTOR',
      glowingComponent: '',
      makeGlowingAnimation: false,
    },
    mode: 'onTouched',
  });
  const reactHookFormObjects: ReactHookFormObjects = {
    textAreaRules: {},
    RadioGroupRules: {},
    setFormIsDirty,
  };
  useSocketConnection(socket);
  useSocketSubcribeEvents(socket, reactHookFormMethods, idMentee, 'MENTOR');

  const {
    data: mentee,
    isPending,
    isError,
  } = useQuery({
    queryKey: [`/employee/${idMentee}`, access_token],
  });

  function alert(message = 'En estos momentos no se puede obtener la información.') {
    // reactHookFormMethods.setValue('message', message);
    // reactHookFormMethods.setValue('showAlert', true);
    SwalAlert({
      title: message,
      icon: 'success',
      confirmButtonText: 'OK',
      showConfirmButton: true,
    });
  }

  // function closeAlert() {
  //   reactHookFormMethods.setValue('showAlert', false);
  // }

  useEffect(() => {
    getAxios
      .instance(access_token)
      .get(`/interview/mentor/mentee/${idMentee}`)
      .then((response) => {
        if (response.status === 200 && response.data !== '') {
          reactHookFormMethods.setValue('interviewDetails.skills', response.data.skills);
          setInterviewId(response.data._id);
        }
      })
      .finally(() => {
        socket.emit('joinRoom', {
          interviewed_id: idMentee,
          type: reactHookFormMethods.getValues('type'),
        });
      });
  }, [access_token, idMentee, reactHookFormMethods]);

  const saveInterview: SubmitHandler<SkillsFormProps> = ({ interviewDetails }) => {
    const newInterview: newInterview = {
      skills: interviewDetails.skills,
      type: 'MENTOR',
      interviewer_id: idMentor,
      interviewed_id: idMentee,
    };
    try {
      if (interviewId) {
        getAxios
          .instance(access_token)
          .patch(`/interview/${interviewId}`, newInterview)
          .then((response) => {
            alert('Entrevista guardada con éxito');
            setFormIsDirty(false);
          });
      } else {
        getAxios
          .instance(access_token)
          .post('/interview', newInterview)
          .then((response) => {
            // alert('Entrevista guardada con éxito');
            SwalAlert({
              background: '#213456',
              icon: 'success',
              title: 'Entrevista guardada con éxito',
              showCancelButton: false,
              timer: 3000,
              showConfirmButton: true,
            });
            setFormIsDirty(false);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) return <FullscreenLoading />;

  if (isError) return <ErrorAlert />;

  return (
    <Box>
      {/* options disponibles : component: "MenteeView" || "MentorView" || "KamView" } */}
      <MenteeProfile
        openWitiPoints={openWitiPoints}
        setOpenWitiPoints={setOpenWitiPoints}
        idMentee={idMentee}
        mentee={mentee}
        options={{ component: 'MentorView' }}
        reloadWitiPoints={reloadWitiPoints}
        typeOfInterview='MENTOR'
        currentMenteeUri={`/mentored/${idMentee}`}
        interviewDetails={reactHookFormMethods.getValues('interviewDetails')}
      />
      {openWitiPoints && (
        <WitiPointsModal
          idMentee={idMentee}
          openWitiPoints={openWitiPoints}
          setOpenWitiPoints={setOpenWitiPoints}
          setReloadWitiPoints={setReloadWitiPoints}
          reloadWitiPoints={reloadWitiPoints}
        />
      )}
      <FormProvider {...reactHookFormMethods}>
        <form onSubmit={reactHookFormMethods.handleSubmit(saveInterview)}>
          <SkillsForm {...reactHookFormObjects} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 6,
            }}
          >
            <GlowingSaveButton isDirty={formIsDirty} />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Mentor;
