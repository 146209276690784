import React, { FC, useContext } from 'react';
import { Form, FormControl, Card, Button } from 'react-bootstrap';
import SadWiti from 'assets/witl_personaje_5-1.png';
import { StyledCard } from './style';

import getAxios from '../../../apiRestConnector';
import { AuthContext } from '../../../services/authContext/AuthContextProvider';
import { useHistory } from 'react-router-dom';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {}

const Unsubscribe: FC<Props> = () => {
  const { storeAuth, actionsAuth } = useContext(AuthContext)!;
  const { user } = storeAuth;
  const { REACT_APP_BACKEND_URL } = process.env;
  const { access_token } = storeAuth.user;
  const history = useHistory();
  let motivo: string = '';

  const handleUnsubscribe = async () => {
    await requestUnsubscribe()
      .then(() => {
        SwalAlert({
          title: 'Desuscripción con éxito',
          text: 'Has sido dado de baja de nuestro boletín semanal.',
          confirmButtonText: 'Entendido',
          icon: 'success',
          showConfirmButton: true,
        }).then((button) => {
          if (button.isConfirmed) {
            history.push('/');
            window.location.reload();
          }
        });
      })
      .catch(() => {
        SwalAlert({
          title: 'Error',
          text: 'Ha ocurrido un error al procesar la solicitud.',
          confirmButtonText: 'Entendido',
          icon: 'success',
          showConfirmButton: true,
        });
      });
  };

  const requestUnsubscribe = async () => {
    return await getAxios
      .instance('')
      .get(`${REACT_APP_BACKEND_URL}/users/unsubscribe/${user.email}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result) => {
        updateSubscribedUser();
        actionsAuth.updateUserState(); // se sincroniza estado del usuario con localstorage
      })
      .catch((error) => {
        console.error('error:', error);
      });
  };

  const updateSubscribedUser = () => {
    // actualizara estado subscribed en local storage
    let user = JSON.parse(localStorage.getItem('user')!);
    user.subscribed = false;
    localStorage.setItem('user', JSON.stringify(user));
  };

  return (
    <>
      <StyledCard>
        <Card.Header as='h5' className='header'>
          Desuscribirse
        </Card.Header>
        <Card.Body className='body'>
          <Card.Img src={SadWiti} variant='top' className='img' />
          <Card.Title>Dar de baja boletín semanal</Card.Title>
          <Form className='col-md-6'>
            <label>Motivo:</label>
            <FormControl type='text' placeholder='No estoy interesado' defaultValue={motivo} />
          </Form>
          <Button variant='success' className='button' onClick={handleUnsubscribe}>
            Guardar
          </Button>
        </Card.Body>
      </StyledCard>
    </>
  );
};

export default Unsubscribe;
