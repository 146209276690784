const rawCountryCodes = [
  {
    id: 4,
    name: 'Afganistán',
    alpha2: 'af',
    alpha3: 'afg',
    nationality: 'AFGANA',
  },
  {
    id: 248,
    name: 'Åland',
    alpha2: 'ax',
    alpha3: 'ala',
    nationality: 'ÅLAND',
  },
  {
    id: 8,
    name: 'Albania',
    alpha2: 'al',
    alpha3: 'alb',
    nationality: 'ALBANESA',
  },
  {
    id: 276,
    name: 'Alemania',
    alpha2: 'de',
    alpha3: 'deu',
    nationality: 'ALEMÁN',
  },
  {
    id: 20,
    name: 'Andorra',
    alpha2: 'ad',
    alpha3: 'and',
    nationality: 'ANDORRANA',
  },
  {
    id: 24,
    name: 'Angola',
    alpha2: 'ao',
    alpha3: 'ago',
    nationality: 'ANGOLEÑA',
  },
  {
    id: 660,
    name: 'Anguila',
    alpha2: 'ai',
    alpha3: 'aia',
    nationality: 'ANGUILA',
  },
  {
    id: 10,
    name: 'Antártida',
    alpha2: 'aq',
    alpha3: 'ata',
    nationality: 'ANTARTICA',
  },
  {
    id: 28,
    name: 'Antigua y Barbuda',
    alpha2: 'ag',
    alpha3: 'atg',
    nationality: 'ANTIGUANA',
  },
  {
    id: 682,
    name: 'Arabia Saudita',
    alpha2: 'sa',
    alpha3: 'sau',
    nationality: 'ÁRABE',
  },
  {
    id: 12,
    name: 'Argelia',
    alpha2: 'dz',
    alpha3: 'dza',
    nationality: 'ARGELINA',
  },
  {
    id: 32,
    name: 'Argentina',
    alpha2: 'ar',
    alpha3: 'arg',
    nationality: 'ARGENTINA',
  },
  {
    id: 51,
    name: 'Armenia',
    alpha2: 'am',
    alpha3: 'arm',
    nationality: 'ARMENIO',
  },
  {
    id: 533,
    name: 'Aruba',
    alpha2: 'aw',
    alpha3: 'abw',
    nationality: 'ARUBA',
  },
  {
    id: 36,
    name: 'Australia',
    alpha2: 'au',
    alpha3: 'aus',
    nationality: 'AUSTRALIANA',
  },
  {
    id: 40,
    name: 'Austria',
    alpha2: 'at',
    alpha3: 'aut',
    nationality: 'AUSTRIACA',
  },
  {
    id: 31,
    name: 'Azerbaiyán',
    alpha2: 'az',
    alpha3: 'aze',
    nationality: 'AZERBAIYANO',
  },
  {
    id: 44,
    name: 'Bahamas',
    alpha2: 'bs',
    alpha3: 'bhs',
    nationality: 'BAHAMEÑA',
  },
  {
    id: 50,
    name: 'Bangladés',
    alpha2: 'bd',
    alpha3: 'bgd',
    nationality: 'BANGLADESÍ',
  },
  {
    id: 52,
    name: 'Barbados',
    alpha2: 'bb',
    alpha3: 'brb',
    nationality: 'BARBADENSE',
  },
  {
    id: 48,
    name: 'Baréin',
    alpha2: 'bh',
    alpha3: 'bhr',
    nationality: 'BAHREINI',
  },
  {
    id: 56,
    name: 'Bélgica',
    alpha2: 'be',
    alpha3: 'bel',
    nationality: 'BELGA',
  },
  {
    id: 84,
    name: 'Belice',
    alpha2: 'bz',
    alpha3: 'blz',
    nationality: 'BELICEÑA',
  },
  {
    id: 204,
    name: 'Benín',
    alpha2: 'bj',
    alpha3: 'ben',
    nationality: 'BENINÉSA',
  },
  {
    id: 60,
    name: 'Bermudas',
    alpha2: 'bm',
    alpha3: 'bmu',
    nationality: 'BERMUDEÑO',
  },
  {
    id: 112,
    name: 'Bielorrusia',
    alpha2: 'by',
    alpha3: 'blr',
    nationality: 'BIELORRUSA',
  },
  {
    id: 68,
    name: 'Bolivia',
    alpha2: 'bo',
    alpha3: 'bol',
    nationality: 'BOLIVIANA',
  },
  {
    id: 535,
    name: 'Bonaire, San Eustaquio y Saba',
    alpha2: 'bq',
    alpha3: 'bes',
    nationality: 'HOLANDÉS',
  },
  {
    id: 70,
    name: 'Bosnia y Herzegovina',
    alpha2: 'ba',
    alpha3: 'bih',
    nationality: 'BOSNIA, HERZEGOVINA',
  },
  {
    id: 72,
    name: 'Botsuana',
    alpha2: 'bw',
    alpha3: 'bwa',
    nationality: 'BOTSUANA',
  },
  {
    id: 76,
    name: 'Brasil',
    alpha2: 'br',
    alpha3: 'bra',
    nationality: 'BRASILERA',
  },
  {
    id: 96,
    name: 'Brunéi',
    alpha2: 'bn',
    alpha3: 'brn',
    nationality: 'BRUNEANA',
  },
  {
    id: 100,
    name: 'Bulgaria',
    alpha2: 'bg',
    alpha3: 'bgr',
    nationality: 'BULGARA',
  },
  {
    id: 854,
    name: 'Burkina Faso',
    alpha2: 'bf',
    alpha3: 'bfa',
    nationality: 'ALTO VOLTA',
  },
  {
    id: 108,
    name: 'Burundi',
    alpha2: 'bi',
    alpha3: 'bdi',
    nationality: 'BURUNDÉSA',
  },
  {
    id: 64,
    name: 'Bután',
    alpha2: 'bt',
    alpha3: 'btn',
    nationality: 'BUTANÉS',
  },
  {
    id: 132,
    name: 'Cabo Verde',
    alpha2: 'cv',
    alpha3: 'cpv',
    nationality: 'CABOVERDIANA',
  },
  {
    id: 116,
    name: 'Camboya',
    alpha2: 'kh',
    alpha3: 'khm',
    nationality: 'KAMPUCHEA',
  },
  {
    id: 120,
    name: 'Camerún',
    alpha2: 'cm',
    alpha3: 'cmr',
    nationality: 'CAMERUNÉSA',
  },
  {
    id: 124,
    name: 'Canadá',
    alpha2: 'ca',
    alpha3: 'can',
    nationality: 'CANADIENSE',
  },
  {
    id: 634,
    name: 'Catar',
    alpha2: 'qa',
    alpha3: 'qat',
    nationality: 'QATARÍ',
  },
  {
    id: 148,
    name: 'Chad',
    alpha2: 'td',
    alpha3: 'tcd',
    nationality: 'CHADIANA',
  },
  {
    id: 152,
    name: 'Chile',
    alpha2: 'cl',
    alpha3: 'chl',
    nationality: 'CHILENA',
  },
  {
    id: 156,
    name: 'China',
    alpha2: 'cn',
    alpha3: 'chn',
    nationality: 'CHINA',
  },
  {
    id: 196,
    name: 'Chipre',
    alpha2: 'cy',
    alpha3: 'cyp',
    nationality: 'CHIPRIOTA',
  },
  {
    id: 170,
    name: 'Colombia',
    alpha2: 'co',
    alpha3: 'col',
    nationality: 'COLOMBIANA',
  },
  {
    id: 174,
    name: 'Comoras',
    alpha2: 'km',
    alpha3: 'com',
    nationality: 'COMORENSE',
  },
  {
    id: 408,
    name: 'Corea del Norte',
    alpha2: 'kp',
    alpha3: 'prk',
    nationality: 'NORCOREANA',
  },
  {
    id: 410,
    name: 'Corea del Sur',
    alpha2: 'kr',
    alpha3: 'kor',
    nationality: 'SURCOREANA',
  },
  {
    id: 384,
    name: 'Costa de Marfil',
    alpha2: 'ci',
    alpha3: 'civ',
    nationality: 'MARFILEÑA',
  },
  {
    id: 188,
    name: 'Costa Rica',
    alpha2: 'cr',
    alpha3: 'cri',
    nationality: 'COSTARRICENSA',
  },
  {
    id: 191,
    name: 'Croacia',
    alpha2: 'hr',
    alpha3: 'hrv',
    nationality: 'CROATA',
  },
  {
    id: 192,
    name: 'Cuba',
    alpha2: 'cu',
    alpha3: 'cub',
    nationality: 'CUBANA',
  },
  {
    id: 531,
    name: 'Curazao',
    alpha2: 'cw',
    alpha3: 'cuw',
    nationality: 'HOLANDÉS',
  },
  {
    id: 208,
    name: 'Dinamarca',
    alpha2: 'dk',
    alpha3: 'dnk',
    nationality: 'DANESA',
  },
  {
    id: 212,
    name: 'Dominica',
    alpha2: 'dm',
    alpha3: 'dma',
    nationality: 'DOMINIQUÉSA',
  },
  {
    id: 218,
    name: 'Ecuador',
    alpha2: 'ec',
    alpha3: 'ecu',
    nationality: 'ECUATORIANA',
  },
  {
    id: 818,
    name: 'Egipto',
    alpha2: 'eg',
    alpha3: 'egy',
    nationality: 'EGIPCIA',
  },
  {
    id: 222,
    name: 'El Salvador',
    alpha2: 'sv',
    alpha3: 'slv',
    nationality: 'SALVADOREÑA',
  },
  {
    id: 784,
    name: 'Emiratos Árabes Unidos',
    alpha2: 'ae',
    alpha3: 'are',
    nationality: 'EMIRATÍ',
  },
  {
    id: 232,
    name: 'Eritrea',
    alpha2: 'er',
    alpha3: 'eri',
    nationality: 'ESTONIA',
  },
  {
    id: 703,
    name: 'Eslovaquia',
    alpha2: 'sk',
    alpha3: 'svk',
    nationality: 'ESLOVACO',
  },
  {
    id: 705,
    name: 'Eslovenia',
    alpha2: 'si',
    alpha3: 'svn',
    nationality: 'ESLOVENO',
  },
  {
    id: 724,
    name: 'España',
    alpha2: 'es',
    alpha3: 'esp',
    nationality: 'ESPAÑOLA',
  },
  {
    id: 840,
    name: 'Estados Unidos',
    alpha2: 'us',
    alpha3: 'usa',
    nationality: 'ESTADOUNIDENSE',
  },
  {
    id: 233,
    name: 'Estonia',
    alpha2: 'ee',
    alpha3: 'est',
    nationality: 'ESTONIO',
  },
  {
    id: 231,
    name: 'Etiopía',
    alpha2: 'et',
    alpha3: 'eth',
    nationality: 'ETÍOPE',
  },
  {
    id: 608,
    name: 'Filipinas',
    alpha2: 'ph',
    alpha3: 'phl',
    nationality: 'FILIPINA',
  },
  {
    id: 246,
    name: 'Finlandia',
    alpha2: 'fi',
    alpha3: 'fin',
    nationality: 'FINLANDESA',
  },
  {
    id: 242,
    name: 'Fiyi',
    alpha2: 'fj',
    alpha3: 'fji',
    nationality: 'FIDJI, ISLAS',
  },
  {
    id: 250,
    name: 'Francia',
    alpha2: 'fr',
    alpha3: 'fra',
    nationality: 'FRANCESA',
  },
  {
    id: 266,
    name: 'Gabón',
    alpha2: 'ga',
    alpha3: 'gab',
    nationality: 'GABONÉSA',
  },
  {
    id: 270,
    name: 'Gambia',
    alpha2: 'gm',
    alpha3: 'gmb',
    nationality: 'GAMBIANA',
  },
  {
    id: 268,
    name: 'Georgia',
    alpha2: 'ge',
    alpha3: 'geo',
    nationality: 'GEORGIANO',
  },
  {
    id: 288,
    name: 'Ghana',
    alpha2: 'gh',
    alpha3: 'gha',
    nationality: 'GHANÉSA',
  },
  {
    id: 292,
    name: 'Gibraltar',
    alpha2: 'gi',
    alpha3: 'gib',
    nationality: 'GIBRALTAREÑA',
  },
  {
    id: 308,
    name: 'Granada',
    alpha2: 'gd',
    alpha3: 'grd',
    nationality: 'GRANADINA',
  },
  {
    id: 300,
    name: 'Grecia',
    alpha2: 'gr',
    alpha3: 'grc',
    nationality: 'GRACIA',
  },
  {
    id: 304,
    name: 'Groenlandia',
    alpha2: 'gl',
    alpha3: 'grl',
    nationality: 'GROENLANDESA',
  },
  {
    id: 312,
    name: 'Guadalupe',
    alpha2: 'gp',
    alpha3: 'glp',
    nationality: 'GUADALUPE',
  },
  {
    id: 316,
    name: 'Guam',
    alpha2: 'gu',
    alpha3: 'gum',
    nationality: 'GUAMEÑA',
  },
  {
    id: 320,
    name: 'Guatemala',
    alpha2: 'gt',
    alpha3: 'gtm',
    nationality: 'GUATEMALTECA',
  },
  {
    id: 254,
    name: 'Guayana Francesa',
    alpha2: 'gf',
    alpha3: 'guf',
    nationality: 'GUYANÉSA',
  },
  {
    id: 831,
    name: 'Guernsey',
    alpha2: 'gg',
    alpha3: 'ggy',
    nationality: 'ISLEÑO DEL CANAL',
  },
  {
    id: 324,
    name: 'Guinea',
    alpha2: 'gn',
    alpha3: 'gin',
    nationality: 'GUINEANA',
  },
  {
    id: 624,
    name: 'Guinea-Bisáu',
    alpha2: 'gw',
    alpha3: 'gnb',
    nationality: 'GUINEANA',
  },
  {
    id: 226,
    name: 'Guinea Ecuatorial',
    alpha2: 'gq',
    alpha3: 'gnq',
    nationality: 'ECUATOGUINEANA',
  },
  {
    id: 328,
    name: 'Guyana',
    alpha2: 'gy',
    alpha3: 'guy',
    nationality: 'GUYANÉSA',
  },
  {
    id: 332,
    name: 'Haití',
    alpha2: 'ht',
    alpha3: 'hti',
    nationality: 'HAITIANA',
  },
  {
    id: 340,
    name: 'Honduras',
    alpha2: 'hn',
    alpha3: 'hnd',
    nationality: 'HONDUREÑA',
  },
  {
    id: 344,
    name: 'Hong Kong',
    alpha2: 'hk',
    alpha3: 'hkg',
    nationality: 'HONGKONESA',
  },
  {
    id: 348,
    name: 'Hungría',
    alpha2: 'hu',
    alpha3: 'hun',
    nationality: 'HÚNGARA',
  },
  {
    id: 356,
    name: 'India',
    alpha2: 'in',
    alpha3: 'ind',
    nationality: 'INDIA',
  },
  {
    id: 360,
    name: 'Indonesia',
    alpha2: 'id',
    alpha3: 'idn',
    nationality: 'INDONESA',
  },
  {
    id: 368,
    name: 'Irak',
    alpha2: 'iq',
    alpha3: 'irq',
    nationality: 'IRAQUÍ',
  },
  {
    id: 364,
    name: 'Irán',
    alpha2: 'ir',
    alpha3: 'irn',
    nationality: 'IRANÍ',
  },
  {
    id: 372,
    name: 'Irlanda',
    alpha2: 'ie',
    alpha3: 'irl',
    nationality: 'IRLANDESA',
  },
  {
    id: 74,
    name: 'Isla Bouvet',
    alpha2: 'bv',
    alpha3: 'bvt',
    nationality: 'BOUVET, ISLA',
  },
  {
    id: 833,
    name: 'Isla de Man',
    alpha2: 'im',
    alpha3: 'imn',
    nationality: 'DE LA ISLA DE MAN',
  },
  {
    id: 162,
    name: 'Isla de Navidad',
    alpha2: 'cx',
    alpha3: 'cxr',
    nationality: 'NAVIDEÑA',
  },
  {
    id: 352,
    name: 'Islandia',
    alpha2: 'is',
    alpha3: 'isl',
    nationality: 'ISLANDÉSA',
  },
  {
    id: 136,
    name: 'Islas Caimán',
    alpha2: 'ky',
    alpha3: 'cym',
    nationality: 'CAIMANES',
  },
  {
    id: 166,
    name: 'Islas Cocos',
    alpha2: 'cc',
    alpha3: 'cck',
    nationality: 'COCANA',
  },
  {
    id: 184,
    name: 'Islas Cook',
    alpha2: 'ck',
    alpha3: 'cok',
    nationality: 'COOKIANA',
  },
  {
    id: 234,
    name: 'Islas Feroe',
    alpha2: 'fo',
    alpha3: 'fro',
    nationality: 'FEROESA',
  },
  {
    id: 239,
    name: 'Islas Georgias del Sur y Sandwich del Sur',
    alpha2: 'gs',
    alpha3: 'sgs',
    nationality: 'GEORGIA DEL SUR Y LAS ISLAS SANDWICH DEL SUR',
  },
  {
    id: 334,
    name: 'Islas Heard y McDonald',
    alpha2: 'hm',
    alpha3: 'hmd',
    nationality: 'HEARD Y MCDONALD, ISLAS',
  },
  {
    id: 238,
    name: 'Islas Malvinas',
    alpha2: 'fk',
    alpha3: 'flk',
    nationality: 'MALVINENSE',
  },
  {
    id: 580,
    name: 'Islas Marianas del Norte',
    alpha2: 'mp',
    alpha3: 'mnp',
    nationality: 'AMERICANO',
  },
  {
    id: 584,
    name: 'Islas Marshall',
    alpha2: 'mh',
    alpha3: 'mhl',
    nationality: 'MARSHALÉS',
  },
  {
    id: 612,
    name: 'Islas Pitcairn',
    alpha2: 'pn',
    alpha3: 'pcn',
    nationality: 'PITCAIRNESA',
  },
  {
    id: 90,
    name: 'Islas Salomón',
    alpha2: 'sb',
    alpha3: 'slb',
    nationality: 'SALOMONENSE',
  },
  {
    id: 796,
    name: 'Islas Turcas y Caicos',
    alpha2: 'tc',
    alpha3: 'tca',
    nationality: 'TURCOCAIQUEÑA',
  },
  {
    id: 581,
    name: 'Islas Ultramarinas Menores de los Estados Unidos',
    alpha2: 'um',
    alpha3: 'umi',
    nationality: 'AMERICANO',
  },
  {
    id: 92,
    name: 'Islas Vírgenes Británicas',
    alpha2: 'vg',
    alpha3: 'vgb',
    nationality: 'VIRGENENSE BRITANICA',
  },
  {
    id: 850,
    name: 'Islas Vírgenes de los Estados Unidos',
    alpha2: 'vi',
    alpha3: 'vir',
    nationality: 'VIRGENES, ISLAS',
  },
  {
    id: 376,
    name: 'Israel',
    alpha2: 'il',
    alpha3: 'isr',
    nationality: 'ISRAELÍ',
  },
  {
    id: 380,
    name: 'Italia',
    alpha2: 'it',
    alpha3: 'ita',
    nationality: 'ITALIANA',
  },
  {
    id: 388,
    name: 'Jamaica',
    alpha2: 'jm',
    alpha3: 'jam',
    nationality: 'JAMAICANA',
  },
  {
    id: 392,
    name: 'Japón',
    alpha2: 'jp',
    alpha3: 'jpn',
    nationality: 'JAPONESA',
  },
  {
    id: 832,
    name: 'Jersey',
    alpha2: 'je',
    alpha3: 'jey',
    nationality: 'ISLEÑO DEL CANAL',
  },
  {
    id: 400,
    name: 'Jordania',
    alpha2: 'jo',
    alpha3: 'jor',
    nationality: 'JORDANA',
  },
  {
    id: 398,
    name: 'Kazajistán',
    alpha2: 'kz',
    alpha3: 'kaz',
    nationality: 'KAZAJO',
  },
  {
    id: 404,
    name: 'Kenia',
    alpha2: 'ke',
    alpha3: 'ken',
    nationality: 'KENIANA',
  },
  {
    id: 417,
    name: 'Kirguistán',
    alpha2: 'kg',
    alpha3: 'kgz',
    nationality: 'KIRGUÍS',
  },
  {
    id: 296,
    name: 'Kiribati',
    alpha2: 'ki',
    alpha3: 'kir',
    nationality: 'KIRIBATIANA',
  },
  {
    id: 414,
    name: 'Kuwait',
    alpha2: 'kw',
    alpha3: 'kwt',
    nationality: 'KUWAITÍ',
  },
  {
    id: 418,
    name: 'Laos',
    alpha2: 'la',
    alpha3: 'lao',
    nationality: 'LAOSIANA',
  },
  {
    id: 426,
    name: 'Lesoto',
    alpha2: 'ls',
    alpha3: 'lso',
    nationality: 'LESOTENSE',
  },
  {
    id: 428,
    name: 'Letonia',
    alpha2: 'lv',
    alpha3: 'lva',
    nationality: 'LETONA',
  },
  {
    id: 422,
    name: 'Líbano',
    alpha2: 'lb',
    alpha3: 'lbn',
    nationality: 'LIBANÉSA',
  },
  {
    id: 430,
    name: 'Liberia',
    alpha2: 'lr',
    alpha3: 'lbr',
    nationality: 'LIBERIANA',
  },
  {
    id: 434,
    name: 'Libia',
    alpha2: 'ly',
    alpha3: 'lby',
    nationality: 'LIBIA',
  },
  {
    id: 438,
    name: 'Liechtenstein',
    alpha2: 'li',
    alpha3: 'lie',
    nationality: 'LIECHTENSTEINIANA',
  },
  {
    id: 440,
    name: 'Lituania',
    alpha2: 'lt',
    alpha3: 'ltu',
    nationality: 'LETONESA',
  },
  {
    id: 442,
    name: 'Luxemburgo',
    alpha2: 'lu',
    alpha3: 'lux',
    nationality: 'LUXEMBURGUÉSA',
  },
  {
    id: 446,
    name: 'Macao',
    alpha2: 'mo',
    alpha3: 'mac',
    nationality: 'MACAENSE',
  },
  {
    id: 807,
    name: 'Macedonia del Norte',
    alpha2: 'mk',
    alpha3: 'mkd',
    nationality: 'MACEDÓNIO',
  },
  {
    id: 450,
    name: 'Madagascar',
    alpha2: 'mg',
    alpha3: 'mdg',
    nationality: 'MALGACHE',
  },
  {
    id: 458,
    name: 'Malasia',
    alpha2: 'my',
    alpha3: 'mys',
    nationality: 'MALAYA',
  },
  {
    id: 454,
    name: 'Malaui',
    alpha2: 'mw',
    alpha3: 'mwi',
    nationality: 'MALAUÍ',
  },
  {
    id: 462,
    name: 'Maldivas',
    alpha2: 'mv',
    alpha3: 'mdv',
    nationality: 'MALDIVA',
  },
  {
    id: 466,
    name: 'Malí',
    alpha2: 'ml',
    alpha3: 'mli',
    nationality: 'MALI',
  },
  {
    id: 470,
    name: 'Malta',
    alpha2: 'mt',
    alpha3: 'mlt',
    nationality: 'MALTÉSA',
  },
  {
    id: 504,
    name: 'Marruecos',
    alpha2: 'ma',
    alpha3: 'mar',
    nationality: 'MARROQUÍ',
  },
  {
    id: 474,
    name: 'Martinica',
    alpha2: 'mq',
    alpha3: 'mtq',
    nationality: 'MARTINICA',
  },
  {
    id: 480,
    name: 'Mauricio',
    alpha2: 'mu',
    alpha3: 'mus',
    nationality: 'MAURICIANA',
  },
  {
    id: 478,
    name: 'Mauritania',
    alpha2: 'mr',
    alpha3: 'mrt',
    nationality: 'MAURITANA',
  },
  {
    id: 175,
    name: 'Mayotte',
    alpha2: 'yt',
    alpha3: 'myt',
    nationality: 'FRANCÉS',
  },
  {
    id: 484,
    name: 'México',
    alpha2: 'mx',
    alpha3: 'mex',
    nationality: 'MEXICANA',
  },
  {
    id: 583,
    name: 'Micronesia',
    alpha2: 'fm',
    alpha3: 'fsm',
    nationality: 'MICRONESIA',
  },
  {
    id: 498,
    name: 'Moldavia',
    alpha2: 'md',
    alpha3: 'mda',
    nationality: 'MOLDAVO',
  },
  {
    id: 492,
    name: 'Mónaco',
    alpha2: 'mc',
    alpha3: 'mco',
    nationality: 'MONEGASCA',
  },
  {
    id: 496,
    name: 'Mongolia',
    alpha2: 'mn',
    alpha3: 'mng',
    nationality: 'MONGOL',
  },
  {
    id: 499,
    name: 'Montenegro',
    alpha2: 'me',
    alpha3: 'mne',
    nationality: 'MONTENEGRINO',
  },
  {
    id: 500,
    name: 'Montserrat',
    alpha2: 'ms',
    alpha3: 'msr',
    nationality: 'MONSERRATINA',
  },
  {
    id: 508,
    name: 'Mozambique',
    alpha2: 'mz',
    alpha3: 'moz',
    nationality: 'MOZAMBIQUEÑA',
  },
  {
    id: 104,
    name: 'Birmania',
    alpha2: 'mm',
    alpha3: 'mmr',
    nationality: 'BIRMANA',
  },
  {
    id: 516,
    name: 'Namibia',
    alpha2: 'na',
    alpha3: 'nam',
    nationality: 'NAMIBIA',
  },
  {
    id: 520,
    name: 'Nauru',
    alpha2: 'nr',
    alpha3: 'nru',
    nationality: 'NAURUANA',
  },
  {
    id: 524,
    name: 'Nepal',
    alpha2: 'np',
    alpha3: 'npl',
    nationality: 'NEPALÉSA',
  },
  {
    id: 558,
    name: 'Nicaragua',
    alpha2: 'ni',
    alpha3: 'nic',
    nationality: 'NICARAGÜENSE',
  },
  {
    id: 562,
    name: 'Níger',
    alpha2: 'ne',
    alpha3: 'ner',
    nationality: 'NIGERINA',
  },
  {
    id: 566,
    name: 'Nigeria',
    alpha2: 'ng',
    alpha3: 'nga',
    nationality: 'NIGERIANA',
  },
  {
    id: 570,
    name: 'Niue',
    alpha2: 'nu',
    alpha3: 'niu',
    nationality: 'NIUEÑA',
  },
  {
    id: 574,
    name: 'Isla Norfolk',
    alpha2: 'nf',
    alpha3: 'nfk',
    nationality: 'NORFOLK, ISLA',
  },
  {
    id: 578,
    name: 'Noruega',
    alpha2: 'no',
    alpha3: 'nor',
    nationality: 'NORUEGA',
  },
  {
    id: 540,
    name: 'Nueva Caledonia',
    alpha2: 'nc',
    alpha3: 'ncl',
    nationality: 'NEOCALEDONIA',
  },
  {
    id: 554,
    name: 'Nueva Zelanda',
    alpha2: 'nz',
    alpha3: 'nzl',
    nationality: 'NEOZELANDÉSA',
  },
  {
    id: 512,
    name: 'Omán',
    alpha2: 'om',
    alpha3: 'omn',
    nationality: 'OMANÍ',
  },
  {
    id: 528,
    name: 'Países Bajos',
    alpha2: 'nl',
    alpha3: 'nld',
    nationality: 'HOLANDESA',
  },
  {
    id: 586,
    name: 'Pakistán',
    alpha2: 'pk',
    alpha3: 'pak',
    nationality: 'PAKISTANÍ',
  },
  {
    id: 585,
    name: 'Palaos',
    alpha2: 'pw',
    alpha3: 'plw',
    nationality: 'PALAU',
  },
  {
    id: 275,
    name: 'Palestina',
    alpha2: 'ps',
    alpha3: 'pse',
    nationality: 'PALESTINO',
  },
  {
    id: 591,
    name: 'Panamá',
    alpha2: 'pa',
    alpha3: 'pan',
    nationality: 'PANAMEÑO',
  },
  {
    id: 598,
    name: 'Papúa Nueva Guinea',
    alpha2: 'pg',
    alpha3: 'png',
    nationality: 'PAPÚA',
  },
  {
    id: 600,
    name: 'Paraguay',
    alpha2: 'py',
    alpha3: 'pry',
    nationality: 'PARAGUAYA',
  },
  {
    id: 604,
    name: 'Perú',
    alpha2: 'pe',
    alpha3: 'per',
    nationality: 'PERUANA',
  },
  {
    id: 258,
    name: 'Polinesia Francesa',
    alpha2: 'pf',
    alpha3: 'pyf',
    nationality: 'FRANCOPOLINESIA',
  },
  {
    id: 616,
    name: 'Polonia',
    alpha2: 'pl',
    alpha3: 'pol',
    nationality: 'POLACA',
  },
  {
    id: 620,
    name: 'Portugal',
    alpha2: 'pt',
    alpha3: 'prt',
    nationality: 'PORTUGUESA',
  },
  {
    id: 630,
    name: 'Puerto Rico',
    alpha2: 'pr',
    alpha3: 'pri',
    nationality: 'PUERTORRIQUEÑO',
  },
  {
    id: 826,
    name: 'Reino Unido',
    alpha2: 'gb',
    alpha3: 'gbr',
    nationality: 'BRITÁNICO',
  },
  {
    id: 732,
    name: 'República Árabe Saharaui Democrática',
    alpha2: 'eh',
    alpha3: 'esh',
    nationality: 'SAHARAUI',
  },
  {
    id: 140,
    name: 'República Centroafricana',
    alpha2: 'cf',
    alpha3: 'caf',
    nationality: 'CENTROAFRICANA',
  },
  {
    id: 203,
    name: 'República Checa',
    alpha2: 'cz',
    alpha3: 'cze',
    nationality: 'CHECO',
  },
  {
    id: 178,
    name: 'República del Congo',
    alpha2: 'cg',
    alpha3: 'cog',
    nationality: 'CONGOLEÑA',
  },
  {
    id: 180,
    name: 'República Democrática del Congo',
    alpha2: 'cd',
    alpha3: 'cod',
    nationality: 'ZAIREÑA',
  },
  {
    id: 214,
    name: 'República Dominicana',
    alpha2: 'do',
    alpha3: 'dom',
    nationality: 'DOMINICANA',
  },
  {
    id: 638,
    name: 'Reunión',
    alpha2: 're',
    alpha3: 'reu',
    nationality: 'REUNION',
  },
  {
    id: 646,
    name: 'Ruanda',
    alpha2: 'rw',
    alpha3: 'rwa',
    nationality: 'RUANDÉSA',
  },
  {
    id: 642,
    name: 'Rumania',
    alpha2: 'ro',
    alpha3: 'rou',
    nationality: 'RUMANA',
  },
  {
    id: 643,
    name: 'Rusia',
    alpha2: 'ru',
    alpha3: 'rus',
    nationality: 'RUSO',
  },
  {
    id: 882,
    name: 'Samoa',
    alpha2: 'ws',
    alpha3: 'wsm',
    nationality: 'SAMOANA',
  },
  {
    id: 16,
    name: 'Samoa Americana',
    alpha2: 'as',
    alpha3: 'asm',
    nationality: 'SAMOAMERICANA',
  },
  {
    id: 652,
    name: 'San Bartolomé',
    alpha2: 'bl',
    alpha3: 'blm',
    nationality: 'ISLA DE SAN BARTOLOMÉ',
  },
  {
    id: 659,
    name: 'San Cristóbal y Nieves',
    alpha2: 'kn',
    alpha3: 'kna',
    nationality: 'KITTIAN Y NEVISIAN',
  },
  {
    id: 674,
    name: 'San Marino',
    alpha2: 'sm',
    alpha3: 'smr',
    nationality: 'SANMARINES',
  },
  {
    id: 663,
    name: 'San Martín',
    alpha2: 'mf',
    alpha3: 'maf',
    nationality: 'ISLA DE SAN MARTÍN',
  },
  {
    id: 666,
    name: 'San Pedro y Miquelón',
    alpha2: 'pm',
    alpha3: 'spm',
    nationality: 'SANPEDRINA',
  },
  {
    id: 670,
    name: 'San Vicente y las Granadinas',
    alpha2: 'vc',
    alpha3: 'vct',
    nationality: 'SANVICENTINA',
  },
  {
    id: 654,
    name: 'Santa Elena, Ascensión y Tristán de Acuña',
    alpha2: 'sh',
    alpha3: 'shn',
    nationality: 'SANTAHELENA',
  },
  {
    id: 662,
    name: 'Santa Lucía',
    alpha2: 'lc',
    alpha3: 'lca',
    nationality: 'SANTALUCENSE',
  },
  {
    id: 678,
    name: 'Santo Tomé y Príncipe',
    alpha2: 'st',
    alpha3: 'stp',
    nationality: 'SANTOTOMENSE',
  },
  {
    id: 686,
    name: 'Senegal',
    alpha2: 'sn',
    alpha3: 'sen',
    nationality: 'SENEGALÉSA',
  },
  {
    id: 688,
    name: 'Serbia',
    alpha2: 'rs',
    alpha3: 'srb',
    nationality: 'SERBIO',
  },
  {
    id: 690,
    name: 'Seychelles',
    alpha2: 'sc',
    alpha3: 'syc',
    nationality: 'SEYCHELLENSA',
  },
  {
    id: 694,
    name: 'Sierra Leona',
    alpha2: 'sl',
    alpha3: 'sle',
    nationality: 'SIERRALEONÉSA',
  },
  {
    id: 702,
    name: 'Singapur',
    alpha2: 'sg',
    alpha3: 'sgp',
    nationality: 'SINGAPURENSA',
  },
  {
    id: 534,
    name: 'San Martín',
    alpha2: 'sx',
    alpha3: 'sxm',
    nationality: 'HOLANDÉS',
  },
  {
    id: 760,
    name: 'Siria',
    alpha2: 'sy',
    alpha3: 'syr',
    nationality: 'SIRIA',
  },
  {
    id: 706,
    name: 'Somalia',
    alpha2: 'so',
    alpha3: 'som',
    nationality: 'SOMALÍ',
  },
  {
    id: 144,
    name: 'Sri Lanka',
    alpha2: 'lk',
    alpha3: 'lka',
    nationality: 'CEILANÉSA',
  },
  {
    id: 748,
    name: 'Suazilandia',
    alpha2: 'sz',
    alpha3: 'swz',
    nationality: 'SUAZI',
  },
  {
    id: 710,
    name: 'Sudáfrica',
    alpha2: 'za',
    alpha3: 'zaf',
    nationality: 'SUDAFRICANA',
  },
  {
    id: 729,
    name: 'Sudán',
    alpha2: 'sd',
    alpha3: 'sdn',
    nationality: 'SUDANÉS',
  },
  {
    id: 728,
    name: 'Sudán del Sur',
    alpha2: 'ss',
    alpha3: 'ssd',
    nationality: 'SURSUDANESES',
  },
  {
    id: 752,
    name: 'Suecia',
    alpha2: 'se',
    alpha3: 'swe',
    nationality: 'SUECA',
  },
  {
    id: 756,
    name: 'Suiza',
    alpha2: 'ch',
    alpha3: 'che',
    nationality: 'SUIZA',
  },
  {
    id: 740,
    name: 'Surinam',
    alpha2: 'sr',
    alpha3: 'sur',
    nationality: 'SURINAMÉSA',
  },
  {
    id: 744,
    name: 'Svalbard y Jan Mayen',
    alpha2: 'sj',
    alpha3: 'sjm',
    nationality: 'SVALBARENSE',
  },
  {
    id: 764,
    name: 'Tailandia',
    alpha2: 'th',
    alpha3: 'tha',
    nationality: 'TAILANDESA',
  },
  {
    id: 158,
    name: 'Taiwán (República de China)',
    alpha2: 'tw',
    alpha3: 'twn',
    nationality: 'TAIWANESA',
  },
  {
    id: 834,
    name: 'Tanzania',
    alpha2: 'tz',
    alpha3: 'tza',
    nationality: 'TANZANA',
  },
  {
    id: 762,
    name: 'Tayikistán',
    alpha2: 'tj',
    alpha3: 'tjk',
    nationality: 'TADJIKISTAN',
  },
  {
    id: 86,
    name: 'Territorio Británico del Océano Índico',
    alpha2: 'io',
    alpha3: 'iot',
    nationality: 'INDICO, OCEANO (T. BRITANICO)',
  },
  {
    id: 260,
    name: 'Tierras Australes y Antárticas Francesas',
    alpha2: 'tf',
    alpha3: 'atf',
    nationality: 'FRANCÉS',
  },
  {
    id: 626,
    name: 'Timor Oriental',
    alpha2: 'tl',
    alpha3: 'tls',
    nationality: 'TIMORENSE',
  },
  {
    id: 768,
    name: 'Togo',
    alpha2: 'tg',
    alpha3: 'tgo',
    nationality: 'TOGOLÉSA',
  },
  {
    id: 772,
    name: 'Tokelau',
    alpha2: 'tk',
    alpha3: 'tkl',
    nationality: 'TOKELAUENSE',
  },
  {
    id: 776,
    name: 'Tonga',
    alpha2: 'to',
    alpha3: 'ton',
    nationality: 'TONGANA',
  },
  {
    id: 780,
    name: 'Trinidad y Tobago',
    alpha2: 'tt',
    alpha3: 'tto',
    nationality: 'TRINITENSA',
  },
  {
    id: 788,
    name: 'Túnez',
    alpha2: 'tn',
    alpha3: 'tun',
    nationality: 'TUNECINA',
  },
  {
    id: 795,
    name: 'Turkmenistán',
    alpha2: 'tm',
    alpha3: 'tkm',
    nationality: 'TURCOMANO',
  },
  {
    id: 792,
    name: 'Turquía',
    alpha2: 'tr',
    alpha3: 'tur',
    nationality: 'TURCA',
  },
  {
    id: 798,
    name: 'Tuvalu',
    alpha2: 'tv',
    alpha3: 'tuv',
    nationality: 'TUVALUANA',
  },
  {
    id: 804,
    name: 'Ucrania',
    alpha2: 'ua',
    alpha3: 'ukr',
    nationality: 'UCRANIANA',
  },
  {
    id: 800,
    name: 'Uganda',
    alpha2: 'ug',
    alpha3: 'uga',
    nationality: 'UGANDÉSA',
  },
  {
    id: 858,
    name: 'Uruguay',
    alpha2: 'uy',
    alpha3: 'ury',
    nationality: 'URUGUAYA',
  },
  {
    id: 860,
    name: 'Uzbekistán',
    alpha2: 'uz',
    alpha3: 'uzb',
    nationality: 'UZBEKISTAN',
  },
  {
    id: 548,
    name: 'Vanuatu',
    alpha2: 'vu',
    alpha3: 'vut',
    nationality: 'VANUATUENSA',
  },
  {
    id: 336,
    name: 'Vaticano, Ciudad del',
    alpha2: 'va',
    alpha3: 'vat',
    nationality: 'VATICANO',
  },
  {
    id: 862,
    name: 'Venezuela',
    alpha2: 've',
    alpha3: 'ven',
    nationality: 'VENEZOLANA',
  },
  {
    id: 704,
    name: 'Vietnam',
    alpha2: 'vn',
    alpha3: 'vnm',
    nationality: 'VIETNAMITA',
  },
  {
    id: 876,
    name: 'Wallis y Futuna',
    alpha2: 'wf',
    alpha3: 'wlf',
    nationality: 'WALISIANA',
  },
  {
    id: 887,
    name: 'Yemen',
    alpha2: 'ye',
    alpha3: 'yem',
    nationality: 'YEMENITA',
  },
  {
    id: 262,
    name: 'Yibuti',
    alpha2: 'dj',
    alpha3: 'dji',
    nationality: 'DJIBUTI',
  },
  {
    id: 894,
    name: 'Zambia',
    alpha2: 'zm',
    alpha3: 'zmb',
    nationality: 'ZAMBIANA',
  },
  {
    id: 716,
    name: 'Zimbabue',
    alpha2: 'zw',
    alpha3: 'zwe',
    nationality: 'ZIMBABUEN',
  },
];

export type CountryType = {
  code: string;
  name: string;
};

const allCountryOptions: CountryType[] = rawCountryCodes.map(
  ({ name, alpha2 }) => ({ name, code: alpha2 }) as CountryType
);

export const countryNameByCode = new Map<string, string>(
  rawCountryCodes.map(({ name, alpha2 }) => [alpha2, name])
);
const getCountryNameByCode = (code: string): string => countryNameByCode.get(code);

export { allCountryOptions, getCountryNameByCode };
