import React from 'react';
import { Box, Card } from '@mui/material';
import { container, transparentBox } from './style';

const Unauthorized = () => {
  return (
    <Card sx={container}>
      <Box sx={transparentBox}>
        <h1>Acceso no autorizado</h1>
      </Box>
    </Card>
  );
};

export default Unauthorized;
