import React, { useState, FC, useContext, useCallback, useEffect } from 'react';
import FilterCard from 'components/ui/cards/filterCard/FilterCard';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import TableRequest from 'components/ui/tables/tableRequest/TableRequest';
import noFound from 'assets/witi-nofound.svg';
import { RequestInterface } from 'components/models/RequestInterfaces';

import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageHeader from '../../ui/PageHeader';

interface Props {}

const ListRequest: FC<Props> = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [loading, setLoading] = useState(true);
  const [orderedRequest, setOrderedRequest] = useState<boolean>(true);
  const [filteredRequest] = useState(Array);
  const [filterEmpresa, setFilterEmpresa] = useState<string>(String);
  const [allRequest, setAllRequest] = useState<boolean>(true);
  const [filterEstado, setFilterEstado] = useState<string>(String);
  const [load, setLoad] = useState<boolean>(false);
  const [templateRequest, setTempateRequest] = useState<RequestInterface[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);

  useEffect(() => {
    getAxios
      .instance(access_token)
      .get('/templates/empresas')
      .then((data) => {
        setCompanies(data.data);
      });
  }, [access_token]);

  const generateFilterStructure = useCallback(() => {
    if (allRequest === true) {
      setLoading(true);
      getAxios
        .instance(access_token)
        .get('/solicitudes')
        .then((result: any) => result.data)
        .then((templatesData: RequestInterface[]) => {
          setTempateRequest(templatesData);
        })
        .then(() => {
          setLoad(true);
          setLoading(false);
        });
    }
  }, [access_token, allRequest]);

  const handleApplyFilters = useCallback(() => {
    const getFilteredRequests = (empresa: string, estado: string) => {
      if (allRequest === false) {
        setLoading(true);
        const urlBackend = `/solicitudes/filtro/?empresa=${empresa}&estado=${estado}`;
        getAxios
          .instance(access_token)
          .get(urlBackend)
          .then((result: any) => {
            setTempateRequest(result.data);
            setLoading(false);
          });
      }
    };

    getFilteredRequests(filterEmpresa, filterEstado);
  }, [filterEmpresa, filterEstado, access_token, allRequest]);

  useEffect(() => {
    if (load) {
      handleApplyFilters();
    }
  }, [handleApplyFilters, load]);

  useEffect(() => {
    generateFilterStructure();
  }, [generateFilterStructure]);

  useEffect(() => {
    templateRequest.sort((c1: any, c2: any) => {
      return new Date(c1['fechaInicio']) < new Date(c2['fechaInicio']) ? 1 : -1;
    });
  }, [templateRequest]);

  const getSortedRequestByParamenter = (key: string) => {
    if (key === 'Empresa') {
      if (!orderedRequest) {
        templateRequest.sort((c1: any, c2: any) => {
          return c1['empresa'].toUpperCase() > c2['empresa'].toUpperCase() ? 1 : -1;
        });
      } else {
        templateRequest.sort((c1: any, c2: any) => {
          return c1['empresa'].toUpperCase() < c2['empresa'].toUpperCase() ? 1 : -1;
        });
      }
    }
    if (key === 'Fecha Inicio') {
      if (!orderedRequest) {
        templateRequest.sort((c1: any, c2: any) => {
          return new Date(c1['fechaInicio']) > new Date(c2['fechaInicio']) ? 1 : -1;
        });
      } else {
        templateRequest.sort((c1: any, c2: any) => {
          return new Date(c1['fechaInicio']) < new Date(c2['fechaInicio']) ? 1 : -1;
        });
      }
    }
    if (key === 'Fecha Estimada Fin') {
      if (!orderedRequest) {
        templateRequest.sort((c1: any, c2: any) => {
          return new Date(c1['fechaEstimadaFin']) > new Date(c2['fechaEstimadaFin']) ? 1 : -1;
        });
      } else {
        templateRequest.sort((c1: any, c2: any) => {
          return new Date(c1['fechaEstimadaFin']) < new Date(c2['fechaEstimadaFin']) ? 1 : -1;
        });
      }
    }
    if (key === 'Estado') {
      if (!orderedRequest) {
        templateRequest.sort((c1: any, c2: any) => {
          return c1['estado'] > c2['estado'] ? 1 : -1;
        });
      } else {
        templateRequest.sort((c1: any, c2: any) => {
          return c1['estado'] < c2['estado'] ? 1 : -1;
        });
      }
    }
    if (key === 'timestamp') {
      if (!orderedRequest) {
        filteredRequest.sort((c1: any, c2: any) => {
          return c1[key] > c2[key] ? 1 : -1;
        });
      } else {
        filteredRequest.sort((c1: any, c2: any) => {
          return c1[key] < c2[key] ? 1 : -1;
        });
      }
    }

    setOrderedRequest(!orderedRequest);
  };

  const handleCheckboxFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;

    companies.forEach((company: string) => {
      if (checked && id === company.toLowerCase()) {
        setFilterEmpresa(company);
        setAllRequest(false);
        localStorage.setItem('filterEmpresa', company.toLowerCase());
      }
    });

    if (checked && id === 'otra') {
      setFilterEmpresa('Otra');
      setAllRequest(false);
      localStorage.setItem('filterEmpresa', 'otra');
    }

    if (checked && id === 'abierto') {
      setFilterEstado('Abierto');
      setAllRequest(false);
      localStorage.setItem('filterEstado', 'abierto');
    } else if (checked && id === 'cerrado ok') {
      setFilterEstado('Cerrado OK');
      setAllRequest(false);
      localStorage.setItem('filterEstado', 'cerrado ok');
    } else if (checked && id === 'cerrado no ok') {
      setFilterEstado('Cerrado no OK');
      setAllRequest(false);
      localStorage.setItem('filterEstado', 'cerrado no ok');
    }

    if (checked && id === 'Todos') {
      setFilterEmpresa('');
      setFilterEstado('');
      setAllRequest(true);
    }
  };

  const tableContent = () => {
    return templateRequest.map((solicitud: any) => {
      return {
        rol: {
          data: solicitud.rol,
          href: `/request/${solicitud.id}/editar`,
        },
        empresa: {
          data: solicitud.empresa,
          href: `/request/${solicitud.id}/editar`,
        },
        nivel: {
          data: solicitud.nivel,
          href: `/request/${solicitud.id}/editar`,
        },
        estado: {
          data: solicitud.estado,
          href: `/request/${solicitud.id}/editar`,
        },
        fechaInicio: {
          data: solicitud.fechaInicio,
          href: `/request/${solicitud.id}/editar`,
        },
        plazo: {
          data: solicitud.plazo,
          href: `/request/${solicitud.id}/editar`,
        },
        stack: {
          data: solicitud.stack,
          href: `/request/${solicitud.id}/editar`,
        },
        fechaFin: {
          data: solicitud.fechaEstimadaFin,
          href: `/request/${solicitud.id}/editar`,
        },
        clonar: {
          id: solicitud.id,
        },
      };
    });
  };

  return (
    <Box>
      <Box mb={2}>
        <PageHeader>Solicitudes</PageHeader>
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FilterCard>
                <FormGroup>
                  <h6>Filtro por Empresa</h6>
                  {companies &&
                    companies.map((company: string, idx: number) => {
                      return (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              onChange={handleCheckboxFilters}
                              id={company.toLowerCase()}
                              name='empresa'
                              value={filterEmpresa}
                              checked={filterEmpresa === company}
                            />
                          }
                          label={company}
                        />
                      );
                    })}

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxFilters}
                        id='otra'
                        name='Empresa'
                        value={filterEmpresa}
                        checked={filterEmpresa === 'Otra'}
                      />
                    }
                    label='Otra'
                  />
                </FormGroup>
                <FormGroup>
                  <h6>Filtro por Estado</h6>

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxFilters}
                        id='cerrado ok'
                        name='Estado'
                        value={filterEstado}
                        checked={filterEstado === 'Cerrado OK'}
                      />
                    }
                    label='Cerrado Ok'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxFilters}
                        id='cerrado no ok'
                        name='Estado'
                        value={filterEstado}
                        checked={filterEstado === 'Cerrado no OK'}
                      />
                    }
                    label='Cerrado no OK'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxFilters}
                        id='abierto'
                        name='Estado'
                        value={filterEstado}
                        checked={filterEstado === 'Abierto'}
                      />
                    }
                    label='Abierto'
                  />
                </FormGroup>
                <FormGroup>
                  <h6>Filtro por Todos</h6>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxFilters}
                        id='Todos'
                        name='Todos'
                        value='Todos'
                        checked={allRequest}
                      />
                    }
                    label='Todos'
                  />
                </FormGroup>
              </FilterCard>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <TableRequest
        contents={tableContent()}
        loading={loading}
        {...{ getSortedRequestByParamenter, orderedRequest }}
      />
      {templateRequest.length === 0 && loading === false && (
        <div
          style={{
            width: '100%',
            height: '55vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={noFound}
            alt='No se encuentra la informacion'
            style={{ width: 250, opacity: 0.7 }}
          />
        </div>
      )}
    </Box>
  );
};

export default ListRequest;
