import React, { FC, useEffect } from 'react';
import Radar from 'components/ui/graphics/radar/Radar';
import { innerSkillsInterface, SkillsInterface } from 'components/models/SkillsInterfaces';
import { StyledDetailsTitleSubskillsContainer } from './style';

export interface Props {
  skills?: SkillsInterface[];
}

export const SkillsComponent: FC<Props> = ({ skills }) => {
  const getSkillsNames = (sks: innerSkillsInterface[]) => {
    const sk = sks.filter(
      (skill: innerSkillsInterface) => skill.estado === undefined || skill.estado === true
    );

    return sk.map((skill: innerSkillsInterface) => skill.nombre);
  };
  const getSkillsNotes = (sks: innerSkillsInterface[]) => {
    const sk = sks.filter(
      (skill: innerSkillsInterface) => skill.estado === undefined || skill.estado === true
    );
    return sk.map((skill: innerSkillsInterface) => skill.nota);
  };

  useEffect(() => {
    return () => document.getElementById('chartjs-tooltip')?.remove();
  }, []);

  return (
    <StyledDetailsTitleSubskillsContainer>
      {skills &&
        skills.map(({ nombre, skills: subSkills, tipo, estado }: SkillsInterface, i: number) => {
          return (
            <Radar
              key={`$radarSkill${nombre + 1}`}
              tipo={tipo}
              name={nombre}
              skillsName={getSkillsNames(subSkills)}
              scores={getSkillsNotes(subSkills)}
              reference={i + 1}
              skills={subSkills.filter(
                (sks: innerSkillsInterface) => sks.estado === undefined || sks.estado === true
              )}
              radarGraphCuantity={skills.length}
            />
          );
        })}
    </StyledDetailsTitleSubskillsContainer>
  );
};
