import styled from '@emotion/styled/macro';

export const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #31cff2;
  height: 40px;
  width: 95%;
  border-radius: 10px;

  &:hover {
    background-color: rgb(35, 35, 35);
    color: white;
    border-color: #31cff2;
  }
`;

export const InputName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;
export const FormContainer = styled.div`
  background: rgba(255, 255, 255, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(6px);
  border-radius: 16px;
`;
export const MainContainer = styled.div`
  #important {
    color: red;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  color: white;
  line-height: 1;
  font-style: normal;
  font-weight: 400;
  background-position: center;

  @media screen and (min-width: 1024px) {
    #titulo {
      font-size: 40px;
    }
    #subtitulo {
      font-size: 30px;
    }
    p {
      font-size: 25px;
    }
    input {
      font-size: 25px;
    }
    .details {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1024px) {
    #titulo {
      font-size: 35px;
    }
    #subtitulo {
      font-size: 25px;
    }
    p {
      font-size: 20px;
    }
    input {
      font-size: 20px;
    }
    .details {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 750px) {
    #titulo {
      font-size: 25px;
    }
    #subtitulo {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }
    .details {
      font-size: 9px;
    }
  }

  ${FormContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    padding: 40px;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 20px;
    }

    input {
      text-align: center;
      width: 80%;
      color: white;
      border: 1px solid #ccc;
      border-radius: 3px;
      background-color: rgb(0, 0, 0, 0);
      border-bottom: 1px solid #ffffff;
      box-sizing: border-box;
      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
    input.bigcheckbox {
      width: 20px;
      height: 20px;
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3vw;
      width: 70%;

      background: rgba(0, 0, 0, 0.5);
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: rgba(49, 207, 242, 0.32);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(6px);
      border-radius: 16px;
    }
  }
`;
