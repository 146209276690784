import React, { FC } from 'react';
import { Box, Tooltip, Typography, Switch } from '@mui/material';
import ROL from 'utils/Roles';

type TswitchProps = {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  setSwitchIsActive: (value: boolean) => void;
  userRol: string;
};

export const MenteeActiveSwitch: FC<TswitchProps> = ({
  isActive,
  setIsActive,
  setSwitchIsActive,
  userRol,
}) => {
  return (
    <>
      {(userRol === ROL.ADMINISTRADOR || userRol === ROL.KAM) && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            top: 0,
            right: 16,
            alignItems: 'center',
          }}
        >
          <Tooltip title='Cambiar estado de un empleado, este proceso se guardará automaticamente'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 1 }}>
              <Typography sx={{ mt: 1, fontWeight: 'bold' }}>Inactivo</Typography>
              <Switch
                checked={isActive}
                onChange={(event) => {
                  setIsActive(event.target.checked);
                  setSwitchIsActive(true);
                }}
              />
              <Typography sx={{ mt: 1, fontWeight: 'bold' }}>Activo</Typography>
            </Box>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
