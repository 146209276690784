import React, { FC } from 'react';
import { Box, Card, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ISkillsDimensions, ISkillsDimensionsRespuestas } from 'components/models/SkillsInterfaces';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';
import { DivGlowEffect } from './style';

type FormProps = {
  tabActive: string;
  tabController: string;
  interviewDetails: CandidateInterface2;
  primaryControllerName: string;
  idMentee: string;
  type: string;
  glowingComponent: string;
  makeGlowingAnimation: boolean;
};

type GlowingRadioGroupProps = {
  setValue: (
    name: string,
    value: any,
    options?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
  ) => void;
  getValues: (name?: string | string[]) => string | undefined;
  sendSkillsSocket: (skillIndex: number, dimensionIndex: number, newValue: string) => void;
  skillIndex: number;
  dimensionIndex: number;
  skillDimensions: ISkillsDimensions;
  control: Control<FormProps>;
  RadioGroupRules: object;
  socketConnected: boolean;
  setFormIsDirty: (isDirty: boolean) => void;
  watch: (value: string) => any;
};

export const GlowingRadioGroup: FC<GlowingRadioGroupProps> = ({
  setFormIsDirty,
  setValue,
  getValues,
  control,
  sendSkillsSocket,
  RadioGroupRules,
  skillDimensions,
  skillIndex,
  dimensionIndex,
  socketConnected,
  watch,
}) => {
  const glowingComponent = watch(`glow-${skillIndex}-${dimensionIndex}`);

  const glowingClassName = glowingComponent === `on` ? 'glow' : '';

  const handleClick = () => {
    if (socketConnected) {
      setValue(`glow-${skillIndex}-${dimensionIndex}`, `on`);
      sendSkillsSocket(
        skillIndex,
        dimensionIndex,
        getValues(`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`) ?? ''
      );
      setTimeout(() => setValue(`glow-${skillIndex}-${dimensionIndex}`, ''), 1000);
    }
  };
  const radioGroupValue = watch(
    `interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`
  );

  return (
    <DivGlowEffect className={glowingClassName}>
      <Card variant='outlined' sx={{ padding: '12px' }}>
        <Box key={'box2' + skillIndex + '-' + dimensionIndex}>
          <Card sx={{ paddingTop: '10px', paddingLeft: '10px' }}>
            <Typography variant='h6' gutterBottom>
              {skillDimensions.nombre}
            </Typography>
          </Card>
          <br />
          <Typography variant='body1' gutterBottom>
            {skillDimensions.description}
          </Typography>
        </Box>
        <Controller
          control={control}
          name={`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.nombre`}
          rules={RadioGroupRules}
          render={({ field: { onChange } }) => {
            return (
              <RadioGroup
                key={'radioGroup' + skillIndex + '-' + dimensionIndex}
                row
                value={radioGroupValue ?? ''}
                onChange={(e) => {
                  setValue(
                    `interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}.valor`,
                    e.target.value
                  );
                  setFormIsDirty(true);
                  handleClick();
                }}
                name={`interviewDetails.skills.${skillIndex}.dimensiones.${dimensionIndex}`}
              >
                {skillDimensions.respuestas.map(
                  (rp: ISkillsDimensionsRespuestas, respuestaIndex) => {
                    return (
                      <Box
                        key={`box2${skillIndex}-${dimensionIndex}-${respuestaIndex}`}
                        sx={{ width: '100%' }}
                      >
                        <FormControlLabel
                          key={`formControlLabel${skillIndex}-${dimensionIndex}-${respuestaIndex}`}
                          // value={rp.puntaje}
                          value={rp.nombre}
                          control={<Radio />}
                          label={
                            <Typography variant='body2' gutterBottom>
                              {rp.nombre}
                            </Typography>
                          }
                          labelPlacement='end'
                        />
                      </Box>
                    );
                  }
                )}
              </RadioGroup>
            );
          }}
        />
      </Card>
    </DivGlowEffect>
  );
};
