import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { flagFunction } from 'helpers/flags';

import Telephone from 'assets/phone.png';
import Wapp from 'assets/wapp.png';
import SwalAlert from '../alerts/SwalAlert';

const whatsappMessage = (name: string) => {
  const myMsg = `Hola ${name}, cómo estás?`;
  return myMsg.replace(/ /g, '%20');
};

const generateHtml = (telefono: string, countryCodeIso2: string, whatsappGreeting: string) => `
  <h5 style="display: flex; justify-content: center; align-items: center; gap: 8px">
    <img
      style="width: 30px"
      src="https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCodeIso2}.svg"
      alt="País"
    />
    ${parsePhoneNumberFromString(`+${telefono}`).formatInternational()}
  </h5> 
  <div>
      <button class="btn btn-whatsapp">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=+${telefono}&text=${whatsappGreeting}">
              <img src="${Wapp}" alt=""/>
          </a>
      </button>
      <button class="btn btn-call">
          <a href="tel:+${telefono}">
              <img src="${Telephone}" alt=""/>
          </a>
      </button>
  </div>               
`;

function phoneTableClick(nombre: string, telefono: string) {
  const whatsappGreeting = whatsappMessage(nombre);
  const countryCodeIso2 = flagFunction(telefono.toString());

  try {
    SwalAlert({
      title: '¿Mensaje o Llamada?',
      cancelButtonText: 'Cerrar',
      showConfirmButton: false,
      showCancelButton: true,
      html: generateHtml(telefono, countryCodeIso2, whatsappGreeting),
    });
  } catch (error) {
    console.error('Error al abrir el cuadro de diálogo', error);
  }

  return '';
}

export { phoneTableClick };
