import React from 'react';
import { Stack, Typography } from '@mui/material';
import TextEditor from '../../../ui/textEditor';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import { SummaryInterface } from 'components/models/SummaryInterfaces';

interface Props {
  dataCandidate: CandidateInterface;
  onChange: (footer: SummaryInterface[] | undefined) => void;
}

const SummaryComponent: React.FC<Props> = ({ dataCandidate, onChange }) => {
  const handleOnChange = (name: string) => (value: string) => {
    const newFooter = dataCandidate.footer?.map((data: SummaryInterface) =>
      name === data.nombre ? { nombre: data.nombre, valor: value } : data
    );
    onChange(newFooter);
  };

  return (
    <Stack spacing={3}>
      {dataCandidate.footer?.map((item, index) => (
        <Stack spacing={1} key={index}>
          <Typography component='p' variant='h6' gutterBottom>
            {item.nombre}
          </Typography>
          <TextEditor
            value={item.valor as string}
            onChange={handleOnChange(item.nombre)}
            placeholder={item.nombre}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default SummaryComponent;
