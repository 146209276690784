import { UpdateRequestInterface } from 'components/models/RequestInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { ProfileCircle, RecruitersWrapper } from './style';
import { Box, Button } from '@mui/material';
import SwalAlert from 'components/ui/alerts/SwalAlert';
import Swal from 'sweetalert2';

export default function AssociatedRecruiters(props: any) {
  const [user, setUser] = useState<string>('');
  const [recruiterArray, setRecruiterArray] = useState<string[]>([]);
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    let userName = typeof localUser === 'string' && JSON.parse(localUser);
    setUser(userName.name);
    setRecruiterArray(props.recruiters || []);
  }, [props.recruiters]);

  const updateRecruiterArray = async (e: any) => {
    let updatedBody = props.body;
    let updatedRecruiters;

    if (updatedBody.element.reclutadores) {
      if (updatedBody.element.reclutadores.includes(user)) {
        updatedRecruiters = updatedBody.element.reclutadores;
      } else {
        updatedRecruiters = [...updatedBody.element.reclutadores, user];
      }
    } else {
      updatedRecruiters = [user];
    }

    const object: UpdateRequestInterface = {
      descripcion: updatedBody.element.descripcion, // modificar esto despues para producción
      empresa: updatedBody.element.empresa, // modificar esto despues para producción
      estado: updatedBody.element.estado,
      fechaEstimadaFin: updatedBody.element.fechaEstimadaFin,
      fechaFin: updatedBody.element.fechaFin,
      fechaInicio: updatedBody.element.fechaInicio,
      id: updatedBody.element.id,
      nivel: updatedBody.element.nivel,
      modalidad: updatedBody.element.modalidad,
      plazo: updatedBody.element.plazo,
      prioridad: updatedBody.prioridad,
      rol: updatedBody.element.rol,
      salario: updatedBody.element.salario,
      responsable: updatedBody.responsable,
      equipo: updatedBody.element.equipo,
      solicitante: updatedBody.element.solicitante,
      stack: updatedBody.element.stack,
      reclutadores: updatedRecruiters,
      managerEmail: updatedBody.element.managerEmail,
      responsibleEmail: updatedBody.element.responsibleEmail,
      responsiblePhone: updatedBody.element.responsiblePhone,
    };
    if (object !== undefined) {
      SwalAlert({
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#cc4846',
        confirmButtonText: '¿Deseas asociarte a esta solicitud?',
        showLoaderOnConfirm: true,
        showConfirmButton: true,
        preConfirm: () => {
          return fetch(`${REACT_APP_BACKEND_URL}/solicitudes/${object.id}`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(object),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          SwalAlert({
            title: `Te asociaste exitosamente`,
            showConfirmButton: true,
          });
          if (recruiterArray) {
            if (recruiterArray.length > 0) {
              setRecruiterArray((prevVal) => [...prevVal, user]);
            } else {
              setRecruiterArray([user]);
            }
          }
        }
      });
    }
  };

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <p>Reclutadores</p>

      {recruiterArray && !recruiterArray.includes(user) && (
        <Button variant='contained' onClick={updateRecruiterArray}>
          Asociarme
        </Button>
      )}

      <RecruitersWrapper>
        {recruiterArray &&
          recruiterArray.map((recruiter: any, index: number) => {
            let separatedName = recruiter.split(' ');
            let firstLetters = '';
            let counter = 0;
            separatedName.forEach((name: string) => {
              if (counter < 2) {
                firstLetters += name[0];
              }
              counter++;
            });
            return (
              <ProfileCircle key={index} title={recruiter}>
                {firstLetters}
              </ProfileCircle>
            );
          })}
      </RecruitersWrapper>
    </Box>
  );
}
