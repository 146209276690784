import styled from '@emotion/styled/macro';
import { styled as style } from '@mui/material';

export const StyledGlossaryContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px',
  marginTop: '64px',
});

export const StyledGlossaryTitle = style('p')({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#FECB16',
});

export const StyledGlossarySkillsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flexStart',
  justifyContent: 'center',
  padding: '32px',
  width: '100%',
  margin: '12px',
  background: 'rgba(55, 80, 108, 0.12)',
  borderWidth: '1px 0 0 1px',
  borderStyle: 'solid',
  borderColor: 'rgba(49, 207, 242, 0.32)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  cursor: 'pointer',
  gap: '32px',
});

export const StyledGlossarySkillsName = style('div')({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  display: 'flex',
  color: '#FECB16',
});

export const StyledSkillsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  minWidth: '100%',
  gap: '20px',
});

export const StyledGlossarySubskillsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '49%',
  minWidth: '49%',
  paddingTop: '12px',
  background: 'rgba(55, 80, 108, 0.24)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  margin: '0 auto',

  '@media(max-width:1103px)': {
    maxWidth: '489px',
    minWidth: '489px',
  },

  '@media(max-width:550px)': {
    maxWidth: '100%',
    minWidth: '100%',
  },
});

export const StyledGlossarySkillContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;
  min-width: 100%;
  gap: 20px;
  padding: 20px;
`;

export const StyledGlossarySubskillsTitle = styled('p')`
  margin: 0 auto;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

export const StyledGlossarySubskillCardContainer = styled('div')`
  display: flex;
  flex-direction: column;

  padding: 20px;
  background: #0f171e;
  border-radius: 8px;
  flex: 1;
  min-width: 99%;
  gap: 24px;
`;

export const StyledGlossarySubtitle = styled('div')`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;

  color: #fecb16;
`;

export const StyledGlossaryQuestion = styled('div')`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;

  color: #637daf;
`;

export const StyledGlossaryButtonTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const StyledGlossaryButton = styled('div')`
  display: inline-block;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: #80a2dd;
  border-radius: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0f171e;
  flex: 1;
`;

export const StyledGlossaryAnswerDescription = styled('div')`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
`;

export const StyledGlossaryAnswerContainer = styled('div')({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
});
