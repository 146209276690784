import React, { FC } from 'react';
import Loading from 'components/ui/loading/Loading';
import { StyledTableDesktop, StyledTableMobile } from './style';
import TableCardRowRequest from '../tableCardRow/TableCardRowRequest';
import TableRowRequest from '../tableRow/TableRowRequest';
import TableHeadRequest from '../tableHead/TableHeadRequest';
import TableHeadRowRequest from '../tableHeadRow/TableHeadRowRequest';
import Spinner from 'components/ui/spinner/Spinner';

interface Props {
  contents: any;
  loading?: boolean;
  getSortedRequestByParamenter: (key: string) => void;
  orderedRequest: boolean;
}

const TableRequest: FC<Props> = ({
  contents,
  loading = false,
  getSortedRequestByParamenter,
  orderedRequest,
}) => {
  return (
    <>
      <StyledTableDesktop responsive>
        <thead>
          <TableHeadRequest {...{ getSortedRequestByParamenter, orderedRequest }} />
        </thead>
        {!loading && (
          <tbody>
            <TableRowRequest {...{ contents }} />
          </tbody>
        )}
      </StyledTableDesktop>

      <StyledTableMobile>
        <TableHeadRowRequest {...{ getSortedRequestByParamenter, orderedRequest }} />
        <TableCardRowRequest {...{ contents }} />
      </StyledTableMobile>
      {loading && (
        <div className='d-flex justify-content-center'>
          <Loading>
            <Spinner color='primary' />
          </Loading>
        </div>
      )}
    </>
  );
};
export default TableRequest;
