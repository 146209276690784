import React, { FC } from 'react';
import { StyledDiv } from './style';

interface Props {
  children: any;
}

const SectionCard: FC<Props> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};

export default SectionCard;
