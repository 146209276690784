import React from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import TeamFormImage from '../teamFormImage/TeamFormImage';
import Master from 'assets/master.png';
import type { TeamFormStepProps } from '../../CreateTeam.types';

const empresaOptions = [
  'Witi',
  'Cencosud',
  'Alsea',
  'Meds',
  'Colmena',
  'Ludens',
  'Talentti',
  'Xintec',
  'Falabella',
  'Vida Cámara',
];

const TeamFormStepClient = ({ teamInfo, setTeamInfo }: TeamFormStepProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TeamFormImage imageUrl={Master} />
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ mt: '20px', mb: '10px' }}>
          <Typography variant='h4' gutterBottom>
            Cliente
          </Typography>
          <Typography>
            Identifica la empresa para la cual el equipo de desarrollo estará trabajando.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Autocomplete
            disablePortal
            options={empresaOptions}
            value={teamInfo.empresa}
            onChange={(event, newInputValue) => {
              setTeamInfo({ ...teamInfo, empresa: newInputValue });
            }}
            sx={{ width: '80%', mb: '10px', mt: '30px' }}
            renderInput={(params) => <TextField {...params} label='Empresa' />}
          />
          <TextField
            label='Nombre del manager'
            sx={{ width: '80%', mb: '10px', mt: '20px' }}
            value={teamInfo.nombre_manager}
            onChange={(e) => setTeamInfo({ ...teamInfo, nombre_manager: e.target.value })}
          />
          <TextField
            label='Email del manager'
            type='email'
            sx={{ width: '80%', mb: '10px', mt: '20px' }}
            value={teamInfo.email_manager}
            onChange={(e) => setTeamInfo({ ...teamInfo, email_manager: e.target.value })}
          />
          <TextField
            label='Area de la empresa'
            sx={{ width: '80%', mb: '10px', mt: '20px' }}
            value={teamInfo.area_empresa}
            onChange={(e) => setTeamInfo({ ...teamInfo, area_empresa: e.target.value })}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TeamFormStepClient;
