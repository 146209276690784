import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { Stack, Container, Box } from '@mui/material';
import { GlowingSaveButton } from 'components/ui/skillForm/GlowingSaveButton';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import MenteeProfile from 'components/ui/MenteeView/MenteeProfile';
import SkillsForm from '../newCandidate/skillsComponent/SkillsForm';
import FullscreenLoading from '../../ui/loading/FullscreenLoading';
import FullscreenAlert from '../../ui/alerts/FullscreenAlert';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';
import jsonTemplate from './utils/teamLeaderInterview.json';
import { socket } from 'services/socketIO/socketCliente';
import { useSocketConnection } from '../../../services/socketIO/useSocketConnection';
import { useSocketSubcribeEvents } from '../../../hooks/useMenteeInterviewSocketSubcribeEvents';
import useBeforeUnload from 'hooks/useBeforeUnload';
import SwalAlert from 'components/ui/alerts/SwalAlert';

type SkillsFormProps = {
  tabActive: string;
  tabController: string;
  interviewDetails: CandidateInterface2;
  primaryControllerName: string;
  idMentee: string;
  type: string;
  glowingComponent: string;
  makeGlowingAnimation: boolean;
};
type ReactHookFormObjects = {
  textAreaRules: object;
  RadioGroupRules: object;
  setFormIsDirty: (isDirty: boolean) => void;
};

const ErrorAlert = () => (
  <FullscreenAlert severity='error' alertTitle='Error'>
    No existen datos asociados a este perfil.
  </FullscreenAlert>
);

const Kam = () => {
  const setShouldConfirmExit = useBeforeUnload();
  const { idMentee } = useParams<{ idMentee: string }>();
  const [interviewId, setInterviewId] = useState('');
  const [menteeName, setMenteeName] = useState<string>('');
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  //const [loading, setLoading] = useState<boolean>(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  useEffect(() => {
    setShouldConfirmExit(formIsDirty);
  }, [formIsDirty, setShouldConfirmExit]);

  const idKAM = storeAuth?.user?._id;

  const url = `/employee/${menteeName}`;

  const {
    data: mentee,
    isPending,
    isError,
  } = useQuery({
    queryKey: [url, access_token],
  });

  const reactHookFormMethods = useForm<SkillsFormProps>({
    defaultValues: {
      tabActive: 'tab-0',
      interviewDetails: structuredClone(jsonTemplate),
      primaryControllerName: 'primaryControllerName',
      idMentee: idMentee,
      type: 'KAM',
      glowingComponent: '',
      makeGlowingAnimation: false,
    },
    mode: 'onChange',
  });
  const reactHookFormObjects: ReactHookFormObjects = {
    textAreaRules: {},
    RadioGroupRules: {},
    setFormIsDirty,
  };
  useSocketConnection(socket);
  useSocketSubcribeEvents(socket, reactHookFormMethods, idMentee, 'KAM');

  useEffect(() => {
    getAxios
      .instance(access_token)
      .get(`/interview/kam/mentee/${idMentee}`)
      .then((response) => {
        if (response.status === 200 && response.data !== '') {
          reactHookFormMethods.setValue('interviewDetails.skills', response.data.skills);
          setInterviewId(response.data._id);
        }
      })
      .finally(() => {
        if (socket.connected)
          socket.emit('joinRoom', {
            interviewed_id: idMentee,
            type: reactHookFormMethods.getValues('type'),
          });
      });
  }, [access_token, idMentee, reactHookFormMethods]);

  const saveInterview: SubmitHandler<SkillsFormProps> = ({ interviewDetails }) => {
    if (interviewDetails) {
      //setLoading(true);

      const newInterview = {
        skills: interviewDetails.skills,
        type: 'KAM',
        interviewer_id: idKAM,
        interviewed_id: idMentee,
        mentor: false,
      };
      if (interviewId) {
        setFormIsDirty(false);
        getAxios
          .instance(access_token)
          .patch(`/interview/${interviewId}`, newInterview)
          .then((response) => {
            SwalAlert({
              text: 'La entrevista se ha guardado con éxito',
              icon: 'success',
              confirmButtonText: 'OK',
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          });
      } else {
        setFormIsDirty(false);
        getAxios
          .instance(access_token)
          .post('/interview', newInterview)
          .then((response: any) => {
            if (response.status === 201) {
              SwalAlert({
                text: 'La entrevista se ha guardado con éxito',
                icon: 'success',
                confirmButtonText: 'OK',
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setFormIsDirty(true);
              SwalAlert({
                title: 'Error',
                text: 'Ha ocurrido un error la guardar',
                icon: 'error',
                confirmButtonText: 'OK',
                showConfirmButton: true,
              });
            }
            //setLoading(false);
          })
          .catch((error) => {
            setFormIsDirty(true);
            SwalAlert({
              title: 'Error',
              text: error.message,
              icon: 'error',
              confirmButtonText: 'OK',
              showConfirmButton: true,
            });
            // setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (idMentee) {
      setMenteeName(idMentee);
    }
  }, [idMentee]);

  if (isPending) return <FullscreenLoading />;

  if (isError) return <ErrorAlert />;
  return (
    <Container maxWidth='lg' disableGutters>
      <Stack justifyContent='center' alignItems='center' pb={5}>
        {idMentee && (
          <MenteeProfile
            idMentee={idMentee}
            mentee={mentee}
            options={{ component: 'KamView' }}
            typeOfInterview='KAM'
            currentMenteeUri={`/kam/${idMentee}`}
            interviewDetails={reactHookFormMethods.getValues('interviewDetails')}
          />
        )}
        <FormProvider {...reactHookFormMethods}>
          <form onSubmit={reactHookFormMethods.handleSubmit(saveInterview)}>
            <SkillsForm {...reactHookFormObjects} />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 6,
              }}
            >
              <GlowingSaveButton isDirty={formIsDirty} />
            </Box>
          </form>
        </FormProvider>
      </Stack>
    </Container>
  );
};

export default Kam;
