import React from 'react';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import LeaderboardTwoToneIcon from '@mui/icons-material/LeaderboardTwoTone';
import NoteAddTwoToneIcon from '@mui/icons-material/NoteAddTwoTone';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import ContactPageTwoToneIcon from '@mui/icons-material/ContactPageTwoTone';
import ROL from 'utils/Roles';

export const capitalizeWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

type MenuLink = {
  path: string;
  name: string;
  roles: string[];
  icon: React.ReactNode;
};

export const menuLinks: MenuLink[] = [
  {
    path: '/',
    name: 'Candidatos',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <GroupTwoToneIcon color='inherit' />,
  },
  {
    path: '/candidate/new',
    name: 'Nuevo Candidato',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <PersonAddAltTwoToneIcon color='inherit' />,
  },
  {
    path: '/list/requests',
    name: 'Solicitudes',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <ContactPageTwoToneIcon color='inherit' />,
  },
  {
    path: '/request',
    name: 'Nueva Solicitud',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <NoteAddTwoToneIcon color='inherit' />,
  },
  {
    path: '/showroom',
    name: 'Vitrina',
    roles: [ROL.ADMINISTRADOR, ROL.USUARIO, ROL.RECLUTADOR],
    icon: <StarTwoToneIcon color='inherit' />,
  },
  {
    path: '/mentor',
    name: 'Mentor',
    roles: [ROL.ADMINISTRADOR, ROL.MENTOR, ROL.KAM],
    icon: <SchoolTwoToneIcon color='inherit' />,
  },
  {
    path: '/kam',
    name: 'Kam',
    roles: [ROL.ADMINISTRADOR, ROL.MENTOR, ROL.KAM],
    icon: <SchoolTwoToneIcon color='inherit' />,
  },
  {
    path: '/teams',
    name: 'Equipos',
    roles: [ROL.ADMINISTRADOR, ROL.KAM, ROL.MENTOR],
    icon: <GroupsTwoToneIcon color='inherit' />,
  },
  {
    path: '/invitations',
    name: 'Invitaciones',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <EmailTwoToneIcon color='inherit' />,
  },
  {
    path: '/report',
    name: 'Reportes',
    roles: [ROL.ADMINISTRADOR, ROL.RECLUTADOR],
    icon: <LeaderboardTwoToneIcon color='inherit' />,
  },
];

export const getFilteredMenuLinksByRole = (role: ROL) =>
  menuLinks.filter((menuLink) => menuLink.roles.includes(role));
