import styled, { keyframes } from 'styled-components';
import { HTMLProps } from 'react';

const glow = (color: string) => keyframes`
  0% 100% {
    box-shadow: ${color}; // Usar el color proporcionado
  }
  50% {
    box-shadow: ${color}, ${color}; // Usar el color proporcionado
  }
`;

const glowSubmitButton = keyframes`
  0% 100% {
    box-shadow: 0 0 1px #fff;
  }
  50% {
    box-shadow: 0 0 3px #fff, 0 0 6px #fff;
  }
`;

interface DivGlowEffectProps extends HTMLProps<HTMLDivElement> {
  duration?: string; // Prop opcional para la duración
  color?: string; // Prop opcional para el color en formato '0 0 10px 2px rgba(255, 0, 0, 0.7)'
}

export const DivGlowEffect = styled.div<DivGlowEffectProps>`
  &.glow {
    animation: ${({ duration = '1s', color = '0 0 10px 2px rgba(255, 255, 255, 0.7)' }) =>
        glow(color)}
      ${({ duration }) => duration} ease-in-out infinite; // Usar la prop para la duración y el color
  }
`;

export const DivGlowEffectSubmitButton = styled.div`
  &.glow-button {
    animation: ${glowSubmitButton} 1s ease-in-out infinite;
    display: inline-block;
  }
`;
