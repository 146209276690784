import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { steps } from '../CreateTeam';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'radial-gradient(circle, rgba(43,180,210,1) 15%, rgba(36,121,153,1) 94%);',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'radial-gradient(circle, rgba(43,180,210,1) 15%, rgba(36,121,153,1) 94%);',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'radial-gradient(circle, rgba(43,180,210,1) 15%, rgba(36,121,153,1) 94%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'radial-gradient(circle, rgba(43,180,210,1) 15%, rgba(36,121,153,1) 94%);',
  }),
}));

interface ColorlibStepIconProps extends StepIconProps {
  stepIndex: number;
}

function ColorlibStepIcon(props: ColorlibStepIconProps) {
  const { active, completed, className, stepIndex } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {steps[stepIndex].icon}
    </ColorlibStepIconRoot>
  );
}

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CreateTeamStepper: React.FC<Props> = ({ step, setStep }) => {
  return (
    <Stack width='100%' spacing={4}>
      <Stepper alternativeLabel activeStep={step - 1} connector={<ColorlibConnector />}>
        {steps.map((step, index) => (
          <Step key={step.name}>
            <StepLabel
              StepIconComponent={(props) => <ColorlibStepIcon {...props} stepIndex={index} />}
              onClick={() => {
                setStep(index + 1);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {step.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CreateTeamStepper;
