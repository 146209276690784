import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

type TiconSelector = {
  color: 'success' | 'error';
  toolTipMessage: string;
};
export const IconSelector: FC<TiconSelector> = ({ color, toolTipMessage }) => {
  const icon =
    color === 'success' ? (
      <TaskAltIcon color='success' />
    ) : (
      <HighlightOffRoundedIcon color='error' />
    );
  return <Tooltip title={toolTipMessage}>{icon}</Tooltip>;
};
