import { useLocation } from 'react-router-dom';
export const GetFirstElement = () => {
  const { pathname } = useLocation();
  let path = pathname.toString().toLowerCase();
  if (path[0] === '/') {
    path = path.substring(1);
  }
  const module = path.toString().toLowerCase().split('/')[0];
  return module || undefined;
};
