import Swal, { SweetAlertOptions } from 'sweetalert2';
import './myStyle.css';

const SwalAlert = ({
  html,
  icon,
  cancelButtonText,
  confirmButtonText,
  text,
  showCancelButton,
  title,
  allowOutsideClick,
  showLoaderOnConfirm,
  timer,
  toast,
  showConfirmButton,
  timerProgressBar,
  input,
  inputOptions,
  preConfirm,
}: SweetAlertOptions) => {
  return Swal.fire({
    color: 'white',
    icon: icon,
    background: '#213456',
    title: title,
    showCancelButton: showCancelButton,
    timer: timer,
    showConfirmButton: showConfirmButton,
    html: html,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
    text: text,
    input: input,
    allowOutsideClick: allowOutsideClick,
    showLoaderOnConfirm: showLoaderOnConfirm,
    confirmButtonColor: '#1674d8',
    cancelButtonColor: '#cc4846',
    toast: toast,
    timerProgressBar: timerProgressBar,
    preConfirm: preConfirm,
    inputOptions: inputOptions,
  });
};

export default SwalAlert;
