import * as React from 'react';
import { DatosInterface } from '../../../../models/PersonalInformationInterfaces';
import { Alert, AlertColor, Autocomplete, Box, Chip, FormControl, TextField } from '@mui/material';
import UploadFileField from '../uploadFileField/UploadFileField';
import CountrySelect from '../countrySelect/CountrySelect';
import { flagFunction } from '../../../../../helpers/flags';
import { countries } from 'data/countriesAndCurrencies';

type Props = {
  data: DatosInterface;
  templateData: DatosInterface[];
  index: number;
  celular: Number;
  initial: DatosInterface[];
  changeValidate: any[];
  uploadFields: {};
  onAutocompleteChange: (_event, newValue) => void;
  handleLink: (value: string[]) => void;
  handleFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  message: { message: string; severity: string };
};

const STYLES_PARAMS = { display: 'flex', flexDirection: 'row', paddingBottom: '20px' };
const PersonalFieldForm = ({
  data,
  templateData,
  onAutocompleteChange,
  onChangeInput,
  handleFiles,
  handleLink,
  index,
  celular,
  initial,
  changeValidate,
  uploadFields,
  message,
}: Props) => {
  const getDatosValorArray = (nombre: string) => {
    const datosValor = getDatosValor(nombre) ?? [];
    return Array.isArray(datosValor) ? datosValor : [datosValor];
  };

  function isUnexpectedValue(value: string) {
    return isNaN(parseInt(value));
  }

  const ValidaRenta = (value: string) => {
    if (value === '' || isUnexpectedValue(value)) {
      return '';
    }
    const rentaActualSoloNumeros = (value as string).replace(/\D/g, '');
    const rentaActualConFormato = parseInt(rentaActualSoloNumeros).toLocaleString('es-CL');
    return rentaActualConFormato;
  };

  const getDatosValor = (nombre: string) => {
    return templateData.find((dato: DatosInterface) => dato.nombre === nombre)?.valor;
  };

  if (data.nombre === 'Residencia') {
    const residence = getDatosValor(data.nombre)?.toString();
    const value = residence?.length ? residence : flagFunction(celular.toString())?.toLowerCase();
    return (
      <FormControl key={'fc' + data.nombre} sx={STYLES_PARAMS}>
        <CountrySelect {...{ value }} label={data.nombre} onChange={onAutocompleteChange} />
      </FormControl>
    );
  }

  const existData = (valueName: string) => {
    if (initial.some((initDatos: DatosInterface) => initDatos.nombre === data.nombre)) {
      return true;
    } else {
      return changeValidate.some((valid) => valid.name === data.nombre && valid.validate);
    }
  };
  const handleLinkDoubleClick = (data: DatosInterface) => {
    const fieldData = templateData.find((d) => d.nombre === data.nombre)?.valor.toString();

    if (fieldData && fieldData.startsWith('http')) {
      window.open(fieldData, '_blank');
    }
  };

  if (
    templateData &&
    Array.isArray(data.valor) &&
    data.nombre !== 'Moneda Renta Actual' &&
    data.nombre !== 'Moneda Pretenciones de Renta'
  ) {
    return (
      <FormControl key={'fc' + data.nombre} sx={STYLES_PARAMS}>
        <Autocomplete
          multiple
          freeSolo
          key={'ac' + data.nombre}
          id='rol'
          size='medium'
          sx={{ width: '100%' }}
          options={data.valor}
          value={existData(data.nombre) ? getDatosValorArray(data.nombre) : []}
          onChange={onAutocompleteChange}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                key={`${option}-${index}`}
                variant='outlined'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label={data.nombre} placeholder='Ingrese un valor' />
          )}
        />
      </FormControl>
    );
  }

  if (
    templateData &&
    Array.isArray(data.valor) &&
    (data.nombre === 'Moneda Renta Actual' || data.nombre === 'Moneda Pretenciones de Renta')
  ) {
    return (
      <FormControl key={'fc' + data.nombre} sx={STYLES_PARAMS}>
        <Autocomplete
          id='typeCoin'
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.typeCoin}
          disableClearable
          sx={{ width: '100%' }}
          value={
            (templateData &&
              countries &&
              countries.find(
                (country) =>
                  country.typeCoin ===
                  templateData.find((dato: DatosInterface) => dato.nombre === data.nombre)?.valor
              )) ||
            null
          }
          onChange={onAutocompleteChange}
          renderOption={(props, option) => (
            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=''
              />
              {option.typeCoin} - {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label={data.nombre} id='moneda' name='moneda' />
          )}
        />
      </FormControl>
    );
  }

  if (data.nombre === 'CV' || data.nombre === 'Test Kandio' || data.type === 'file') {
    const value = uploadFields[data.nombre] || data.valor;
    const severity: AlertColor = message.severity as AlertColor;
    return (
      <>
        <UploadFileField
          key={data.nombre + index + 'file'}
          name={data.nombre}
          defaultValue={value}
          value={getDatosValorArray(data.nombre) as string[]}
          onChange={handleFiles}
          onClick={handleLink}
          onChangeInput={onChangeInput}
        />
        {message.message && data.nombre === 'CV' && (
          <Alert severity={severity} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        )}
      </>
    );
  }

  if (data.type === 'url') {
    return (
      <FormControl key={'fc' + data.nombre} sx={STYLES_PARAMS}>
        <TextField
          disabled={data.nombre.toLowerCase() === 'score'}
          label={data.nombre}
          placeholder={data.nombre}
          sx={{ width: '100%' }}
          type='text'
          key={data.nombre + 'input'}
          id={data.nombre + 'input'}
          spellCheck={true}
          onDoubleClick={() => handleLinkDoubleClick(data)}
          name={data.nombre}
          onChange={onChangeInput}
          value={
            data.nombre === 'Renta Actual' || data.nombre === 'Pretenciones de Renta'
              ? ValidaRenta(getDatosValor(data.nombre)?.toString())
              : getDatosValor(data.nombre)?.toString() ?? ''
          }
        />
      </FormControl>
    );
  }

  if (data.nombre !== 'Nombre' && data.nombre !== 'Correo') {
    return (
      <FormControl key={'fc' + data.nombre} sx={STYLES_PARAMS}>
        <TextField
          disabled={data.nombre.toLowerCase() === 'score'}
          label={data.nombre}
          placeholder={data.nombre}
          sx={{ width: '100%' }}
          type='text'
          key={data.nombre + 'input'}
          id={data.nombre + 'input'}
          spellCheck={true}
          name={data.nombre}
          onChange={onChangeInput}
          value={
            data.nombre === 'Renta Actual' || data.nombre === 'Pretenciones de Renta'
              ? ValidaRenta(getDatosValor(data.nombre)?.toString())
              : getDatosValor(data.nombre)?.toString() ?? ''
          }
        />
      </FormControl>
    );
  }

  return null;
};

export default PersonalFieldForm;
