import React, { useContext, useState, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SVGUser from 'components/ui/icons/SVGUser';
import SadWiti from 'assets/witl_personaje_5-1.png';

import { AuthContext } from 'services/authContext/AuthContextProvider';
import { logoutFirebase } from 'utils/firebase';
import getAxios from '../../../../apiRestConnector';
import { StyledRoleWrapper } from '../roles/style';
import RoleButton from '../roles/RoleButton';
import CurrentRoleLabel from '../roles/CurrentRoleLabel';
import {
  StyledCard,
  StyledImage2,
  StyledButton,
  StyledSVG,
  StyledSwitch,
  RolesContainer,
  StyledGlassDiv,
  StyledSection,
  NameImageWrapper,
  StyledImage,
} from './style';
import ROL from 'utils/Roles';
import SwalAlert from 'components/ui/alerts/SwalAlert';

interface Props {
  role: ROL;
  roles: ROL[];
}

const UserProfileCard = ({ role, roles }: Props) => {
  const { storeAuth, actionsAuth } = useContext(AuthContext)!;
  const { user } = storeAuth;
  const history = useHistory();
  const [isChecked, setChecked] = useState(user.subscribed);
  const { access_token } = storeAuth.user;
  const [card, setCard] = useState(false);

  const handleUnsubscribe = async () => {
    let result = await SwalAlert({
      title: 'Cancelar suscripción',
      text: 'Cuéntanos tu motivo para cancelar la suscripción',
      imageUrl: SadWiti,
      confirmButtonText: 'Cancelar suscripción',
      cancelButtonText: 'Seguir suscrito',
      showCancelButton: true,
      input: 'text',
      showConfirmButton: true,
    });
    if (result.isConfirmed) {
      await requestUnsubscribe();
      SwalAlert({
        title: 'Suscripción cancelada',
        text: 'Has sido dado de baja en nuestro boletín semanal',
        confirmButtonText: 'Entendido',
        showConfirmButton: true,
      });
      return true;
    } else {
      return false;
    }
  };

  const requestUnsubscribe = async () => {
    return await getAxios
      .instance(access_token)
      .get(`/users/unsubscribe/${user.email}`)
      .then((result) => {
        updateSubscribedUser();
        actionsAuth.updateUserState(); // se sincroniza estado del usuario con localstorage
      })
      .catch((error) => {
        console.error('error:', error);
      });
  };

  const requestSubscribe = async () => {
    return await getAxios
      .instance(access_token)
      .get(`/users/subscribe/${user.email}`)
      .then((result) => {
        updateSubscribedUser();
        actionsAuth.updateUserState(); // se sincroniza estado del usuario con localstorage
        SwalAlert({
          title: 'Suscrito con éxito',
          text: 'Has sido dado de alta en nuestro boletín semanal',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangeSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (!checked) {
      const result = await handleUnsubscribe();
      setChecked(!result);
    } else {
      await requestSubscribe();
      setChecked(true);
    }
  };

  const updateSubscribedUser = () => {
    // actualizara estado subscribed en local storage
    let user = JSON.parse(localStorage.getItem('user')!);
    user.subscribed = !user.subscribed;
    localStorage.setItem('user', JSON.stringify(user));
  };

  useEffect(() => {
    const clickOut = (e: any) => {
      const notificationWrappers = document.querySelectorAll(`.general`);
      let isWithinNotification = false;
      notificationWrappers.forEach((notificationWrapper) => {
        const isWithinCurrentNotification = e.composedPath().includes(notificationWrapper);
        if (isWithinCurrentNotification) {
          isWithinNotification = true;
        }
      });
      if (!isWithinNotification) {
        setCard(false);
      }
    };
    window.addEventListener('click', clickOut);
    return () => window.removeEventListener('click', clickOut);
  }, []);

  return (
    <ButtonBase
      component='div'
      className={'general'}
      sx={{
        position: 'relative',
        maxWidth: '210px',
        borderRadius: '16px',
      }}
    >
      <NameImageWrapper onClick={() => setCard(!card)}>
        <StyledRoleWrapper>
          <section>{user.name}</section>
          <CurrentRoleLabel role={role} />
        </StyledRoleWrapper>

        <Box ml={2}>
          {!user.imageUrl ? (
            <StyledSVG size={30} scale={0.9}>
              <SVGUser />
            </StyledSVG>
          ) : (
            <StyledImage src={user.imageUrl} alt='Profile' />
          )}
        </Box>
      </NameImageWrapper>
      {card && (
        <StyledCard>
          <StyledGlassDiv>
            {!user.imageUrl ? (
              <StyledSVG size={100} scale={1}>
                <SVGUser />
              </StyledSVG>
            ) : (
              <StyledImage2 src={user.imageUrl} alt='Profile' />
            )}
            <strong style={{ color: '#FCD13B' }}>{user.name}</strong>
            <span>{user.email}</span>
            <span>{user.company}</span>
          </StyledGlassDiv>

          <StyledSwitch>
            <label>Boletín Semanal</label>
            <StyledSection>
              <Switch size='small' checked={isChecked} onChange={onChangeSwitch} />
            </StyledSection>
          </StyledSwitch>

          {roles.length > 1 && (
            <RolesContainer>
              {roles.sort().map((rol, index) => (
                <RoleButton role={rol} key={index} />
              ))}
            </RolesContainer>
          )}

          <StyledButton onClick={() => logoutFirebase(history, actionsAuth.logout)}>
            <ExitToAppIcon></ExitToAppIcon>
            Logout
          </StyledButton>
        </StyledCard>
      )}
    </ButtonBase>
  );
};

export default UserProfileCard;
