import Background from 'assets/witi-background-01.jpg';

export const wrapper = {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
};

export const container = {
  width: '80%',
  height: '80%',
  display: 'grid',
  placeItems: 'center',
  zIndex: 1000,
};

export const acceptCall = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
};

export const boxCallBtn = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  gap: '2rem',
};

export const callBtn = {
  height: '60px',
  width: '50px',
  color: 'white',
  borderRadius: '50%',
  backgroundColor: 'green',
};

export const meetingBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  minWidth: '100%',
  backgroundColor: 'rgba(0,0,0,.95)',
  padding: '1rem',
  gap: '1rem',
  borderRadius: '1rem',
};

export const streamsBox = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column-reverse',
  width: '100%',
  height: '100%',
  // backgroundColor: 'yellow',
  gap: '1rem',
  backgroundColor: '#7b9bd914',
  padding: '1rem',
  borderRadius: '1rem',
};

export const videoBox = {
  width: '100%',
  height: '100%',
  aspectRatio: '3/2',
  position: 'relative',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

export const muteRemoteWithPulse = {
  position: 'absolute',
  bottom: 0,
  right: '10%',
  width: '24px',
  height: '24px',
  backgroundColor: 'rgba(120, 120, 120, 0.8)', // Color inicial establecido
  borderRadius: '50%',
  marginBottom: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1001,
  padding: '1rem',
  animation: 'pulse-animation 1.5s infinite ease-in-out',
  '@keyframes pulse-animation': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(120, 120, 120, 0.8)',
      backgroundColor: 'rgba(120, 120, 120, 0.8)',
    },
    '50%': {
      boxShadow: '0 0 0 10px rgba(255, 0, 0, 0.5)',
      backgroundColor: 'rgba(255, 0, 0, 0.8)',
    },
    '100%': {
      boxShadow: '0 0 0 0px rgba(120, 120, 120, 0.8)',
      backgroundColor: 'rgba(120, 120, 120, 0.8)',
    },
  },
};

export const titleMeeting = {
  backgroundColor: 'rgba(0,0,0,.95)',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: '2rem',
};
