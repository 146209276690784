import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';

import { History } from 'history';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

export const firebaseAnalytics = firebase.analytics();

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'consent',
});

export const loginGoogle = () => {
  firebase.auth().signInWithPopup(googleProvider);
};

export const loginMicrosoft = () => {
  firebase.auth().signInWithPopup(microsoftProvider);
};

export const logoutFirebase = (history: History, contextLogout: () => void) => {
  firebase.auth().signOut();
  contextLogout();
  history.push('/');
};

export const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const userId = firebase.auth().currentUser?.uid;
    const userName = firebase.auth().currentUser?.displayName;
    // Envía el evento de cambio de página a Firebase Analytics
    if (userId) {
      firebaseAnalytics.logEvent(`${userName}-logs`, {
        user_name: userName,
        user_id: userId,
        path: location.pathname,
      });
    }
  }, [location]);
};
