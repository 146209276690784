import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function FullscreenLoading() {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <CircularProgress color='primary' size={100} />
    </Backdrop>
  );
}

export default FullscreenLoading;
