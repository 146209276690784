import React, { useState, useEffect, FC, useContext, useCallback, useRef } from 'react';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';
import { WorkflowInterface } from 'components/models/WorkFlowInterfaces';
import {
  DatosInterface,
  DatosTemplateInterface,
} from 'components/models/PersonalInformationInterfaces';
import {
  Button,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  useTheme,
} from '@mui/material';
import { TemplateInterface } from 'components/models/CandidateInterfaces';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';

interface skillScore {
  skill: string;
  score: string;
}

interface IProps {
  setFilter: React.Dispatch<React.SetStateAction<object>>;
  nameFilter: string;
  setNameFilter: React.Dispatch<React.SetStateAction<string>>;
  setFilterJoinPersonasSolicituds: React.Dispatch<React.SetStateAction<object>>;
}

const ListCandidateFilter: FC<IProps> = ({
  setFilter,
  nameFilter,
  setNameFilter,
  setFilterJoinPersonasSolicituds,
}) => {
  const [filterCandidateDesde, setFilterCandidateDesde] = useState<Date>(new Date());
  const [filterCandidateHasta, setFilterCandidateHasta] = useState<Date>(new Date());
  const [rol, setRol] = useState<string[]>([]);
  const [fuenteCaptacion, setFuenteCaptacion] = useState<string[]>([]);

  const [filterCandidateStatus, setFilterCandidateStatus] = useState<string[]>(Array);
  const [filterSolicitud, setFilterSolicitud] = useState<number>();

  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const [template, setTemplate] = useState<TemplateInterface>({});
  const [filterCandidateSkill, setFilterCandidateSkill] = useState<string[]>(Array);
  const [filterCandidateLevel, setFilterCandidateLevel] = useState<string[]>(Array);

  const [excluded, setExcluded] = useState<boolean>(false);
  const [contratado, setContratado] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  const [recruiters, setRecruiters] = useState<string[]>(Array);
  const [filteredRecruiters, setFilteredRecruiters] = useState<string[]>(Array);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [skillControl, setSkillControl] = useState<skillScore[]>([
    {
      skill: '',
      score: '',
    },
  ]);

  const [roles, setRoles] = useState<string[]>([]);
  const [fuentesCaptacion, setFuentesCaptacion] = useState<string[]>([]);

  const [switchCondition, setSwitchCondition] = useState<boolean>(true);

  let hSearch = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (nameFilter && hSearch.current) {
      hSearch.current.click();
    }
  }, [nameFilter]);

  const handleCheckboxFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value, name, id } = e.target;

    if (checked && id === 'activo') {
      localStorage.setItem('filterActivo', 'activo');
    } else if (checked && id === 'inactivo') {
      localStorage.setItem('filterActivo', 'inactivo');
    } else if (checked && id === 'todos') {
      localStorage.setItem('filterActivo', 'todos');
    }

    if (name === 'excluidos') {
      if (checked) {
        setExcluded(true);
      } else {
        setExcluded(false);
      }
    }
    if (name === 'contratados') {
      if (checked) {
        setContratado(true);
      } else {
        setContratado(false);
      }
    }

    if (name === 'skill') {
      checked && setFilterCandidateSkill([...filterCandidateSkill, value]);
      !checked &&
        setFilterCandidateSkill(filterCandidateSkill.filter((filter) => filter !== value));
    }

    if (name === 'recrutier') {
      if (checked) {
        let list = [...filteredRecruiters, value];
        setFilteredRecruiters(list);

        localStorage.setItem('filteredRecruiters', JSON.stringify(list));
      } else {
        let list = filteredRecruiters.filter((filter) => filter !== value);
        setFilteredRecruiters(list);

        localStorage.setItem('filteredRecruiters', JSON.stringify(list));
      }
    }

    if (name === 'level') {
      if (checked) {
        let list = [...filterCandidateLevel, value];
        setFilterCandidateLevel(list);
        localStorage.setItem('filterCandidateLevel', JSON.stringify(list));
      } else {
        let list = filterCandidateLevel.filter((filter) => filter !== value);
        setFilterCandidateLevel(list);
        localStorage.setItem('filterCandidateLevel', JSON.stringify(list));
      }
    }

    if (name === 'estado') {
      let valueEstado = value;
      if (checked) {
        let list = [...filterCandidateStatus, valueEstado];
        setFilterCandidateStatus(list);
        localStorage.setItem('filterCandidateStatus', JSON.stringify(list));
      } else {
        let list = filterCandidateStatus.filter((filter) => filter !== valueEstado);
        setFilterCandidateStatus(list);
        localStorage.setItem('filterCandidateStatus', JSON.stringify(list));
      }
    }
  };

  const handleSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //Filtro por Reclutador sin LookUp
    let filterReclutadoresCharts = {};
    let filterCandidateStatusCharts = {};
    let filterCandidateLevelRolFuenteCaptacionCharts = {};
    let filterCandidateFechasCharts = {};
    let filterCandidateContratadoCharts = {};
    let filterCandidateExcludedCharts = {};
    let filterSolicitudCharts = {};
    //Filtro por Reclutador con LookUp
    let filterReclutadoresChartslookUp = {};
    let filterCandidateStatusChartslookUp = {};
    let filterCandidateLevelRolFuenteCaptacionChartslookUp = {};
    let filterCandidateFechasChartslookUp = {};
    let filterCandidateContratadoExcludedChartslookUp = {};
    let filterSolicitudChartslookUp = {};

    let filter = {};
    let filterLookUp = {};

    if (filteredRecruiters) {
      if (filteredRecruiters.length > 1) {
        filterReclutadoresCharts['workflow.responsable'] = { $in: [...filteredRecruiters] };
        filterReclutadoresChartslookUp['personas.workflow.responsable'] = {
          $in: [...filteredRecruiters],
        };
      } else if (filteredRecruiters.length === 1) {
        filterReclutadoresCharts['workflow.responsable'] = filteredRecruiters[0].toString();
        filterReclutadoresChartslookUp['personas.workflow.responsable'] =
          filteredRecruiters[0].toString();
      }
    } else {
      filterReclutadoresCharts = {};
      filterReclutadoresChartslookUp = {};
    }
    //Filtro por Etapa
    if (filterCandidateStatus) {
      if (filterCandidateStatus.length >= 1) {
        filterCandidateStatusCharts = JSON.parse(
          `{"workflow.nombre":{"$in":[${filterCandidateStatus.map((element) => {
            return `"${element}"`;
          })}]},"workflow.estado":true}`
        );
        filterCandidateStatusChartslookUp = JSON.parse(
          `{"personas.workflow.nombre":{"$in":[${filterCandidateStatus.map((element) => {
            return `"${element}"`;
          })}]},"personas.workflow.estado":true}`
        );
      } else {
        filterCandidateStatusCharts = {};
        filterCandidateStatusChartslookUp = {};
      }
    }
    //Seniority y Rol

    if (filterCandidateLevel || rol) {
      if (filterCandidateLevel.length >= 1 && rol.length === 0) {
        filterCandidateLevelRolFuenteCaptacionCharts = JSON.parse(
          `{"datos":{"$elemMatch": {"nombre": "Nivel","valor": {"$in": [${filterCandidateLevel.map(
            (element) => {
              return `"${element}"`;
            }
          )}]}}}}`
        );
        filterCandidateLevelRolFuenteCaptacionChartslookUp = JSON.parse(
          `{"personas.datos":{"$elemMatch": {"nombre": "Nivel","valor": {"$in": [${filterCandidateLevel.map(
            (element) => {
              return `"${element}"`;
            }
          )}]}}}}`
        );
      } else if (filterCandidateLevel.length >= 1 && rol.length >= 1) {
        filterCandidateLevelRolFuenteCaptacionCharts = JSON.parse(
          `{"$and":[{"datos":{"$elemMatch": {"nombre": "Rol","valor": { "$in": [${rol.map(
            (element) => {
              return `"${element}"`;
            }
          )}] }}}},{"datos":{"$elemMatch": {"nombre": "Nivel","valor": { "$in": [${filterCandidateLevel.map(
            (element) => {
              return `"${element}"`;
            }
          )}]}}}}]}`
        );
        filterCandidateLevelRolFuenteCaptacionChartslookUp = JSON.parse(
          `{"$and":[{"personas.datos":{"$elemMatch": {"nombre": "Rol","valor": { "$in": [${rol.map(
            (element) => {
              return `"${element}"`;
            }
          )}] }}}},{"personas.datos":{"$elemMatch": {"nombre": "Nivel","valor": { "$in": [${filterCandidateLevel.map(
            (element) => {
              return `"${element}"`;
            }
          )}]}}}}]}`
        );
      } else if (filterCandidateLevel.length === 0 && rol.length >= 1) {
        filterCandidateLevelRolFuenteCaptacionCharts = JSON.parse(
          `{"datos":{"$elemMatch": {"nombre": "Rol","valor": {"$in": [${rol.map((element) => {
            return `"${element}"`;
          })}]}}}}`
        );
        filterCandidateLevelRolFuenteCaptacionChartslookUp = JSON.parse(
          `{"personas.datos":{"$elemMatch": {"nombre": "Rol","valor": {"$in": [${rol.map(
            (element) => {
              return `"${element}"`;
            }
          )}]}}}}`
        );
      } else {
        filterCandidateLevelRolFuenteCaptacionCharts = {};
        filterCandidateLevelRolFuenteCaptacionChartslookUp = {};
      }
    }
    //Fuente de captacion
    if (fuenteCaptacion) {
      if (fuenteCaptacion.length >= 1) {
        if (
          filterCandidateLevelRolFuenteCaptacionCharts['datos'] === undefined &&
          filterCandidateLevelRolFuenteCaptacionCharts['$and'] === undefined
        ) {
          filterCandidateLevelRolFuenteCaptacionCharts = JSON.parse(
            `{"datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
              (element) => {
                return `"${element}"`;
              }
            )}]}}}}`
          );
          filterCandidateLevelRolFuenteCaptacionChartslookUp = JSON.parse(
            `{"personas.datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
              (element) => {
                return `"${element}"`;
              }
            )}]}}}}`
          );
        } else if (
          filterCandidateLevelRolFuenteCaptacionCharts['datos'] === undefined &&
          filterCandidateLevelRolFuenteCaptacionCharts['$and'] !== undefined
        ) {
          filterCandidateLevelRolFuenteCaptacionCharts['$and'].push(
            JSON.parse(
              `{"datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
                (element) => {
                  return `"${element}"`;
                }
              )}]}}}}`
            )
          );
          filterCandidateLevelRolFuenteCaptacionChartslookUp['$and'].push(
            JSON.parse(
              `{"personas.datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
                (element) => {
                  return `"${element}"`;
                }
              )}]}}}}`
            )
          );
        } else if (
          filterCandidateLevelRolFuenteCaptacionCharts['datos'] !== undefined &&
          filterCandidateLevelRolFuenteCaptacionCharts['$and'] === undefined
        ) {
          let provisirio = JSON.stringify(filterCandidateLevelRolFuenteCaptacionCharts);
          let provisirioLookUp = JSON.stringify(filterCandidateLevelRolFuenteCaptacionChartslookUp);
          filterCandidateLevelRolFuenteCaptacionCharts = JSON.parse(
            `{"$and":[${provisirio},{"datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
              (element) => {
                return `"${element}"`;
              }
            )}]}}}}]}`
          );
          filterCandidateLevelRolFuenteCaptacionChartslookUp = JSON.parse(
            `{"$and":[${provisirioLookUp},{"personas.datos":{"$elemMatch": {"nombre": "Fuente","valor": {"$in": [${fuenteCaptacion.map(
              (element) => {
                return `"${element}"`;
              }
            )}]}}}}]}`
          );
        }
      }
    }
    //Fechas
    if (filterCandidateDesde && filterCandidateHasta) {
      filterCandidateFechasCharts['timestamp'] = {
        $gte: filterCandidateDesde,
        $lt: filterCandidateHasta,
      };
      filterCandidateFechasChartslookUp['personas.timestamp'] = {
        $gte: filterCandidateDesde,
        $lt: filterCandidateHasta,
      };
    } else {
      filterCandidateFechasCharts = {};
      filterCandidateFechasChartslookUp = {};
    }
    //Contratados o Exluidos
    if (contratado) {
      filterCandidateContratadoCharts['Contratados'] = 1;
      filterCandidateContratadoExcludedChartslookUp['estado'] = 'Cerrado OK';
    } else {
      filterCandidateContratadoCharts = {};
      filterCandidateContratadoExcludedChartslookUp = {};
    }
    if (excluded) {
      filterCandidateExcludedCharts['Descartados'] = 1;
      if (filterCandidateContratadoExcludedChartslookUp['estado'] === undefined) {
        filterCandidateContratadoExcludedChartslookUp['estado'] = 'Cerrado no OK';
      } else {
        filterCandidateContratadoExcludedChartslookUp['estado'] = JSON.parse(
          '{"$in":["Cerrado OK","Cerrado no OK"]}'
        );
      }
    } else {
      filterCandidateExcludedCharts = {};
    }
    //filtro por solicitud
    if (filterSolicitud > 0) {
      filterSolicitudCharts['telefono'] = filterSolicitud;
      filterSolicitudChartslookUp['asociacions.telefono'] = filterSolicitud;
    } else {
      filterSolicitudCharts = {};
      filterSolicitudChartslookUp = {};
    }
    filter = {
      ...filterReclutadoresCharts,
      ...filterCandidateStatusCharts,
      ...filterCandidateLevelRolFuenteCaptacionCharts,
      ...filterCandidateFechasCharts,
      ...filterCandidateContratadoCharts,
      ...filterCandidateExcludedCharts,
      ...filterSolicitudCharts,
    };
    filterLookUp = {
      ...filterReclutadoresChartslookUp,
      ...filterCandidateStatusChartslookUp,
      ...filterCandidateLevelRolFuenteCaptacionChartslookUp,
      ...filterCandidateFechasChartslookUp,
      ...filterCandidateContratadoExcludedChartslookUp,
      ...filterSolicitudChartslookUp,
    };

    setFilter(filter);
    setFilterJoinPersonasSolicituds(filterLookUp);
    setNameFilter('');
  };

  const generateFilterStructure = useCallback(() => {
    const isFilter = () => {
      if (localStorage.getItem('filteredRecruiters')) {
        setFilteredRecruiters(JSON.parse(localStorage.getItem('filteredRecruiters')!));
      }
      if (localStorage.getItem('filterCandidateStatus')) {
        setFilterCandidateStatus(JSON.parse(localStorage.getItem('filterCandidateStatus')!));
      }
      if (localStorage.getItem('filterCandidateLevel')) {
        setFilterCandidateLevel(
          JSON.parse(localStorage.getItem('filterCandidateLevel').toString()!)
        );
      }
      if (localStorage.getItem('rol')) {
        setRol(JSON.parse(localStorage.getItem('rol').toString()!));
      }
      if (localStorage.getItem('fuenteCaptacion')) {
        setFuenteCaptacion(JSON.parse(localStorage.getItem('fuenteCaptacion').toString()!));
      }
    };

    if (JSON.stringify(template) === '{}') {
      const urlBackend = `${REACT_APP_BACKEND_URL}/templates/skills/`;
      getAxios
        .instance('')
        .get(urlBackend, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((result: any) => result.data)
        .then((templatesData: any) => {
          setTemplate(templatesData);
          templatesData.datos.forEach((dato: DatosInterface) => {
            if (
              dato.nombre === 'Rol' &&
              Array.isArray(dato.valor) &&
              typeof dato.valor === 'object'
            ) {
              setRoles(dato.valor);
            } else if (
              dato.nombre === 'Fuente' &&
              Array.isArray(dato.valor) &&
              typeof dato.valor === 'object'
            ) {
              setFuentesCaptacion(dato.valor);
            }
          });
        })
        .then(() => {
          isFilter();
        })
        .then(() => setLoad(true));
    }
  }, [
    REACT_APP_BACKEND_URL,
    access_token,
    template,
    setFilterCandidateStatus,
    setRol,
    setFuenteCaptacion,
  ]);

  useEffect(() => {
    generateFilterStructure();
  }, [generateFilterStructure]);

  const getRecruiters = useCallback(async () => {
    const allRecruiters: string[] = [];
    const urlBackend = `${REACT_APP_BACKEND_URL}/users`;

    await getAxios
      .instance('')
      .get(urlBackend, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((data) => {
        data.map((res: any) => allRecruiters.push(res.name));
      });
    //Se devuelve un arreglo de reclutadores
    return allRecruiters;
  }, [REACT_APP_BACKEND_URL, access_token]);

  useEffect(() => {
    async function saveRecruiters() {
      await getRecruiters().then((data) => {
        setRecruiters(data);
      });
    }

    saveRecruiters();
  }, [getRecruiters]);

  useEffect(() => {
    const formatDateForFilterCandidateDesde = () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);
      const formatDateString = `${
        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }/${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${date.getFullYear()}`;
      setFilterCandidateDesde(new Date(formatDateString));
    };
    formatDateForFilterCandidateDesde();
    document.title = `WiTI - LevelUp`;
  }, [setFilterCandidateDesde]);

  useEffect(() => {
    if (load && hSearch.current) {
      hSearch.current.click();
    }
  }, [load]);

  useEffect(() => {
    const skill = localStorage.getItem('skillControl');
    if (skill) {
      setSkillControl(JSON.parse(skill));
    }

    const switchh = localStorage.getItem('switchCondition');
    if (switchh) {
      setSwitchCondition(JSON.parse(switchh));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('skillControl', JSON.stringify(skillControl));
  }, [skillControl]);

  useEffect(() => {
    localStorage.setItem('switchCondition', JSON.stringify(switchCondition));
  }, [switchCondition]);

  const theme = useTheme();
  return (
    <FormControl
      sx={{
        display: 'grid',
        margin: '0 1rem',
        gap: '1rem',
        gridTemplateAreas: `
          'filtroFecha'
          'filtroEstado'
          'filtroSolicitud'
          'filtroNivel'
          'filtroExclusion'
          'filtroReclutador'
          'filtroRol'
          'fuenteCaptacion'
          'buttonAplicarFiltros'
        `,
        [theme.breakpoints.up('md')]: {
          gridTemplateAreas: `
            'filtroFecha filtroEstado filtroExclusion filtroNivel'
            'filtroSolicitud filtroSolicitud filtroReclutador filtroReclutador' 
            'filtroRol filtroRol fuenteCaptacion fuenteCaptacion'
            'buttonAplicarFiltros buttonAplicarFiltros buttonAplicarFiltros buttonAplicarFiltros'
          `,
        },
      }}
    >
      <FormGroup sx={{ display: 'flex', gap: '1rem', gridArea: 'filtroFecha', padding: '0 3rem' }}>
        <h6> Filtros por Fecha </h6>
        <DatePicker
          label='Desde'
          maxDate={
            filterCandidateHasta ? DateTime.fromJSDate(filterCandidateHasta) : DateTime.local()
          }
          value={DateTime.fromJSDate(filterCandidateDesde)}
          onChange={(date: DateTime) => {
            const dateInput = date.startOf('day').toJSDate();
            setFilterCandidateDesde(dateInput);
          }}
        />

        <DatePicker
          label='Hasta'
          minDate={DateTime.fromJSDate(filterCandidateDesde)}
          maxDate={DateTime.local()}
          value={DateTime.fromJSDate(filterCandidateHasta)}
          onChange={(date: DateTime) => {
            const dateInput = date.endOf('day').toJSDate();
            setFilterCandidateHasta(dateInput);
          }}
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroEstado' }}>
        <h6>Filtro por Estado</h6>
        {template.workflow?.map(({ nombre }: WorkflowInterface, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={filterCandidateStatus.includes(nombre)}
                key={nombre}
                name='estado'
                value={nombre}
                onChange={handleCheckboxFilters}
              />
            }
            label={nombre}
          />
        ))}
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroNivel' }}>
        <h6>Filtro por Nivel</h6>
        {template.datos?.map((dato: DatosTemplateInterface, datoIndex) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }} key={datoIndex}>
            {dato.nombre === 'Nivel' &&
              dato.valor.map((nivel: string, nivelIndex: number) => (
                <FormControlLabel
                  key={nivelIndex}
                  control={
                    <Checkbox
                      onChange={handleCheckboxFilters}
                      id={`Level${nivelIndex}`}
                      name='level'
                      value={nivel}
                      checked={filterCandidateLevel.includes(nivel)}
                    />
                  }
                  label={nivel}
                />
              ))}
          </Box>
        ))}
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroExclusion' }}>
        <h6>Exclusión</h6>
        <FormControlLabel
          control={
            <Checkbox
              checked={excluded}
              key='excluded'
              name='excluidos'
              value='Excluidos'
              id='excluded'
              onChange={handleCheckboxFilters}
            />
          }
          label='Excluidos'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={contratado}
              key='contratado'
              name='contratados'
              value='Contratados'
              id='contratado'
              onChange={handleCheckboxFilters}
            />
          }
          label='Contratados'
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroSolicitud' }}>
        <TextField
          type='number'
          id='filterSolicitud'
          value={filterSolicitud}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilterSolicitud(Number(e.target.value))
          }
          label='Solicitud'
          variant='outlined'
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroReclutador' }}>
        <Autocomplete
          multiple
          id='recruiters'
          options={recruiters.sort()}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={filteredRecruiters}
          filterSelectedOptions
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setFilteredRecruiters(newValue);
              localStorage.setItem('filteredRecruiters', newValue);
            } else if (newValue.length > 0) {
              setFilteredRecruiters(newValue.map((v: string) => v));
              localStorage.setItem('filteredRecruiters', JSON.stringify(newValue));
            } else {
              setFilteredRecruiters(newValue.map((v: any) => v));
              localStorage.setItem('filteredRecruiters', JSON.stringify(newValue));
            }
          }}
          renderInput={(params) => <TextField {...params} label='Reclutador' />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip {...getTagProps({ index })} key={option} variant='outlined' label={option} />
            ))
          }
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'filtroRol' }}>
        <Autocomplete
          multiple
          id='rol'
          options={roles}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={rol}
          filterSelectedOptions
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setRol(newValue);
              localStorage.setItem('rol', newValue);
            } else if (newValue.length > 0) {
              setRol(newValue.map((v: string) => v));
              localStorage.setItem('rol', JSON.stringify(newValue));
            } else {
              setRol(newValue.map((v: any) => v));
              localStorage.setItem('rol', JSON.stringify(newValue));
            }
          }}
          renderInput={(params) => <TextField {...params} label='Rol' />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip {...getTagProps({ index })} key={option} variant='outlined' label={option} />
            ))
          }
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'fuenteCaptacion' }}>
        <Autocomplete
          multiple
          id='fuenteCaptacion'
          options={fuentesCaptacion}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={fuenteCaptacion}
          filterSelectedOptions
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setFuenteCaptacion(newValue);
              localStorage.setItem('fuenteCaptacion', newValue);
            } else if (newValue.length > 0) {
              setFuenteCaptacion(newValue.map((v: string) => v));
              localStorage.setItem('fuenteCaptacion', JSON.stringify(newValue));
            } else {
              setFuenteCaptacion(newValue.map((v: any) => v));
              localStorage.setItem('fuenteCaptacion', JSON.stringify(newValue));
            }
          }}
          renderInput={(params) => <TextField {...params} label='Fuente de captación' />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip {...getTagProps({ index })} key={option} variant='outlined' label={option} />
            ))
          }
        />
      </FormGroup>
      <FormGroup sx={{ gridArea: 'buttonAplicarFiltros', placeSelf: 'center' }}>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setNameFilter('');
            handleSearch(e);
          }}
          id='hsearch'
          ref={hSearch}
          variant='contained'
        >
          Aplicar filtros
        </Button>
      </FormGroup>
    </FormControl>
  );
};

export default ListCandidateFilter;
