import React, { ReactNode } from 'react';
import { Box, Paper, Link } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getTheme } from '../../../styles/theme';
import witiBackground01 from 'assets/witi-background-01.svg';
import witiBackground02 from 'assets/witi-background-02.jpg';
import witiImagotipo from 'assets/witi-imagotipo.svg';

let loginTheme = getTheme('dark');
loginTheme = createTheme(loginTheme, {
  palette: {
    primary: loginTheme.palette.augmentColor({
      color: {
        main: '#EE721C',
      },
    }),
  },
});

interface Props {
  children: ReactNode;
}

const LinkWiti = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 25px)',
      }}
    >
      <Link
        href='https://witi.cl/'
        target='_blank'
        rel='noopener noreferrer'
        sx={{ display: 'block', p: 2 }}
      >
        <Box component='img' src={witiImagotipo} alt='WiTI' height={50} />
      </Link>
    </Box>
  );
};

const LoginContainer = ({ children }: Props) => {
  return (
    <ThemeProvider theme={loginTheme}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            flexGrow: 1,
            height: '100vh',
            backgroundImage: `url(${witiBackground01})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Paper
          sx={{
            flexGrow: 1,
            maxWidth: { xs: '100%', sm: 650 },
            height: '100vh',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: { xs: 2, sm: 10 },
            backgroundImage: `url(${witiBackground02})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <LinkWiti />
          {children}
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default LoginContainer;
