import React from 'react';
import './text.css';

const Text = (props: any) => {
  return (
    <h3
      className={`${props?.variant?.display} ${props?.variant?.color} ${props?.variant?.fontWeight}`}
      key={`${props?.key?.key}`}
    >
      {props.children}
    </h3>
  );
};

export default Text;
