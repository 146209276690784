import styled from '@emotion/styled/macro';

export const StyledDiv = styled.div`
  background-color: #2857a7;
  padding: 5px 40px;
  width: 100%;
  color: white;
  text-align: center;
  user-select: none;
  & > * {
    margin: 0 10px;
    transition: 200ms ease;
    cursor: pointer;
    &:hover {
      color: #f15b29;
    }
  }
`;
