import NotificationsIcon from '@mui/icons-material/Notifications';
import SVGCalendar from 'components/ui/icons/SVGCalendar';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  StyledDivDot,
  StyledDivDot2,
  StyledDivImage,
  StyledListItem,
  StyledListNotifications,
  StyledSpanDescription,
  StyledContainerDark,
  StyledContainerlight,
  StyledLastDiv,
  ListNotificationHeader,
} from './style';
import { Button, CircularProgress, ListItemText, Typography, ButtonBase } from '@mui/material';
import { DateTime } from 'luxon';
import { NotificationInterface } from 'components/models/NotificationInterface';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ROL from '../../../../utils/Roles';

type NotificationsProps = {
  role: ROL;
};

const Notifications = ({ role }: NotificationsProps) => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { user } = storeAuth;
  const { _id } = user;
  const { REACT_APP_BACKEND_URL } = process.env;

  const [actualPage, setActualPage] = useState<number>(1);
  const [notificationsTemplate, setNotificationsTemplate] = useState<NotificationInterface[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const [display, setDisplay] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastElement, setLastElement] = useState(null);
  const [notViewed, setNotViewed] = useState<number>(0);
  const [allNotifications, setAllNotifications] = useState<NotificationInterface[]>([]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setActualPage((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    if (notViewed) {
      getAxios
        .instance(access_token)
        .get(`/notification/user/${_id}`)
        .then((result: any) => {
          setAllNotifications(result.data);
        });
    }
  }, [_id, access_token, actualPage, notViewed]);

  const getNotificationsPerPage = useCallback(
    (updateInfo) => {
      const params = {
        offset: updateInfo ? 1 : actualPage,
        limit: updateInfo ? 10 * actualPage : 10,
      };

      setLoading(true);
      getAxios
        .instance('')
        .get(`${REACT_APP_BACKEND_URL}/notification/user/${_id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          params,
        })
        .then((result: any) => {
          setNotViewed(result.data.activeNotificationCount);
          setNotificationsTemplate((oldNot) => [...oldNot, ...result.data.result]);
          setHasNextPage(result.data.hasNextPage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [REACT_APP_BACKEND_URL, _id, access_token, actualPage]
  );

  useEffect(() => {
    getNotificationsPerPage(false);
  }, [getNotificationsPerPage]);

  const updateNotification = (
    notification: NotificationInterface,
    updateData: { [key: string]: any }
  ) => {
    const query = {
      notificationIds: [notification._id],
      body: updateData,
    };

    const NotificationsObj = notificationsTemplate.map((notifications: NotificationInterface) => {
      if (notifications._id === notification._id) {
        return { ...notifications, ...updateData };
      }
      return notifications;
    });

    setNotificationsTemplate([...NotificationsObj]);

    sendNotificationUpdate(query);
  };

  const changeViewed = (notification: NotificationInterface) => {
    const updateData = {
      viewed: new Date().toISOString(),
    };
    updateNotification(notification, updateData);
  };

  const changeHidden = (notification: NotificationInterface) => {
    const updateData = {
      hidden: true,
      viewed: new Date().toISOString(),
    };
    updateNotification(notification, updateData);
  };

  const sendNotificationUpdate = (query: any) => {
    getAxios
      .instance(access_token)
      .patch('/notification', query)
      .then((result) => {
        getNotificationsPerPage(true);
      });
  };
  useEffect(() => {
    const clickOut = (e: any) => {
      const notificationWrappers = document.querySelectorAll(`.notification-wrapper`);
      let isWithinNotification = false;
      notificationWrappers.forEach((notificationWrapper) => {
        const isWithinCurrentNotification = e.composedPath().includes(notificationWrapper);
        if (isWithinCurrentNotification) {
          isWithinNotification = true;
        }
      });

      if (!isWithinNotification) {
        setDisplay(false);
      }
    };
    window.addEventListener('click', clickOut);
    return () => window.removeEventListener('click', clickOut);
  }, []);

  const updateViewedNotifications = () => {
    let arrIds = [''];
    allNotifications.forEach((notification: NotificationInterface) => {
      if (notification.viewed === '' || notification.viewed === undefined) {
        notification.viewed = new Date();
        arrIds.push(notification._id);
      }
    });

    let arrSplited = arrIds.filter((a: string, i: number) => {
      if (i !== 0) {
        return a;
      }
      return null;
    });

    if (arrIds.length > 1) {
      let res = {
        notificationIds: arrSplited,
        body: {
          viewed: new Date().toISOString(),
        },
      };

      getAxios
        .instance(access_token)
        .patch('/notification', res)
        .then((result) => {
          getNotificationsPerPage(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  if (role !== ROL.ADMINISTRADOR && role !== ROL.RECLUTADOR) {
    return null;
  }

  return (
    <ButtonBase
      component='div'
      className={`notification-wrapper`}
      sx={{
        position: 'relative',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '50%',
      }}
    >
      <StyledDivImage
        onClick={(e) => {
          setDisplay(!display);
        }}
      >
        <NotificationsIcon sx={{ color: 'white' }} />
        {Boolean(notViewed) && (
          <StyledDivDot>
            <StyledDivDot2>{notViewed <= 99 ? notViewed : '99+'}</StyledDivDot2>
          </StyledDivDot>
        )}
      </StyledDivImage>

      <StyledListNotifications style={{ display: display ? 'flex' : 'none' }}>
        <ListNotificationHeader>
          <Typography
            sx={{
              color: 'white',
              fontSize: '21px',
              fontWeight: 'bold',
              lineHeight: '1.6',
              userSelect: 'none',
            }}
            component='span'
            variant='body2'
            color='text.primary'
          >
            Notificaciones
          </Typography>
          <Button
            variant='text'
            style={{ textTransform: 'none', color: '#32cff2', paddingLeft: '13px' }}
            onClick={() => updateViewedNotifications()}
            startIcon={<DoneAllIcon />}
          >
            Marcar como leídas
          </Button>
        </ListNotificationHeader>
        <div style={{ overflow: 'auto', overscrollBehavior: 'contain' }}>
          {notificationsTemplate &&
            notificationsTemplate.map((notification: NotificationInterface, index: number) => {
              if (!notification.hidden) {
                return (
                  <div key={index}>
                    <div style={{ margin: 'auto 15px', position: 'relative' }}>
                      <a
                        href={notification.action}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => {
                          changeViewed(notification);
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <StyledContainerDark>
                          <StyledContainerlight>
                            <StyledListItem alignItems='flex-start'>
                              <ListItemText
                                sx={{ width: 'inherit', marginTop: '7px' }}
                                primary={
                                  <h3
                                    style={{
                                      color: '#31CFF2',
                                      fontSize: '16px',
                                      textDecoration: 'none !important',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {notification.title}
                                  </h3>
                                }
                                secondary={
                                  <StyledSpanDescription>
                                    <Typography
                                      sx={{ color: 'white', margin: '10px 0 10px' }}
                                      component='span'
                                      variant='body2'
                                      color='text.primary'
                                    >
                                      {notification.description}
                                    </Typography>
                                  </StyledSpanDescription>
                                }
                              />
                            </StyledListItem>
                          </StyledContainerlight>
                          <div
                            title={DateTime.fromISO(notification.timestamp).toLocaleString({
                              ...DateTime.DATETIME_FULL,
                              weekday: 'long',
                            })}
                            style={{
                              padding: '4px 8px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <SVGCalendar width={15} height={15} color={'#637DAF'} />
                              <Typography
                                sx={{ color: '#637DAF', fontSize: '12px', marginLeft: '0.5rem' }}
                                component='span'
                              >
                                {DateTime.fromISO(notification.timestamp).toRelative()}
                              </Typography>
                            </div>
                          </div>
                          {!notification.viewed && (
                            <CircleIcon
                              style={{
                                position: 'absolute',
                                top: '6px',
                                right: '6px',
                                width: '8px',
                                height: '8px',
                                color: '#31CFF2',
                              }}
                            />
                          )}
                        </StyledContainerDark>
                      </a>
                      <Button
                        title={'Ocultar notificación'}
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '16px',
                          color: '#637daf',
                          borderRadius: '200px',
                          padding: '2px 10px',
                          fontSize: '12px',
                        }}
                        onClick={() => {
                          changeHidden(notification);
                        }}
                        variant='text'
                        size='small'
                        startIcon={<VisibilityOffIcon />}
                      >
                        Ocultar
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return '';
              }
            })}

          {hasNextPage && (
            <StyledLastDiv>
              {loading ? (
                <Button
                  sx={{ fontWeight: '600' }}
                  color='secondary'
                  onClick={() => {
                    setActualPage((prevActualPage) => prevActualPage + 1);
                  }}
                  size='small'
                  variant='contained'
                >
                  Ver más
                </Button>
              ) : (
                <CircularProgress sx={{ margin: 'auto', color: '#31CFF2' }} ref={setLastElement} />
              )}
            </StyledLastDiv>
          )}
          {!hasNextPage && <StyledLastDiv>Estás al día</StyledLastDiv>}
        </div>
      </StyledListNotifications>
    </ButtonBase>
  );
};

export default Notifications;
