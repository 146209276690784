import styled from '@emotion/styled/macro';

export const StyledDiv = styled('div')`
  & > h3 {
    font-weight: 900;
    color: green;
  }
  & > span {
    margin: 0 10px;
  }
  &:hover {
    color: green;
  }
`;
