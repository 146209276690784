import React, { FC, useEffect, useState } from 'react';
import { IconSelector } from './iconSelector';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';
import { ISkills } from 'components/models/SkillsInterfaces';
import { ISkillsDimensions } from 'components/models/SkillsInterfaces';
import ROL from 'utils/Roles';
import getAxios from 'apiRestConnector';
import CircularProgress from '@mui/material/CircularProgress';

type TInterviewValidator = {
  userRol: string;
  idMentee: string;
  REACT_APP_BACKEND_URL: string;
  access_token: string;
};

type TIconSelectorProps = {
  color: 'error' | 'success';
  toolTipMessage: string;
};

const answeredQuestiosCounter = (
  interviewDetails: CandidateInterface2,
  setLoading: (v: boolean) => void,
  setIconProps: (v: TIconSelectorProps) => void,
  module: string
): boolean => {
  if (interviewDetails) {
    const skills: ISkills[] = interviewDetails?.skills;
    const skillsValidated: ISkillsDimensions[][] = skills.map((skill) => {
      return skill?.dimensiones;
    });
    let count = 0;
    skillsValidated.forEach((dimension) => {
      dimension.forEach((dimension) => {
        if (dimension.valor === undefined) return false;
        if (!isNaN(Number(dimension.valor))) return false;
        if (dimension.valor === '') return false;
        count++;
      });
    });
    if (count >= 3) {
      setIconProps({ color: 'success', toolTipMessage: `Entrevista ${module} completada` });
    }
    setLoading(false);
    return count >= 3;
  } else {
    setLoading(false);
    return false;
  }
};

export const Interviewvalidator: FC<TInterviewValidator> = ({
  userRol,
  idMentee,
  REACT_APP_BACKEND_URL,
  access_token,
}) => {
  const [interviewMentor, setInterviewMentor] = useState<CandidateInterface2>(undefined);
  const [interviewKam, setInterviewKam] = useState<CandidateInterface2>(undefined);
  const [loadingMentor, setLoadingMentor] = useState(true);
  const [loadingKam, setLoadingKam] = useState(true);
  const [iconMentorSelelectorProps, setIconMentorSelelectorProps] = useState<TIconSelectorProps>({
    color: 'error',
    toolTipMessage: `Entrevista Mentor no completada`,
  });
  const [iconKamSelelectorProps, setIconKamSelelectorProps] = useState<TIconSelectorProps>({
    color: 'error',
    toolTipMessage: `Entrevista Kam no completada`,
  });

  useEffect(() => {
    if (userRol === ROL.ADMINISTRADOR || userRol === ROL.KAM || userRol === ROL.MENTOR) {
      getAxios
        .instance(access_token)
        .get(`${REACT_APP_BACKEND_URL}/interview/kam/mentee/${idMentee}`)
        .then((result) => {
          if (!result.data) {
            setLoadingKam(false);
          }
          setInterviewKam(result.data);
        });
    }
  }, [userRol, idMentee, REACT_APP_BACKEND_URL, access_token]);

  useEffect(() => {
    if (userRol === ROL.ADMINISTRADOR || userRol === ROL.KAM || userRol === ROL.MENTOR) {
      getAxios
        .instance(access_token)
        .get(`${REACT_APP_BACKEND_URL}/interview/mentor/mentee/${idMentee}`)
        .then((result) => {
          if (!result.data) {
            setLoadingMentor(false);
          }
          setInterviewMentor(result.data);
        });
    }
  }, [userRol, idMentee, REACT_APP_BACKEND_URL, access_token]);

  if (interviewMentor) {
    setTimeout(() => {
      answeredQuestiosCounter(
        interviewMentor,
        setLoadingMentor,
        setIconMentorSelelectorProps,
        'Mentor'
      );
    }, 2000);
  }
  if (interviewKam) {
    setTimeout(() => {
      answeredQuestiosCounter(interviewKam, setLoadingKam, setIconKamSelelectorProps, 'Kam');
    }, 2000);
  }

  return (
    <>
      <>
        {loadingMentor ? (
          <CircularProgress size={20} />
        ) : (
          <IconSelector {...iconMentorSelelectorProps} />
        )}
      </>
      <>
        {loadingKam ? <CircularProgress size={20} /> : <IconSelector {...iconKamSelelectorProps} />}
      </>
    </>
  );
};
