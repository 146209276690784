import React, { useContext, useEffect, useState, FC } from 'react';
import {
  Paper,
  Modal,
  Typography,
  Box,
  Stack,
  IconButton,
  ButtonGroup,
  useMediaQuery,
  Button,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MeetingRoomOutlined } from '@mui/icons-material';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import getAxios from 'apiRestConnector';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import SeniorityAvatar from '../LevelUp/seniorityAvatar/SeniorityAvatar';
import { InlineWidget } from 'react-calendly';
import { StyledMenteeProfileData } from './MenteeView.styles';
import { useRouteMatch } from 'react-router';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import MenteeProfileBackground from './assets/mentee-profile-background.svg';
import WitiDojoTitle from './assets/witi-dojo.svg';
import ROL from 'utils/Roles';
import { formatNumber } from '../../../helpers/helpers';
import { MenteeActiveSwitch } from './components/menteeActiveSwitch';
import { MenteeProgressIndicators } from './components/menteeProgressIndicators';
import { CandidateInterface2 } from 'components/models/CandidateInterfaces';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  p: 4,
};

interface Props {
  setOpenWitiPoints?: (r: boolean) => void;
  openWitiPoints?: boolean;
  idMentee: string;
  mentee: any;
  options: any;
  reloadWitiPoints?: number;
  typeOfInterview: string;
  currentMenteeUri: string;
  interviewDetails?: CandidateInterface2;
}

const MenteeProfile: FC<Props> = ({
  openWitiPoints,
  setOpenWitiPoints,
  idMentee,
  mentee,
  options,
  reloadWitiPoints,
  typeOfInterview,
  currentMenteeUri,
  interviewDetails,
}) => {
  const match = useRouteMatch();
  const { storeAuth } = useContext(AuthContext)!;
  const { REACT_APP_BACKEND_URL } = process.env;
  const { access_token, role } = storeAuth.user;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMentor: string =
    role?.[0] === ROL.ADMINISTRADOR || role?.[0] === ROL.KAM || role?.[0] === ROL.MENTOR
      ? `/employee/${idMentee}`
      : '';
  const isViewMentor = useRouteMatch('/mentored');
  const [addedWitiPointsMentee, setAddedWitiPointsMentee] = useState('0');
  const [selectedRole, setSelectedRole] = useState<string>();
  const theme = useTheme();
  const matchesLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [belt, setBelt] = useState<string>(mentee.belt);
  const [teams, setTeams] = useState([
    {
      _id: '',
      nombre: '',
    },
  ]);
  const [isActive, setIsActive] = useState(mentee.active ?? true);
  const [switchIsActive, setSwitchIsActive] = useState(false);

  const history = useHistory();
  const urlMentorFeedback: string = 'https://forms.gle/nKqJNJbxFNPRW9ud8';

  const urlRequestCertification: string =
    'https://docs.google.com/forms/d/e/1FAIpQLSerVHoRe8FxpBqtvuRE40fMSBFkGyy0GFEj6wS5eWK2XvTmzw/viewform?usp=sf_link';

  const urlVerComo: string = isViewMentor
    ? match.url.replace('mentored', 'mentee')
    : match.url.replace('mentee', 'mentored');
  const userActive = storeAuth?.user;
  const hasRequiredRole =
    userActive?.role?.[0] === ROL.ADMINISTRADOR ||
    userActive?.role?.[0] === ROL.MENTOR ||
    userActive?.role?.[0] === ROL.KAM;

  const handleMeeting = () => {
    history.push(`/meeting/${idMentee}/${typeOfInterview}`);
  };

  useEffect(() => {
    setIsActive(mentee.active);
  }, [mentee.active]);

  useEffect(() => {
    if (isMentor !== '') {
      localStorage.setItem('currentMenteeUri', currentMenteeUri);
    }
  }, [currentMenteeUri, typeOfInterview, isMentor]);

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      const role = parsedUser.role[0];
      setSelectedRole(role);
    }
  }, [setSelectedRole]);

  useEffect(() => {
    getAxios
      .instance('')
      .get(`${REACT_APP_BACKEND_URL}/witipoints/points/${idMentee}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result) => {
        setAddedWitiPointsMentee(result.data);
      });
  }, [access_token, idMentee, REACT_APP_BACKEND_URL, reloadWitiPoints]);

  useEffect(() => {
    getAxios
      .instance('')
      .get(`${REACT_APP_BACKEND_URL}/team/names/${idMentee}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result) => {
        setTeams(result.data);
      });
  }, [access_token, idMentee, REACT_APP_BACKEND_URL]);

  useEffect(() => {
    if (switchIsActive) {
      const scMentee = structuredClone(mentee);
      scMentee.active = isActive;
      getAxios
        .instance(access_token)
        .patch(`${REACT_APP_BACKEND_URL}/employee`, scMentee)
        .then(() => {
          setSwitchIsActive(false);
        });
    }
  }, [switchIsActive, isActive, mentee, access_token, REACT_APP_BACKEND_URL]);

  return (
    <>
      {mentee && mentee._id === idMentee && (
        <Paper
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 6,
            borderRadius: 8,
            background: `url(${MenteeProfileBackground}) no-repeat top center`,
            backgroundSize: 'cover',
          }}
        >
          <MenteeActiveSwitch
            isActive={isActive}
            setIsActive={setIsActive}
            setSwitchIsActive={setSwitchIsActive}
            userRol={userActive?.role?.[0]}
          />
          <MenteeProgressIndicators
            userRol={userActive?.role?.[0]}
            mentee={mentee}
            access_token={access_token}
            idMentee={idMentee}
            teams={teams}
          />

          <img src={WitiDojoTitle} alt='WiTI Dojo' style={{ width: '100%', maxWidth: '570px' }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: { xs: 'column', lg: 'row' },
              gap: 6,
            }}
          >
            <SeniorityAvatar variant={belt} />
            <Stack gap={3}>
              <Stack justifyContent='center' alignItems={{ xs: 'center', lg: 'flex-start' }}>
                <Typography
                  sx={{
                    fontSize: { xs: '1.5rem', lg: '2.5rem' },
                    fontWeight: 'bold',
                    textAlign: { xs: 'center', lg: 'left' },
                  }}
                >
                  {mentee.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexShrink: 0 }}>
                  <EmojiEventsIcon
                    color='warning'
                    sx={{ fontSize: { xs: '1.5rem', lg: '2rem' } }}
                  />
                  <Typography
                    color='warning.main'
                    sx={{
                      fontSize: { xs: '1.2rem', lg: '1.5rem' },
                      fontWeight: 'bold',
                      textAlign: { xs: 'center', lg: 'left' },
                    }}
                  >
                    WiTI Points:
                    <Box component='span' sx={{ ml: 1 }}>
                      {formatNumber(Number(addedWitiPointsMentee) || 0)}
                    </Box>
                  </Typography>
                  {isMentor && (
                    <Tooltip arrow title='Editar WiTI Points'>
                      <IconButton
                        color='warning'
                        size='small'
                        onClick={() => setOpenWitiPoints(!openWitiPoints)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', lg: 'flex-start' },
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 2,
                }}
              >
                {Boolean(mentee.belt) && (
                  <StyledMenteeProfileData>
                    <Typography>Cinturón</Typography>
                    {Boolean(teams) &&
                      (selectedRole === ROL.MENTOR || selectedRole === ROL.ADMINISTRADOR) && (
                        <Typography>
                          <Select
                            sx={{ height: 23, marginX: 0, minWidth: 100 }}
                            value={belt}
                            label='Cinturon'
                            onChange={(val) => {
                              setBelt(val.target.value);
                              const form = {
                                employeeId: idMentee,
                                belt: val.target.value,
                              };

                              getAxios
                                .instance('')
                                .patch(`${REACT_APP_BACKEND_URL}/employee/belt`, form, {
                                  headers: {
                                    Authorization: `Bearer ${access_token}`,
                                  },
                                })
                                .then((result) => {})
                                .catch((err) => console.log(err));
                            }}
                          >
                            <MenuItem value={'Blanco'}>Blanco</MenuItem>
                            <MenuItem value={'Amarillo'}>Amarillo</MenuItem>
                            <MenuItem value={'Naranja'}>Naranja</MenuItem>
                            <MenuItem value={'Verde'}>Verde</MenuItem>
                            <MenuItem value={'Azul'}>Azul</MenuItem>
                            <MenuItem value={'Morado'}>Morado</MenuItem>
                            <MenuItem value={'Marrón'}>Marrón</MenuItem>
                            <MenuItem value={'Negro'}>Negro</MenuItem>
                          </Select>
                        </Typography>
                      )}
                    {Boolean(teams) &&
                      !(selectedRole === ROL.MENTOR || selectedRole === ROL.ADMINISTRADOR) && (
                        <Typography>{mentee.belt}</Typography>
                      )}
                  </StyledMenteeProfileData>
                )}
                {Boolean(teams) &&
                  teams.length >= 1 &&
                  teams[0].nombre !== '' &&
                  (selectedRole === ROL.MENTOR || selectedRole === ROL.ADMINISTRADOR) && (
                    <StyledMenteeProfileData>
                      <Typography>Equipos:</Typography>
                      <Typography>
                        {teams.map((team, i: number) => {
                          return (
                            <Box
                              key={team._id}
                              sx={{ color: 'white' }}
                              component={RouterLink}
                              to={`/teams/${team._id}/edit`}
                              target='_blank'
                            >
                              {i !== 0 && ', '}
                              {team.nombre}
                            </Box>
                          );
                        })}
                      </Typography>
                    </StyledMenteeProfileData>
                  )}

                {Boolean(mentee.companyEmail) && (
                  <StyledMenteeProfileData>
                    <Typography>Email</Typography>
                    <Typography>{mentee.companyEmail}</Typography>
                  </StyledMenteeProfileData>
                )}
                {Boolean(mentee.location) && (
                  <StyledMenteeProfileData>
                    <Typography>Ubicación</Typography>
                    <Typography>{mentee.location}</Typography>
                  </StyledMenteeProfileData>
                )}
                {Boolean(mentee.cell) && (
                  <StyledMenteeProfileData>
                    <Typography>Célula</Typography>
                    <Typography>{mentee.cell}</Typography>
                  </StyledMenteeProfileData>
                )}
              </Box>

              <ButtonGroup
                variant='outlined'
                size='small'
                orientation={`${matchesLgScreen ? `horizontal` : `vertical`}`}
              >
                <Button onClick={handleOpen} startIcon={<EditCalendarIcon />}>
                  Agendar Sesión
                </Button>
                <Button href={urlMentorFeedback} target='_blank' startIcon={<FeedbackIcon />}>
                  Feedback {typeOfInterview}
                </Button>
                <Button
                  href={urlRequestCertification}
                  target='_blank'
                  startIcon={<WorkspacePremiumIcon />}
                >
                  Solicitar Certificación
                </Button>
              </ButtonGroup>
              <ButtonGroup
                variant='outlined'
                size='small'
                orientation={`${matchesLgScreen ? `horizontal` : `vertical`}`}
              >
                {isMentor &&
                  (selectedRole === ROL.MENTOR || selectedRole === ROL.ADMINISTRADOR) && (
                    <Button
                      component={RouterLink}
                      to={`/mentored/${idMentee}/roadmap`}
                      startIcon={<EditIcon />}
                    >
                      Editar Roadmap
                    </Button>
                  )}
                {hasRequiredRole && (
                  <Button startIcon={<MeetingRoomOutlined />} onClick={handleMeeting}>
                    Meeting
                  </Button>
                )}
                {(selectedRole === ROL.MENTOR || selectedRole === ROL.ADMINISTRADOR) && (
                  <Button component={RouterLink} to={urlVerComo} startIcon={<VisibilityIcon />}>
                    {isViewMentor ? 'Ver como Mentee' : 'Ver como Mentor'}
                  </Button>
                )}
              </ButtonGroup>
            </Stack>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Paper sx={style}>
              <Typography id='modal-modal-title' variant='h5' component='h2' align='center'>
                Agendar Sesión
              </Typography>
              <Box id='modal-modal-description' sx={{ mt: 2 }}>
                <InlineWidget url='https://calendly.com/macarena-rebolledo/30min' />
              </Box>
            </Paper>
          </Modal>
        </Paper>
      )}
    </>
  );
};

export default MenteeProfile;
