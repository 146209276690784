import React, { FC } from 'react';
import { StyledContainer } from './style';
import FilterButtonTableCard from '../filterButtonTableCard/FilterButtonTableCard';

interface Props {
  getSortedCanditatesByParamenter: (key: string) => void;
  orderedCandidate: boolean;
}

const TableHeadRow: FC<Props> = ({ getSortedCanditatesByParamenter, orderedCandidate }) => {
  return (
    <StyledContainer>
      <FilterButtonTableCard
        onClick={getSortedCanditatesByParamenter}
        state={orderedCandidate}
        onClickParameter='Rol'
        label='Rol'
      />
      <FilterButtonTableCard
        onClick={getSortedCanditatesByParamenter}
        state={orderedCandidate}
        onClickParameter='Nivel'
        label='Nivel'
      />
      <FilterButtonTableCard
        onClick={getSortedCanditatesByParamenter}
        state={orderedCandidate}
        onClickParameter='estado'
        label='Etapa'
      />
      <FilterButtonTableCard
        onClick={getSortedCanditatesByParamenter}
        state={orderedCandidate}
        onClickParameter='timestamp'
        label='Fecha etapa actual'
      />
      <FilterButtonTableCard
        onClick={getSortedCanditatesByParamenter}
        state={orderedCandidate}
        onClickParameter='workflowInicial'
        label='Fecha inicio'
      />
    </StyledContainer>
  );
};

export default TableHeadRow;
