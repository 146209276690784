import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Spinner = ({ color }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color={color} />
    </Box>
  );
};

export default Spinner;
