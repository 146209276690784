import React, { FC, useEffect, useState } from 'react';
import { StyledDivTooltip, StyledTaskCardBar, StyledTaskCardCircleBar } from './style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Box } from '@mui/material';
import { EstadosCandidateInterface } from 'components/models/requestCandidate';

const AssociateCandidates: FC<Props> = ({ candidatesArray }) => {
  const [namesCandidates, setNamesCandidates] =
    useState<EstadosCandidateInterface[]>(candidatesArray);

  useEffect(() => {
    setNamesCandidates(candidatesArray);
  }, [candidatesArray]);

  const cantidadCandidatos = (estadoWorkflow: string, estados: EstadosCandidateInterface[]) => {
    let contador = 0;
    estados.forEach((estado: EstadosCandidateInterface) => {
      if (estado.nombre === estadoWorkflow) {
        contador = estado.candidatos.length;
      }
    });
    return contador;
  };

  const renderTooltip = (estado: string, candidatos: EstadosCandidateInterface[]) => (
    <Tooltip id='button-tooltip'>
      <StyledDivTooltip>
        {candidatos.map(
          (e: EstadosCandidateInterface) =>
            e?.nombre === estado &&
            e.candidatos.map((candidato) => {
              return (
                <div
                  key={Math.random()}
                  style={{ display: 'flex', textAlign: 'left', padding: '4px 8px 4px 4px' }}
                >
                  {candidato}
                </div>
              );
            })
        )}
      </StyledDivTooltip>
    </Tooltip>
  );

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <StyledTaskCardBar>
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(' inicial', namesCandidates)}
        >
          <StyledTaskCardCircleBar
            style={cantidadCandidatos(' inicial', namesCandidates) ? active : inactive}
          >
            {cantidadCandidatos(' inicial', namesCandidates)}
          </StyledTaskCardCircleBar>
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip('Soft Skills', namesCandidates)}
        >
          <StyledTaskCardCircleBar
            style={cantidadCandidatos('Soft Skills', namesCandidates) ? active : inactive}
          >
            {cantidadCandidatos('Soft Skills', namesCandidates)}
          </StyledTaskCardCircleBar>
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip('Técnica', namesCandidates)}
        >
          <StyledTaskCardCircleBar
            style={cantidadCandidatos('Técnica', namesCandidates) ? active : inactive}
          >
            {cantidadCandidatos('Técnica', namesCandidates)}
          </StyledTaskCardCircleBar>
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip('Cliente', namesCandidates)}
        >
          <StyledTaskCardCircleBar
            style={cantidadCandidatos('Cliente', namesCandidates) ? active : inactive}
          >
            {cantidadCandidatos('Cliente', namesCandidates)}
          </StyledTaskCardCircleBar>
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip('Contratado', namesCandidates)}
        >
          <StyledTaskCardCircleBar
            style={cantidadCandidatos('Contratado', namesCandidates) ? active : inactive}
          >
            {cantidadCandidatos('Contratado', namesCandidates)}
          </StyledTaskCardCircleBar>
        </OverlayTrigger>
      </StyledTaskCardBar>
    </Box>
  );
};
export default AssociateCandidates;

interface Props {
  candidatesArray?: EstadosCandidateInterface[];
}

const active = {
  background: 'rgb(255 197 121)',
};

const inactive = {
  color: 'black',
  //color: '#ededed',
};
