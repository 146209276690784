import React from 'react';
import { Typography, Stack, StackProps } from '@mui/material';

type PageHeaderProps = StackProps & {
  children: React.ReactNode;
};

function PageHeader({ children, ...restProps }: PageHeaderProps) {
  return (
    <Stack mb={2} {...restProps}>
      <Typography component='h1' variant='h5' fontWeight='bold'>
        {children}
      </Typography>
    </Stack>
  );
}

export default PageHeader;
