import { List, ListItem } from '@mui/material';
import emotionStyled from '@emotion/styled/macro';

export const StyledListItem = emotionStyled(ListItem)`
  width: auto;
  border-radius: 8px;
`;

export const StyledLastDiv = emotionStyled('div')`
  display: grid;
  place-content: center;
  text-align: center;
  margin: 5px auto 3px;
  color: #637daf;
  user-select: none;
`;

export const StyledDivImage = emotionStyled('div')`
  display: grid;
  place-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
`;

export const StyledDivDot = emotionStyled('div')`
  position: absolute;
  top: 3px;
  left: 25px;
  color: white;
`;

export const StyledListNotifications = emotionStyled(List)`
  height: 672px;
  max-width: 435px;
  min-width: 435px;
  color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 63px;
  right: 0;
  box-sizing: border-box;
  background: radial-gradient(circle, rgb(28, 67, 110) 0%, rgb(29, 46, 67) 100%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: rgba(49, 207, 242, 0.32);
  border-radius: 8px;

  @media screen and (max-width: 635px) {
    position: fixed;
    top: 58px;
    right: 0;
    max-width: 100vw;
    min-width: 100vw;
    height: calc(100dvh - 58px);
    border: none;
    border-radius: 0;
  }
`;

export const StyledDivDot2 = emotionStyled('div')`
  display: grid;
  place-items: center;
  padding: 0 5px;
  min-width: 20px;
  min-height: 20px;
  font-size: 13px;
  border-radius: 200px;
  background-color: red;
`;

export const StyledSpanDescription = emotionStyled('span')`
  display: flex;
  flex-direction: column;
`;

export const StyledContainerDark = emotionStyled('div')`
  background-color: #0f171e;
  margin: 10px auto;
  border-radius: 8px;
  padding: 7px;
  border: 2px solid transparent;

  &:hover {
    background-color: #121c25;
    border-color: #2b95ac;
  }

  &:hover li {
    background-color: rgba(55, 80, 108, 0.24);
  }
`;

export const StyledContainerlight = emotionStyled('div')`
  background-color: #151e29;
  margin: 7px;
  border-radius: 8px;
`;

export const ListNotificationHeader = emotionStyled('div')`
  margin: 16px;
  display: flex;
  justify-content: space-between;
`;
