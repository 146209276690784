import React, { useContext, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Tooltip, Switch, Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import useLocalStorageState from '../../../../hooks/useLocalStorageState';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import getAxios from 'apiRestConnector';
import { mapDataToRows } from './MentoringTable.helpers';
import { columns } from './MentoringTableColumns';
import ROL from 'utils/Roles';

const MentoringTable = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token, role } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;

  const [sorting, setSorting] = useLocalStorageState<MRT_SortingState>({
    storageKey: 'levelup.mentoringTable.sorting',
    initialState: [],
  });
  const [isActive, setIsActive] = useState(true);

  const {
    data = [],
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['employee', access_token, isActive],

    queryFn: async () => {
      const fetchURLMenteesTable = new URL('/mentor', REACT_APP_BACKEND_URL);
      fetchURLMenteesTable.searchParams.append('isActive', isActive.toString());
      const employees = await getAxios.instance(access_token).get(fetchURLMenteesTable.href);
      return employees.data;
    },
    select: (data) => mapDataToRows(data),
    placeholderData: keepPreviousData,
  });

  const ToolbarCustomActions = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Tooltip title='Refrescar datos'>
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      {(role?.[0] === ROL.ADMINISTRADOR || role?.[0] === ROL.KAM) && (
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '10px' }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Inactivo</Typography>
          <Switch
            checked={isActive}
            onChange={(event) => {
              setIsActive(event.target.checked);
            }}
          />
          <Typography sx={{ fontWeight: 'bold' }}>Activo</Typography>
        </Box>
      )}
    </Box>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
    },
    state: {
      isLoading: isLoading,
      showProgressBars: isRefetching,
      showLoadingOverlay: false,
      showAlertBanner: isError,
      sorting,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error cargando los datos',
        }
      : undefined,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: ToolbarCustomActions,
    muiTopToolbarProps: {
      justifyContent: 'flex-start',
    },
    positionGlobalFilter: 'left',
    layoutMode: 'grid',
    enableGlobalFilterRankedResults: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    enableBottomToolbar: false,
    localization: MRT_Localization_ES,
  });

  return <MaterialReactTable table={table} />;
};

export default MentoringTable;
