import React, { useState, useContext, useEffect, useRef } from 'react';
import getAxios from 'apiRestConnector';
import { Eye, Pencil, Phone } from 'react-bootstrap-icons';
import { TemplateInterface } from 'components/models/CandidateInterfaces';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { DateTime } from 'luxon';
import noFound from 'assets/witi-nofound.svg';
import { phoneTableClick } from 'components/ui/phone/phoneFunction';
import { flagFunction } from 'helpers/flags';
import TableInvitations from 'components/ui/tables/tablesInvitations/TableInvitations';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination/Pagination';
import { PaginatorSearchOptionsInterface } from 'components/models/PaginatorSearchOptionsInterface';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PageHeader from '../../ui/PageHeader';
import SwalAlert from 'components/ui/alerts/SwalAlert';

const Invitations = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_INVITATION_PER_PAGE, REACT_APP_BACKEND_URL } = process.env;
  const [loading, setLoading] = useState(true);
  const [orderedCandidate, setOrderedCandidate] = useState<boolean>(true);
  const [filteredCandidates, setFilteredCandidates] = useState<TemplateInterface[]>([]);
  const [filterCandidateName, setFilterCandidateName] = useState<string>(String);
  const [filterShowName, setFilterShowName] = useState<string>(String);
  const [refrescaCandidatos, setRefrescaCandidatos] = useState(false);
  const [searched, setSearched] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [limit] = useState<number>(parseInt(REACT_APP_INVITATION_PER_PAGE));
  const [searchedName, setSearchedName] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const searchButtonRef = useRef(null);
  const theme = useTheme();

  function getCandidatesByName(params: PaginatorSearchOptionsInterface) {
    const urlBackend = `${REACT_APP_BACKEND_URL}/personas/nombre`;
    getAxios
      .instance('')
      .get(urlBackend, {
        params,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((result: any) => result.data)
      .then((data: any) => {
        setFilteredCandidates(data.personas);
        setTotalPages(data.totalPages);
        setTotalDocs(data.totalDocs);
        setRefrescaCandidatos(false);
      })
      .catch((error) => {
        console.error('error:', error.message);
        SwalAlert({
          title: 'Error en la consulta',
          icon: 'error',
          confirmButtonText: 'Entendido',
          showConfirmButton: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getPersona = (e: any) => {
    e.preventDefault();
    if (e.key === 'Enter' || e.type === 'click') {
      setLoading(true);
      setPage(1);
      setTotalPages(1);
      setSearchedName(e.target.value);
      const params: PaginatorSearchOptionsInterface = {
        nombre: e.target.value,
        limit,
        page: 1,
      };
      getCandidatesByName(params);
    }
  };

  const getPersonaByPage = (limit: number, page: number) => {
    setLoading(true);
    const params: PaginatorSearchOptionsInterface = {
      nombre: searchedName,
      limit,
      page,
    };
    getCandidatesByName(params);
  };

  const filterName = (filterlist: any) => {
    return filterlist.filter((candidate: any) => {
      const removeAccents = candidate.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const toLowerCase = removeAccents.toLowerCase();
      return toLowerCase?.includes(filterCandidateName.toLowerCase());
    });
  };

  const tableContent = () => {
    return filterName(filteredCandidates).map((candidate: any) => {
      return {
        name: {
          data: candidate.nombre,
          href: `/candidate/${candidate.telefono}/edit`,
        },
        state: {
          data: candidate.estado,
          workflow: candidate.workflow,
        },
        date: {
          data: candidate.timestamp,
          invitacion: candidate.fechaUltimaInvitacion,
        },
        personalInfo: {
          data: candidate.datos,
          skills: candidate.skills,
          state: candidate.activo,
          softSkills: candidate.competencias,
          footer: candidate.footer,
          fono: candidate.telefono,
        },
        options: [
          {
            icon: <Eye />,
            href: `/candidate/${candidate.telefono}/view`,
          },
          {
            icon: <Pencil />,
            href: `/candidate/${candidate.telefono}/edit`,
          },
          {
            icon: <Phone />,
            href: '',
            action: () => {
              return phoneTableClick(candidate.nombre, candidate.telefono);
            },
          },
          {
            icon: (
              <img
                style={{ width: '20px' }}
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${flagFunction(
                  candidate.telefono.toString()
                )}.svg`}
                alt='Pais'
              />
            ),
          },
        ],
      };
    });
  };

  const getSortedCanditatesByParamenter = (key: string) => {
    if (key === 'estado') {
      if (!orderedCandidate) {
        filteredCandidates.sort((c1: any, c2: any) =>
          (c1[key].toUpperCase().trim() === 'INICIAL'
            ? 1
            : c1[key].toUpperCase().trim() === 'SOFT SKILLS'
              ? 2
              : c1[key].toUpperCase().trim() === 'TÉCNICA'
                ? 3
                : c1[key].toUpperCase().trim() === 'CLIENTE'
                  ? 4
                  : c1[key].toUpperCase().trim() === 'CONTRATADO'
                    ? 5
                    : 6) >
          (c2[key].toUpperCase().trim() === 'INICIAL'
            ? 1
            : c2[key].toUpperCase().trim() === 'SOFT SKILLS'
              ? 2
              : c2[key].toUpperCase().trim() === 'TÉCNICA'
                ? 3
                : c2[key].toUpperCase().trim() === 'CLIENTE'
                  ? 4
                  : c2[key].toUpperCase().trim() === 'CONTRATADO'
                    ? 5
                    : 6)
            ? -1
            : 1
        );
      } else {
        filteredCandidates.sort((c1: any, c2: any) => {
          if (
            (c1[key].toUpperCase().trim() === 'INICIAL'
              ? 1
              : c1[key].toUpperCase().trim() === 'SOFT SKILLS'
                ? 2
                : c1[key].toUpperCase().trim() === 'TÉCNICA'
                  ? 3
                  : c1[key].toUpperCase().trim() === 'CLIENTE'
                    ? 4
                    : c1[key].toUpperCase().trim() === 'CONTRATADO'
                      ? 5
                      : 6) <
            (c2[key].toUpperCase().trim() === 'INICIAL'
              ? 1
              : c2[key].toUpperCase().trim() === 'SOFT SKILLS'
                ? 2
                : c2[key].toUpperCase().trim() === 'TÉCNICA'
                  ? 3
                  : c2[key].toUpperCase().trim() === 'CLIENTE'
                    ? 4
                    : c2[key].toUpperCase().trim() === 'CONTRATADO'
                      ? 5
                      : 6)
          ) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    }

    if (key === 'timestamp') {
      if (!orderedCandidate) {
        filteredCandidates.sort((c1: any, c2: any) => {
          return c1[key] > c2[key] ? 1 : -1;
        });
      } else {
        filteredCandidates.sort((c1: any, c2: any) => {
          return c1[key] < c2[key] ? 1 : -1;
        });
      }
    }

    if (key === 'fechaUltimaInvitacion') {
      if (!orderedCandidate) {
        filteredCandidates.sort((c1: any, c2: any) => {
          let value1 = DateTime.now();
          let value2 = DateTime.now();
          c1[key] === undefined ? value1.plus({ days: 1 }) : (value1 = DateTime.fromISO(c1[key]));
          c2[key] === undefined ? value2.plus({ days: 1 }) : (value2 = DateTime.fromISO(c2[key]));
          return value1.valueOf() > value2.valueOf() ? 1 : -1;
        });
      } else {
        filteredCandidates.sort((c1: any, c2: any) => {
          let value1 = DateTime.now();
          let value2 = DateTime.now();
          c1[key] === undefined ? value1.plus({ days: 1 }) : (value1 = DateTime.fromISO(c1[key]));
          c2[key] === undefined ? value2.plus({ days: 1 }) : (value2 = DateTime.fromISO(c2[key]));
          return value1.valueOf() > value2.valueOf() ? -1 : 1;
        });
      }
    }

    if (key === 'workflowInicial') {
      filteredCandidates.sort((c1: any, c2: any) => {
        let valorc1;
        let valorc2;

        try {
          valorc1 = DateTime.fromISO(c1.workflow[0].fecha).isValid
            ? DateTime.fromISO(c1.workflow[0].fecha)
            : DateTime.now();
        } catch (error) {
          valorc1 = DateTime.now();
        }

        try {
          valorc2 = DateTime.fromISO(c2.workflow[0].fecha).isValid
            ? DateTime.fromISO(c2.workflow[0].fecha)
            : new DateTime.now();
        } catch (error) {
          valorc2 = DateTime.now();
        }

        if (valorc1.valueOf() > valorc2.valueOf()) {
          return orderedCandidate ? -1 : 1;
        } else {
          return orderedCandidate ? 1 : -1;
        }
      });
    }

    if (key === 'Responsable') {
      if (!orderedCandidate) {
        filteredCandidates.sort((c1: any, c2: any) => {
          return (c1.workflow[0].responsable !== undefined ? c1.workflow[0].responsable : null) >
            (c2.workflow[0].responsable !== undefined ? c2.workflow[0].responsable : null)
            ? 1
            : -1;
        });
      } else {
        filteredCandidates.sort((c1: any, c2: any) => {
          return (c1.workflow[0].responsable !== undefined ? c1.workflow[0].responsable : null) <
            (c2.workflow[0].responsable !== undefined ? c2.workflow[0].responsable : null)
            ? 1
            : -1;
        });
      }
    }
    if (key === 'Rol' || key === 'Nivel') {
      if (!orderedCandidate) {
        filteredCandidates.sort((c1: any, c2: any) => {
          const valorc1 = c1.datos.map((c11: any) => {
            if (c11.nombre === key) {
              return c11.valor;
            } else {
              return '';
            }
          });
          const valorc2 = c2.datos.map((c22: any) => {
            if (c22.nombre === key) {
              return c22.valor;
            } else {
              return '';
            }
          });
          if (valorc1.filter((c1: any) => c1 !== '') > valorc2.filter((c1: any) => c1 !== '')) {
            return -1;
          } else {
            return 1;
          }
        });
      } else {
        filteredCandidates.sort((c1: any, c2: any) => {
          const valorc1 = c1.datos.map((c11: any) => {
            if (c11.nombre === key) {
              return c11.valor;
            } else {
              return '';
            }
          });
          const valorc2 = c2.datos.map((c22: any) => {
            if (c22.nombre === key) {
              return c22.valor;
            } else {
              return '';
            }
          });
          if (valorc1.filter((c1: any) => c1 !== '') < valorc2.filter((c1: any) => c1 !== '')) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    }
    setOrderedCandidate(!orderedCandidate);
  };

  function getCandidatesByPage(e: React.ChangeEvent, pageNumber: number) {
    setPage(pageNumber);
    getPersonaByPage(limit, pageNumber);
  }

  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    getPersona(event);
    if (!searched) {
      setSearched(true);
    }
  }

  useEffect(() => {
    searchButtonRef.current?.click();
  }, []);

  return (
    <Box>
      <PageHeader>Invitaciones</PageHeader>
      <TextField
        id='FilterName'
        type='text'
        placeholder='Filtre por nombre'
        name='name'
        className='FilterName'
        variant='outlined'
        value={filterShowName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFilterShowName(e.target.value);
          setFilterCandidateName(e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        }}
        onKeyUp={getPersona}
        onKeyDown={(e: any) => e.key === 'Enter' && e.preventDefault()}
        sx={{
          borderRadius: '10px',
          marginBottom: '10px',
          width: '100%',
          backgroundColor: theme.palette.mode === 'light' ? 'white' : 'default',
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
        <Button
          style={{ width: '50%', margin: 'auto' }}
          value={filterShowName}
          onClick={handleButtonClick}
          ref={searchButtonRef}
          variant='contained'
        >
          Buscar
        </Button>
        {tableContent().length >= 0 && filteredCandidates.length > 0 && (
          <Chip
            label={`Se han encontrado ${totalDocs} registros.`}
            sx={{
              margin: '15px auto',
              textAlign: 'center',
              padding: '10px',
              borderRadius: '5px',
              width: '400px',
              backgroundColor: theme.palette.mode === 'light' ? 'white' : 'default',
            }}
            variant='filled'
          />
        )}
      </div>
      <>
        <TableInvitations
          contents={tableContent()}
          loading={loading}
          {...{
            getSortedCanditatesByParamenter,
            orderedCandidate,
            refrescaCandidatos,
            setRefrescaCandidatos,
          }}
        />
      </>

      {Object.keys(filteredCandidates).length >= 0 &&
        !loading &&
        searched &&
        tableContent().length === 0 && (
          <div
            style={{
              width: '100%',
              height: '55vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={noFound}
              alt='No se encuentra la informacion'
              style={{ width: 250, opacity: 0.7 }}
            />
          </div>
        )}
      <Box sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            padding: '1rem',
            borderRadius: '0.5rem',
            display: 'inline-block',
            margin: '1rem auto',
            backgroundColor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
          }}
        >
          <Pagination
            onChange={getCandidatesByPage}
            page={page}
            defaultPage={1}
            count={totalPages}
            variant='outlined'
            color='primary'
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? 'grey.900' : 'white',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Invitations;
