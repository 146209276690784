import React, { createContext, useContext, useState } from 'react';

export type WorkflowEventContextType = {
  messageInput: string;
  setMessageInput: (value: string) => void;
  clearMessageInput: () => void;
};

export const WorkflowEventContext = createContext<WorkflowEventContextType | undefined>(undefined);

export const useWorkflowEventContext = () => {
  const context = useContext(WorkflowEventContext);
  if (!context) {
    throw new Error('useWorkflowEventContext must be used within a WorkflowEventProvider');
  }
  return context;
};

export const WorkflowEventProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messageInput, setMessageInput] = useState('');

  const clearMessageInput = () => {
    setMessageInput('');
  };

  return (
    <WorkflowEventContext.Provider value={{ messageInput, setMessageInput, clearMessageInput }}>
      {children}
    </WorkflowEventContext.Provider>
  );
};
