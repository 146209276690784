import React, { FC } from 'react';
import { StyledLoadingContainer } from './Loading.styles';

interface Props {
  children: React.ReactNode;
}

const Loading: FC<Props> = ({ children }) => (
  <StyledLoadingContainer>{children}</StyledLoadingContainer>
);

export default Loading;
