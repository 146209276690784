import React, { FC } from 'react';
import { Line } from './style';

interface Props {}

const Separador: FC<Props> = () => {
  return <Line></Line>;
};

export default Separador;
