import styled from '@emotion/styled/macro';

export const StyledContainer = styled.div`
  @media print {
    display: none;
  }
`;
export const StyledMessage = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
  position: fixed;
  bottom: 110px;
  right: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  color: white;
  border-radius: 5px;
  & > h5 {
    border-bottom: 1px solid white;
  }
  & > div {
    font-size: 12px;
  }
`;

export const StyledButton = styled.span<{ like: boolean }>`
  cursor: pointer;
  background-color: ${({ like }) => (like ? '#31CFF2' : 'rgba(55, 80, 108, 0.12)')};
  backdrop-filter: blur(5px);
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 999px;
  color: white;
  width: 90px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease;
  border: 2px solid white;
  &:hover {
    background-color: rgb(252, 209, 59);
    border: none;
    width: 200px;
    & > svg {
      fill: #151e29;
    }
    & circle {
      color: #151e29;
    }
    & > span {
      display: flex;
      opacity: 1;
    }
  }
  & > span {
    opacity: 0;
    display: none;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #151e29;
    transition: opacity 300ms ease;
    white-space: nowrap;
    overflow: hidden;
  }
  & circle {
    color: white;
  }
`;
