import React, { createContext, ComponentType, Component } from 'react';
import { IAuthContext } from '../../components/models/AuthContextInterfaces';
import getState from './authContextDefinitions';

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IProps {}

const AuthContextProvider = (PassedComponent: ComponentType) => {
  class StoreWrapper extends Component<{}, IAuthContext> {
    constructor(props: IProps) {
      super(props);
      this.state = getState({
        getStore: () => this.state.storeAuth,
        getActions: () => this.state.actionsAuth,
        setStore: (updatedStore) => {
          this.setState((prevState) => ({
            storeAuth: { ...structuredClone(prevState.storeAuth), ...updatedStore },
          }));
        },
      });
    }
    render() {
      return (
        <AuthContext.Provider value={this.state}>
          <PassedComponent {...this.props} />
        </AuthContext.Provider>
      );
    }
  }
  return StoreWrapper;
};

export default AuthContextProvider;
