import React, { FC } from 'react';
import { Button } from '@mui/material';
import { DivGlowEffectSubmitButton } from './style';
import SaveIcon from '@mui/icons-material/Save';

type GlowingSaveButtonProps = {
  isDirty: boolean;
};

export const GlowingSaveButton: FC<GlowingSaveButtonProps> = ({ isDirty }) => {
  return (
    <DivGlowEffectSubmitButton className={isDirty ? 'glow-button' : ''}>
      <Button variant='contained' size='large' type='submit' startIcon={<SaveIcon />}>
        Guardar entrevista
      </Button>
    </DivGlowEffectSubmitButton>
  );
};
