import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const StyledIcon = styled.span`
  & > * {
    color: #2857a7;
    cursor: pointer;
    font-size: 20px;
    margin: 20px 10px;
    transition: color 200ms ease;
    text-decoration: none;

    &:hover {
      color: #f15b29;
    }
  }
`;

export const StyledIconSpan = styled.span`
  color: #2857a7;
  cursor: pointer;
  font-size: 20px;
  margin: 20px 10px;
  transition: color 200ms ease;
  &:hover {
    color: #f15b29;
  }
`;

export const StyledTd = styled.td`
  color: #2857a7;
  background-color: transparent !important;
  text-align: center;
  font-weight: 600;
  transform: scale(1);
  transition: 200ms ease;
  &:hover {
    color: #f15b29;
    transform: scale(1.05);
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 600;
  color: #2857a7;
  text-decoration: none !important;
  transition: 200ms ease;
  &:hover {
    color: #f15b29;
  }
`;

export const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: 170px;
`;

export const InputNumber = styled.div`
  font-weight: 600;
  width: 40%;
  border: 1px solid #8888d9;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
  background: white;
`;
export const ButtonPlus = styled.button`
  width: 25%;
  height: 28px;
  border: none;
  background: #5789df;
  color: white;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
export const ButtonRest = styled.button`
  width: 25%;
  height: 28px;
  border: none;
  background: #5789df;
  color: white;
  font-weight: 600;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;
