import { styled } from '@mui/material';

export const StyledDetailsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontStyle: 'normal',
  paddingBottom: '110px',

  '.SkillCard': {
    background: 'red',
  },
});

export const StyledDetailsTitleCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledVirtuesDefectsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '20px',
  marginTop: '63px',
});

export const StyledDetailsTitleSkillsContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexWrap: 'wrap',
  marginTop: '63px',
});

export const StyledDetailsSkillsTitle = styled('p')({
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  color: '#FECB16',
});

export const CompetenciasContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexwrap: 'wrap',
  gap: '12px',
  alignItems: 'flex-start',
  padding: '16px 24px',
  backgroundColor: 'rgba(55, 80, 108, 0.12)',
  borderWidth: '1px 0 0 1px',
  borderStyle: 'solid',
  borderColor: 'rgba(49, 207, 242, 0.32)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  borderRadius: '8px',
  flex: '2 1 372px',
});

export const CompetenciasButton = styled('div')({
  display: 'flex',
  flexwrap: 'wrap',
  gap: '4px',
  width: '100%',
  padding: ' 4px 16px',
  background: '#80A2DD',
  borderRadius: '4px',
  justifyContent: 'center',
  fontWeight: 700,
});

export const ButtonTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '700',
  color: '#0D1116',
});

export const CompetenciasInfo = styled('div')({
  color: 'white',
  width: '100%',
});

export const ColorLi = styled('li')({
  color: 'white',
  '&:not(:last-child)': {
    marginBottom: '4px',
  },
});

export const CompetenciasUL = styled('ul')({
  paddingLeft: '1rem',
});
