import React from 'react';
import { Autocomplete, Stack, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import type { TeamFormStepProps, ITeam } from '../../../CreateTeam.types';
import type { Mentee } from '../../../../../ui/MenteeView/MenteeView.types';

type TeamFormAddMembersProps = TeamFormStepProps & {
  data: Mentee[];
  handleOpenModal: (isTL: boolean) => void;
  loading: boolean;
};

const TeamFormAddMembers = ({
  teamInfo,
  setTeamInfo,
  data,
  loading,
  handleOpenModal,
}: TeamFormAddMembersProps) => {
  return (
    <Stack gap={4} mt={4}>
      <Stack direction='row' alignItems='center' gap={2}>
        <Autocomplete
          multiple
          loading={loading}
          value={teamInfo.lider_tecnico_ids}
          options={data
            .filter((option) => {
              return option.isMentor;
            })
            .map((option) => {
              return { id: option._id, nombre: option.name };
            })}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.nombre}
          getOptionKey={(option) => option.id}
          onChange={(event, newInputValue) => {
            setTeamInfo((prevTeamInfo) => {
              const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
              return {
                ...newTeamInfo,
                lider_tecnico_ids: newInputValue,
              };
            });
          }}
          sx={{ width: '80%' }}
          renderInput={(params) => (
            <TextField {...params} label='Líder Técnico' placeholder='Líder Técnico' />
          )}
        />
        <IconButton
          title='Agregar nuevo Líder Técnico'
          onClick={() => {
            handleOpenModal(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction='row' alignItems='center' gap={2}>
        <Autocomplete
          multiple
          loading={loading}
          value={teamInfo.integrantes_ids}
          options={data.map((option) => {
            return { id: option._id, nombre: option.name };
          })}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.nombre}
          getOptionKey={(option) => option.id}
          onChange={(event, newInputValue) => {
            setTeamInfo((prevTeamInfo) => {
              const newTeamInfo: ITeam = structuredClone(prevTeamInfo);
              return {
                ...newTeamInfo,
                integrantes_ids: newInputValue,
              };
            });
          }}
          sx={{ width: '80%' }}
          renderInput={(params) => (
            <TextField {...params} label='Integrantes' placeholder='Integrantes' />
          )}
        />
        <IconButton
          title='Agregar nuevo integrante'
          onClick={() => {
            handleOpenModal(false);
          }}
        >
          <AddIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default TeamFormAddMembers;
