import { useEffect, useCallback } from 'react';

/**
 * Hook personalizado para manejar el atajo de teclado "Command+S" en macOS o "Control+S" en Windows.
 * Principalmente se usa para guardar los cambios en un formulario.
 *
 * @param {() => void} callback - La función que se debe ejecutar cuando se presiona el atajo de teclado.
 *
 * @example
 * useSaveShortcut(() => console.log('Guardado!'));
 */
function useSaveShortcut(callback: () => void): void {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}

export default useSaveShortcut;
