export const roomInfo = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  gap: '2rem',
};

export const warningMessage = {
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem',
  width: '60%',
  alignItems: 'center',
};

export const headerModal = {
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem',
  alignItems: 'center',
};

export const warningIcon = {
  fontSize: '4rem',
  color: 'yellow',
};

export const titleModal = {
  fontWeight: 'bold',
};

export const bodyModal = { textAlign: 'center', textWrap: 'balance' };

export const buttonModal = {
  color: 'white',
};
