import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import AdminImage from 'assets/admin-role.svg';
import UserImage from 'assets/user-role.svg';
import RecruiterImage from 'assets/recruiter-role.svg';
import MentorImage from 'assets/mentor-role.svg';
import KamImage from 'assets/kam-role.svg';
import MenteeImage from 'assets/mentee-role.svg';
import ROL from 'utils/Roles';

import './style.css';

interface Props {
  role: ROL;
}

const roleImages = {
  [ROL.ADMINISTRADOR]: AdminImage,
  [ROL.USUARIO]: UserImage,
  [ROL.RECLUTADOR]: RecruiterImage,
  [ROL.MENTOR]: MentorImage,
  [ROL.MENTEE]: MenteeImage,
  [ROL.KAM]: KamImage,
};

const RoleButton: FC<Props> = ({ role }) => {
  const { actionsAuth, storeAuth } = useContext(AuthContext)!;
  const { user } = storeAuth;
  const [selectedRole] = useState(role);
  const [roleImage, setRoleImage] = useState<string>();
  const [savedRole, setSavedRole] = useState<string>();

  useEffect(() => {
    setRoleImage(roleImages[role]);
  }, [role]);

  const setNewRoleArray = useCallback(
    (localUserInfo: string): ROL[] => {
      const localRoles: ROL[] = JSON.parse(localUserInfo).role;
      localRoles.splice(0, 0, selectedRole);
      return Array.from(new Set(localRoles));
    },
    [selectedRole]
  );

  const setNewRole = useCallback(() => {
    const localUserInfo = localStorage.getItem('user');
    if (actionsAuth && user && selectedRole && localUserInfo) {
      const newLocalRoles = setNewRoleArray(localUserInfo);
      if (newLocalRoles) {
        user.role = newLocalRoles;
      }
      localStorage.setItem('user', JSON.stringify(user));
      actionsAuth.updateUserState();
    }
  }, [actionsAuth, user, selectedRole, setNewRoleArray]);

  useEffect(() => {
    const localUserInfo = localStorage.getItem('user');
    if (actionsAuth && user && selectedRole && localUserInfo) {
      setSavedRole(JSON.parse(localUserInfo).role);
    }
  }, [actionsAuth, user, selectedRole, setNewRoleArray]);

  return (
    <div
      className={`role-wrapper ${savedRole && savedRole[0] === role ? 'selected-role' : ''}`}
      onClick={setNewRole}
    >
      <img className='role-image' src={roleImage} alt='' />
      <span>{role === ROL.ADMINISTRADOR ? 'Admin' : role.toLocaleLowerCase()}</span>
    </div>
  );
};

export default RoleButton;
