import React, { FC } from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
}

const SVGLike: FC<Props> = ({ width, height, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill={color}
  >
    <path d='M11.417 6.18725L10.277 10.3083C10.1185 10.8823 9.59153 11.2833 8.99553 11.2833H4.06203V4.19475L6.24803 1.09925C6.55803 0.66025 7.27953 0.64775 7.60453 1.07725C7.76453 1.28925 7.81553 1.55725 7.74303 1.81275L7.14453 3.93125C7.10653 4.06675 7.13403 4.21275 7.21853 4.32525C7.30353 4.43775 7.43653 4.50375 7.57753 4.50375H10.1355C10.554 4.50375 10.9395 4.69475 11.193 5.02825C11.4475 5.36125 11.5285 5.78375 11.417 6.18725Z' />
    <path d='M0.534033 10.4953V5.28875C0.534033 4.85575 0.886033 4.50375 1.31903 4.50375H3.16153V11.2833H1.32153C0.887033 11.2828 0.534033 10.9298 0.534033 10.4953Z' />
  </svg>
);

export default SVGLike;
