import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface WhatsappBubbleProps {
  origin: 'user' | 'assistant';
}

export const WhatsappBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'origin',
})<WhatsappBubbleProps>(({ theme, origin }) => ({
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  maxWidth: '70%',
  borderRadius: '8px',
  backgroundColor:
    origin === 'assistant' ? theme.palette.primary.light : theme.palette.background.paper,
  color: origin === 'assistant' ? theme.palette.primary.contrastText : theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  alignSelf: origin === 'assistant' ? 'flex-end' : 'flex-start',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    [origin === 'assistant' ? 'right' : 'left']: -8,
    borderStyle: 'solid',
    borderWidth: '8px 8px 0 0',
    borderColor: `${
      origin === 'assistant' ? theme.palette.primary.light : theme.palette.background.paper
    } transparent transparent transparent`,
  },
}));
