import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { esES as mui_esES } from '@mui/material/locale';
import { esES as datepickers_esES } from '@mui/x-date-pickers/locales';

const getLightTheme = () => {
  let theme = createTheme(
    {
      palette: {
        mode: 'light',
        primary: {
          main: '#637DAF',
        },
        secondary: {
          main: '#637DAF',
        },
      },
    },
    mui_esES,
    datepickers_esES
  );

  theme = createTheme(theme, {
    palette: {
      mode: 'light',
      tertiary: theme.palette.augmentColor({
        color: {
          main: '#637DAF',
        },
      }),
      quaternary: theme.palette.augmentColor({
        color: {
          main: '#EE721C',
        },
      }),
    },
  });

  return theme;
};

const getDarkTheme = () => {
  let theme = createTheme(
    {
      palette: {
        mode: 'dark',
        primary: {
          main: '#31CFF2',
        },
        secondary: {
          main: '#7B9BD9',
        },
        background: {
          default: '#17223a',
          paper: '#213456',
        },
        divider: '#637DAF',
      },
    },
    mui_esES,
    datepickers_esES
  );

  theme = createTheme(theme, {
    palette: {
      mode: 'dark',
      tertiary: theme.palette.augmentColor({
        color: {
          main: '#28334E',
        },
      }),
      quaternary: theme.palette.augmentColor({
        color: {
          main: '#EE721C',
        },
      }),
    },
  });

  return theme;
};

export const getTheme = (mode: PaletteMode) => {
  return mode === 'light' ? getLightTheme() : getDarkTheme();
};
