import { styled, Box } from '@mui/material';

export const StyledReactQuillContainer = styled(Box)`
  .quill {
    --ql-color-black: #000;
    --ql-color-red: #e60000;
    --ql-color-orange: #f90;
    --ql-color-yellow: #ff0;
    --ql-color-green: #008a00;
    --ql-color-blue: ${({ theme }) => theme.palette.primary.main}; // #06c;
    --ql-color-purple: #93f;
    --ql-color-white: ${({ theme }) => theme.palette.background.paper}; // #fff;
    --ql-color-grey: #777;
    --ql-color-light-grey: ${({ theme }) =>
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'}; // #ccc;
    --ql-color-dark-grey: ${({ theme }) => theme.palette.text.primary}; // #444;
    --ql-color-lightest-grey: #f0f0f0;
    --ql-color-darkest-grey: #23241f;
    --ql-color-lightest-blue: #f8f8f2;
    --ql-color-placeholder: ${({ theme }) =>
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.7)'
        : 'rgba(0, 0, 0, 0.6)'}; // rgba(0, 0, 0, 0.6);
  }

  .ql-container {
    font-size: 16px;
  }

  .ql-editor {
    min-height: 100px;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
  }

  .ql-editor ul[data-checked='true'] > li::before,
  .ql-editor ul[data-checked='false'] > li::before {
    color: var(--ql-color-grey);
  }

  .ql-editor .ql-bg-black {
    background-color: var(--ql-color-black);
  }

  .ql-editor .ql-bg-red {
    background-color: var(--ql-color-red);
  }

  .ql-editor .ql-bg-orange {
    background-color: var(--ql-color-orange);
  }

  .ql-editor .ql-bg-yellow {
    background-color: var(--ql-color-yellow);
  }

  .ql-editor .ql-bg-green {
    background-color: var(--ql-color-green);
  }

  .ql-editor .ql-bg-blue {
    background-color: var(--ql-color-blue);
  }

  .ql-editor .ql-bg-purple {
    background-color: var(--ql-color-purple);
  }

  .ql-editor .ql-color-white {
    color: var(--ql-color-white);
  }

  .ql-editor .ql-color-red {
    color: var(--ql-color-red);
  }

  .ql-editor .ql-color-orange {
    color: var(--ql-color-orange);
  }

  .ql-editor .ql-color-yellow {
    color: var(--ql-color-yellow);
  }

  .ql-editor .ql-color-green {
    color: var(--ql-color-green);
  }

  .ql-editor .ql-color-blue {
    color: var(--ql-color-blue);
  }

  .ql-editor .ql-color-purple {
    color: var(--ql-color-purple);
  }

  .ql-editor.ql-blank::before {
    color: var(--ql-color-placeholder);
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: var(--ql-color-blue);
  }

  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: var(--ql-color-blue);
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--ql-color-blue);
  }

  @media (pointer: coarse) {
    .ql-snow.ql-toolbar button:hover:not(.ql-active),
    .ql-snow .ql-toolbar button:hover:not(.ql-active) {
      color: var(--ql-color-dark-grey);
    }

    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
      fill: var(--ql-color-dark-grey);
    }

    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
      stroke: var(--ql-color-dark-grey);
    }
  }

  .ql-snow .ql-stroke {
    stroke: var(--ql-color-dark-grey);
  }

  .ql-snow .ql-stroke-miter {
    stroke: var(--ql-color-dark-grey);
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--ql-color-dark-grey);
  }

  .ql-snow .ql-editor blockquote {
    border-left: 4px solid var(--ql-color-light-grey);
  }

  .ql-snow .ql-editor code,
  .ql-snow .ql-editor pre {
    background-color: var(--ql-color-lightest-grey);
  }

  .ql-snow .ql-editor pre.ql-syntax {
    background-color: var(--ql-color-darkest-grey);
    color: var(--ql-color-lightest-blue);
  }

  .ql-snow .ql-picker {
    color: var(--ql-color-dark-grey);
  }

  .ql-snow .ql-picker-options {
    background-color: var(--ql-color-white);
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: var(--ql-color-light-grey);
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: var(--ql-color-light-grey);
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: var(--ql-color-light-grey);
  }

  .ql-snow .ql-color-picker.ql-background .ql-picker-item {
    background-color: var(--ql-color-white);
  }

  .ql-snow .ql-color-picker.ql-color .ql-picker-item {
    background-color: var(--ql-color-black);
  }

  .ql-toolbar.ql-snow {
    border: 1px solid var(--ql-color-light-grey);
    border-radius: 4px 4px 0 0;
  }

  .ql-toolbar.ql-snow .ql-picker-options {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: var(--ql-color-light-grey);
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--ql-color-light-grey);
    z-index: 2;
  }

  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
    border-color: var(--ql-color-black);
  }

  .ql-snow .ql-tooltip {
    background-color: var(--ql-color-white);
    border: 1px solid var(--ql-color-light-grey);
    box-shadow: 0 0 5px #ddd;
    color: var(--ql-color-dark-grey);
  }

  .ql-snow .ql-tooltip input[type='text'] {
    border: 1px solid var(--ql-color-light-grey);
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid var(--ql-color-light-grey);
  }

  .ql-snow a {
    color: var(--ql-color-blue);
  }

  .ql-container.ql-snow {
    border: 1px solid var(--ql-color-light-grey);
    border-radius: 0 0 4px 4px;
  }
`;
