import { Box, Chip, ListItem, Typography } from '@mui/material';
import React from 'react';

import { formatDate } from 'utils/date';
import { EventItemProps } from '../types';

type EventType =
  | 'call'
  | 'meeting'
  | 'whatsapp'
  | 'getOnBoard'
  | 'sendEmail'
  | 'whatsappOnBoard'
  | 'workflowSubetapaUpdated'
  | 'unknown';

const EventColor: Record<EventType, 'default' | 'primary' | 'info' | 'success' | 'warning'> = {
  getOnBoard: 'primary',
  sendEmail: 'warning',
  call: 'success',
  meeting: 'warning',
  whatsapp: 'default',
  whatsappOnBoard: 'success',
  workflowSubetapaUpdated: 'warning',
  unknown: 'default',
};

const EventIcon: Record<EventType, string> = {
  getOnBoard: '🎯',
  sendEmail: '📧',
  call: '📞',
  meeting: '📅',
  whatsapp: '💬',
  whatsappOnBoard: '🤖',
  unknown: '🤷',
  workflowSubetapaUpdated: '🔔',
};

export const EventItemFlag = ({ event }: EventItemProps) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        my: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1.5,
          width: '100%',
        }}
      >
        <Chip
          icon={
            <Box component='span' sx={{ fontSize: '1.2rem' }}>
              {EventIcon[event.event]}
            </Box>
          }
          label={event.detail}
          color={EventColor[event.event]}
          variant='outlined'
          sx={{
            px: 2,
            py: 2.5,
            fontSize: '1rem',
            gap: 1,
            fontWeight: 'medium',
            '& .MuiChip-label': {
              px: 1,
            },
          }}
        />
        {/* <Typography
          variant='body1'
          sx={{
            color: 'text.primary',
            textAlign: 'center',
            fontWeight: 'medium',
            fontSize: '1rem',
          }}
        >
          {event.detail}
        </Typography> */}
        <Typography
          variant='caption'
          sx={{
            color: 'text.secondary',
            fontSize: '0.85rem',
          }}
        >
          {formatDate(event.createdAt)}
        </Typography>
      </Box>
    </ListItem>
  );
};
