import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import ShowRoomCandidate from '../components/pages/showRoomCandidate/ShowRoomCandidate';
import DetailsCandidate from '../components/pages/detailsCandidate/DetailsCandidate';
import ListCandidate from '../components/pages/listCandidate/ListCandidate';
import NewCandidate from '../components/pages/newCandidate/NewCandidate';
import MenuBar from '../components/ui/menu/MenuBar';
import LoginContainer from '../components/ui/login/LoginContainer';
import Login from '../components/pages/login/Login';
import Register from '../components/pages/register/Register';
import Unsubscribe from '../components/pages/unsubscribe/Unsubscribe';
import Request from 'components/pages/request/Request';
import ListRequest from 'components/pages/listRequest/ListRequest';
import Report from 'components/pages/report/Report';
import LoginStatus from 'components/ui/login/LoginStatus';
import Invitations from 'components/pages/invitations/Invitations';
import ListMentoring from 'components/pages/ListMentoring';
import Kam from 'components/pages/kam/Kam';
import Mentor from 'components/pages/mentor/mentor';
import Mentee from 'components/pages/mentee/Mentee';
import IncorporationForm from 'components/pages/incorporationForm/IncorporationForm';
import NotFound from 'components/pages/notFound/NotFound';
import Unauthorized from 'components/pages/unauthorized/Unauthorized';
import RoadmapManager from 'components/pages/roadmapManager/RoadmapManager';
import CreateTeam from 'components/pages/createTeam/CreateTeam';
import Teams from 'components/pages/teams/Teams';
import UpdateTeam from 'components/pages/updateTeam/UpdateTeam';
import Container from '@mui/material/Container';
import ROL from 'utils/Roles';
import RoomPage from 'components/pages/meeting/room/Room';

type Role = ROL | 'PUBLIC';

interface RouteItem {
  path: string;
  component: React.ComponentType;
}

interface RenderSwitchProps {
  routes: RouteItem[];
  redirectPath: string;
}

const Routes = () => {
  const { storeAuth } = useContext(AuthContext)!;
  const { user } = storeAuth;
  if (storeAuth?.isAuth && !user?.role) {
    storeAuth.isAuth = false;
  }

  const commonRoutes: RouteItem[] = [
    {
      path: '/unsubscribe',
      component: Unsubscribe,
    },
    {
      path: '/unauthorized',
      component: Unauthorized,
    },
    {
      path: '/notFound',
      component: NotFound,
    },
  ];

  const meetingRoutes: RouteItem[] = [
    {
      path: '/meeting/:idMentee/:typeOfInterview',
      component: RoomPage,
    },
  ];

  const administradorRoutes: RouteItem[] = [
    {
      path: '/kam/:idMentee',
      component: Kam,
    },
    {
      path: '/',
      component: ListCandidate,
    },
    {
      path: '/invitations',
      component: Invitations,
    },
    {
      path: '/report',
      component: Report,
    },
    {
      path: '/list/requests',
      component: ListRequest,
    },
    {
      path: '/request',
      component: Request,
    },
    {
      path: '/request/:requestId/editar',
      component: Request,
    },
    {
      path: '/candidate/new',
      component: NewCandidate,
    },
    {
      path: '/candidate/:phone/edit',
      component: NewCandidate,
    },
    {
      path: '/candidate/:phone/view',
      component: DetailsCandidate,
    },
    {
      path: '/showroom',
      component: ShowRoomCandidate,
    },
    {
      path: '/mentor',
      component: ListMentoring,
    },
    {
      path: '/kam',
      component: ListMentoring,
    },
    {
      path: '/mentored/:idMentee',
      component: Mentor,
    },
    {
      path: '/mentored/:idMentee/roadmap',
      component: RoadmapManager,
    },
    {
      path: '/mentee',
      component: Mentee,
    },
    {
      path: '/mentee/:idMentee',
      component: Mentee,
    },
    {
      path: '/teams',
      component: Teams,
    },
    {
      path: '/teams/create',
      component: CreateTeam,
    },
    {
      path: '/teams/:idMentee/edit',
      component: UpdateTeam,
    },
  ];

  const mentorRoutes: RouteItem[] = [
    {
      path: '/mentor',
      component: ListMentoring,
    },
    {
      path: '/kam',
      component: ListMentoring,
    },
    {
      path: '/kam/:idMentee',
      component: Kam,
    },
    {
      path: '/mentored/:idMentee',
      component: Mentor,
    },
    {
      path: '/mentored/:idMentee/roadmap',
      component: RoadmapManager,
    },
    {
      path: '/mentee/:idMentee',
      component: Mentee,
    },
    {
      path: '/teams',
      component: Teams,
    },
    {
      path: '/teams/create',
      component: CreateTeam,
    },
    {
      path: '/teams/:idMentee/edit',
      component: UpdateTeam,
    },
  ];

  const kamRoutes: RouteItem[] = [
    {
      path: '/mentored/:idMentee',
      component: Mentor,
    },
    {
      path: '/kam/:idMentee',
      component: Kam,
    },
    {
      path: '/teams/create',
      component: CreateTeam,
    },
    {
      path: '/teams/:idMentee/edit',
      component: UpdateTeam,
    },
    {
      path: '/teams',
      component: Teams,
    },
    {
      path: '/mentor',
      component: ListMentoring,
    },
    {
      path: '/kam',
      component: ListMentoring,
    },
    {
      path: '/mentored/:idMentee/roadmap',
      component: RoadmapManager,
    },
    {
      path: '/mentee/:idMentee',
      component: Mentee,
    },
  ];

  const usuarioRoutes: RouteItem[] = [
    {
      path: '/showroom',
      component: ShowRoomCandidate,
    },
    {
      path: '/candidate/:phone/view',
      component: DetailsCandidate,
    },
  ];

  const reclutadorRoutes: RouteItem[] = [
    {
      path: '/',
      component: ListCandidate,
    },
    {
      path: '/invitations',
      component: Invitations,
    },
    {
      path: '/report',
      component: Report,
    },
    {
      path: '/list/requests',
      component: ListRequest,
    },
    {
      path: '/request',
      component: Request,
    },
    {
      path: '/request/:requestId/editar',
      component: Request,
    },
    {
      path: '/candidate/new',
      component: NewCandidate,
    },
    {
      path: '/candidate/:phone/edit',
      component: NewCandidate,
    },
    {
      path: '/candidate/:phone/view',
      component: DetailsCandidate,
    },
    {
      path: '/showroom',
      component: ShowRoomCandidate,
    },
  ];

  const menteeRoutes: RouteItem[] = [
    {
      path: '/',
      component: Mentee,
    },
    {
      path: '/mentee/:idMentee',
      component: Mentee,
    },
  ];

  const employeeRoutes: RouteItem[] = [
    {
      path: '/',
      component: IncorporationForm,
    },
  ];

  const publicRoutes: RouteItem[] = [
    {
      path: '/',
      component: Login,
    },
    {
      path: '/register',
      component: Register,
    },
    {
      path: '/StatusRequest',
      component: LoginStatus,
    },
  ];

  const roleRoutes: Record<Role, RouteItem[]> = {
    [ROL.ADMINISTRADOR]: [...commonRoutes, ...administradorRoutes],
    [ROL.USUARIO]: [...commonRoutes, ...usuarioRoutes],
    [ROL.RECLUTADOR]: [...commonRoutes, ...reclutadorRoutes],
    [ROL.MENTOR]: [...commonRoutes, ...mentorRoutes],
    [ROL.MENTEE]: [...commonRoutes, ...menteeRoutes],
    [ROL.KAM]: [...commonRoutes, ...kamRoutes],
    [ROL.EMPLOYEE]: [...commonRoutes, ...employeeRoutes],
    PUBLIC: [...commonRoutes, ...publicRoutes],
  };

  const redirectPaths: Record<Role, string> = {
    [ROL.ADMINISTRADOR]: '/',
    [ROL.USUARIO]: '/showroom',
    [ROL.RECLUTADOR]: '/',
    [ROL.MENTOR]: '/mentored',
    [ROL.MENTEE]: '/',
    [ROL.KAM]: '/mentored',
    [ROL.EMPLOYEE]: '/',
    PUBLIC: '/',
  };

  const RenderSwitch = ({ routes, redirectPath }: RenderSwitchProps) => (
    <Container maxWidth='xl' sx={{ py: 2 }}>
      <Switch>
        {routes.map((route) => {
          return <Route exact path={route.path} component={route.component} key={route.path} />;
        })}
        <Redirect to={redirectPath} />
      </Switch>
    </Container>
  );

  const RenderRoutes = () => {
    const role: Role = storeAuth?.isAuth
      ? user?.role
        ? (user?.role[0] as ROL)
        : 'PUBLIC'
      : 'PUBLIC';
    const routes =
      role === 'PUBLIC' && window.location.pathname.includes('/meeting')
        ? [...commonRoutes, ...meetingRoutes]
        : [...meetingRoutes, ...roleRoutes[role]];
    const redirectPath = redirectPaths[role];

    return (
      <Router>
        {role === 'PUBLIC' && window.location.pathname.includes('/meeting') && (
          <RenderSwitch routes={routes} redirectPath={redirectPath} />
        )}

        {role === 'PUBLIC' && !window.location.pathname.includes('/meeting') && (
          <LoginContainer>
            <RenderSwitch routes={routes} redirectPath={redirectPath} />
          </LoginContainer>
        )}

        {role !== 'PUBLIC' && (
          <MenuBar role={role} roles={user.role}>
            <RenderSwitch routes={routes} redirectPath={redirectPath} />
          </MenuBar>
        )}
      </Router>
    );
  };

  return <RenderRoutes />;
};

export default Routes;
