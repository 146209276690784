import React, { FC, useState, useEffect } from 'react';
import { IconSelector } from './iconSelector';
import { innerSkillsInterface } from 'components/models/SkillsInterfaces';
import { CandidateInterface } from 'components/models/CandidateInterfaces';
import CircularProgress from '@mui/material/CircularProgress';
import getAxios from 'apiRestConnector';

type TSkillValidator = {
  userRol: string;
  candidateID: string;
  access_token: string;
  REACT_APP_BACKEND_URL: string;
};
type TIconSelectorProps = {
  color: 'error' | 'success';
  toolTipMessage: string;
};

export const SkillValidator: FC<TSkillValidator> = ({
  userRol,
  candidateID,
  access_token,
  REACT_APP_BACKEND_URL,
}) => {
  const [loading, setLoading] = useState(true);

  const [iconSelelectorProps, setIconSelelectorProps] = useState<TIconSelectorProps>({
    color: 'error',
    toolTipMessage: 'No se encontro al candidato',
  });
  const [candidateNotFound, setCandidateNotFound] = useState(false);
  const [candidate, setCandidate] = useState<CandidateInterface>(undefined);

  const validateSkills = () => {
    const skillsValidated: boolean[] = candidate?.skills?.map((skill) => {
      const subSkills: innerSkillsInterface[] = skill?.skills;
      if (!subSkills) return false;

      return subSkills.some((subSkill) => {
        const questions = subSkill.preguntas;
        if (!questions) return false;

        const validQuestions = questions.filter((question) => {
          if (!question.respuestas) return false;
          const answeredQuestions = question.respuestas.filter((answer) => answer.valor === true);
          return answeredQuestions.length > 0;
        });

        return validQuestions.length >= 3;
      });
    });
    const pass: boolean = skillsValidated.filter((value) => value === true).length >= 3;
    if (pass) {
      setIconSelelectorProps({
        color: 'success',
        toolTipMessage: 'El candidato cumple las habilidades',
      });
    } else {
      setIconSelelectorProps({
        color: 'error',
        toolTipMessage: 'El candidato no cumple las habilidades',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAxios
      .instance(access_token)
      .get(`${REACT_APP_BACKEND_URL}/personas/asociacion/mentee/${candidateID}`)
      .then((result) => {
        if (!result.data) {
          setCandidateNotFound(true);
        }
        setCandidate(result.data);
      })
      .catch((error) => {
        setCandidateNotFound(true);
      });
  }, [userRol, candidateID, access_token, REACT_APP_BACKEND_URL]);

  if (candidate) {
    setTimeout(validateSkills, 2000);
  }
  if (candidateNotFound) {
    return <IconSelector {...iconSelelectorProps} />;
  }
  if (loading) {
    return <CircularProgress size={20} />;
  }
  return (
    <>
      <IconSelector {...iconSelelectorProps} />
    </>
  );
};
