import styled from '@emotion/styled/macro';
import Card from 'react-bootstrap/Card';

export const StyledCard = styled(Card)`
  margin-bottom: 20px;

  .header {
    display: flex;
    justify-content: center;
  }

  .inputTextDiv {
    width: 90%;
  }

  .divText {
    margin-left: 13px;
    margin-top: 8px;
  }

  .img {
    width: 50px;
    height: 52px;
    display: flex;
    align-content: center;
  }

  .button {
    height: 40px;
    justify-items: start;
  }

  .body {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;
