import React from 'react';
import { DateTime } from 'luxon';
import { Box, Button, ButtonGroup, Typography, Divider } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { createMRTColumnHelper, type MRT_SortingFn } from 'material-react-table';
import { WorkflowStatus } from './WorkflowStatus';
import {
  getFormattedDates,
  getLastActiveStage,
  getLastActiveSubStage,
} from './CandidatesTable.helpers';
import { type WorkflowInterface } from '../../../models/WorkFlowInterfaces';
import { type CandidateRowActions } from '../../../pages/listCandidate/ListCandidate';
import { type CandidateTableRow } from './CandidatesTable.types';
import { type ContactProps } from '../../phone/ContactModal';
import { joinArrayWithCommas } from '../../../../helpers/helpers';

function rolAccessor(row: CandidateTableRow) {
  const rol = row.rol;
  return joinArrayWithCommas(rol);
}

function nivelAccessor(row: CandidateTableRow) {
  const experienceLevels = row.nivel;
  return joinArrayWithCommas(experienceLevels);
}

const etapaComparator: MRT_SortingFn<CandidateTableRow> = (rowA, rowB, columnId) => {
  const getActiveIndices = (stages: WorkflowInterface[]) => {
    const lastActiveStage = getLastActiveStage(stages);
    const lastActiveSubStage = getLastActiveSubStage(lastActiveStage);
    const lastActiveStageIndex = stages.findIndex((stage) => stage === lastActiveStage);
    const lastActiveSubStageIndex = lastActiveStage?.subEtapas?.findIndex(
      (subStage) => subStage === lastActiveSubStage
    );
    return { lastActiveStageIndex, lastActiveSubStageIndex };
  };

  const stagesA: WorkflowInterface[] = rowA.getValue(columnId);
  const stagesB: WorkflowInterface[] = rowB.getValue(columnId);

  const {
    lastActiveStageIndex: aLastActiveStageIndex,
    lastActiveSubStageIndex: aLastActiveSubStageIndex,
  } = getActiveIndices(stagesA);
  const {
    lastActiveStageIndex: bLastActiveStageIndex,
    lastActiveSubStageIndex: bLastActiveSubStageIndex,
  } = getActiveIndices(stagesB);

  if (aLastActiveStageIndex !== bLastActiveStageIndex) {
    return aLastActiveStageIndex - bLastActiveStageIndex;
  } else {
    return aLastActiveSubStageIndex - bLastActiveSubStageIndex;
  }
};

function defaultCell({ renderedCellValue }) {
  return (
    <Box
      title={renderedCellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderedCellValue}
    </Box>
  );
}

function maxWidthCell({ renderedCellValue }) {
  return (
    <Box
      title={renderedCellValue}
      sx={{
        maxWidth: 'calc(var(--col-responsable-size) * 1px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderedCellValue}
    </Box>
  );
}

function etapaCell({ renderedCellValue }) {
  const stages: WorkflowInterface[] = renderedCellValue;
  return <WorkflowStatus stages={stages} />;
}

function fechaEtapaActualCell({ renderedCellValue }) {
  const fechaEtapaActual: string = renderedCellValue;
  const { relativeDate, title } = getFormattedDates(fechaEtapaActual);

  return <Box title={title}>{relativeDate}</Box>;
}

function fechaInicioCell({ renderedCellValue }) {
  const fechaInicio: string = renderedCellValue;
  const { localeDate, title } = getFormattedDates(fechaInicio);

  return <Box title={title}>{localeDate}</Box>;
}

export function rowActions(
  actions: CandidateRowActions,
  contactCallback: (contact: ContactProps) => void
) {
  const { edit, view, contact } = actions;
  const countryFlagIconUrl = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${contact.countryCode}.svg`;

  return (
    <ButtonGroup
      size='small'
      color='tertiary'
      variant='contained'
      onClick={(e) => e.stopPropagation()}
    >
      <Button title='Editar candidato' href={edit.href}>
        <Edit />
      </Button>
      <Button title='Ver perfil de candidato' href={view.href}>
        <Visibility />
      </Button>
      <Button
        title='Contactar candidato'
        onClick={() => contactCallback(contact)}
        sx={{ display: 'flex', gap: 1 }}
      >
        <WhatsApp />
        <Box component='img' src={countryFlagIconUrl} alt='País' sx={{ width: '20px' }} />
      </Button>
    </ButtonGroup>
  );
}

export function detailPanel({ row }) {
  const stages: WorkflowInterface[] = row.original.etapa;
  const lastActiveStage = getLastActiveStage(stages);
  const lastActiveStageComments = lastActiveStage?.feedback;
  const lastActiveStageUpdatedBy = lastActiveStage?.feedbackUpdatedBy;

  return (
    <Box>
      <Box dangerouslySetInnerHTML={{ __html: lastActiveStageComments }} />
      {lastActiveStageUpdatedBy?.name && (
        <>
          <Divider />
          <Typography component='legend' variant='body2' gutterBottom mt={2}>
            Comentario actualizado por <b>{lastActiveStageUpdatedBy?.name} </b>
            <b
              title={DateTime.fromISO(lastActiveStageUpdatedBy?.date).toLocaleString({
                ...DateTime.DATETIME_FULL,
                weekday: 'long',
              })}
            >
              {DateTime.fromISO(lastActiveStageUpdatedBy?.date).toRelative()}
            </b>
          </Typography>
        </>
      )}
    </Box>
  );
}

const columnHelper = createMRTColumnHelper<CandidateTableRow>();

export const columns = [
  columnHelper.accessor('responsable', {
    header: 'Responsable',
    size: 140,
    maxSize: 140,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: maxWidthCell,
  }),
  columnHelper.accessor('nombre', {
    header: 'Nombre',
    size: 210,
    minSize: 130,
    enableSorting: true,
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: false,
    Cell: defaultCell,
  }),
  columnHelper.accessor(rolAccessor, {
    id: 'rol',
    header: 'Rol',
    size: 150,
    maxSize: 150,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: maxWidthCell,
  }),
  columnHelper.accessor(nivelAccessor, {
    id: 'nivel',
    header: 'Nivel',
    size: 140,
    maxSize: 140,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: maxWidthCell,
  }),
  columnHelper.accessor('score', {
    id: 'score',
    header: 'Score',
    size: 105,
    maxSize: 105,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: maxWidthCell,
  }),
  columnHelper.accessor('etapa', {
    header: 'Etapa',
    size: 200,
    minSize: 120,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: etapaCell,
    sortingFn: etapaComparator,
  }),
  columnHelper.accessor('fechaEtapaActual', {
    header: 'Fecha Etapa Actual',
    size: 140,
    maxSize: 140,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: fechaEtapaActualCell,
  }),
  columnHelper.accessor('fechaInicio', {
    header: 'Fecha Inicio',
    size: 100,
    maxSize: 100,
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    Cell: fechaInicioCell,
  }),
];
