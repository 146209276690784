import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Box,
  Stack,
  Paper,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Divider,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { type Objective, Status } from './MenteeView.types';
import defaultThumbnail from './assets/default-thumbnail.jpg';

const getColorFromStatus = (estado: Status) => {
  switch (estado) {
    case 'Finalizado':
      return 'success';
    case 'En progreso':
      return 'warning';
    case 'Pendiente':
      return 'error';
    default:
      return 'info';
  }
};

type MenteeActivityProps = {
  nombre: string;
  descripcion: string;
  estado: Status;
  tiempoEstimado: string;
  dedicacion_semanal: string;
  activityUrl: string;
  imageUrl: string;
};

const MenteeActivity = ({
  nombre,
  descripcion,
  estado,
  tiempoEstimado,
  dedicacion_semanal,
  activityUrl,
  imageUrl,
}: MenteeActivityProps) => {
  return (
    <Card elevation={4}>
      <CardActionArea
        href={activityUrl}
        rel='noopener noreferrer'
        target='_blank'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CardMedia component='img' height='260' image={imageUrl} alt={nombre} />
        <CardContent style={{ width: '100%', flexGrow: 1 }}>
          <Typography gutterBottom variant='h6' component='div' color='primary'>
            {nombre}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {descripcion}
          </Typography>
        </CardContent>
        <CardActions style={{ width: '100%' }}>
          {Boolean(tiempoEstimado) && (
            <Chip
              icon={<AccessTimeIcon />}
              label={`T. estimado: ${tiempoEstimado}`}
              variant='outlined'
            />
          )}
          {Boolean(dedicacion_semanal) && (
            <Chip
              icon={<AccessTimeIcon />}
              label={`H. Semanal: ${dedicacion_semanal}`}
              variant='outlined'
            />
          )}
          <Chip
            label={estado}
            color={getColorFromStatus(estado)}
            sx={{ width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}
          />
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

type MenteeObjectiveProps = {
  nombre: string;
  descripcion: string;
  estado: Status;
  tiempoEstimadoTotal: string;
  dedicacion_semanal: string;
  children?: React.ReactNode;
};

const MenteeObjective = ({
  nombre,
  descripcion,
  estado,
  tiempoEstimadoTotal,
  dedicacion_semanal,
  children,
}: MenteeObjectiveProps) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          justifyContent={{ xs: 'flex-start', md: 'space-between' }}
          gap={1}
          width='100%'
        >
          <Box>
            <Typography gutterBottom variant='h5' component='div' color='primary'>
              {nombre}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary'>
              {descripcion}
            </Typography>
          </Box>
          <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
            {Boolean(tiempoEstimadoTotal) && (
              <Chip
                icon={<AccessTimeIcon />}
                label={`Tiempo estimado total: ${tiempoEstimadoTotal}`}
                variant='outlined'
              />
            )}
            {Boolean(dedicacion_semanal) && (
              <Chip
                icon={<AccessTimeIcon />}
                label={`H. Semanales: ${dedicacion_semanal}`}
                variant='outlined'
              />
            )}
            <Chip
              label={estado}
              color={getColorFromStatus(estado)}
              sx={{ fontWeight: 'bold', textTransform: 'uppercase', mr: { md: 1 } }}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const NoDataMessage = ({ children }) => {
  return (
    <Stack alignItems='center'>
      <Alert variant='outlined' severity='warning' sx={{ maxWidth: 600, my: 6 }}>
        {children}
      </Alert>
    </Stack>
  );
};

type MenteeRoadmapProps = {
  objectives: Objective[];
};

const MenteeRoadmap = ({ objectives }: MenteeRoadmapProps) => {
  return (
    <Paper elevation={0} sx={{ borderRadius: 8, mt: 4, py: 4, px: { md: 2 } }}>
      <Typography
        variant='h2'
        component='h2'
        align='center'
        sx={{ fontFamily: 'Karasha, Roboto, serif', mb: 4 }}
      >
        Objetivos
      </Typography>
      <Stack spacing={2}>
        {Boolean(objectives?.length) ? (
          objectives.map((objective, index) => (
            <MenteeObjective
              nombre={objective.nombre}
              descripcion={objective.descripcion}
              estado={objective.estado}
              tiempoEstimadoTotal={objective.tiempo_estimado}
              dedicacion_semanal={objective.dedicacion_semanal}
              key={index}
            >
              {Boolean(objective.actividades?.length) ? (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
                    gap: 3,
                  }}
                >
                  {objective.actividades.map((activity, index) => (
                    <MenteeActivity
                      nombre={activity.nombre || activity.openGraph.ogTitle}
                      descripcion={activity.descripcion || activity.openGraph.ogDescription}
                      estado={activity.estado}
                      tiempoEstimado={activity.tiempo_estimado}
                      dedicacion_semanal={activity.dedicacion_semanal}
                      activityUrl={activity.url}
                      imageUrl={activity.openGraph.ogImage[0]?.url || defaultThumbnail}
                      key={index}
                    />
                  ))}
                </Box>
              ) : (
                <NoDataMessage>
                  El mentee no tiene actividades asignadas a este objetivo
                </NoDataMessage>
              )}
            </MenteeObjective>
          ))
        ) : (
          <NoDataMessage>El mentee no tiene objetivos asignados</NoDataMessage>
        )}
      </Stack>
    </Paper>
  );
};

export default MenteeRoadmap;
