export const createTeamTemplate = `{
  "nombre": "",
  "descripcion": "",
  "empresa": null,
  "nombre_manager": "",
  "email_manager": "",
  "lider_tecnico_ids": [],
  "integrantes_ids": [],
  "stack": []
}`;
