import SwalAlert from 'components/ui/alerts/SwalAlert';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Hook que muestra una confirmación de salida cuando el usuario intenta abandonar la página.
 *
 * @returns {void} Una función que establece si se debe mostrar o no la confirmación de salida.
 */
function useBeforeUnload(): (shouldConfirmExit: boolean) => void {
  const history = useHistory();
  const [shouldConfirmExit, setShouldConfirmExit] = useState(true);

  useEffect(() => {
    if (!shouldConfirmExit) return;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const unblock = history.block((location) => {
      SwalAlert({
        icon: 'warning',
        title: 'Cambios sin guardar',
        text: '¿Estás seguro de que deseas salir sin guardar?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Salir',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          unblock(); // Desbloquear antes de redirigir.
          history.push(location);
        }
      });

      // Se detiene el cambio de ruta hasta que el usuario tome una decisión.
      return false;
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      unblock();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldConfirmExit, history]);

  return setShouldConfirmExit;
}

export default useBeforeUnload;
