import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { logoutFirebase } from 'utils/firebase';
import getAxios from 'apiRestConnector';
import { Box, Stack, Button, Typography, Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import SwalAlert from 'components/ui/alerts/SwalAlert';

const Welcome = () => {
  const history = useHistory();
  const [company, setCompany] = useState<string>('');
  const [companies, setCompanies] = useState<string[]>([]);
  const {
    storeAuth: { token },
    actionsAuth,
  } = useContext(AuthContext)!;
  const { storeAuth } = useContext(AuthContext)!;

  const onSubmit = () => {
    if (!company) {
      SwalAlert({
        title: 'Error',
        text: 'Debes seleccionar una empresa',
        icon: 'error',
        confirmButtonText: 'Entendido',
        showConfirmButton: true,
      });
    } else {
      actionsAuth.register(token, company, history, actionsAuth.logout);
    }
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(event.target.value);
  };

  const logout = () => {
    logoutFirebase(history, actionsAuth.logout);
    window.location.reload();
  };

  useEffect(() => {
    getAxios
      .instance(storeAuth.user.access_token)
      .get('/templates/empresas')
      .then((result) => {
        setCompanies(result.data);
      });
  }, [storeAuth.user.access_token]);

  return (
    <Stack spacing={6} width='100%'>
      <Box>
        <Typography component='h1' variant='h2' fontWeight='bolder' gutterBottom>
          Bienvenido
        </Typography>
        <Typography>
          Para comenzar, selecciona tu empresa y solicita la aprobación del administrador para
          ingresar a <strong>LevelUp</strong>.
        </Typography>
      </Box>
      <Stack spacing={2}>
        {companies ? (
          <>
            <FormControl fullWidth>
              <InputLabel id='company-select-register-label'>Empresa</InputLabel>
              <Select
                label='Empresa'
                labelId='company-select-register-label'
                id='company-select-register'
                value={company}
                onChange={handleCompanyChange}
              >
                {companies.map((company, index) => (
                  <MenuItem key={index} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button size='large' variant='contained' fullWidth onClick={onSubmit}>
              Solicitar aprobación
            </Button>

            <Button size='large' variant='outlined' fullWidth onClick={logout}>
              Volver
            </Button>
          </>
        ) : (
          <>
            <Skeleton variant='rounded' width={'100%'} height={56} />
            <Skeleton variant='rounded' width={'100%'} height={42} />
            <Skeleton variant='rounded' width={'100%'} height={42} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Welcome;
