enum ROL {
  ADMINISTRADOR = 'ADMINISTRADOR',
  RECLUTADOR = 'RECLUTADOR',
  MENTEE = 'MENTEE',
  KAM = 'KAM',
  MENTOR = 'MENTOR',
  USUARIO = 'USUARIO',
  EMPLOYEE = 'EMPLOYEE',
}

export default ROL;
