import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { innerSkillsInterface, IPreguntas } from 'components/models/SkillsInterfaces';
import AnswersComp from '../answersComp/AnswersComp';
import { OptionsInterface } from '../OptionsInterface';
import { KamCardTitle, QuestionsCompContainer, QuestionsCompTitleContainer } from './style';

interface Props {
  innerSkills: innerSkillsInterface;
  setAdd: (e: IPreguntas) => void;
  options?: OptionsInterface;
}

const QuestionsComp: FC<Props> = ({ innerSkills, setAdd, options }) => {
  const theme = useTheme();

  return (
    <QuestionsCompContainer>
      {innerSkills &&
        innerSkills.preguntas?.map((preg: IPreguntas, i: number) => {
          return (
            <div key={`${preg.nombre}-${i}`}>
              <div>
                <QuestionsCompTitleContainer>
                  <KamCardTitle
                    sx={{ color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#101010' }}
                  >
                    {preg.nombre}
                  </KamCardTitle>
                </QuestionsCompTitleContainer>
                <div>
                  {options && <AnswersComp options={options} questions={preg} setAdd={setAdd} />}

                  {!options && <AnswersComp questions={preg} setAdd={setAdd} />}
                </div>
              </div>
            </div>
          );
        })}
    </QuestionsCompContainer>
  );
};

export default QuestionsComp;
