import React, { FC, useState } from 'react';
import { ArrowUpCircleFill, ArrowDownCircleFill, FunnelFill } from 'react-bootstrap-icons';
import { StyledDiv } from './style';

interface Props {
  onClick: (key: string) => void;
  state: boolean;
  onClickParameter: string;
  label: string;
}

const FilterButtonTableCard: FC<Props> = ({ onClick, onClickParameter, state, label }) => {
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [condition, setCondition] = useState<boolean>(state);

  const filter = () => {
    setShowFilter(!showFilter);
    onClick(onClickParameter);
  };

  return (
    <StyledDiv>
      Ordernar por {label}
      {showFilter ? (
        <span>
          <FunnelFill onClick={filter} />
        </span>
      ) : (
        <span
          onClick={() => {
            onClick(onClickParameter);
            setCondition(!condition);
          }}
        >
          {condition ? <ArrowUpCircleFill /> : <ArrowDownCircleFill />}
        </span>
      )}
    </StyledDiv>
  );
};

export default FilterButtonTableCard;
