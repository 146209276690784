import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { createMRTColumnHelper } from 'material-react-table';
import { getFormattedDates, GenerateMenteeUrl } from './MentoringTable.helpers';
import { MentoringData } from './MentoringTable.types';

function defaultCell({ renderedCellValue }) {
  return (
    <Box
      title={renderedCellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderedCellValue}
    </Box>
  );
}

/*function numberCell({ renderedCellValue }) {
  return (
    <Box
      title={renderedCellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderedCellValue}
    </Box>
  );
}*/

function stringArrayCell({ renderedCellValue }) {
  const cellValue = Array.isArray(renderedCellValue) ? renderedCellValue.join(', ') : '';
  return (
    <Box
      title={cellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {cellValue}
    </Box>
  );
}

function menteeCell({ renderedCellValue, row }) {
  const url = GenerateMenteeUrl(row.original.id);
  return (
    <Link
      component={RouterLink}
      to={url}
      underline='none'
      title={renderedCellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderedCellValue}
    </Link>
  );
}

/*function ratingCell({ renderedCellValue }) {
  return (
    <Box
      title={renderedCellValue}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
      }}
    >
      <Rating name='skill' value={renderedCellValue ? renderedCellValue : 0} />
    </Box>
  );
}
*/
function fechaCell({ renderedCellValue }) {
  const fecha: string = renderedCellValue;
  const { localeDate, title } = getFormattedDates(fecha);

  return <Box title={title}>{localeDate}</Box>;
}

const columnHelper = createMRTColumnHelper<MentoringData>();

export const columns = [
  columnHelper.accessor('mentee', {
    header: 'Colaborador',
    enableSorting: true,
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: false,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: menteeCell,
  }),
  columnHelper.accessor('teams', {
    header: 'Equipos',
    enableSorting: true,
    enableGlobalFilter: true,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    enableColumnFilter: true,
    enableHiding: true,
    Cell: stringArrayCell,
  }),
  columnHelper.accessor('lastMentor', {
    header: 'Ultimo mentor',
    enableSorting: true,
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: defaultCell,
  }),
  columnHelper.accessor('lastMentorUpdate', {
    header: 'Ultima actualización',
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: false,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: fechaCell,
  }),
  columnHelper.accessor('rol', {
    header: 'Rol',
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    muiTableHeadCellProps: {
      align: 'left',
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      align: 'left',
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: stringArrayCell,
  }),
  columnHelper.accessor('belt', {
    header: 'Cinturon',
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '50px',
        width: '50px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '50px',
        width: '50px',
      },
    },
    Cell: defaultCell,
  }),
  columnHelper.accessor('creatorName', {
    header: 'Creador',
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    muiTableHeadCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: defaultCell,
  }),
  columnHelper.accessor('creationDate', {
    header: 'Fecha creación',
    enableSorting: true,
    enableGlobalFilter: false,
    enableColumnFilter: true,
    enableHiding: true,
    muiTableHeadCellProps: {
      align: 'justify',
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    muiTableBodyCellProps: {
      align: 'justify',
      sx: {
        flex: 1,
        minWidth: '100px',
      },
    },
    Cell: fechaCell,
  }),
];
