import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  cameraAdvisoryDescription,
  cameraAdvisoryTitle,
  cameraIcon,
  cameraOff,
  identityBox,
  isNotSpeakingStyle,
  isSpeakingStyle,
  videoBox,
} from './styles';
import { UserInterface } from 'components/models/UserInterfaces';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

interface StreamProps {
  stream: MediaStream;
  isMicOff: boolean;
  isCameraOn: boolean;
  user: UserInterface;
}

const StreamPlayer: React.FC<StreamProps> = ({ stream, isMicOff, isCameraOn, user }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const theme = useTheme();

  const analyzeAudio = useCallback((stream: MediaStream, setSpeaking: (arg0: boolean) => void) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    source.connect(analyser);
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const checkAudio = () => {
      analyser.getByteFrequencyData(dataArray);
      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i];
      }
      const average = sum / dataArray.length;

      // 15 es el umbral para detectar que el usuario está hablando
      setSpeaking(average > 15); // Umbral de detección de sonido, ajustar segn sea necesario
    };

    const intervalId = setInterval(checkAudio, 100);
    return () => {
      clearInterval(intervalId);
      audioContext.close();
    };
  }, []);

  useEffect(() => {
    if (stream) {
      const cleanup = analyzeAudio(stream, setIsSpeaking);
      return cleanup;
    }
  }, [stream, analyzeAudio]);

  return stream ? (
    <Box
      sx={{
        ...videoBox,
        ...(isSpeaking ? isSpeakingStyle : isNotSpeakingStyle),
        boxSizing: 'border-box',
      }}
    >
      <ReactPlayer
        playing={true}
        muted={isMicOff}
        volume={isMicOff ? 0 : 1}
        width={isCameraOn ? '100%' : '0%'}
        height={isCameraOn ? '100%' : '0%'}
        minwidth={isCameraOn ? '50%' : '0%'}
        url={stream}
        pip={false}
        controls={false}
        playsinline={true}
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controls: false,
              controlsList: 'nodownload noremoteplayback nofullscreen',
              disableRemotePlayback: true,
            },
          },
        }}
      />

      <Box sx={identityBox}>
        <Typography style={{ color: 'white', fontSize: '0.75rem' }}>{user?.name}</Typography>
        <Typography style={{ color: 'white', fontSize: '0.5rem' }}>{user?.email}</Typography>
      </Box>

      {!isCameraOn && (
        <Box sx={cameraOff}>
          <VideocamOffIcon
            sx={{ ...cameraIcon, [theme.breakpoints.up('md')]: { fontSize: '5rem' } }}
          />
          <Typography
            sx={{ ...cameraAdvisoryTitle, [theme.breakpoints.up('md')]: { fontSize: '1.5rem' } }}
          >
            Transmisión de Video desactivada <br />
            <Typography
              sx={{
                ...cameraAdvisoryDescription,
                [theme.breakpoints.up('md')]: { fontSize: '1rem' },
              }}
            >
              El led de la cámara permanecerá encendido, pero no se enviará la transmisión de video
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        ...videoBox,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default StreamPlayer;
